import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { logEvent } from 'Shared/actions/event';
import { selectUserType } from 'Shared/actions/signup';
import { USER_TYPES } from 'Shared/types/shared';
import { GA_EVENTS, SIGNUP } from 'Shared/types/events';

export function useUserTypeSelection() {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();

  const handleClick = (userType: USER_TYPES) => {
    void logEvent({
      page: SIGNUP.USER_TYPE_SELECTED,
      gaEvent: GA_EVENTS.EVENT,
      nonInteraction: false,
    })(dispatch, store.getState.bind(store));

    selectUserType(userType)(dispatch);
  };

  useEffect(() => {
    void logEvent({
      page: SIGNUP.USER_TYPE_SELECTION,
      gaEvent: GA_EVENTS.PAGEVIEW,
      nonInteraction: true,
    })(dispatch, store.getState.bind(store));
  }, []);

  return { handleClick };
}
