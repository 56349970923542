import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from 'Shared/types/shared';

export function useLoginStatusBar() {
  const authUser = useSelector((state: RootState) => state.user.data);
  const { pathname } = useLocation();
  const isInSubscriptionProcess = pathname === '/subscribe/' || pathname === '/subscribe/success';
  const isInDischargeHomeworkActivation = pathname.includes('/activate/homework-invitation');

  return {
    authUser,
    isInSubscriptionProcess,
    isInDischargeHomeworkActivation,
  };
}
