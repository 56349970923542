import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';

import { REPORT_TYPES } from 'Shared/constants';
import { Spinner } from 'Shared/components/spinner';
import { SUPPORT } from 'Shared/constants';
import { User } from 'Shared/types/shared';

import { instanceOfPerformanceReport } from '../../shared/reportUtils';

import { useNote } from './shared/useNote';
import { NoteActions } from './shared/actions';
import { PerformanceAndDischargeReport } from './shared/performanceAndDischargeReport';

import sharedStyles from './shared/note.module.scss';

interface DischargeSummaryProps {
  patient: User;
}

export const DischargeSummary: FC<DischargeSummaryProps> = ({ patient }) => {
  const {
    report,
    componentRef,
    textToCopy,
    handleCopy,
    isCopied,
    handlePrint,
    popper,
    arrow,
    setReferenceElement,
  } = useNote({
    patient,
    reportType: REPORT_TYPES.DISCHARGE_SUMMARY,
  });

  if (!report) {
    return <Spinner />;
  }

  if (!instanceOfPerformanceReport(report)) {
    return null; //TODO:
  }

  return (
    <div className={sharedStyles.note} ref={componentRef}>
      <header className={sharedStyles.preHeader}>
        <h2 className={classNames('h2', sharedStyles.preHeaderTitle)}>
          Continued Home Practice Discharge Instructions
        </h2>
        <NoteActions
          textToCopy={textToCopy}
          handleCopy={handleCopy}
          handlePrint={handlePrint}
          setReferenceElement={setReferenceElement}
          isCopied={isCopied}
          popper={popper}
          arrow={arrow}
        />
        <CTUI.Font.Text>
          Constant Therapy is available for patients to use for continued, independent practice at
          home through a monthly or annual subscription program. You can download Constant Therapy
          to Apple, Android, and Kindle Fire touchscreen tablets and smartphones by going to your
          device’s app store and searching for “Constant Therapy” The program will pick up right
          where the patient left off. Contact Constant Therapy’s customer support at{' '}
          {SUPPORT.PHONE_FORMATTED} or at {SUPPORT.EMAIL} for help setting up your home practice
          program.
        </CTUI.Font.Text>
      </header>
      <header className={sharedStyles.header}>
        <h1 className={classNames('h1', sharedStyles.preHeaderTitle)}>
          <FontAwesomeIcon icon={faFileLines} className={sharedStyles.mainIcon} />
          Discharge summary
        </h1>
        <h2 className={classNames('h2', sharedStyles.headerSubTitle)}>
          This report is to help therapists or other healthcare providers update their records.
        </h2>
        <CTUI.Font.H4 variant="medium">User activity report - compared to baseline</CTUI.Font.H4>
      </header>
      <header className={sharedStyles.patientInfo}>
        <h2 className={classNames('h2', sharedStyles.patientInfoTitle)}>Patient Data</h2>
        <CTUI.Font.H4 variant="medium">User name: {report.patientName}</CTUI.Font.H4>
        <CTUI.Font.H4 variant="medium">Password: ___________________</CTUI.Font.H4>
        <CTUI.Font.Text customClasses={sharedStyles.patientInfoDescription}>
          Patient completed various Constant Therapy tasks during speech-language pathology{' '}
          treatment session, as summarized below:
        </CTUI.Font.Text>
      </header>
      <PerformanceAndDischargeReport sections={report.sections} />
    </div>
  );
};
