import React, { FC } from 'react';
import classNames from 'classnames';

import { BE_ERRORS_LIST } from 'Shared/constants';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { useValidateUserModal } from './useValidateUserModal';
import { ValidateUserForm } from './validateUserForm';

import styles from './validateUserModal.module.scss';

interface ValidateUserModalProps {
  externalError?: string;
}

export const ValidateUserModal: FC<ValidateUserModalProps> = ({ externalError }) => {
  const { handleSubmit, emailOrUsername } = useValidateUserModal();
  const customError =
    'Sorry, you must already have an account to subscribe. Please <a' +
    ' href="/signup">create an account</a> or try again.';
  const knownErrors = [
    BE_ERRORS_LIST.NO_USER_FOUNT_TOKEN_ERROR,
    BE_ERRORS_LIST.NO_USER_FOUNT_EMAIL_ERROR,
    BE_ERRORS_LIST.NO_USER_FOUNT_USERNAME_ERROR,
  ];

  return (
    <FloatingPage
      customClasses={styles.validateUserModal}
      header={<h1 className={classNames('h1', styles.title)}>Buy home subscription</h1>}
    >
      <ValidateUserForm
        initialValues={{ emailOrUsername }}
        onSubmit={handleSubmit}
        externalError={knownErrors.includes(externalError) ? customError : externalError}
      />
    </FloatingPage>
  );
};
