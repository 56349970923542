import React, { FC } from 'react';

import { SUPPORT } from 'Shared/constants';
import { Spinner } from 'Shared/components/spinner';
import { PageError } from 'Shared/components/pageError';

import { useActivation } from './useActivation';
import { ActivationForm } from './activationForm';
import { Confirmation } from './confirmation';

const authorizationError = `
  <p>
    Something went wrong and we weren&apos;t able to identify your Constant Therapy account.
    <br />
    <br /> 
    Please reach out to Customer Support at
    <a href="tel:${SUPPORT.PHONE}">${SUPPORT.PHONE_FORMATTED}</a> or
    <a href="mailto:${SUPPORT.EMAIL}">${SUPPORT.EMAIL}</a> to proceed.
    We apologize for the inconvenience.
  </p>`;

export const HomeworkInvitationActivation: FC = () => {
  const { isLoading, preAuthorizedUser, isActivated, handleActivation, action } = useActivation();

  if (isLoading) {
    return <Spinner />;
  }

  if (!preAuthorizedUser) {
    return <PageError error={{ title: ' ', message: authorizationError }} />;
  }

  if (isActivated) {
    return <Confirmation preAuthorizedUser={preAuthorizedUser} />;
  }

  return (
    <ActivationForm
      preAuthorizedUser={preAuthorizedUser}
      initialValues={{
        email: preAuthorizedUser.user.email,
        action,
      }}
      onSubmit={handleActivation}
    />
  );
};
