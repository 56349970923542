import React, { FC } from 'react';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import step1Image from 'Shared/src/images/1.png';
import step2Image from 'Shared/src/images/2.png';
import step3Image from 'Shared/src/images/3.png';

import sharedStyles from './shared.module.scss';
import styles from './guide.module.scss';

export const Guide: FC = () => {
  return (
    <FloatingPage
      customClasses={sharedStyles.forgotPasswordPage}
      header={<h1 className="h1">Account Information Sent</h1>}
    >
      <dl className={styles.guideList}>
        <dt>
          <img src={step1Image} alt="Step 1" title="Step 1" />
        </dt>
        <dd>Please check your email for your login information.</dd>
        <dt>
          <img src={step2Image} alt="Step 2" title="Step 2" />
        </dt>
        <dd>Click on the CT app on your iPad and continue using Constant Therapy!</dd>
        <dt>
          <img src={step3Image} alt="Step 3" title="Step 3" />
        </dt>
        <dd>Please change your password the next time you login.</dd>
      </dl>
    </FloatingPage>
  );
};
