import React, { FC } from 'react';
import classNames from 'classnames';

import { convertDatetimeToUTC } from 'Shared/utils';
import { PerformanceReportDomain } from 'Shared/types/shared';

import styles from './chartLegend.module.scss';

interface ChartLegendProps {
  skillArea: PerformanceReportDomain;
}

export const ChartLegend: FC<ChartLegendProps> = ({ skillArea: { baseline, latest } }) => {
  const baselineDate = convertDatetimeToUTC(baseline.date.dateTime.date);
  const latestDate = convertDatetimeToUTC(latest.date.dateTime.date);
  const diff = latest.value - baseline.value;
  const latestClassName = classNames(styles.latest, {
    [styles.negative]: diff < 0,
  });

  return (
    <ul className={styles.chartLegend}>
      <li className={styles.baseline}>
        <b>Baseline {baseline.value}</b> ({baselineDate.format('MM/DD/YYYY')}){' '}
        <i>{baseline.description}</i>
      </li>
      <li className={latestClassName}>
        <b>Latest {latest.value}</b> ({latestDate.format('MM/DD/YYYY')}) <i>{latest.description}</i>
      </li>
    </ul>
  );
};
