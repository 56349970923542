import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Main } from 'App/pages/main';

import { DigitalClinic } from './digitalClinic';

export const Pages: FC = (props) => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Main {...props} />} />
      <Route
        exact
        path="/therapist-consult"
        render={(routerProps) => <DigitalClinic {...routerProps} {...props} />}
      />
    </Switch>
  );
};
