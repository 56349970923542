import { MouseEvent, useState } from 'react';
import { evaluate } from 'mathjs';
import { result } from 'lodash';

const maxAllowedCharacters = 20;

export function useCalculator() {
  const [expression, setExpression] = useState('');

  const handleUpdateExpression = (e: MouseEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.value;
    //If state is empty and the first input is not a number or `.` character - just ignore it
    if (!expression.length && value !== '.' && isNaN(parseInt(value))) {
      return;
    }

    //Do not allow to enter more than `maxAllowedCharacters` chars
    if (expression.length === maxAllowedCharacters) {
      return;
    }

    setExpression((prevState) => {
      return prevState.concat(value);
    });
  };

  const handleClearExpression = () => {
    setExpression('');
  };

  const handleEvaluateExpression = () => {
    if (expression.length) {
      setExpression((prevState) => {
        const result = (evaluate(prevState) as number).toString();
        if (result.length > maxAllowedCharacters) {
          return result.slice(0, maxAllowedCharacters);
        }
        return result;
      });
    }
  };

  const handleDeleteFromExpression = () => {
    if (expression.length) {
      setExpression((prevState) => {
        return prevState.slice(0, prevState.length - 1);
      });
    }
  };

  return {
    expression,
    handleUpdateExpression,
    handleClearExpression,
    handleEvaluateExpression,
    handleDeleteFromExpression,
  };
}
