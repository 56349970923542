import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { logEvent } from 'Shared/actions/event';
import { SUBSCRIPTION } from 'Shared/types/events';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { SubscriptionPlansResponse, User } from 'Shared/types/shared';
import { getUserDataNoAuth } from 'Shared/actions/user';
import { getSubscriptionPlans } from 'Shared/actions/subscription';

import { CouponForm } from '../constants';

export interface PlansListProps {
  displayName: string;
  token: string;
}

export function usePlansList({ displayName, token }: PlansListProps) {
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();
  const queryParams = useQueryParams();
  const source = queryParams.get('source');

  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionPlansData, setSubscriptionPlansData] = useState<SubscriptionPlansResponse>();
  const [shouldSkipCoupon, setShouldSkipCoupon] = useState(false);

  const isProgressMail = source && source === 'progress_mail';
  const isSignupFlow = source && source === 'signup';
  const coupon = queryParams.get('cc');
  const emailOrUsername = queryParams.get('emailOrUsername');

  const choosePlanLater = async () => {
    try {
      await logEvent({ page: SUBSCRIPTION.CHOOSE_PLAN_LATER, data: { source } })(
        dispatch,
        store.getState.bind(store)
      );
    } catch (e: unknown) {
      console.log(e);
    } finally {
      history.push('/dashboard');
    }
  };

  const handleCouponSubmit = (formData: CouponForm) => {
    const qsParams = {
      ...queryString.parse(document.location.search),
      cc: formData.coupon,
    };
    //TODO: Find out why useEffect is not triggered on value change of url-search params...
    // history.push(document.location.pathname + '?' + queryString.stringify(qsParams));
    window.location.assign(document.location.pathname + '?' + queryString.stringify(qsParams));
  };

  const fetchSubscriptionPlans = async () => {
    let userIdentifier = emailOrUsername;
    try {
      if (token) {
        const preAuthorizedUser = (await getUserDataNoAuth(token)(dispatch)) as User;
        if (preAuthorizedUser) {
          userIdentifier = preAuthorizedUser.user.email || preAuthorizedUser.user.username;
        }
      }
      const subscriptionPlansData = await getSubscriptionPlans({
        emailOrUsername: userIdentifier,
        couponCode: coupon?.toLowerCase(),
        referrerPage: queryParams.get('source'),
      })(dispatch);
      setSubscriptionPlansData(subscriptionPlansData);
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchSubscriptionPlans();
    void logEvent({
      page: SUBSCRIPTION.PLANS_LIST,
      token,
      data: {
        displayName,
        source,
        isProgressMail,
        coupon,
        isSignupFlow,
        emailOrUsername,
      },
    })(dispatch, store.getState.bind(store));
  }, []);

  useEffect(() => {
    if (
      coupon &&
      subscriptionPlansData &&
      subscriptionPlansData.couponMessage &&
      !subscriptionPlansData.couponMessageSuccess
    ) {
      setShouldSkipCoupon(true);
    } else {
      setShouldSkipCoupon(false);
    }
  }, [coupon, subscriptionPlansData]);

  return {
    isLoading,
    isProgressMail,
    isSignupFlow,
    coupon,
    emailOrUsername,
    subscriptionPlansData,
    handleChoosePlanLater: (e: SyntheticEvent & { target: HTMLAnchorElement }) => {
      e.preventDefault();
      e.persist();
      void choosePlanLater();
    },
    handleCouponSubmit,
    shouldSkipCoupon,
  };
}
