import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { TableColumn } from 'react-data-table-component';
import { Dispatch } from 'redux';

import { getUsersList } from 'Shared/actions/institution';
import type { RootState, InstitutionUser } from 'Shared/types/shared';

import { PreviewCell } from './previewCell';
import { StatusCell } from './statusCell';
import { ActionsCell } from './actionsCell';

export interface UsersListProps {
  institutionId: number;
}

export function useUsersList({ institutionId }: UsersListProps) {
  const dispatch: Dispatch = useDispatch();
  const filteredUsersList = useSelector((state: RootState) => state.institution.filteredUsersList);
  const [isLoading, setIsLoading] = useState(true);

  const columns: TableColumn<InstitutionUser>[] = useMemo(() => {
    return [
      {
        name: '',
        selector: (row) => row.preview,
        grow: 0,
        width: '67px',
        // eslint-disable-next-line react/display-name
        cell: (row) => <PreviewCell {...row} />,
      },
      {
        name: 'Name',
        selector: (row) => row.firstName,
        sortable: true,
        compact: true,
        grow: 2,
        format: (row) => {
          const fullName = [];
          if (row.firstName) {
            fullName.push(row.firstName);
          }
          if (row.lastName) {
            fullName.push(row.lastName);
          }
          return fullName.join(' ');
        },
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Status',
        sortable: true,
        grow: 1,
        selector: (row) => row.statusDisplay,
        cell: (row) => <StatusCell {...row} />,
      },
      {
        name: 'Type',
        selector: (row) => row.type,
        sortable: true,
        grow: 0,
      },
      {
        name: 'Last sign in',
        selector: (row) => row.lastSignInDate,
        sortable: true,
        grow: 2,
        format: (row) => {
          return row.lastSignInDate ? moment(row.lastSignInDate, 'X').fromNow() : 'not logged in';
        },
      },
      {
        name: '',
        grow: 0,
        width: '65px',
        center: true,
        cell: (row) => <ActionsCell {...row} institutionId={institutionId} />,
      },
    ];
  }, [institutionId]);

  const fetchUsersList = useCallback(async () => {
    try {
      await getUsersList(institutionId)(dispatch);
    } catch (e: unknown) {
      throw new Error(e.toString());
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    void fetchUsersList();
  }, []);

  return {
    isLoading,
    columns,
    filteredUsersList,
  };
}
