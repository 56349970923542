import { MouseEvent, useState } from 'react';

export interface ControlsProps {
  showScratchpad: boolean;
  showCalculator: boolean;
}

export function useControls({ showScratchpad, showCalculator }: ControlsProps) {
  const [activeTab, setActiveTab] = useState(showScratchpad ? 1 : 2);

  const handleSwitchActiveTab = (e: MouseEvent<HTMLButtonElement>) => {
    setActiveTab(parseInt(e.currentTarget.value));
  };

  return { activeTab, handleSwitchActiveTab };
}
