import { useCallback, useRef } from 'react';
import { Moment } from 'moment-timezone';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';

export interface ProgressAndDischargePopupProps {
  patientId: string;
  closePopupHandler: () => void;
  isProgressNote?: boolean;
}

export function useProgressAndDischargePopup({
  patientId,
  closePopupHandler,
  isProgressNote,
}: ProgressAndDischargePopupProps) {
  const modalRef = useRef(null);
  const { isPortrait } = useMobileOrientation();

  const numberOfMonths = isPortrait && isMobileOnly ? 1 : 2;
  const noteType = isProgressNote ? 'progress-note' : 'discharge-summary';

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      closePopupHandler();
    }
  });

  // selected end date should be included or not? currently endDate.startOf('day') its not included.
  const applyChanges = useCallback(
    (startDate: Moment, endDate: Moment) => {
      window.open(
        `/dashboard/patient/${patientId}` +
          `/${noteType}/${startDate.format('YYYY-MM-DD')}/` +
          `${endDate.format('YYYY-MM-DD')}`
      );
    },
    [patientId, noteType]
  );

  return {
    modalRef,
    numberOfMonths,
    applyChanges,
  };
}
