import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { SUPPORT } from 'Shared/constants';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import sharedStyles from '../shared.module.scss';

export const Guide: FC = () => {
  return (
    <FloatingPage customClasses={sharedStyles.unsubscribe}>
      <h1 className={classNames('h1', sharedStyles.title)}>You have successfully unsubscribed</h1>
      <p>
        <b>Please Note:</b> Your subscription has been successfully cancelled. No charges will be
        applied to your credit card. If you wish to return, please contact our support at{' '}
        <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a> or reactivate your
        subscription by visiting your <Link to="/account">account</Link> page.
      </p>
    </FloatingPage>
  );
};
