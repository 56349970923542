import { useState, useEffect, SyntheticEvent } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { doNotContact } from 'Shared/actions/user';
import { logEvent } from 'Shared/actions/event';
import { DO_NOT_CONTACT } from 'Shared/types/events';
import { DEFAULT_ERROR } from 'Shared/constants';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { DoNotContactResponse } from 'Shared/types/api';
import { User } from 'Shared/types/shared';

export interface DoNotContactProps {
  authUser: User;
}

export function useDoNotContact({ authUser }: DoNotContactProps) {
  const store = useStore();
  const dispatch: Dispatch = useDispatch();
  const queryParams = useQueryParams();
  const token = queryParams.get('token');
  const [isLoading, setIsLoading] = useState(false);
  const [doNotContactData, setDoNotContactData] = useState<DoNotContactResponse>();
  const [errorMessage, setErrorMessage] = useState<{ title: string; message: string }>(
    !token &&
      !authUser && {
        title: 'Error: We were not able to identify you',
        message: DEFAULT_ERROR,
      }
  );

  const handleDoNotContact = async () => {
    void logEvent({ page: DO_NOT_CONTACT, token })(dispatch, store.getState.bind(store));

    try {
      const doNotContactResponse = (await doNotContact({ token })(
        dispatch
      )) as DoNotContactResponse;
      if (
        doNotContactResponse.error &&
        (doNotContactResponse.error === 'no-token' ||
          doNotContactResponse.error === 'no-user-found-token-error')
      ) {
        setErrorMessage({
          title: 'Error: We were not able to identify you',
          message: DEFAULT_ERROR,
        });
      } else {
        setDoNotContactData(doNotContactResponse);
      }
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    token,
    isLoading,
    errorMessage,
    doNotContactData,
    handleDoNotContact,
  };
}
