import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Welcome to your Patient Dashboard, where you can 
  review progress notes, stay on schedule with your therapy calendar, and power your recovery 
  journey with personalized data and analytics.`;
  return (
    <Helmet>
      <title>Patient Dashboard | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
