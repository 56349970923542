import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Love your Constant Therapy program but need more 
  time to explore? Extend your trial today! Get more time using our premium features without any 
  commitment so you can discover more and decide better.
`;
  return (
    <Helmet>
      <title>Extend Your Trial | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
