import { useEffect, useState } from 'react';

export function useBackToTop() {
  const [shouldShowBackToTop, setShouldShowBackToTop] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 300) {
        setShouldShowBackToTop(true);
      } else {
        setShouldShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    scrollToTop,
    shouldShowBackToTop,
  };
}
