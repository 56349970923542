import React, { FC } from 'react';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

import { PerformanceReportDomain } from 'Shared/types/shared';

import styles from './chartPointer.module.scss';

interface ChartPointerProps {
  skillArea: PerformanceReportDomain;
}

export const ChartPointer: FC<ChartPointerProps> = ({ skillArea: { latest, nextMilestone } }) => {
  const maxValue = 100;

  return (
    <div className={styles.chartPointer}>
      {nextMilestone.value && latest.value !== maxValue ? (
        <dl className={styles.milestone} style={{ left: `${nextMilestone.value}%` }}>
          <dt>Next Milestone: {nextMilestone.value}</dt>
          <dd>
            <FontAwesomeIcon icon={faArrowCircleDown} className={styles.icon} />
            <Truncate>{nextMilestone.description}</Truncate>
          </dd>
        </dl>
      ) : (
        <dl className={styles.milestone} style={{ left: '100%' }}>
          <dt>Done!</dt>
          <dd>
            <FontAwesomeIcon icon={faArrowCircleDown} className={styles.icon} />
            <Truncate>Skill area completed</Truncate>
          </dd>
        </dl>
      )}
    </div>
  );
};
