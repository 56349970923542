import React, { FC } from 'react';

import type { NoneStimulus } from 'Shared/types/shared';

import styles from './none.module.scss';

interface NoneComponentProps {
  stimulus: NoneStimulus;
  value?: string;
}

export const NoneComponent: FC<NoneComponentProps> = ({ stimulus, value }) => {
  return <div className={styles.none}>{value || ''}</div>;
};
