import _ from 'lodash';
import _fpFlow from 'lodash/fp/flow';
import _fpMapValues from 'lodash/fp/mapValues';
import _fpOmitBy from 'lodash/fp/omitBy';
import fetch from 'isomorphic-fetch';

export const AT_LEAST_ONE_ALPHANUMERIC = /^(?=.*[a-zA-Z0-9]).+$/i;

export const defaultValidators = {
  required(value) {
    const trimmedValue = _.trim(value);
    if (_.isNil(trimmedValue) || trimmedValue === '') {
      return 'error';
    }
    return null;
  },
  decimal(value) {
    const regexp = /^\d+(\.\d{1,2})?$/;
    return regexp.test(value) ? null : 'Please enter a valid input.';
  },
  atLeastOneAlphanumeric(value) {
    return AT_LEAST_ONE_ALPHANUMERIC.test(value) ? null : 'error';
  },
  allAlphanumeric(value) {
    const regexp = /^[\w.]+$/i;
    return regexp.test(value) ? null : 'Must contain only letters digits, underscores and periods';
  },
  integer(value) {
    const regexp = /^[0-9]+$/i;
    return regexp.test(value) ? null : 'Please enter a valid input.';
  },
  birthyear(value) {
    return 1900 <= value && value <= (new Date()).getUTCFullYear() - 5 ?
      null :
      'Please enter a valid birth year';
  },
  phone(value) {
    const regexp = /^\d{3}[-]\d{3}[-]\d{4}$/i;
    //We don't validate if value is empty
    if (!value || !value.trim().length) {
      return null;
    }
    return regexp.test(value) ? null : 'Please enter a valid phone number, e.g 123-456-7890';
  },
  zip(value) {
    const regexp = /^[0-9]+$/i;
    return regexp.test(value) ? null : 'Please enter a valid ZIP code.';
  },
  email(value) {
    const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return regexp.test(value) ? null : 'Enter a valid email address.';
  },
  pass(value) {
    const regexp = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regexp.test(value) ?
      null :
      'The password should include at least 8 characters (1 Capital, 1 Digit).';
  },
  url(value) {
    const regexp = /^(http|https):\/\/[^ "]+$/;
    const trimmedValue = _.trim(value);
    if (!trimmedValue) {
      return null;
    }
    return regexp.test(trimmedValue) ?
      null :
      'Please enter a valid URL -  should start with http:// or https:// without spaces.';
  },
  percentage(value) {
    const regexp = /^(?=.*[1-9])\d{0,3}(?:\.\d{0,2})?$/;
    return regexp.test(value);
  },
  disorders(value) {
    if (_.isEmpty(value)) {
      return 'Please choose at least one condition.';
    }
    if (!_.some(Object.values(value), value => value)) {
      return 'Please choose at least one condition.';
    }
    return null;
  },
  deficits(value) {
    if (_.isEmpty(value)) {
      return 'Please choose at least one area.';
    }
    if (!_.some(Object.values(value), value => value)) {
      return 'Please choose at least one area.';
    }
    return null;
  },
  step(value) {
    return this.required(value);
  }
};

export const validateSync = (values, config) => (_fpFlow(
  _fpMapValues.convert({ cap: false })((validationType, fieldName) => {
    const value = _.get(values, fieldName);

    if (_.isString(validationType)) {
      return defaultValidators[validationType](value);
    }

    if (_.isFunction(validationType)) {
      return validationType(value, values);
    }

    if (_.isArray(validationType)) {
      return _.reduce(validationType, (prevValidation, innerValidation) =>
        prevValidation || validateSync(values, {
          [fieldName]: innerValidation,
        })[fieldName]
      , undefined);
    }
    throw new Error();
  }),
  _fpOmitBy(_.isNil)
))(config);

export const validateAsync = (values, config) => {
  return new Promise(
    (resolve, reject) => {
      Promise.all(Object.values(validateSync(values, config)))
             .then(errors => {
               return _.isEmpty(errors) ? resolve() : reject(errors);
             }, errors => {
               return _.isEmpty(errors) ? resolve() : reject(errors);
             });

    });
  // return Object.values(validateSync(values, config)).reduce((promiseChain, currentTask) => {
  //   return promiseChain.then(chainResults =>
  //     currentTask.then(currentResult =>
  //       [ ...chainResults, currentResult ]
  //     )
  //   );
  // }, Promise.resolve([])).then(null, arrayOfResults => {
  //   console.log(arrayOfResults);
  // });
};

// eslint-disable-next-line no-unused-vars
export const isMailExists = (values, dispatch, props, field) => {
  // The check for `props.isEmailLocked` is a hacky way to skip onBlur async validation for
  // email field
  if (!values.email || props.isEmailLocked) {
    return null;
  }

  return fetch('/api/user/null/validate', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: JSON.stringify({
      email: values.email
    })
  })
    .then(res => res.json())
    .then(result => {
      if (result.exists && result.exists !== 'false') {
        switch (result.exists) {
          case 'true':
            throw { email: 'The email provided is already in use' };
          case 'invalid':
            throw { email: 'The email provided is invalid' };
        }
      } else if (result.message) {
        throw { email: result.message };
      } else if (result.error) {
        throw { email: result.error };
      }
    });
};

// eslint-disable-next-line no-unused-vars
export const isUsernameExists = (values, dispatch, props, field) => {
  if (!values.username) {
    return null;
  }

  return fetch('/api/user/null/validate', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: JSON.stringify({
      username: values.username
    })
  })
    .then(res => res.json())
    .then(result => {
      if (result.exists && result.exists !== 'false') {
        switch (result.exists) {
          case 'true':
            throw { username: 'The username provided is already in use' };
          case 'invalid':
            throw { username: 'The username provided is invalid' };
        }
      } else if (result.message) {
        throw { username: result.message };
      } else if (result.error) {
        throw { username: result.error };
      }
    });
};

//TODO: think of better way of new_password/repeat fields sync
export const passwordMatch = (value, allValues) => {
  if (value !== allValues['new_password']) {
    return '<b>New password</b> and <b>Repeat password</b> should match';
  }
};
