import React, { FC, SyntheticEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import type { TaskTypeCategory } from 'Shared/types/task';

import styles from './categoryItem.module.scss';

interface CategoryItemProps {
  taskTypeCategory: TaskTypeCategory;
  children: React.ReactNode;
}

export const CategoryItem: FC<CategoryItemProps> = ({ taskTypeCategory, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <li
      className={styles.categoryItem}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
        setIsExpanded(!isExpanded);
      }}
    >
      <div className={styles.content}>
        <FontAwesomeIcon
          icon={isExpanded ? faAngleUp : faAngleDown}
          className={styles.expandIcon}
        />
        <div className={styles.data}>
          <CTUI.Font.Text>{taskTypeCategory.displayName}</CTUI.Font.Text>
        </div>
      </div>
      {isExpanded && children}
    </li>
  );
};
