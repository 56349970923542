import React, { FC } from 'react';
import md5 from 'md5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { DailySessionNotePopup } from 'App/dashboard/shared/dailySessionNotePopup';
// eslint-disable-next-line max-len
import { useDailySessionNoteAction } from 'App/dashboard/clinicianDashboard/patientsList/table/averageActivityCell/actionsSubCell/dailySessionNoteAction/useDailySessionNoteAction';

import { Tooltip } from 'Shared/components/tooltip';

interface DailySessionNoteActionProps {
  patientId: number;
}

export const DailySessionNoteAction: FC<DailySessionNoteActionProps> = ({ patientId }) => {
  const {
    isTooltipShown,
    showTooltip,
    hideTooltip,
    tooltipRef,
    setTooltipRef,
    shouldShowDailySessionPopup,
    showDailySessionPopup,
    hideDailySessionPopup,
  } = useDailySessionNoteAction();
  const hashedPatientId = md5(patientId.toString());

  return (
    <>
      <span
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onClick={showDailySessionPopup}
        ref={setTooltipRef}
        className="actionIcon"
      >
        <FontAwesomeIcon icon={faFileLines} />
      </span>
      {isTooltipShown && (
        <Tooltip referenceElement={tooltipRef}>
          <p className="tooltipTitle">DAILY SESSION NOTE</p>
          This report is intended to help clinicians with daily documentation by providing
          information about activities completed in clinic along with performance data (including
          level, accuracy, and speed) on a specific day.
        </Tooltip>
      )}
      {shouldShowDailySessionPopup && (
        <DailySessionNotePopup
          patientId={hashedPatientId}
          closePopupHandler={hideDailySessionPopup}
        />
      )}
    </>
  );
};
