import React, { FC } from 'react';
import classNames from 'classnames';

import { useCalculator } from './useCalculator';

import styles from './index.module.scss';

export const Calculator: FC = () => {
  const {
    expression,
    handleUpdateExpression,
    handleClearExpression,
    handleDeleteFromExpression,
    handleEvaluateExpression,
  } = useCalculator();

  return (
    <div className={styles.calculator}>
      <div className={styles.screen}>{expression}</div>
      <table className={styles.controls}>
        <tbody>
          <tr>
            <td>
              <button
                className={classNames(styles.button, styles.accent)}
                onClick={handleClearExpression}
              >
                C
              </button>
            </td>
            <td>
              <button
                className={classNames(styles.button, styles.accent)}
                onClick={handleDeleteFromExpression}
              >
                DEL
              </button>
            </td>
            <td>
              <button
                className={classNames(styles.button, styles.accent)}
                value="/"
                onClick={handleUpdateExpression}
              >
                ÷
              </button>
            </td>
            <td>
              <button
                className={classNames(styles.button, styles.accent)}
                value="*"
                onClick={handleUpdateExpression}
              >
                ×
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button className={styles.button} value="7" onClick={handleUpdateExpression}>
                7
              </button>
            </td>
            <td>
              <button className={styles.button} value="8" onClick={handleUpdateExpression}>
                8
              </button>
            </td>
            <td>
              <button className={styles.button} value="9" onClick={handleUpdateExpression}>
                9
              </button>
            </td>
            <td>
              <button
                className={classNames(styles.button, styles.accent)}
                value="-"
                onClick={handleUpdateExpression}
              >
                -
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button className={styles.button} value="4" onClick={handleUpdateExpression}>
                4
              </button>
            </td>
            <td>
              <button className={styles.button} value="5" onClick={handleUpdateExpression}>
                5
              </button>
            </td>
            <td>
              <button className={styles.button} value="6" onClick={handleUpdateExpression}>
                6
              </button>
            </td>
            <td>
              <button
                className={classNames(styles.button, styles.accent)}
                value="+"
                onClick={handleUpdateExpression}
              >
                +
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button className={styles.button} value="1" onClick={handleUpdateExpression}>
                1
              </button>
            </td>
            <td>
              <button className={styles.button} value="2" onClick={handleUpdateExpression}>
                2
              </button>
            </td>
            <td>
              <button className={styles.button} value="3" onClick={handleUpdateExpression}>
                3
              </button>
            </td>
            <td rowSpan={2} className={styles.rowSpan}>
              <button
                className={classNames(styles.button, styles.equal, styles.accent)}
                onClick={handleEvaluateExpression}
              >
                =
              </button>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <button
                className={classNames(styles.button, styles.zero)}
                value="0"
                onClick={handleUpdateExpression}
              >
                0
              </button>
            </td>
            <td>
              <button className={styles.button} value="." onClick={handleUpdateExpression}>
                .
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
