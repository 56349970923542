import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { Tooltip } from 'Shared/components/tooltip';

import { useFilters } from './useFilters';
import { FiltersSet } from './filtersSet';

import styles from './index.module.scss';

const tooltipOptions = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 10],
      },
    },
  ],
  placement: 'bottom' as const,
  classes: styles.tooltip,
  theme: 'light' as const,
};

export const Filters: FC = () => {
  const { isTooltipShown, toggleToolTip, wrapperRef, referenceElement, setReferenceElement } =
    useFilters();

  return (
    <div className={classNames(styles.filters, { [styles.desktop]: !isMobile })}>
      {isMobile ? (
        <div ref={wrapperRef}>
          <div ref={setReferenceElement}>
            <CTUI.Button
              type="button"
              icon={faFilter}
              text="Filter"
              customClasses={styles.filterButton}
              onClick={toggleToolTip}
            />
          </div>
          {isTooltipShown && (
            <Tooltip referenceElement={referenceElement} options={tooltipOptions}>
              <FiltersSet modalsParent={wrapperRef} />
            </Tooltip>
          )}
        </div>
      ) : (
        <FiltersSet />
      )}
    </div>
  );
};
