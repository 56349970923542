import { Dispatch } from 'redux';
import queryString from 'query-string';

import { fetchWrapper } from 'Shared/fetchWrapper';
import {
  GET_PATIENT_REPORT,
  GET_PATIENT_CALENDAR,
  ACTIVATE_PATIENT_HOMEWORK_INVITATION,
} from 'Shared/types/redux';
import { getBaseUrl } from 'Shared/utils';
import { ActivatePatientHWInvitationForm } from 'Shared/types/forms';
import { DetailedPerformanceReportResponse } from 'Shared/types/api';
import { TherapySessionResponse } from 'Shared/types/shared';

export enum BE_REPORT_TRUE_FALSE {
  'TRUE' = 1,
  'FALSE' = 0,
}

interface PatientReportParams {
  patientId: number;
  token?: string;
  assistedOnly?: BE_REPORT_TRUE_FALSE;
  ignoreStartDateForBaseline?: BE_REPORT_TRUE_FALSE;
  startDate?: string;
  endDate?: string;
}

export const getPatientReport =
  ({
    patientId,
    token,
    assistedOnly = 0,
    ignoreStartDateForBaseline = 0,
    startDate,
    endDate,
  }: PatientReportParams) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        assistedOnly,
        ignoreStartDateForBaseline,
        startDate,
        endDate,
        tz: 'UTC',
        newDateFormat: true,
        token: token || localStorage.getItem('accessToken'),
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return await fetchWrapper.get(
      `${getBaseUrl()}/api/patient/${patientId}/report/performance?${formattedParams}`,
      {
        dispatch,
        types: GET_PATIENT_REPORT,
      }
    );
  };

interface DailySessionReportParams {
  patientId: number;
  token: string;
  assistedOnly: BE_REPORT_TRUE_FALSE;
  date: string;
}

export const getDailySessionReport =
  ({ patientId, token, assistedOnly = 0, date }: DailySessionReportParams) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token,
        assistedOnly,
        date,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return await fetchWrapper.get(
      `${getBaseUrl()}/api/patient/${patientId}/report/daily-session-notes?${formattedParams}`,
      {
        dispatch,
        types: GET_PATIENT_REPORT,
      }
    );
  };

interface DetailedReportParams {
  patientId: number;
  token?: string;
  startDate?: string;
  endDate?: string;
  columnType?: string;
}

export const getDetailedPerformanceReport =
  ({ patientId, token, startDate, endDate, columnType = 'BySchedule' }: DetailedReportParams) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        startDate,
        endDate,
        columnType,
        tz: 'UTC',
        newDateFormat: true,
        token: token || localStorage.getItem('accessToken'),
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return (await fetchWrapper.get(
      `${getBaseUrl()}/api/patient/${patientId}/performance?${formattedParams}`,
      {
        dispatch,
        types: GET_PATIENT_REPORT,
      }
    )) as DetailedPerformanceReportResponse;
  };

interface TherapySessionResponsesParams {
  patientId: number;
  token?: string;
  sessionIds: string;
}

export const getTherapySessionResponses =
  ({ patientId, token, sessionIds }: TherapySessionResponsesParams) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        sessionIds,
        token: token || localStorage.getItem('accessToken'),
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return (await fetchWrapper.get(
      `${getBaseUrl()}/api/patient/${patientId}/responses?${formattedParams}`,
      {
        dispatch,
        types: GET_PATIENT_REPORT,
      }
    )) as TherapySessionResponse[];
  };

interface TherapySessionResponseScreenshotParams {
  patientId: number;
  token?: string;
  responseId: number;
}

export const getTherapySessionResponseScreenshot = ({
  patientId,
  token,
  responseId,
}: TherapySessionResponseScreenshotParams) => {
  const formattedParams = queryString.stringify(
    {
      token: token || localStorage.getItem('accessToken'),
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  // eslint-disable-next-line max-len
  return `${getBaseUrl()}/api/patient/${patientId}/response/${responseId}/screenshot?${formattedParams}`;
};

interface PatientCalendarParams {
  patientId: number;
  token?: string;
}

export const getPatientCalendar =
  ({ patientId, token }: PatientCalendarParams) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: token || localStorage.getItem('accessToken'),
        logEvent: true,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return fetchWrapper.get(
      `${getBaseUrl()}/api/patient/${patientId}/compliance/daily?${formattedParams}`,
      {
        dispatch,
        types: GET_PATIENT_CALENDAR,
      }
    );
  };

interface PatientHomeworkInvitationParams {
  patientId: number;
  clinicianId: string;
  patientToken: string;
  values: ActivatePatientHWInvitationForm;
}

export const activatePatientHomeworkInvitation =
  ({ patientId, clinicianId, patientToken, values }: PatientHomeworkInvitationParams) =>
  async (dispatch: Dispatch) => {
    // TODO:
    // Mahendra knows that I don't like this approach, but we need to transform values to be
    // strings for older BE implementation to handle them properly
    const transformedValues = Object.entries(values).reduce(
      (acc, element: [string, string | boolean]) => {
        return {
          ...acc,
          ...{ [element[0]]: element[1].toString() },
        };
      },
      {}
    );
    const formattedParams = queryString.stringify(
      {
        token: patientToken,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return await fetchWrapper.put(
      // eslint-disable-next-line max-len
      `${getBaseUrl()}/api/patients/${patientId}/clinicians/${clinicianId}/hw-invite?${formattedParams}`,
      {
        dispatch,
        types: ACTIVATE_PATIENT_HOMEWORK_INVITATION,
      },
      transformedValues,
      { isSubmission: true }
    );
  };
