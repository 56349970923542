import { useState } from 'react';
import { usePopper } from 'react-popper';
import { Options, Modifier } from '@popperjs/core';
import merge from 'lodash.merge';

export type TooltipOptions = Partial<Options> & {
  classes?: string;
  usePortal?: boolean;
  theme?: 'light' | 'dark';
  modifiers?: Partial<Modifier<any, any>>[];
};

export interface TooltipProps {
  referenceElement: HTMLElement;
  options?: TooltipOptions;
}

export function useTooltip({ referenceElement, options = {} }: TooltipProps) {
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement>(null);
  const { classes, usePortal, ...cleanPopperOption } = options;
  const defaultOptions = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
    placement: 'left' as const,
  };

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    merge(defaultOptions, cleanPopperOption)
  );

  return {
    popper: {
      ref: setPopperElement,
      style: styles.popper,
      attributes: attributes.popper,
    },
    arrow: {
      ref: setArrowElement,
      style: styles.arrow,
    },
  };
}
