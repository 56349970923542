import React, { FC } from 'react';

import { Spinner } from 'Shared/components/spinner';

import { Meta } from './meta';
import { TokenInvalid } from './tokenInvalid';
import { WizardPatient } from './wizardPatient';
import { useActivation } from './useActivation';

import './index.scss';

export const FlintActivation: FC = () => {
  const { isLoading, preAuthorizedUser, activationData, handlePatientActivation } = useActivation();

  if (isLoading) {
    return <Spinner />;
  }

  if (!preAuthorizedUser) {
    return <TokenInvalid />;
  }

  return (
    <>
      <Meta />
      <WizardPatient
        activationData={activationData}
        handlePatientActivation={handlePatientActivation}
        preAuthorizedUser={preAuthorizedUser}
      />
    </>
  );
};
