import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

import { User } from 'Shared/types/shared';

interface MainProps {
  authUser?: User;
}

export const Main: FC<MainProps> = ({ authUser }) => {
  if (authUser) {
    return <Redirect to="/dashboard" />;
  }

  return <Redirect to="/login" />;
};
