import React, { FC } from 'react';
import classNames from 'classnames';

import { Spinner } from 'Shared/components/spinner';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { InstitutionForm } from './institutionForm';
import { InstitutionProps, useInstitution } from './useInstitution';

import styles from './index.module.scss';

export const Institution: FC<InstitutionProps> = ({ institutionId }) => {
  const { isLoading, institution, handleSaveInstitution } = useInstitution({
    institutionId,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <FloatingPage
      customClasses={styles.institution}
      header={<h1 className={classNames('h1', styles.title)}>Institution information</h1>}
    >
      <InstitutionForm onSubmit={handleSaveInstitution} initialValues={institution} />
    </FloatingPage>
  );
};
