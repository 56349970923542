//TODO: split this file into reasonable entities once migrated to TS
import { SUBSCRIBE_BUTTON_STATES } from 'Shared/constants';

export enum USER_TYPES {
  CLINICIAN = 'clinician',
  PATIENT = 'patient',
}

//TODO: display some meaningful message to a user if they were not permitted to login
//The only state we allow to login is `ACTIVATED`
export enum USER_STATUSES {
  ACTIVE = 'ACTIVE', //User has accepted the invitation and license is active
  DECLINED = 'DECLINED', //User has declined the invitation (the flow is not implemented right now)
  EXPIRED = 'EXPIRED', //User has received invitation but neither accepted or declined yet
  INVITED = 'INVITED',
  SUSPENDED = 'SUSPENDED', //User is temporarily suspended, but the license is still associated
}

export enum PAYMENT_METHODS {
  stripe = 'Stripe',
  apple = 'iOSIAP',
  amazon = 'AmazonIAP',
  google = 'GoogleIAP',
  flex = 'Flex',
  none = 'none',
}

export interface User {
  user: {
    userId: number;
    username: string;
    email: string;
    type: USER_TYPES;
    firstName: string;
    lastName: string;
    createDate: number;
    leadSourceDetails?: string;
    acceptedEULA: boolean;
    promptChangePassword: boolean;
  };
  institutionInfo?: {
    institutionId: number;
    institutionAdmin: boolean;
    facilityName: string;
    sessionTimeoutSeconds: number;
    mfaRequired: boolean;
  };
  subscriptionState?: {
    status: string; //TODO:
    paymentMethod: PAYMENT_METHODS;
    userId: number;
    permissions: {
      canPerformHomework: boolean;
      canViewExpandedDashboard: boolean;
    };
  };
}

export interface Institution {
  customerId: number;
  institutionId: number;
  role: string;
  timestampAdded: Date;
  timestampRemoved: Date;
  timestampSuspended: Date;
}

export interface InstitutionExpanded {
  facilityName?: string;
  country?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export interface InstitutionUser {
  email: string;
  firstName?: string;
  id: number;
  lastName?: string;
  lastSignInDate: number;
  preview?: string;
  statusDisplay: USER_STATUSES;
  type: USER_TYPES;
}

export interface DefaultActivationInvitation {
  email: string;
  userId: number;
  userType: USER_TYPES;
  redirect?: string; //TODO:
  action?: string;
  targetId?: string;
  data: {
    institutionId: number;
    message: string;
    logo_image_path?: string;
  };
}

export interface SubscriptionPlansResponse {
  couponMessage: string;
  couponMessageSuccess: boolean;
  description: string;
  plans: StripePlan[];
}

export interface StripePlan {
  id: string;
  amount: number; //Usually this number represent price ibn cents/pence/kopeks/etc.
  amountDisplay: string; //Formatted amount pre-processed on BE
  discountedAmount?: number; //Usually this number represent price ibn cents/pence/kopeks/etc.
  discountedAmountDisplay?: string; //Formatted amount pre-processed on BE
  discountAmount?: string;
  discountUnit?: StripePlanDiscountUnit;
  discountDuration?: string;
  discountDurationMonths?: number;
  currency: string;
  productId: string;
  productName: string;
  productDescription: string;
  savingsDescription?: string;
  numTrialDays?: number;
  title?: string;
}

export enum StripePlanDiscountUnit {
  PERCENTAGE = 'percentage',
  USD = 'usd',
}

export interface StripeSubscriptionSession {
  sessionId: string;
}

export interface SubscriptionConfirmation {
  chargeDate: number;
  chargePeriod: 'month' | 'year';
  couponMessage?: string;
  numTrialDays?: number;
  planSubtotal: string;
  planTitle: string;
  planTotal: string;
  trialEndDate?: number;
  trialStartDate?: number;
  trialing: boolean;
  unitAmount: number;
}

export enum PATIENT_TYPES {
  NEW = 'new',
  EXISTING = 'existing',
}

export interface PatientCalendarDay {
  completedTaskCount: number;
  date: number;
  duration: number;
  inClinic: boolean;
}

//TODO: move to store?
export interface RootState {
  user?: {
    data?: User;
    subscription?: SubscriptionInfo;
    unsubscribeReasons?: object;
    errors?: {
      subscription?: string;
    };
  };
  patient?: {
    report?: PerformanceReport | DailySessionNote;
    reportError?: string | { message?: string };
    calendar?: PatientCalendarDay[];
    calendarError?: string | { message?: string };
  };
  clinician?: {
    dashboardData?: ClinicianDashboard;
    dashboardError?: string | { title?: string; message?: string };
    patientsExpansions?: ClinicianDashboardPatientExpansion[];
  };
  addPatient?: {
    patientType: PATIENT_TYPES;
  };
  institution?: {
    institution: InstitutionExpanded;
    usersList?: InstitutionUser[];
    filteredUsersList?: InstitutionUser[];
  };
  signup?: SignupData;
  service?: {
    flashMessage?: FlashMessage;
  };
}

export interface SignupData {
  institutionId?: number;
  userType?: USER_TYPES;
  email?: string;
  source?: string;
  invitationToken?: string;
}

export interface ClinicianDashboard {
  clinicianId: number;
  Patients: ClinicianDashboardPatientBrief[];
  StatusTypes: ClinicianDashboardStatusType[];
  Summary: ClinicianDashboardSummary;
}

export interface ClinicianDashboardStatusType {
  sortOrder: number;
  statusIcon: number;
  statusLabel: string;
  statusPopup: string;
}

export interface ClinicianDashboardPatientBrief {
  clinicExercises: number;
  dateLastActive: number;
  daysActivePerWeek: number;
  email: string;
  homeExercises: number;
  onBot: boolean;
  patientId: number;
  patientUsername: string;
  statusLabel: string;
}

export interface ClinicianDashboardSummary {
  addedHomeExercises: number;
  addedTotalExercises: number;
  clinicianUsername: string;
  homeExercises: number;
  numAddedPatients: number;
  numDischargedPatients: number;
  numPatients: number;
  totalExercises: number;
}

export interface ClinicianDashboardPatientExpansion {
  calendar: any[];
  currentSchedule: ClinicianDashboardPatientExpansionScheduleItem[];
  domains: ClinicianDashboardPatientExpansionDomain[];
}

export interface ClinicianDashboardPatientExpansionScheduleItem {
  accuracy: number;
  isFailing: boolean;
  taskName: string;
}

export interface ClinicianDashboardPatientExpansionDomain {
  domainName: string;
  radarDisplayOrder: number;
  baseline: ClinicianDashboardPatientExpansionDomainIndex;
  latest: ClinicianDashboardPatientExpansionDomainIndex;
}

export interface ClinicianDashboardPatientExpansionDomainIndex {
  date: number;
  value: number;
}

export interface PerformanceReport {
  patientId: number;
  patientName: string;
  sections: {
    assessments: PerformanceReportAssessment[];
    domains: {
      [key: string]: PerformanceReportDomain;
    };
    tasks: {
      [key: string]: PerformanceReportTask;
    };
  };
  summary: PerformanceReportSummary;
  timeStart: BETimeObject;
  timeEnd: BETimeObject;
}

export interface BETimeObject {
  dateTime: {
    date: {
      day: number;
      month: number;
      year: number;
    };
    time: {
      hour: number;
      minute: number;
      second: number;
      nano: number;
    };
  };
  offset: {
    totalSeconds: number;
  };
  zone: {
    id: string;
  };
}

export interface PerformanceReportSummary {
  accuracy: number;
  daysActivePerWeek: number;
  tasksCompleted: number;
}

export interface PerformanceReportAssessment {
  id: number;
  title: string;
  description: string;
  questions: PerformanceReportAssessmentQuestion[];
}

export interface PerformanceReportAssessmentQuestion {
  id: number;
  questionId: number;
  questionText: string;
  labels: PerformanceReportAssessmentQuestionLabel[];
  baseline: PerformanceReportAssessmentQuestionIndex;
  latest: PerformanceReportAssessmentQuestionIndex;
}

export interface PerformanceReportAssessmentQuestionLabel {
  icon: string;
  text: string;
  value: number;
}

export interface PerformanceReportAssessmentQuestionIndex {
  label: string;
  value: number;
  date: BETimeObject;
}

export interface PerformanceReportDomain {
  systemName: string;
  displayName: string;
  description: string;
  radarDisplayOrder: number;
  nextMilestone: {
    value: number;
    description: string;
  };
  baseline: PerformanceReportDomainIndex;
  latest: PerformanceReportDomainIndex;
}

export interface PerformanceReportDomainIndex {
  description: string;
  value: number;
  date: BETimeObject;
}

export interface PerformanceReportTask {
  systemName: string;
  displayName: string;
  description: string;
  activityDescription?: string;
  documentationSkillReport: string;
  skillAreas: string[];
  levels: {
    [key: number]: PerformanceReportTaskLevel;
  };
}

export interface PerformanceReportTaskLevel {
  accuracy: PerformanceReportTaskLevelValue;
  latency: PerformanceReportTaskLevelValue;
  baselineCues: PerformanceReportTaskLevelCue[];
  latestCues: PerformanceReportTaskLevelCue[];
}

export interface PerformanceReportTaskLevelValue {
  unit: string;
  min: number;
  max: number;
  isInverted: boolean;
  baseline: PerformanceReportTaskLevelValueIndex;
  latest: PerformanceReportTaskLevelValueIndex;
}

export interface PerformanceReportTaskLevelValueIndex {
  value: number;
  date: BETimeObject;
}

export interface PerformanceReportTaskLevelCue {
  numCues: number;
  cueType: string;
}

export interface DailySessionNote {
  patientId: number;
  patientName: string;
  timeStart: BETimeObject;
  timeEnd: BETimeObject;
  tasks: {
    [key: string]: PerformanceReportTask;
  };
}

export interface PasswordRequirements {
  length?: number;
  uppercaseCount?: number;
  numericCount?: number;
  specialCharsCount?: number;
  description?: string;
}

export interface SubscriptionInfo {
  manageSubscriptionURL: string;
  paymentMethod: string;
  subscriptionBlurb: string;
  subscribeButton: SUBSCRIBE_BUTTON_STATES;
  subscriptionStatus: string;
  userType: USER_TYPES;
}

export interface UnsubscribeReasons {
  reasons: { key: string; value: string }[];
}

export interface FlashMessage {
  message: string;
  type?: FlashMessageType;
  keepFor?: number;
}

export enum FlashMessageType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface DPRColumn {
  dateRange: string;
  empty: boolean;
  endDate: { day: number; month: number; year: number };
  location: 'Clinic' | 'Home';
  locationDisplay: string;
  sessionIds: string;
  startDate: { day: number; month: number; year: number };
}

export interface DPRRow {
  taskDetail: string;
  taskLevel: number;
  taskTypeSystemName: string;
}

export interface DPRRawDataElement {
  accuracy: number;
  accuracyColor: string;
  accuracyMean: number;
  accuracyPercentile: number;
  accuracyStdDev: number;
  assignedTasks: number;
  completedTasks: number;
  dateRange: string;
  endDate: { day: number; month: number; year: number };
  latency: number;
  latencyColor: string;
  latencyMean: number;
  latencyPercentile: number;
  latencyStdDev: number;
  location: 'Clinic' | 'Home';
  sessionIds: string;
  startDate: { day: number; month: number; year: number };
  taskDetail: string;
  taskLevel: number;
  taskTypeId: number;
  taskTypeSystemName: string;
}

export interface TherapySessionResponse {
  accuracy: number;
  latency: number;
  skipped: boolean;
  timestamp: number;
  taskType: string;
  taskTypeId: number;
  taskLevel: number;
  sessionId: number;
  patientId: number;
  latitude: number;
  longitude: number;
  additionalDataJson: string;
  taskId: number;
  taskJson: string;
  systemVersionNumber: string;
  clientVersionNumber: string;
  clientOSVersion: string;
  clientHardwareType: string;
  startTime: number;
  responseId: number;
}

export interface TaskView {
  type: TASK_TYPES;
  templateName: string;
  resourceUrl: string;
  resources: string[];
  instructions: {
    text: string;
    instructionAudioPaths: string[];
    autoplayInstructions: boolean;
    showAudioSignifier?: boolean;
    choiceCoverText?: string;
  };
  stimulus?: StimulusItem[];
}

export interface TaskViewNumberProblemSolving extends TaskView {
  type: TASK_TYPES.NUMBER_PROBLEM_SOLVING;
  answerType: string;
  answersList: object[];
  showScratchpad: boolean;
  showCalculator: boolean;
}

export interface TaskViewNumberProblemSolvingInteger extends TaskViewNumberProblemSolving {
  answerType: 'INTEGER';
  answersList: { value: number }[];
}

export interface TaskViewNumberProblemSolvingDecimal extends TaskViewNumberProblemSolving {
  answerType: 'DECIMAL';
  answersList: {
    maximum: number;
    minimum: number;
  }[];
}

export interface TaskViewMultipleChoice extends TaskView {
  type: TASK_TYPES.MULTIPLE;
  questions: { choices: TaskViewChoice[]; question?: string }[];
}

export interface TaskViewProduction extends TaskView {
  type: TASK_TYPES.PRODUCTION;
  repeatInstructionAudioPaths: string[];
  answers: string[];
}

export interface TaskViewSequenceCompletion extends TaskView {
  type: TASK_TYPES.SEQUENCE_COMPLETION;
  showStimulusLabelView: boolean;
  showCompletionPopup: boolean;
  shouldShuffleItemViews: boolean;
  removeExtraItemViewsAtEnd: boolean;
  preservePlaceholderAspectRatio: boolean;
  preserveItemAspectRatio: boolean;
  playSoundOnFirstTap: boolean;
  placementType: TaskViewPlacementType;
  placeholdersLayout: TaskViewLayout;
  placeholders: TaskViewPlaceholder[];
  isStimulusAudioButtonHidden: boolean;
  isInstructionsViewEnabled: boolean;
  isImageViewUnderStimulusHidden: boolean;
  equalPlaceholderAspectRatio: boolean;
  choicesLayout: TaskViewLayout;
  choices: TaskViewChoice[];
  answerSoundPaths: string[];
  rows?: number;
  columns?: number;
}

export interface TaskViewBoardCompletion extends TaskView {
  type: TASK_TYPES.BOARD_COMPLETION;
  rows: number;
  wsrows: number;
  columns: number;
  wscolumns: number;
  choices: TaskViewChoice[];
  placementType: TaskViewPlacementType;
  allowHints: boolean;
  answers: TaskViewBoardCompletionAnswer[];
}

export interface TaskViewBoardCompletionAnswer {
  correctValue: string;
}

export interface TaskViewMemoryBoard extends TaskView {
  type: TASK_TYPES.MEMORY_BOARD;
  rows: number;
  wsrows: number;
  columns: number;
  wscolumns: number;
  items: TaskViewChoice[];
  itemTimeout: number;
}

export interface TaskViewMemorySequence extends TaskView {
  type: TASK_TYPES.MEMORY_SEQUENCE;
  sequence: { isAnswer: boolean; timeInterval: number; stimulus: StimulusItem }[];
}

export enum TASK_TYPES {
  MULTIPLE = 'MultipleChoice',
  PRODUCTION = 'Production',
  SEQUENCE_COMPLETION = 'SequenceCompletion',
  NUMBER_PROBLEM_SOLVING = 'NumberProblemSolving',
  MEMORY_BOARD = 'MemoryBoard',
  MEMORY_SEQUENCE = 'MemorySequence',
  BOARD_COMPLETION = 'BoardCompletion',
}

export enum STIMULUS_CONTENT_TYPES {
  TEXT = 'text',
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  HTML = 'html',
  AUDIO_PLAYER = 'audio_player',
  NONE = 'none',
}

export interface TaskViewChoice {
  correct?: boolean;
  stimulus: StimulusItem;
  value: string;
  playSoundOnFirstTap?: boolean;
}

export interface TaskViewPlaceholder {
  correctValue: string;
  expandOnTap: boolean;
  order: number;
  prefilledChoice?: {
    stimulus: StimulusItem;
    value: string;
  };
}

export type StimulusItem =
  | TextStimulus
  | ImageStimulus
  | AudioStimulus
  | VideoStimulus
  | HtmlStimulus
  | AudioPlayerStimulus
  | NoneStimulus;

export interface BaseStimulus {
  backgroundColor: string;
  cues?: StimulusCue[];
  text?: string;
  textAlignment?: string;
  audioPaths: string[];
  audioSegments: number[];
}

export interface TextStimulus extends BaseStimulus {
  contentType: STIMULUS_CONTENT_TYPES.TEXT;
  text: string;
  textAlignment?: TextStimulusAlignment;
}

export enum TextStimulusAlignment {
  LEFT = 'Left',
  RIGHT = 'Right',
  CENTER = 'Center',
}

export interface ImageStimulus extends BaseStimulus {
  contentType: STIMULUS_CONTENT_TYPES.IMAGE;
  imagePath: string;
  imageRotation?: number;
}

export interface AudioStimulus extends BaseStimulus {
  contentType: STIMULUS_CONTENT_TYPES.AUDIO;
}

export interface VideoStimulus extends BaseStimulus {
  contentType: STIMULUS_CONTENT_TYPES.VIDEO;
  videoPath: string;
}

export interface HtmlStimulus extends BaseStimulus {
  contentType: STIMULUS_CONTENT_TYPES.HTML;
  htmlPath: string;
  htmlQuestionReplacementTokens: {
    [key: string]: string;
  };
  htmlAnswerReplacementTokens: {
    [key: string]: string;
  };
}

export interface AudioPlayerStimulus extends BaseStimulus {
  contentType: STIMULUS_CONTENT_TYPES.AUDIO_PLAYER;
}

export interface NoneStimulus extends BaseStimulus {
  contentType: STIMULUS_CONTENT_TYPES.NONE;
}

export interface StimulusCue {
  type: StimulusCueType;
  contentType: 'text' | 'audio';
  text: string;
  audioPaths: string[];
  tapToDisplayText?: string;
}

export enum StimulusCueType {
  SEMANTIC = 'semantic',
  PHONEMIC = 'phonemic',
  GRAPHEMIC = 'graphemic',
  FULL = 'full',
}

export enum TaskViewLayout {
  HORIZONTAL = 'HorizontalWrap',
  VERTICAL = 'VerticalWrap',
  GRID = 'Grid',
}

export enum TaskViewPlacementType {
  ORDERED = 'Ordered',
  UNORDERED = 'Unordered',
}

export enum TherapyActivityActions {
  START_ASSESSMENT_SESSION = 'START_ASSESSMENT_SESSION',
  DEFER_ASSESSMENT = 'DEFER_ASSESSMENT',
  START_THERAPY_SESSION = 'START_THERAPY_SESSION',
  EMAIL_REPORT = 'EMAIL_REPORT',
  CONTINUE = 'CONTINUE',
}
