import React, { FC } from 'react';
import classNames from 'classnames';

import { Tooltip } from 'Shared/components/tooltip';

import { ProgressBarCategoryStatus } from './useProgressBar';
import { CategoryBarProps, useCategoryBar } from './useCategoryBar';

import styles from './categoryBar.module.scss';

const tooltipOptions = { placement: 'top' as const, classes: styles.tooltip };

export const CategoryBar: FC<CategoryBarProps> = ({ category, baseCategoryBarWidth }) => {
  const { isTooltipShown, showTooltip, hideTooltip, tooltipRef, setTooltipRef, categoryStyles } =
    useCategoryBar({
      category,
      baseCategoryBarWidth,
    });

  return (
    <li
      ref={setTooltipRef}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      className={classNames(styles.bar, { [styles[category.progressionStatus]]: true })}
      style={categoryStyles}
    >
      {isTooltipShown && (
        <Tooltip referenceElement={tooltipRef} options={tooltipOptions}>
          <p className="text2">
            <b className="text2 medium">{category.name}</b>
            <br />
            {category.count} Exercises{' '}
            {category.progressionStatus !== ProgressBarCategoryStatus.DONE
              ? 'assigned'
              : 'completed'}{' '}
            in this category
          </p>
        </Tooltip>
      )}
    </li>
  );
};
