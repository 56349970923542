import React, { FC } from 'react';
import classNames from 'classnames';

import { SUPPORT } from 'Shared/constants';
import { DownloadAppList } from 'Shared/components/downloadAppList';
// eslint-disable-next-line max-len
import iphoneScreenshotImage from 'Shared/src/themes/2019/images/Flint_thankyou_iPhone_screenshot.png';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import sharedStyles from './sharedStyles.module.scss';
import styles from './flintLed.module.scss';

interface FlintLedProps {
  displayName: string;
}

export const FlintLed: FC<FlintLedProps> = ({ displayName }) => {
  const modalClasses = classNames(sharedStyles.emptyReport, styles.flintLed);

  return (
    <>
      <FloatingPage
        customClasses={modalClasses}
        header={<h1 className={classNames('h1', sharedStyles.title)}>Thanks, {displayName}!</h1>}
        footer={
          <p>
            If you need help at any point, call us at{' '}
            <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a> or email{' '}
            <a href={`mailto:${SUPPORT.EMAIL}`}>{SUPPORT.EMAIL}</a>
          </p>
        }
      >
        <p>
          You are almost ready to go! <b>Please follow the next two steps to get started.</b>
        </p>
        <div className={styles.manual}>
          <ol>
            <li>
              Download the Constant Therapy app. If you’re on a mobile or tablet device, use the
              buttons below. Or go directly to your device’s app store and search for “Constant
              Therapy”.
            </li>
            <li>In the app, select “Sign in”, then enter the email and password you created.</li>
          </ol>
          <img
            src={iphoneScreenshotImage}
            alt="Constant Therapy iPhone App Screenshot"
            title="Constant Therapy iPhone App Screenshot"
          />
        </div>
      </FloatingPage>
      <div className={styles.downloadsBlock}>
        <h3 className={classNames('h3', styles.dbHeading)}>Download the Constant Therapy App</h3>
        <DownloadAppList />
      </div>
    </>
  );
};
