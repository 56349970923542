import React, { FC } from 'react';
import classNames from 'classnames';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { defaultValidators } from 'Shared/validators';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { ADD_PATIENT_FORM_NAME } from '../../shared/constants';

import { useAdvanced } from './useAdvanced';

import stepStyles from '../../shared/step.module.scss';
import styles from './advanced.module.scss';

interface AdvancedComponentProp {
  previousPage: () => void;
}

const AdvancedComponent: FC<
  AdvancedComponentProp & InjectedFormProps<object, AdvancedComponentProp>
> = ({ handleSubmit, previousPage, error, pristine, submitting }) => {
  const { genderOptions, educationOptions } = useAdvanced();

  return (
    <FloatingPage
      customClasses={stepStyles.step}
      header={
        <>
          <ProgressBar currentStep={2} totalSteps={5} />
          <h1 className={classNames('h1', stepStyles.title)}>Tell us about your client!</h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses={stepStyles.form}
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="primary-inverted"
              customClasses={stepStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text="Next &rarr;"
              variant="primary"
              customClasses={stepStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="gender"
          component={CTUI.Form.Select}
          type="select"
          label="Gender"
          options={genderOptions}
          value=""
          required
          autofocus
        />
        <Field
          name="education"
          component={CTUI.Form.Select}
          type="select"
          label="Education"
          options={educationOptions}
          value=""
          required
        />
        <Field
          name="age"
          component={CTUI.Form.Number}
          type="number"
          label={'Age <i>(helps estimate therapy duration)</i>'}
          validate={[defaultValidators.decimal.bind(defaultValidators)]}
          min={5}
          max={130}
          required
          customClasses={styles.oneHalf}
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Advanced = reduxForm<object, AdvancedComponentProp>({
  form: ADD_PATIENT_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AdvancedComponent);

export { Advanced };
