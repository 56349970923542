import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Review your profile information, manage your 
  subscription, and change your password with just a few clicks. Your preferences matter to us!`;
  return (
    <Helmet>
      <title>My Account | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
