import React, { FC } from 'react';

import styles from './info.module.scss';

export const ExpandableRowCalendarInfo: FC = () => {
  return (
    <ul className={styles.expandableRowCalendarInfo}>
      <li className={styles.unfinished}>Unfinished</li>
      <li className={styles.goalMet}>Goal met</li>
    </ul>
  );
};
