import React, { FC } from 'react';

import { Spinner } from 'Shared/components/spinner';
import { PageError } from 'Shared/components/pageError';

import { Confirmation } from './confirmation';
import { useSuccess } from './useSuccess';

export const Success: FC = () => {
  const { isLoading, subscriptionConfirmation, subscriptionError } = useSuccess();

  if (isLoading) {
    return <Spinner />;
  }

  if (subscriptionError) {
    return <PageError error={subscriptionError} />;
  }

  return <Confirmation subscriptionConfirmation={subscriptionConfirmation} />;
};
