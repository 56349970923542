import React, { FC, SyntheticEvent } from 'react';
import { Launcher } from 'App/dashboard/patientDashboard/dashboardNavigation/mobileMenu/launcher';
import { Dropdown } from 'App/dashboard/patientDashboard/dashboardNavigation/mobileMenu/dropdown';

import { PopperMenu } from 'Shared/components/layout/popperMenu';

import styles from './index.module.scss';

interface MobileMenuProps {
  showProgressPopup: (e: SyntheticEvent) => void;
  surveillanceMode?: boolean;
  patientId?: string;
}

export const MobileMenu: FC<MobileMenuProps> = ({
  showProgressPopup,
  surveillanceMode,
  patientId,
}) => {
  return (
    <div className={styles.mobileMenu}>
      <PopperMenu
        renderLauncher={Launcher}
        renderMenu={({ popper, toggleMenuVisibility }) => {
          return (
            <Dropdown
              popper={popper}
              toggleMenuVisibility={toggleMenuVisibility}
              showProgressPopup={showProgressPopup}
              surveillanceMode={surveillanceMode}
              patientId={patientId}
            />
          );
        }}
      />
    </div>
  );
};
