import React, { FC } from 'react';

import styles from './progressBar.module.scss';

interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
}

export const ProgressBar: FC<ProgressBarProps> = ({ currentStep, totalSteps }) => {
  return (
    <div className={styles.progressBarSurveyCampaign}>
      Question {currentStep} of {totalSteps}
    </div>
  );
};
