import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import { InlineWidget } from 'react-calendly';
import classNames from 'classnames';

import { SUPPORT } from 'Shared/constants';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { Meta } from './meta';
import { DigitalClinicForm } from './form';
import { useDigitalClinic } from './useDigitalClinic';

import styles from './index.module.scss';

export const DigitalClinic: FC<RouteProps> = () => {
  const { handleDigitalClinicFormSubmit, formData, isSupportedState } = useDigitalClinic();

  return (
    <>
      <Meta />
      {formData ? (
        isSupportedState ? (
          <FloatingPage
            header={
              <h1 className={classNames('h1')}>
                Set up a Free virtual consult with a Constant Therapy certified licensed speech
                therapist
              </h1>
            }
            customClasses={styles.digitalClinicModal}
          >
            <InlineWidget url="https://calendly.com/speechworks365/zach-smith" />
          </FloatingPage>
        ) : (
          <FloatingPage
            header={
              <h1 className={classNames('h1')}>
                Unfortunately, Constant Therapy certified licensed speech therapists are not yet
                available in your state.
              </h1>
            }
            customClasses={styles.digitalClinicModal}
          >
            <p className={styles.notAvailableMessage}>
              Please check back at a later time or contact{' '}
              <a href={`mailto:${SUPPORT.EMAIL}`}>{SUPPORT.EMAIL}</a> or call{' '}
              <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a> for more information.
            </p>
          </FloatingPage>
        )
      ) : (
        <div className={styles.digitalClinicBase}>
          <article className={styles.main}>
            <h1 className="h1">
              Set up a Free virtual consult with a Constant Therapy certified licensed speech
              therapist
            </h1>
            <h3 className={classNames('h3', styles.subheader)}>
              Enhance your quality of life with personalized, one-on-one virtual therapy with a
              licensed speech therapist:
            </h3>
            <ul className={styles.benefits}>
              <li>Tailored speech and cognitive program for your needs</li>
              <li>Convenient sessions from home</li>
              <li>Easy-to-use, scientifically-proven technology</li>
              <li>Undivided attention from your therapist</li>
              <li>Track your progress digitally</li>
              <li>Enjoy continuous care and support</li>
            </ul>
          </article>
          <aside className={styles.sidebar}>
            <DigitalClinicForm onSubmit={handleDigitalClinicFormSubmit} />
          </aside>
        </div>
      )}
    </>
  );
};
