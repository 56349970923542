import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { PAYMENT_METHODS, SUBSCRIPTION_STATUSES_WITH_CC_AVAILABLE } from 'Shared/constants';
import { USER_TYPES } from 'Shared/types/shared';
import { User } from 'Shared/types/shared';

import styles from './menu.module.scss';

interface MenuComponentProps {
  authUser: User;
}

export const MenuComponent: FC<MenuComponentProps> = ({ authUser }) => {
  const menuItems = [
    <li key="2" className={styles.item}>
      <NavLink
        to="/account/password"
        className={styles.itemLink}
        activeClassName={styles.active}
        exact
      >
        <CTUI.Font.Text variant="medium" customClasses={styles.itemLinkText}>
          Change Password
        </CTUI.Font.Text>
      </NavLink>
    </li>,
  ];

  //disable navigation to enforce user to change password
  if (!authUser.user.promptChangePassword) {
    menuItems.unshift(
      <li key="1" className={styles.item}>
        <NavLink to="/account/" className={styles.itemLink} activeClassName={styles.active} exact>
          <CTUI.Font.Text variant="medium" customClasses={styles.itemLinkText}>
            Account Details
          </CTUI.Font.Text>
        </NavLink>
      </li>
    );
  }

  if (
    authUser.user.type === USER_TYPES.PATIENT &&
    authUser.subscriptionState.paymentMethod === PAYMENT_METHODS.stripe &&
    SUBSCRIPTION_STATUSES_WITH_CC_AVAILABLE.includes(authUser.subscriptionState.status)
  ) {
    menuItems.push(
      <li key="4" className={styles.item}>
        <a
          href="/payment_update"
          className={styles.itemLink}
          target="_blank"
          title="Update Payment Method (Opens in a New Tab)"
        >
          <CTUI.Font.Text variant="medium" customClasses={styles.itemLinkText}>
            Update Payment Method <FontAwesomeIcon icon={faExternalLink} />
          </CTUI.Font.Text>
        </a>
      </li>
    );
  }

  return (
    <div className={styles.menu}>
      <CTUI.Font.H1 variant="bold" customClasses={styles.title}>
        My Account
      </CTUI.Font.H1>
      <nav>
        <ul>{menuItems}</ul>
      </nav>
    </div>
  );
};
