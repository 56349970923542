import React, { FC } from 'react';

import type { ImageStimulus } from 'Shared/types/shared';

import styles from './image.module.scss';

interface ImageComponentProps {
  stimulus: ImageStimulus;
  resourceUrl: string;
}

export const ImageComponent: FC<ImageComponentProps> = ({ stimulus, resourceUrl }) => {
  return (
    <img
      src={resourceUrl + stimulus.imagePath}
      alt={stimulus.text}
      title={stimulus.text}
      className={styles.image}
    />
  );
};
