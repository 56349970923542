import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faCirclePlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

import { Spinner } from 'Shared/components/spinner';
import { EnhancedScheduledTaskType, ScheduledTaskTypeStub } from 'Shared/types/schedule';

import { useActiveScheduleTasksModal } from './useModal';
import { DragItem } from './taskTypeItem/useTaskTypeItem';

import { ActiveScheduleTasks } from '.';

import styles from './modal.module.scss';

interface ActiveScheduleTasksModalProps {
  isScheduleLoading: boolean;
  scheduledTasksList: (EnhancedScheduledTaskType | ScheduledTaskTypeStub)[];
  moveScheduledTasks: (dragIndex: number, hoverIndex: number, item: DragItem) => void;
  deleteScheduledTask: (index: number) => void;
  adjustScheduledTask: (task: EnhancedScheduledTaskType | ScheduledTaskTypeStub) => void;
  modalCloseHandler: () => Promise<void>;
  showTasksHierarchyInPortraitMode: () => void;
  handleSavePatientSchedule: () => Promise<void>;
  isSavingChanges: boolean;
  isPristine: boolean;
}

export const ActiveScheduleTasksModal: FC<ActiveScheduleTasksModalProps> = ({
  isScheduleLoading,
  scheduledTasksList,
  moveScheduledTasks,
  adjustScheduledTask,
  deleteScheduledTask,
  modalCloseHandler,
  showTasksHierarchyInPortraitMode,
  handleSavePatientSchedule,
  isSavingChanges,
  isPristine,
}) => {
  const { modalRef } = useActiveScheduleTasksModal({ modalCloseHandler });

  return (
    <CTUI.Modal
      ref={modalRef}
      id={styles.activeScheduleTasksModal}
      size={CTUI.ModalSizes.LARGE}
      closeButtonHandler={() => {
        void modalCloseHandler();
      }}
      header={<h1 className="h1">Assigned exercises</h1>}
      footer={
        <>
          <CTUI.Button
            icon={faCirclePlus}
            customClasses={styles.addMoreTaskButton}
            text="Add more tasks"
            variant="secondary"
            onClick={showTasksHierarchyInPortraitMode}
            disabled={isScheduleLoading || isSavingChanges}
          />
          <CTUI.Form.SubmitButton
            icon={faFloppyDisk}
            customClasses={styles.saveChangesButton}
            text="Save changes"
            isPristine={isPristine}
            isSubmitting={isSavingChanges}
            onClick={() => {
              void handleSavePatientSchedule();
            }}
          />
        </>
      }
    >
      {isScheduleLoading ? (
        <Spinner />
      ) : (
        <ActiveScheduleTasks
          scheduledTasksList={scheduledTasksList}
          moveScheduledTasks={moveScheduledTasks}
          deleteScheduledTask={deleteScheduledTask}
          adjustScheduledTask={adjustScheduledTask}
        />
      )}
    </CTUI.Modal>
  );
};
