import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import {
  PerformanceReportTask,
  PerformanceReportTaskLevel,
  PerformanceReportTaskLevelCue,
} from 'Shared/types/shared';

import styles from './brief.module.scss';

interface DailySessionNoteBriefTherapyItemProps {
  therapy: PerformanceReportTask;
  levelKey: string;
  level: PerformanceReportTaskLevel;
}

export const DailySessionNoteBriefTherapyItem: FC<DailySessionNoteBriefTherapyItemProps> = ({
  therapy,
  levelKey,
  level,
}) => {
  return (
    <li className={styles.dailySessionNoteBriefTherapyItem}>
      <p className={styles.itemDescription}>
        <CTUI.Font.Text variant="medium">
          {therapy.displayName}, Level {levelKey}
        </CTUI.Font.Text>
      </p>
      <ul className={styles.itemData}>
        <li>
          <CTUI.Font.Text>
            Average accuracy: {level.accuracy.latest.value}
            {level.accuracy.unit}
          </CTUI.Font.Text>
        </li>
        <li>
          <CTUI.Font.Text>
            Average latency: {level.latency.min}
            {level.latency.unit}
          </CTUI.Font.Text>
        </li>
        <li>
          <CTUI.Font.Text>Number and type of cues used: {getCuesText(level)}</CTUI.Font.Text>
        </li>
      </ul>
    </li>
  );
};

function getCuesText({
  baselineCues,
  latestCues,
}: {
  baselineCues: PerformanceReportTaskLevelCue[];
  latestCues: PerformanceReportTaskLevelCue[];
}) {
  let usedCues = 'No cues were used';
  const isAnyCuesUsed = baselineCues.length || latestCues.length;
  const baselineCuesTextLine = baselineCues
    .map((cue) => {
      return `${cue.numCues} '${cue.cueType}' baseline cue${cue.numCues > 1 ? 's' : ''}`;
    })
    .join();
  const latestCuesTextLine = latestCues
    .map((cue) => {
      return `${cue.numCues} '${cue.cueType}' latest cue${cue.numCues > 1 ? 's' : ''}`;
    })
    .join();

  if (isAnyCuesUsed) {
    if (baselineCuesTextLine) {
      usedCues = baselineCuesTextLine;
      if (latestCuesTextLine) {
        usedCues = `${baselineCuesTextLine} and ${latestCuesTextLine}`;
      }
    } else if (latestCuesTextLine) {
      usedCues = latestCuesTextLine;
    }
  }

  return usedCues;
}
