import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import styles from './index.module.scss';

interface FooterProps {
  isAnswered: boolean;
  solveTask: () => Promise<void>;
}

export const Footer: FC<FooterProps> = ({ isAnswered, solveTask }) => {
  return (
    <footer className={styles.footer}>
      <CTUI.Button
        text="Next"
        icon={faArrowRightLong}
        onClick={solveTask}
        customClasses={classNames(styles.button, { [styles.visible]: isAnswered })}
      />
    </footer>
  );
};
