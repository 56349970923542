import React, { FC } from 'react';
import classNames from 'classnames';

import { User } from 'Shared/types/shared';

import { Table } from './table';
import { Legend } from './legend';
import { Filters } from './filters';
import { DetailedPerformanceProvider } from './context';

import styles from './index.module.scss';

export interface DetailedPerformanceProps {
  patient: User;
}

export const DetailedPerformance: FC<DetailedPerformanceProps> = ({ patient }) => {
  return (
    <DetailedPerformanceProvider patient={patient}>
      <div className={styles.detailedPerformance}>
        <Legend />
        <div className={styles.header}>
          <h2 className={classNames('h2', styles.title)}>Detailed Performance</h2>
          <Filters />
        </div>
        <Table patient={patient} />
      </div>
    </DetailedPerformanceProvider>
  );
};
