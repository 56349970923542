import React, { FC } from 'react';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faMinus, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { SharedModalProps } from './sharedModalProps';

import sharedModalStyles from './sharedModalStyles.module.scss';
import styles from './dischargeUser.module.scss';

export const DischargeUserModal: FC<SharedModalProps> = ({
  email,
  isLoading,
  handleModalSubmit,
  closeActiveModal,
  modalSubmissionError,
  modalRef,
}) => {
  return (
    <CTUI.Modal
      ref={modalRef}
      header={
        <h1 className="h1">
          <FontAwesomeIcon
            icon={faMinusCircle}
            className={classNames(sharedModalStyles.icon, styles.icon)}
          />
          Discharge user
        </h1>
      }
      headerClasses={sharedModalStyles.header}
      customClasses={sharedModalStyles.actionCellModal}
      closeButtonHandler={closeActiveModal}
      footer={
        <>
          <CTUI.Form.SubmitButton
            isSubmitting={isLoading}
            isPristine={false}
            onClick={handleModalSubmit}
            icon={faMinus}
            text="Discharge user"
          />
          <CTUI.Form.ResetButton
            isSubmitting={isLoading}
            isPristine={false}
            onClick={closeActiveModal}
            text="Cancel"
          />
        </>
      }
      footerClasses={sharedModalStyles.footer}
      error={modalSubmissionError}
    >
      <div className={sharedModalStyles.extraMargin}>
        <h4 className={classNames('h4', sharedModalStyles.message)}>
          You are discharging <b>{`<${email}>`}</b> from your user list.
        </h4>
        <p className={styles.message}>
          <FontAwesomeIcon icon={faInfoCircle} className={styles.infoIcon} />
          Discharging this user will not delete their account, but will remove them from your user
          list so that you can assign their license to a different user as appropriate. Once they
          are discharged, if the user chooses, they can continue their CT Home exercise program
          independently.
        </p>
      </div>
    </CTUI.Modal>
  );
};
