import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';

import { useText, TextContentProps } from './useText';

import styles from './text.module.scss';

export const TextContent: FC<TextContentProps> = ({ stimulus, resourceUrl }) => {
  // const { handleAudioCuePlay } = useText({ stimulus, resourceUrl });

  return (
    <div>TextContent</div>
    // <div
    //   className={classNames(styles.content, styles.text, {
    //     [styles.withAudio]: !!stimulus.audioPaths.length,
    //   })}
    // >
    //   <CTUI.Font.H2>{stimulus.text}</CTUI.Font.H2>
    //   {!!stimulus.audioPaths.length && (
    //     <FontAwesomeIcon
    //       icon={faCirclePlay}
    //       title="Play sound"
    //       onClick={handleAudioCuePlay}
    //       className={styles.cue}
    //     />
    //   )}
    // </div>
  );
};
