import React, { FC } from 'react';
import { isInclusivelyBeforeDay, DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faEye } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import {
  DAILY_SESSION_NOTE_DETAILED_TYPE_ID,
  DAILY_SESSION_NOTE_BRIEF_TYPE_ID,
} from 'Shared/constants';

import { RadioGroup } from './radioGroup';
import { useDailySessionNotePopup, DailySessionNotePopupProps } from './useDailySessionNotePopup';

import sharedStyles from '../sharedModalProps.module.scss';
import styles from './index.module.scss';

const radioData = [
  {
    name: 'reportType',
    label: 'Detailed',
    value: DAILY_SESSION_NOTE_DETAILED_TYPE_ID,
  },
  {
    name: 'reportType',
    label: 'Brief',
    value: DAILY_SESSION_NOTE_BRIEF_TYPE_ID,
  },
];

export const DailySessionNotePopup: FC<DailySessionNotePopupProps> = ({
  patientId,
  closePopupHandler,
}) => {
  const {
    modalRef,
    numberOfMonthsToDisplay,
    reportType,
    setReportType,
    date,
    setDate,
    applyChanges,
  } = useDailySessionNotePopup({
    patientId,
    closePopupHandler,
  });

  return (
    <CTUI.Modal
      ref={modalRef}
      id={styles.dailySessionNotePopup}
      header={
        <h1 className={classNames('h1', sharedStyles.title)}>
          <FontAwesomeIcon icon={faFileLines} className={sharedStyles.titleIcon} /> Daily Session
          Note
        </h1>
      }
      customClasses={sharedStyles.reportsModal}
      closeButtonHandler={closePopupHandler}
      footer={
        <CTUI.Form.SubmitButton
          text="See Report"
          icon={faEye}
          isPristine={!date || !reportType}
          isSubmitting={false}
          onClick={applyChanges}
        />
      }
      footerClasses={styles.footer}
    >
      <p>This note is intended to help clinicians with documentation. It will generate either:</p>
      <p>
        1. A brief report with all of the activities completed on the selected day (including level,
        accuracy, and speed), or
      </p>
      <p>
        2. A narrative report with descriptions of activities completed on the selected day and the
        underlying skills they target (along with data for level, accuracy, and speed)
      </p>
      <div className={styles.reportType}>
        <h2 className="h2">Report type:</h2>
        <div className={styles.radioWrap}>
          <RadioGroup
            radioData={radioData}
            checkedValue={reportType}
            setIsChecked={setReportType}
          />
        </div>
      </div>
      <i className={sharedStyles.divider} />
      <div className={classNames(sharedStyles.calendarWrap, styles.calendarWrap)}>
        <h2 className="h2">Choose a date:</h2>
        <DayPickerSingleDateController
          date={date}
          onDateChange={setDate}
          numberOfMonths={numberOfMonthsToDisplay}
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          initialVisibleMonth={() => moment()}
          focused={true}
          onFocusChange={() => null}
        />
      </div>
    </CTUI.Modal>
  );
};
