import { useEffect, useState, CSSProperties, useContext } from 'react';

import correctSound from 'Shared/src/themes/2023/sounds/correct_answer.mp3';
import wrongSound from 'Shared/src/themes/2023/sounds/wrong_answer.mp3';
import { AudioContext } from 'Shared/context/audioContext';
import { type TaskViewMemorySequence } from 'Shared/types/shared';

import { AnswerResult } from '../../useTaskView';

import {
  ANIMATION_ADJUST_FACTOR,
  ENTRANCE_ANIMATION_DURATION,
  EXIT_ANIMATION_DURATION,
} from './constants';

interface UseMemorySequenceProps {
  task: TaskViewMemorySequence;
  handleAnswer: (result: AnswerResult) => void;
}

interface UseMemorySequenceShape {
  activeItemKey: number;
  isStarted: boolean;
  handleStartClick: () => void;
  handleItemClick: (index: number) => void;
  animationStyles: CSSProperties;
  isClickedCorrect: boolean;
  isClickedWrong: boolean;
}

export function useMemorySequence({
  task,
  handleAnswer,
}: UseMemorySequenceProps): UseMemorySequenceShape {
  const { stopAudioSequence, playAudioSequence } = useContext(AudioContext);

  const [activeItemKey, setActiveItemKey] = useState<number>(0);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [animationStyles, setAnimationStyles] = useState<CSSProperties>({ animation: 'none' });
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isClickedCorrect, setIsClickedCorrect] = useState<boolean>(false);
  const [isClickedWrong, setIsClickedWrong] = useState<boolean>(false);
  const [correctGuesses, setCorrectGuesses] = useState<number>(0);
  const [wrongGuesses, setWrongGuesses] = useState<number>(0);

  const handleStartClick = () => {
    setIsStarted(true);
  };

  const handleItemClick = (index: number) => {
    // Per the native apps implementation we ignore clicks on the first card entirely
    if (index === 0 || isClicked) {
      return;
    }

    stopAudioSequence();
    setIsClicked(true);
    if (task.sequence[index].isAnswer) {
      setCorrectGuesses(correctGuesses + 1);
      playAudioSequence({ audioElementsList: [new Audio(correctSound as string)] });
      setIsClickedCorrect(true);
    } else {
      setWrongGuesses(wrongGuesses + 1);
      playAudioSequence({ audioElementsList: [new Audio(wrongSound as string)] });
      setIsClickedWrong(true);
    }
  };
  const resetState = () => {
    setActiveItemKey(0);
    setIsStarted(false);
    setAnimationStyles({ animation: 'none' });
    setIsClicked(false);
    setIsClickedCorrect(false);
    setIsClickedWrong(false);
    setCorrectGuesses(0);
    setWrongGuesses(0);
  };

  //To replicate native app's behaviour
  //Tracks if the phased out item was correct and wasn't clicked so we need to play sound and increase wrongGuesses count
  useEffect(() => {
    if (activeItemKey) {
      if (task.sequence[activeItemKey - 1].isAnswer && !isClicked) {
        setWrongGuesses(wrongGuesses + 1);
        stopAudioSequence();
        playAudioSequence({ audioElementsList: [new Audio(wrongSound as string)] });
      }
      if (isClicked) {
        setIsClicked(false);
        setIsClickedCorrect(false);
        setIsClickedWrong(false);
      }
    }
  }, [activeItemKey, task]);

  //Launches timeout every time `activeItemKey` changes
  useEffect(() => {
    if (isStarted && activeItemKey < task.sequence.length) {
      const timeout =
        (ENTRANCE_ANIMATION_DURATION +
          task.sequence[activeItemKey].timeInterval +
          EXIT_ANIMATION_DURATION -
          ANIMATION_ADJUST_FACTOR) *
        1000;

      const timeoutId = window.setTimeout(() => {
        setActiveItemKey(activeItemKey + 1);
      }, timeout);

      setAnimationStyles({
        animationDuration: `${ENTRANCE_ANIMATION_DURATION}s, ${EXIT_ANIMATION_DURATION}s`,
        animationDelay: `0s, ${task.sequence[activeItemKey].timeInterval}s`,
      });
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [activeItemKey, isStarted, task]);

  useEffect(() => {
    if (activeItemKey === task.sequence.length) {
      const correctAmount = task.sequence.filter((item) => item.isAnswer).length;
      if (correctGuesses === correctAmount) {
        if (wrongGuesses > 0) {
          handleAnswer(AnswerResult.PARTIALLY);
        } else {
          handleAnswer(AnswerResult.CORRECT);
        }
      } else {
        handleAnswer(AnswerResult.WRONG);
      }
    }
  }, [activeItemKey, task]);

  //Reset everything on task change
  useEffect(() => resetState, [task]);

  return {
    activeItemKey,
    isStarted,
    handleStartClick,
    handleItemClick,
    animationStyles,
    isClickedCorrect,
    isClickedWrong,
  };
}
