import React, { FC } from 'react';

import { TaskViewMemoryBoard } from 'Shared/types/shared';

import { AnswerResult } from '../../../useTaskView';

import { useControls } from './useControls';
import { Choice } from './choice';

import styles from './index.module.scss';

interface ControlsProps {
  task: TaskViewMemoryBoard;
  handleAnswer: (result: AnswerResult) => void;
}

export const Controls: FC<ControlsProps> = ({ task, handleAnswer }) => {
  const { itemsList, handleClick } = useControls({ task, handleAnswer });

  return (
    <ul
      className={styles.controls}
      style={{
        gridTemplateColumns: `repeat(${task.columns}, 250px)`,
        gridTemplateRows: `repeat(${task.rows}, 250px)`,
      }}
    >
      {itemsList.map((item, index) => {
        return (
          <Choice
            key={index}
            index={index}
            choice={item}
            handleClick={handleClick}
            resourceUrl={task.resourceUrl}
          />
        );
      })}
    </ul>
  );
};
