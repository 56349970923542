import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ClinicianSetupFormShape } from 'App/activate/clinicianSetup/types';

import { saveUserInfo, getUserData } from 'Shared/actions/user';
import { User } from 'Shared/types/shared';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { loadActivityInitialScreen } from 'Shared/actions/therapy';

export interface ClinicianSetupProps {
  authUser: User;
}

export function useClinicianSetup({ authUser }: ClinicianSetupProps) {
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const hasCC = queryParams.has('returnUrl')
    ? new URL(queryParams.get('returnUrl'), document.URL).searchParams.has('cc')
    : false;

  const handleProfileUpdate = async (data: ClinicianSetupFormShape) => {
    try {
      //Updates user's info
      await saveUserInfo({ userId: authUser.user.userId, data })(dispatch);
      //Transitions from `clinician_setup` into `trialing` state
      await loadActivityInitialScreen({ patientId: authUser.user.userId })(dispatch);
      //Reads refreshed user data into the memory
      await getUserData()(dispatch);

      //This handles redirect back to the subscription page with the coupon
      if (hasCC) {
        return history.push(queryParams.get('returnUrl'));
      }

      return history.push('/subscribe?source=signup');
    } catch (e: unknown) {
      console.log(e);
    }
  };

  return {
    handleProfileUpdate,
    hasCC,
  };
}
