import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { getTherapiesCompositeKey } from 'Shared/utils';
import { PerformanceReportTask, PerformanceReportTaskLevel } from 'Shared/types/shared';

import itemStyles from './item.module.scss';

interface PerformanceAndDischargeTaskItemProps {
  therapy: PerformanceReportTask;
  levelKey: string;
  level: PerformanceReportTaskLevel;
}

export const PerformanceAndDischargeTaskItem: FC<PerformanceAndDischargeTaskItemProps> = ({
  therapy,
  levelKey,
  level,
}) => {
  return (
    <li className={itemStyles.item}>
      <header className={itemStyles.header}>
        <CTUI.Font.H4 variant="medium" id={getTherapiesCompositeKey(therapy.displayName, levelKey)}>
          Therapy activity: {therapy.displayName}, level {levelKey}
        </CTUI.Font.H4>
        <CTUI.Font.Text customClasses={itemStyles.description}>
          Instructions: {therapy.description}
        </CTUI.Font.Text>
        {Boolean(therapy.skillAreas.length) && (
          <CTUI.Font.Text customClasses={itemStyles.description}>
            Categories: {therapy.skillAreas.join().replaceAll('\\n', '')}
          </CTUI.Font.Text>
        )}
      </header>
      <table className={itemStyles.data}>
        <caption>
          <CTUI.Font.H4 variant="medium" customClasses={itemStyles.caption}>
            Performance:
          </CTUI.Font.H4>
        </caption>
        <thead>
          <tr>
            <th>
              <CTUI.Font.Text variant="medium">Metric (baseline)</CTUI.Font.Text>
            </th>
            <th>
              <CTUI.Font.Text variant="medium">Metric (latest)</CTUI.Font.Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <CTUI.Font.Text>
                Accuracy: {level.accuracy.baseline.value}
                {level.accuracy.unit}
              </CTUI.Font.Text>
            </td>
            <td>
              <CTUI.Font.Text>
                Accuracy: {level.accuracy.latest.value}
                {level.accuracy.unit}
              </CTUI.Font.Text>
            </td>
          </tr>
          <tr>
            <td>
              <CTUI.Font.Text>
                Latency (avg): {level.latency.baseline.value}
                {level.latency.unit}
              </CTUI.Font.Text>
            </td>
            <td>
              <CTUI.Font.Text>
                Latency (avg): {level.latency.latest.value}
                {level.latency.unit}
              </CTUI.Font.Text>
            </td>
          </tr>
          <tr>
            <td>
              <CTUI.Font.Text>Notes: TBD</CTUI.Font.Text>
            </td>
            <td>
              <CTUI.Font.Text>Notes: TBD</CTUI.Font.Text>
            </td>
          </tr>
        </tbody>
      </table>
    </li>
  );
};
