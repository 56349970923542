import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `We value your opinion! Please share your feedback 
  via this survey, which will help us optimize our product and ensure a better user experience 
  for you and others. Thank you!`;
  return (
    <Helmet>
      <title>Share Your Feedback | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
