import React, { FC } from 'react';
import classNames from 'classnames';

import type { ImageStimulus } from 'Shared/types/shared';

import sharedStyles from './shared.module.scss';
import styles from './image.module.scss';

interface ImageComponentProps {
  stimulus: ImageStimulus;
  resourceUrl: string;
}

export const ImageComponent: FC<ImageComponentProps> = ({ stimulus, resourceUrl }) => {
  return (
    <div className={classNames(sharedStyles.stimulus, styles.image)}>
      <img src={resourceUrl + stimulus.imagePath} alt={stimulus.text} title={stimulus.text} />
    </div>
  );
};
