import React, { FC } from 'react';

import { AddNewUser } from './addNewUser';
import { Search } from './search';
import { FilterByUserType } from './filterByUserType';
import { useHeaderActions } from './useHeaderActions';

import styles from './index.module.scss';

interface HeaderActionsProps {
  institutionId: number;
}

export const HeaderActions: FC<HeaderActionsProps> = ({ institutionId }) => {
  const { searchTerm, doSearch, type, filterByType } = useHeaderActions();

  return (
    <div className={styles.headerActions}>
      <AddNewUser institutionId={institutionId} />
      <Search searchTerm={searchTerm} doSearch={doSearch} />
      <FilterByUserType type={type} filterByType={filterByType} />
    </div>
  );
};
