import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { getUserSubscription } from 'Shared/actions/user';
import { getDisplayName } from 'Shared/utils';
import { RootState } from 'Shared/types/shared';

export function useAuthUserFlow() {
  const dispatch: Dispatch = useDispatch();
  const authUser = useSelector((state: RootState) => state.user.data);
  const subscriptionInfo = useSelector((state: RootState) => state.user.subscription);
  const subscriptionError = useSelector((state: RootState) => state.user.errors.subscription);
  const [isLoading, setIsLoading] = useState(true);

  const displayName = getDisplayName({ userObject: authUser });
  const token = localStorage.getItem('accessToken');

  const fetchUserSubscriptionInfo = async () => {
    try {
      await getUserSubscription({ token })(dispatch);
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchUserSubscriptionInfo();
  }, []);

  return {
    isLoading,
    displayName,
    subscriptionInfo,
    token,
    subscriptionError,
  };
}
