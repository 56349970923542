import React, { FC } from 'react';

import { NonAuthBlock } from './nonAuthBlock';
import { AuthBlock } from './authBlock';
import { useLoginStatusBar } from './useLoginStatusBar';

export const LoginStatusBar: FC = () => {
  const { authUser, isInSubscriptionProcess, isInDischargeHomeworkActivation } =
    useLoginStatusBar();

  //This is specific request to hide login/signup buttons if we're in one of the specified flows
  if (isInSubscriptionProcess || isInDischargeHomeworkActivation) {
    return null;
  }

  if (authUser) {
    return <AuthBlock authUser={authUser} />;
  }

  return <NonAuthBlock />;
};
