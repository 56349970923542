import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { ClinicianDashboardSummary } from 'Shared/types/shared';

import styles from './statsBlock.module.scss';

interface StatsTotalExercisesProps {
  Summary: ClinicianDashboardSummary;
}

export const StatsTotalExercises: FC<StatsTotalExercisesProps> = ({
  Summary: { totalExercises, addedTotalExercises },
}) => {
  return (
    <div className={styles.statsBlock}>
      <CTUI.Font.Text customClasses={styles.counter}>
        {totalExercises.toLocaleString()}
      </CTUI.Font.Text>
      <span className={styles.placement}>
        <FontAwesomeIcon icon={faChartColumn} className={styles.mainIcon} />
      </span>
      <CTUI.Font.H4 customClasses={styles.title}>Total Exercises Done</CTUI.Font.H4>
      <CTUI.Font.Text2 customClasses={styles.diff}>
        <span>
          <FontAwesomeIcon icon={faCaretUp} /> {addedTotalExercises.toLocaleString()}
        </span>{' '}
        from last week
      </CTUI.Font.Text2>
    </div>
  );
};
