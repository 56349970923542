import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { resetPassword } from 'Shared/actions/user';

import { ForgotPasswordFormDataStructure } from './constants';

export function useMain() {
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();

  const handleResetPassword = async (formData: ForgotPasswordFormDataStructure) => {
    await resetPassword(formData)(dispatch);
    history.push('/forgot-password/guide');
  };

  return { handleResetPassword };
}
