import React, { FC } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { useInstitutionForm } from './useInstitutionForm';

import styles from './institutionForm.module.scss';

const InstitutionFormComponent: FC<InjectedFormProps> = ({
  error,
  handleSubmit,
  initialValues,
  pristine,
  reset,
  submitting,
}) => {
  const {
    countriesOptions,
    statesOptions,
    provincesOptions,
    handleCountryChange,
    handleFormReset,
    shouldShowStatesList,
    shouldShowProvincesList,
  } = useInstitutionForm({
    initialValues,
    reset,
  });

  return (
    <CTUI.Form.Form
      handleSubmit={handleSubmit}
      error={error}
      customClasses={styles.institutionForm}
      actionsWrapperClasses={styles.actions}
      actions={
        <>
          <CTUI.Form.SubmitButton
            isPristine={pristine}
            isSubmitting={submitting}
            customClasses={styles.submitButton}
            icon={faFloppyDisk}
            text="Update"
          />
          <CTUI.Form.ResetButton
            isPristine={pristine}
            isSubmitting={submitting}
            customClasses={styles.resetButton}
            onClick={handleFormReset}
            text="Reset"
          />
        </>
      }
    >
      <Field
        name="facilityName"
        component={CTUI.Form.Field}
        type="text"
        required={true}
        placeholder="Institution / Organization name"
        label="Institution / Organization name"
      />
      <Field
        name="country"
        component={CTUI.Form.Select}
        type="select"
        label="Country"
        options={countriesOptions}
        required={true}
        onChange={handleCountryChange}
      />
      <div className={styles.inputGroup}>
        <Field
          name="address1"
          component={CTUI.Form.Field}
          type="text"
          required={true}
          placeholder="Street address"
          label="Street address"
        />
        <Field
          name="address2"
          component={CTUI.Form.Field}
          type="text"
          required={true}
          placeholder="Apt / Suite"
          label="Apt / Suite (optional)"
          customClasses={styles.apt}
        />
      </div>
      <div className={styles.inputGroup}>
        {shouldShowStatesList && (
          <Field
            name="state"
            component={CTUI.Form.Select}
            type="select"
            label="State"
            options={statesOptions}
            required
            customClasses={styles.state}
          />
        )}
        {shouldShowProvincesList && (
          <Field
            name="state"
            component={CTUI.Form.Select}
            type="select"
            label="Province"
            options={provincesOptions}
            required
            customClasses={styles.state}
          />
        )}
        <Field
          name="city"
          component={CTUI.Form.Field}
          type="text"
          required={true}
          placeholder="City"
          label="City"
        />
        <Field
          name="postalCode"
          component={CTUI.Form.Field}
          type="text"
          required={true}
          placeholder="Zip / Postal code"
          label="Zip / Postal code"
          customClasses={styles.zip}
        />
      </div>
    </CTUI.Form.Form>
  );
};

const InstitutionForm = reduxForm({ form: 'institutionForm' })(InstitutionFormComponent);

export { InstitutionForm };
