import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { FORGOT_PASSWORD_FORM_NAME } from './constants';

import sharedStyles from './shared.module.scss';

const ForgotPasswordFormComponent: FC<InjectedFormProps> = ({
  handleSubmit,
  error,
  pristine,
  submitting,
}) => {
  return (
    <CTUI.Form.Form
      handleSubmit={handleSubmit}
      customClasses="forgotPasswordForm"
      error={error}
      actions={
        <CTUI.Form.SubmitButton
          text="Submit"
          variant="primary"
          customClasses={sharedStyles.button}
          isSubmitting={submitting}
          isPristine={pristine}
        />
      }
    >
      <Field
        name="username"
        label="Please enter the email or username associated with your Constant Therapy account."
        component={CTUI.Form.Field}
        type="text"
        required={true}
        autoFocus={true}
        autoComplete="on"
      />
    </CTUI.Form.Form>
  );
};

const ForgotPasswordForm = reduxForm({
  form: FORGOT_PASSWORD_FORM_NAME,
})(ForgotPasswordFormComponent);

export { ForgotPasswordForm };
