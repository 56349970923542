import React, { FC, SyntheticEvent } from 'react';

import { DropdownProps } from 'Shared/components/layout/popperMenu';

import styles from './dropdown.module.scss';

interface DocumentationExportMenuDropdownProps extends DropdownProps {
  showProgressPopup: (e: SyntheticEvent) => void;
  showDailySessionPopup: (e: SyntheticEvent) => void;
  showDischargePopup: (e: SyntheticEvent) => void;
}

export const Dropdown: FC<DocumentationExportMenuDropdownProps> = ({
  popper: { ref, style, attributes },
  showProgressPopup,
  showDailySessionPopup,
  showDischargePopup,
}) => {
  return (
    <ul className={styles.documentationExportMenu} ref={ref} style={style} {...attributes}>
      <li onClick={showDailySessionPopup}>
        <h3>Daily session note</h3>
        <p>
          Pulls all of the therapy activities and exercises you completed with a patient on a
          specific day. Great for writing daily session notes!
        </p>
      </li>
      <li onClick={showProgressPopup}>
        <h3>Progress note</h3>
        <p>
          Pulls all of the therapy activities and exercises a patient completed over a specific
          period. Great for writing multi-session progress notes!
        </p>
      </li>
      <li onClick={showDischargePopup}>
        <h3>Discharge summary</h3>
        <p>
          Pulls baseline and most recent data for all therapy activities. Includes information to
          help patients continue with Constant Therapy at home.
        </p>
      </li>
    </ul>
  );
};
