import React, { FC, RefObject, useContext } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

import { Tooltip } from 'Shared/components/tooltip';

import { AccuracyLatency, ColumnType } from '../constants';
import { DetailedPerformanceContext } from '../context';

import { TasksFilter } from './tasksFilter';
import { useFiltersSet } from './useFiltersSet';

import sharedStyles from './shared.module.scss';
import styles from './filtersSet.module.scss';

const tooltipOptions = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-10, 10],
      },
    },
  ],
  placement: 'bottom' as const,
  classes: styles.tooltip,
  theme: 'light' as const,
};

const columnOptions = [
  <option value={ColumnType.bySchedule} key={0}>
    By Schedule
  </option>,
  <option value={ColumnType.byDay} key={1}>
    By Day
  </option>,
  <option value={ColumnType.byWeek} key={2}>
    By Week
  </option>,
];

interface FiltersSetProps {
  modalsParent?: RefObject<HTMLBaseElement>; // We need to pass reference to the parent element so
  // modals are rendered within it and click anywhere in the modal won't trigger a callback passed
  // into "useClickOutside" hook, as opposed to the default behaviour when modals are rendered
  // directly into `document.body`
}

export const FiltersSet: FC<FiltersSetProps> = ({ modalsParent }) => {
  const {
    accuracyLatencyToggle,
    handleAccuracyLatencyChange,
    columnType,
    handleColumnTypeChange,
    dateRange,
    handleDateRangeChange,
    tasksSelectedList,
    handleTasksSelectedListChange,
  } = useContext(DetailedPerformanceContext);
  const {
    dateRangeWrapperRef,
    dateRangeReferenceElement,
    setDateRangeReferenceElement,
    isDateRangeTooltipShown,
    toggleDateRangeToolTip,
    tasksFilterWrapperRef,
    tasksFilterReferenceElement,
    setTasksFilterReferenceElement,
    isTasksFilterTooltipShown,
    toggleTasksFilterToolTip,
    modalRef,
    closeModalsHandler,
  } = useFiltersSet();

  return (
    <div className={styles.filtersSet}>
      <div className={styles.filterField} ref={tasksFilterWrapperRef}>
        <button
          type="button"
          className={sharedStyles.fakeSelect}
          onClick={toggleTasksFilterToolTip}
          ref={setTasksFilterReferenceElement}
        >
          Filter Tasks
        </button>
        {isTasksFilterTooltipShown && tasksSelectedList && !isMobile && (
          <Tooltip
            referenceElement={tasksFilterReferenceElement}
            options={{ ...tooltipOptions, classes: styles.tasksFilterTooltip }}
          >
            <TasksFilter
              tasksSelectedList={tasksSelectedList}
              handleTasksSelectedListChange={handleTasksSelectedListChange}
            />
          </Tooltip>
        )}
        {isTasksFilterTooltipShown && tasksSelectedList && isMobile && (
          <CTUI.Modal
            ref={modalRef}
            id={styles.mobileModal}
            closeButtonHandler={closeModalsHandler}
            closeButtonClasses={styles.closeButton}
            container={modalsParent.current}
          >
            <TasksFilter
              tasksSelectedList={tasksSelectedList}
              handleTasksSelectedListChange={handleTasksSelectedListChange}
            />
          </CTUI.Modal>
        )}
      </div>
      <div className={styles.filterField} ref={dateRangeWrapperRef}>
        <button
          type="button"
          className={sharedStyles.fakeSelect}
          onClick={toggleDateRangeToolTip}
          ref={setDateRangeReferenceElement}
        >
          {dateRange}
        </button>
        {isDateRangeTooltipShown && !isMobile && (
          <Tooltip referenceElement={dateRangeReferenceElement} options={tooltipOptions}>
            <CTUI.DateRangeSelector
              submitButton={{
                onClick: (startDate, endDate) => {
                  handleDateRangeChange(startDate, endDate);
                  toggleDateRangeToolTip();
                },
              }}
            />
          </Tooltip>
        )}
        {isDateRangeTooltipShown && isMobile && (
          <CTUI.Modal
            ref={modalRef}
            id={styles.mobileModal}
            closeButtonHandler={closeModalsHandler}
            closeButtonClasses={styles.closeButton}
            container={modalsParent.current}
          >
            <CTUI.DateRangeSelector
              submitButton={{
                onClick: (startDate, endDate) => {
                  handleDateRangeChange(startDate, endDate);
                  toggleDateRangeToolTip();
                },
              }}
              dayPickerRangeControllerProps={{ numberOfMonths: 1, orientation: 'horizontal' }}
            />
          </CTUI.Modal>
        )}
      </div>
      <div className={styles.filterField}>
        <div
          className={classNames(sharedStyles.fakeToggle, {
            [sharedStyles.a]: accuracyLatencyToggle === AccuracyLatency.accuracy,
            [sharedStyles.b]: accuracyLatencyToggle === AccuracyLatency.latency,
          })}
          onClick={handleAccuracyLatencyChange}
        >
          <span className={sharedStyles.valueA}>Accuracy</span>
          <span className={sharedStyles.toggle} />
          <span className={sharedStyles.valueB}>Latency</span>
        </div>
      </div>
      <div className={styles.filterField}>
        <select
          className={sharedStyles.fakeSelect}
          defaultValue={columnType}
          onChange={handleColumnTypeChange}
        >
          {columnOptions}
        </select>
      </div>
    </div>
  );
};
