import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLowVision } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { FloatingPage } from 'Shared/components/layout/floatingPage';

import styles from './noAccess.module.scss';

export const NoAccess: FC = () => {
  return (
    <FloatingPage
      customClasses={styles.noAccessModal}
      header={
        <h1 className={classNames('h1', styles.title)}>
          <FontAwesomeIcon icon={faLowVision} className={styles.icon} />
          Access Denied.
        </h1>
      }
    >
      <p>Sorry, you don&apos;t have access to this page.</p>
    </FloatingPage>
  );
};
