import React, { FC, useRef } from 'react';
import { CTUI, useClickOutside } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';

import { PATIENT_BROCHURE_URL } from 'Shared/constants';
import clinicianDashboardPreviewImage from 'Shared/src/images/dashboard.png';

import styles from './zeroHomeExercisesPopup.module.scss';

interface ZeroHomeExercisesPopupProps {
  closePopupHandler: () => void;
  downloadPDFClickHandler: () => void;
}

export const ZeroHomeExercisesPopup: FC<ZeroHomeExercisesPopupProps> = ({
  closePopupHandler,
  downloadPDFClickHandler,
}) => {
  const modalRef = useRef(null);

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      closePopupHandler();
    }
  });

  return (
    <CTUI.Modal
      ref={modalRef}
      id={styles.zeroHomeExercisesPopup}
      closeButtonHandler={closePopupHandler}
      header={
        <h1 className="h1">
          <FontAwesomeIcon
            icon={faNewspaper}
            title="Download Patient Brochure"
            className={styles.titleIcon}
          />
          Download Patient Brochure
        </h1>
      }
      footerClasses={styles.footer}
      footer={
        <a
          href={PATIENT_BROCHURE_URL}
          target="_blank"
          rel="noreferrer"
          onClick={downloadPDFClickHandler}
          className="button button--pink--inverted"
        >
          Download Patient Brochure
        </a>
      }
    >
      <div className={styles.zeroHomeExercisesPopupBody}>
        <div className={styles.message}>
          <h3 className={classNames('h3', styles.subTitle)}>Important Message</h3>
          <p>
            None of your patients are exercising at home. Those who do complete an average of four
            times more exercises.
            <br />
            <br />
            Encourage your patients to exercise at home by providing them our{' '}
            <a
              href={PATIENT_BROCHURE_URL}
              target="_blank"
              rel="noreferrer"
              onClick={downloadPDFClickHandler}
            >
              Patient Brochure.
            </a>
            <br />
            <br />
            When your patients begin exercising at home, you will see their status change from “Not
            started” to “On track.”
          </p>
        </div>
        <div className={styles.preview}>
          <img
            src={clinicianDashboardPreviewImage}
            alt="Clinician Dashboard Preview"
            title="Clinician Dashboard Preview"
          />
        </div>
      </div>
    </CTUI.Modal>
  );
};
