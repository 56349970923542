import React, { FC } from 'react';

import { AddNewUserProps, useAddNewUser } from './useAddNewUser';
import { ActionButton } from './actionButton';
import { InvitationPopup } from './invitationPopup';

import styles from './index.module.scss';

export const AddNewUser: FC<AddNewUserProps> = ({ institutionId }) => {
  const { isPopupShown, addUserHandler, inviteUserHandler, hidePopup } = useAddNewUser({
    institutionId,
  });

  return (
    <div className={styles.addNewUser}>
      <ActionButton clickHandler={addUserHandler} />
      {isPopupShown && (
        <InvitationPopup
          submitHandler={inviteUserHandler}
          hidePopup={hidePopup}
          institutionId={institutionId}
        />
      )}
    </div>
  );
};
