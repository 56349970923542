import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { User, USER_TYPES } from 'Shared/types/shared';
import { getDownloadDestination } from 'Shared/utils';
import { FloatingPage } from 'Shared/components/layout/floatingPage';
import successfullyActivatedImage from 'Shared/src/themes/2019/images/icAccountActivated.svg';

import styles from './success.module.scss';

interface SuccessProps {
  successMessage?: string;
  userType: USER_TYPES;
}

export const Success: FC<SuccessProps> = ({
  successMessage = "You've successfully accepted invitation",
  userType,
}) => {
  return (
    <FloatingPage
      customClasses={styles.defaultActivationSuccess}
      header={
        <h3 className={classNames('h3', styles.title)}>
          <img
            src={successfullyActivatedImage as string}
            alt="Account successfully activated"
            title="Account successfully activated"
            className={styles.icon}
          />
          {successMessage}
        </h3>
      }
    >
      {userType === USER_TYPES.CLINICIAN ? (
        <>
          <p className={styles.message}>
            You can log in to your{' '}
            <a href={getDownloadDestination({ user: { type: userType } } as User)}>
              Constant Therapy Clinician app
            </a>{' '}
            on an iOS or Android device to continue using Constant Therapy with your patients.
          </p>
          <p className={styles.message}>
            You can also access your <Link to="/login">Clinician Web Dashboard</Link> by clicking
            this link and logging in.
          </p>
        </>
      ) : (
        <>
          <p className={styles.message}>
            You can log in to your{' '}
            <a href={getDownloadDestination({ user: { type: userType } } as User)}>
              Constant Therapy app
            </a>{' '}
            on an iOS or Android device to continue practicing.
          </p>
          <p className={styles.message}>
            You can also access your <Link to="/login">Web Report</Link> by clicking this link and
            logging in.
          </p>
        </>
      )}
    </FloatingPage>
  );
};
