import { useEffect } from 'react';

export function use3rdParty({
  scriptUrl,
  onLoadHandler,
  deps = [],
}) {
  useEffect(() => {
    const serviceScript = document.createElement('script');

    serviceScript.type = 'application/javascript';
    serviceScript.src = scriptUrl;
    serviceScript.async = true;

    document.body.appendChild(serviceScript);

    serviceScript.addEventListener('load', () => {
      if (typeof onLoadHandler === 'function') {
        onLoadHandler();
      }
    });
  }, [...deps, scriptUrl]);
}
