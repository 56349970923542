import { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

import sharedModalStyles from './modals/sharedModalStyles.module.scss';

export function useActionCell() {
  const cellRef = useRef(null);
  const [menuTriggerElement, setMenuTriggerElement] = useState<Element>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);
  const [isMenuShown, setIsMenuShown] = useState(false);
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    menuTriggerElement,
    popperElement,
    {
      strategy: 'fixed',
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-10, 0],
          },
        },
      ],
    }
  );

  useClickOutside(cellRef, (e: MouseEvent) => {
    //We don't want to close menu if click happens when overlay is in place
    if (
      isMenuShown &&
      e.target instanceof HTMLElement &&
      !e.target.closest(`.${sharedModalStyles.actionCellModal}`) && // If click has happened on modal's overlay
      !e.target.querySelector(`:scope .${sharedModalStyles.actionCellModal}`) // If click has happened on the element inside modal
    ) {
      setIsMenuShown(false);
    }
  });

  return {
    cellRef,
    setMenuTriggerElement,
    popper: {
      ref: setPopperElement,
      style: popperStyles.popper,
      attributes: popperAttributes.popper,
    },
    isMenuShown,
    toggleMenu: () => {
      setIsMenuShown(!isMenuShown);
    },
    hideMenu: () => {
      setIsMenuShown(false);
    },
  };
}
