import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { DAILY_SESSION_NOTE_BRIEF_TYPE_ID, REPORT_TYPES } from 'Shared/constants';
import { getTherapiesCompositeKey } from 'Shared/utils';
import { Spinner } from 'Shared/components/spinner';
import { PerformanceReportTask, User } from 'Shared/types/shared';

import { useNote } from '../shared/useNote';
import { NoteActions } from '../shared/actions';
import { instanceOfPerformanceReport } from '../../../shared/reportUtils';

import { DailySessionNoteBriefTherapyItem } from './brief';
import { DailySessionNoteDetailedTherapyItem } from './detailed';
import { EmptyNote } from './emptyNote';

import sharedStyles from '../shared/note.module.scss';

interface DailySessionNoteProps {
  patient: User;
}

interface DailySessionNoteParams {
  type: string;
  date: string;
}

export const DailySessionNote: FC<DailySessionNoteProps> = ({ patient }) => {
  const {
    report,
    componentRef,
    textToCopy,
    handleCopy,
    isCopied,
    handlePrint,
    popper,
    arrow,
    setReferenceElement,
  } = useNote({
    patient,
    reportType: REPORT_TYPES.DAILY_SESSION_NOTE,
    ignoreStartDateForBaseline: 1,
  });
  const { type, date } = useParams<DailySessionNoteParams>();

  let reportBody = <EmptyNote type={type} />;

  if (!report) {
    return <Spinner />;
  }

  if (instanceOfPerformanceReport(report)) {
    return null; //TODO:
  }

  if (Object.values(report.tasks).length) {
    reportBody = (
      <ul className="therapies">
        {Object.values(report.tasks).map((therapy: PerformanceReportTask) => {
          return Object.entries(therapy.levels).map(([levelKey, level]) => {
            if (type === DAILY_SESSION_NOTE_BRIEF_TYPE_ID) {
              return (
                <DailySessionNoteBriefTherapyItem
                  key={getTherapiesCompositeKey(therapy.displayName, levelKey)}
                  therapy={therapy}
                  levelKey={levelKey}
                  level={level}
                />
              );
            }
            return (
              <DailySessionNoteDetailedTherapyItem
                key={getTherapiesCompositeKey(therapy.displayName, levelKey)}
                therapy={therapy}
                levelKey={levelKey}
                level={level}
              />
            );
          });
        })}
      </ul>
    );
  }

  return (
    <div className={sharedStyles.note} ref={componentRef}>
      <header className={sharedStyles.preHeader}>
        <NoteActions
          textToCopy={textToCopy}
          handleCopy={handleCopy}
          handlePrint={handlePrint}
          setReferenceElement={setReferenceElement}
          isCopied={isCopied}
          popper={popper}
          arrow={arrow}
        />
      </header>
      <header className={sharedStyles.header}>
        <h1 className="h1">
          <FontAwesomeIcon icon={faFileLines} className={sharedStyles.mainIcon} />
          Daily session note - {type.charAt(0).toUpperCase() + type.slice(1)}
        </h1>
        <CTUI.Font.H2 customClasses={sharedStyles.headerSubTitle}>
          This report is to help therapists or other healthcare providers update their records.
        </CTUI.Font.H2>
      </header>
      <header className={sharedStyles.patientInfo}>
        <CTUI.Font.H4 variant="medium" customClasses={sharedStyles.patientInfoTitle}>
          User name: {report.patientName}
        </CTUI.Font.H4>
        <CTUI.Font.Text>
          Constant Therapy exercises completed in clinic:{' '}
          <b>{moment(date).format('MMMM D, YYYY')}</b>
        </CTUI.Font.Text>
      </header>
      {reportBody}
    </div>
  );
};
