import queryString from 'query-string';
import { Dispatch } from 'redux';

import { fetchWrapper } from 'Shared/fetchWrapper';
import { getBaseUrl } from 'Shared/utils';
import {
  SELECT_PATIENT_TYPE,
  CLEAR_PATIENT_TYPE,
  SAVE_NEW_PATIENT,
  SAVE_EXISTING_PATIENT,
} from 'Shared/types/redux';
import { PATIENT_TYPES } from 'Shared/types/shared';
import { AddExistingPatientForm } from 'Shared/types/forms';
import { AddNewPatientResponse } from 'Shared/types/api';

export const selectPatientType = (patientType: PATIENT_TYPES) => (dispatch: Dispatch) => {
  dispatch({
    type: SELECT_PATIENT_TYPE,
    payload: patientType,
  });
};

export const clearPatientType = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_PATIENT_TYPE,
    payload: null,
  });
};

export const saveNewPatient = (data: object) => async (dispatch: Dispatch) => {
  const formattedParams = queryString.stringify(
    {
      token: localStorage.getItem('accessToken'),
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  return (await fetchWrapper.post(
    `${getBaseUrl({
      apiSection: 'userSignup',
    })}/api/clinician/clinician_setup/patient?${formattedParams}`,
    {
      dispatch,
      types: SAVE_NEW_PATIENT,
    },
    data,
    {
      isSubmission: true,
      skipAccessToken: true,
    }
  )) as AddNewPatientResponse;
};

export const saveExistingPatient =
  ({ data, clinicianId }: { data: AddExistingPatientForm; clinicianId: number }) =>
  (dispatch: Dispatch) => {
    return fetchWrapper.post(
      `${getBaseUrl()}/api/clinician/${clinicianId}/patient/add`,
      {
        dispatch,
        types: SAVE_EXISTING_PATIENT,
      },
      {
        patientUsername: data.username,
        patientPassword: data.password,
        clinicianToken: localStorage.getItem('accessToken'),
      },
      {
        isSubmission: true,
      }
    );
  };
