import React, { FC } from 'react';
import classNames from 'classnames';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';

import { ADD_PATIENT_FORM_NAME, conditionsPeriodList } from '../../shared/constants';

import stepStyles from '../../shared/step.module.scss';

interface ConditionsPeriodComponentProps {
  previousPage: () => void;
}

const ConditionsPeriodComponent: FC<
  ConditionsPeriodComponentProps & InjectedFormProps<object, ConditionsPeriodComponentProps>
> = ({ handleSubmit, previousPage, error, pristine, submitting }) => {
  return (
    <FloatingPage
      customClasses={stepStyles.step}
      header={
        <>
          <ProgressBar currentStep={4} totalSteps={5} />
          <h1 className={classNames('h1', stepStyles.title)}>
            When was your condition first diagnosed?
          </h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses={stepStyles.form}
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="primary-inverted"
              customClasses={stepStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text="Next &rarr;"
              variant="primary"
              customClasses={stepStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="conditionsPeriod"
          component={CTUI.Form.RadioGroup}
          options={conditionsPeriodList}
          type="radioGroup"
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const ConditionsPeriod = reduxForm<object, ConditionsPeriodComponentProps>({
  form: ADD_PATIENT_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ConditionsPeriodComponent);

export { ConditionsPeriod };
