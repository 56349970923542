import React, { FC } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { PerformanceReportDomain } from 'Shared/types/shared';

import styles from './chartDiff.module.scss';

interface ChartDiffProps {
  skillArea: PerformanceReportDomain;
}

export const ChartDiff: FC<ChartDiffProps> = ({ skillArea: { baseline, latest } }) => {
  const diff = latest.value - baseline.value;
  const chartDiffClassName = classNames(styles.chartDiff, {
    [styles.negative]: diff < 0,
  });

  return (
    <div className={chartDiffClassName}>
      {diff !== 0 && (
        <>
          <FontAwesomeIcon icon={diff > 0 ? faCaretUp : faCaretDown} className={styles.icon} />
          {Math.abs(diff)}
        </>
      )}
    </div>
  );
};
