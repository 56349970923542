import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import md5 from 'md5';
import 'react-dates/initialize';
import { DayPicker } from 'react-dates';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PatientCalendarDay } from 'Shared/types/shared';

import { ExpandableRowCalendarDay } from './day';
import { ExpandableRowCalendarInfo } from './info';

import sharedStyles from '../widget.module.scss';
import styles from './index.module.scss';

interface ExpandableRowCalendarProps {
  patientId: number;
  calendar: PatientCalendarDay[];
}

export const ExpandableRowCalendar: FC<ExpandableRowCalendarProps> = ({ patientId, calendar }) => {
  const widgetClasses = classNames(sharedStyles.widget, styles.calendar);

  return (
    <div className={widgetClasses}>
      <DayPicker
        noBorder={true}
        daySize={43}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel={true}
        enableOutsideDays={false}
        noNavButtons={true}
        renderDayContents={(day) => <ExpandableRowCalendarDay day={day} calendar={calendar} />}
        renderCalendarInfo={() => <ExpandableRowCalendarInfo />}
      />
      <Link
        to={`/dashboard/patient/${md5(patientId.toString())}/calendar`}
        title="Review Therapy Calendar"
        className={classNames('yeahBabe', sharedStyles.link)}
      >
        Review Therapy Calendar{' '}
        <FontAwesomeIcon icon={faChevronRight} className={sharedStyles.chevronRightIcon} />
      </Link>
    </div>
  );
};
