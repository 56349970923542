import React, { createContext, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';

interface FfmpegContextProps {
  isLoaded: boolean;
  transcode: ({ inputFile }: { inputFile: string | File | Blob }) => Promise<Blob>;
}

export const FfmpegContext = createContext<FfmpegContextProps>(null);

interface FfmpegContextProviderProps {
  children: ReactNode;
}

export const FfmpegContextProvider: FC<FfmpegContextProviderProps> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const ffmpegRef = useRef(new FFmpeg());

  const load = async () => {
    const baseURL = document.location.origin;
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('log', ({ message }) => {
      console.log(message);
    });
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    try {
      await ffmpeg.load({
        coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
        wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
      });
    } catch (e) {
      console.log('FFmpeg loading error', e);
    }
    setIsLoaded(true);
  };

  const transcode = async ({ inputFile }: { inputFile: string | File | Blob }) => {
    const ffmpeg = ffmpegRef.current;
    await ffmpeg.writeFile('input.webm', await fetchFile(inputFile));
    await ffmpeg.exec([
      '-i',
      'input.webm',
      '-acodec',
      'pcm_s16le',
      '-ac',
      '1',
      '-ar',
      '16000',
      'output.wav',
    ]);
    const data = await ffmpeg.readFile('output.wav');
    return new Blob([data], { type: 'audio/wav' });
  };

  useEffect(() => {
    void load();
  }, []);

  return (
    <FfmpegContext.Provider value={{ isLoaded, transcode }}>{children}</FfmpegContext.Provider>
  );
};
