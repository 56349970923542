import React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { DASHBOARD } from 'Shared/types/events';
import { logEvent } from 'Shared/actions/event';
import { RootState } from 'Shared/types/shared';
import { getDisplayName } from 'Shared/utils';

export interface EmptyReportProps {
  surveillanceMode: boolean;
}

export function useEmptyReport({ surveillanceMode }: EmptyReportProps) {
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();
  const authUser = useSelector((state: RootState) => state.user.data);
  const displayName = getDisplayName({ userObject: authUser });

  const downloadHandlerAsync = async (pathname: string) => {
    try {
      await logEvent({
        page: DASHBOARD.EMPTY_REPORT_DOWNLOAD,
        data: { surveillanceMode },
      })(dispatch, store.getState.bind(store));
    } catch (e: unknown) {
      console.log(e);
    } finally {
      history.push(pathname);
    }
  };

  return {
    downloadHandler: (e: React.MouseEvent<HTMLAnchorElement>) => {
      //Ticket: https://constanttherapy.atlassian.net/browse/WEBAPP-477
      //Explanation: https://legacy.reactjs.org/docs/legacy-event-pooling.html
      e.persist();
      e.preventDefault();
      void downloadHandlerAsync(e.currentTarget.pathname);
    },
    displayName,
    authUser,
  };
}
