import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { FloatingPage } from 'Shared/components/layout/floatingPage';

import sharedStyles from './sharedStyles.module.scss';

interface DefaultProps {
  downloadHandler: (e: React.MouseEvent) => void;
  displayName: string;
}

export const Default: FC<DefaultProps> = ({ downloadHandler, displayName }) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.emptyReport}
      header={<h1 className={classNames('h1', sharedStyles.title)}>Hi {displayName}!</h1>}
      footer={
        <Link to="/download" className={sharedStyles.link} onClick={downloadHandler}>
          <CTUI.Button text="Download Constant Therapy" customClasses={sharedStyles.button} />
        </Link>
      }
    >
      <p>
        Welcome to Constant Therapy. This is your activity dashboard. It&apos;s a little empty right
        now, but once you start using the app you can view your progress by logging in here.
      </p>
      <p className={sharedStyles.hint}>Download the app now to get started!</p>
    </FloatingPage>
  );
};
