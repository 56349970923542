import React, { FC } from 'react';

import { DailySessionNoteAction } from './dailySessionNoteAction';
import { EditHomeworkScheduleAction } from './editHomeworkScheduleAction';

import styles from './index.module.scss';

interface ActionsSubCellProps {
  patientId: number;
}

export const ActionsSubCell: FC<ActionsSubCellProps> = ({ patientId }) => {
  return (
    <div className={styles.actionCell}>
      <ul className={styles.actionCellList}>
        <li>
          <DailySessionNoteAction patientId={patientId} />
        </li>
        <li>
          <EditHomeworkScheduleAction patientId={patientId} />
        </li>
      </ul>
    </div>
  );
};
