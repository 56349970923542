import React, { FC } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './progressBar.module.scss';

interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
}

export const ProgressBar: FC<ProgressBarProps> = ({ currentStep, totalSteps }) => {
  return (
    <ul className={styles.progressBar}>
      {Array.from(new Array(totalSteps)).map((element, index) => {
        const stepClass = classNames(styles.step, { [styles.active]: index === currentStep - 1 });
        const icon = index === currentStep - 1 ? faCircleCheck : faCircle;
        return (
          <li className={stepClass} key={index}>
            <FontAwesomeIcon icon={icon} className={styles.icon} />
          </li>
        );
      })}
    </ul>
  );
};
