import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, getFormValues } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import { SURVEY_CAMPAIGN_FORM_NAME, SurveyCampaignForm } from 'Shared/types/forms';

import { ProgressBar } from '../progressBar';

import sharedStyles from '../shared.module.scss';
import styles from './additionalInfo.module.scss';

interface AdditionalInfoProps {
  previousPage: () => void;
}

const AdditionalInfoComponent: FC<
  AdditionalInfoProps & InjectedFormProps<SurveyCampaignForm, AdditionalInfoProps>
> = ({ handleSubmit, previousPage, error, pristine, submitting }) => {
  const formValues = useSelector(getFormValues(SURVEY_CAMPAIGN_FORM_NAME)) as SurveyCampaignForm;

  let title =
    formValues.difficulty === 'perfect'
      ? "Do you have any other questions or comments you'd like to share?"
      : "Can you share a little more about what's too easy or too hard?";

  if (formValues.phase === '2' && formValues.difficulty === 'perfect') {
    title = 'Anything you’d like to add? Add here: (optional)';
  }

  if (formValues.phase === '3' && formValues.difficulty === 'perfect') {
    title =
      'We value your opinion! Share your feedback below. ' +
      'How are your Constant Therapy exercises going? (optional)';
  }

  return (
    <FloatingPage
      customClasses={sharedStyles.surveyPage}
      header={
        <>
          <ProgressBar currentStep={2} totalSteps={2} />
          <h1 className="h1">{title}</h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses={styles.additionalInfoForm}
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text="Submit"
              variant="primary"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <>
          <Field
            name="additionalInfo"
            component={CTUI.Form.Textarea}
            type="textarea"
            rows={5}
            cols={30}
            customClasses={styles.textarea}
          />
          {formValues.difficulty === 'perfect' && (
            <Field
              name="quote_consent"
              component={CTUI.Form.Checkbox}
              customClasses={styles.checkbox}
              type="checkbox"
              label="Click here if you consent to Constant Therapy sharing your feedback publicly on
            our website or marketing materials. Only your first name will be used in attribution."
            />
          )}
        </>
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const AdditionalInfo = reduxForm<SurveyCampaignForm, AdditionalInfoProps>({
  form: SURVEY_CAMPAIGN_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AdditionalInfoComponent);

export { AdditionalInfo };
