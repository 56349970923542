import React, { FC } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellSlash } from '@fortawesome/free-regular-svg-icons';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import { Spinner } from 'Shared/components/spinner';
import { PageError } from 'Shared/components/pageError';

import { Meta } from './meta';
import { useDoNotContact, DoNotContactProps } from './useDoNotContact';
import { DoNotContactForm } from './dncForm';

import styles from './index.module.scss';

export const DoNotContact: FC<DoNotContactProps> = ({ authUser }) => {
  const { isLoading, errorMessage, doNotContactData, handleDoNotContact } = useDoNotContact({
    authUser,
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (errorMessage) {
    return <PageError error={errorMessage} />;
  }

  return (
    <FloatingPage
      customClasses={styles.doNotContact}
      header={
        <h1 className={classNames('h1', styles.title)}>
          <FontAwesomeIcon icon={faBellSlash} className={styles.icon} />
          Stop receiving emails from Constant Therapy
        </h1>
      }
    >
      <Meta />
      {doNotContactData ? (
        <p>
          We’re sorry to see you go.
          <br />
          <br />
          <b className="text bold">{doNotContactData.username}</b> will no longer receive emails
          from Constant Therapy.
        </p>
      ) : (
        <DoNotContactForm onSubmit={handleDoNotContact} initialValues={{ optout: true }} />
      )}
    </FloatingPage>
  );
};
