import React, { FC } from 'react';

import { useChart, ChartProps } from './useChart';

import styles from './chart.module.scss';

export const Chart: FC<ChartProps> = ({ level }) => {
  const { accuracyChartRef, latencyChartRef } = useChart({ level });

  return (
    <div className={styles.chart}>
      <h3 className={styles.title}>Performance</h3>
      <div className="chartAccuracy" ref={accuracyChartRef} />
      <div className="chartLatency" ref={latencyChartRef} />
    </div>
  );
};
