import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Meta } from './meta';
import { Start } from './start';
import { Guide } from './guide';

export const Unsubscribe: FC = () => {
  return (
    <>
      <Meta />
      <Switch>
        <Route exact path="/unsubscribe">
          <Start />
        </Route>
        <Route exact path="/unsubscribe/guide">
          <Guide />
        </Route>
      </Switch>
    </>
  );
};
