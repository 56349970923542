import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Opt out of emails on this page. Please take a moment 
  to share your feedback, which will help us improve. Remember, you're always welcome to 
  re-subscribe!`;
  return (
    <Helmet>
      <title>Unsubscribe from Emails | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
