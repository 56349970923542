import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { SUPPORT } from 'Shared/constants';

import styles from './shared.module.scss';

export const Fail: FC = () => {
  return (
    <div className={styles.paymentUpdate}>
      <CTUI.Font.H1 customClasses={styles.title}>
        Unable to update your payment information
      </CTUI.Font.H1>
      <CTUI.Font.H2 customClasses={styles.subTitle}>
        We were unable to update your payment information for some reason,
        <br />
        please try again using the button below
      </CTUI.Font.H2>
      <Link to="/payment_update" className={styles.link}>
        <CTUI.Button text="Update Payment Info" customClasses={styles.updatePaymentButton} />
      </Link>
      <CTUI.Font.Text className={styles.terms}>
        Please reach out to Customer Support at{' '}
        <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a> or{' '}
        <a href={`mailto:${SUPPORT.EMAIL}`}>{SUPPORT.EMAIL}</a> if the error persists.
      </CTUI.Font.Text>
    </div>
  );
};
