import React, { FC } from 'react';
import classNames from 'classnames';

import { STIMULUS_CONTENT_TYPES } from 'Shared/types/shared';

import { type EnhancedChoice } from '../types';

import { TextComponent } from './stimulus/text';
import { ImageComponent } from './stimulus/image';
import { NoneComponent } from './stimulus/none';

import styles from './index.module.scss';

interface ChoiceProps {
  choice: EnhancedChoice;
  index: number;
  resourceUrl: string;
  handleClick: (index: number) => void;
}

export const Choice: FC<ChoiceProps> = ({ choice, index, resourceUrl, handleClick }) => {
  return (
    <li
      className={classNames(styles.choice, {
        [styles.isPlaced]: choice.isPlaced,
        [styles.isHighlighted]: choice.isHighlighted,
      })}
      style={{ backgroundColor: choice.stimulus.backgroundColor }}
      onClick={() => handleClick(index)}
    >
      {choice.stimulus.contentType === STIMULUS_CONTENT_TYPES.TEXT && (
        <TextComponent stimulus={choice.stimulus} />
      )}
      {choice.stimulus.contentType === STIMULUS_CONTENT_TYPES.IMAGE && (
        <ImageComponent stimulus={choice.stimulus} resourceUrl={resourceUrl} />
      )}
      {choice.stimulus.contentType === STIMULUS_CONTENT_TYPES.NONE && (
        <NoneComponent stimulus={choice.stimulus} value={choice.isPlaced && choice.value} />
      )}
    </li>
  );
};
