import { Dispatch } from 'redux';
import { useDispatch, useStore } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga';

import { USER_TYPES } from 'Shared/types/shared';
import config from 'Shared/config.json';
import { logEvent } from 'Shared/actions/event';
import { DOWNLOAD_APP } from 'Shared/types/events';

export interface DownloadAppListProps {
  userType?: USER_TYPES;
}

export function useDownloadAppList({ userType }: DownloadAppListProps) {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();

  const appStoreLink = useMemo(() => {
    return (
      'https://itunes.apple.com/us/app/constant-therapy/id' +
      (userType === USER_TYPES.PATIENT ? config.itunesApp : config.clin_itunesApp)
    );
  }, [userType, config]);
  const playStoreLink = useMemo(() => {
    return (
      'https://play.google.com/store/apps/details?id=' +
      (userType === USER_TYPES.PATIENT ? config.googleApp : config.clin_googleApp)
    );
  }, [userType, config]);
  const amazonLink = useMemo(() => {
    return (
      'https://www.amazon.com/gp/product/' +
      (userType === USER_TYPES.PATIENT ? config.amazonApp : config.clin_amazonApp)
    );
  }, [userType, config]);

  const handleOutboundClick = useCallback(
    (link: string) => {
      void logEvent({ page: DOWNLOAD_APP, data: { userType, link } })(
        dispatch,
        store.getState.bind(store)
      );
    },
    [userType, dispatch, store]
  );

  useEffect(() => {
    ReactGA.initialize(config.gaTrackingId, {
      titleCase: false,
      debug: process.env.ENV === 'dev' || process.env.ENV === 'staging', //Enables debug
    });
  }, []);

  return {
    appStoreLink,
    playStoreLink,
    amazonLink,
    handleOutboundClick,
  };
}
