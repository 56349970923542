import React, { FC } from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { PATIENT_ACTIVATION_FORM_NAME } from 'Shared/types/forms';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import sharedStyles from '../shared.module.scss';

const conditionsPeriodList = [
  {
    value: '0-3M',
    label: '0-3 months',
  },
  {
    value: '3-6M',
    label: '4-6 months',
  },
  {
    value: '6-12M',
    label: '7-12 months',
  },
  {
    value: '1-5Y',
    label: '1-5 years',
  },
  {
    value: '6-10Y',
    label: '6-10 years',
  },
  {
    value: '>10Y',
    label: 'More than 10 years',
  },
  {
    value: 'NA',
    label: 'Not applicable/Not sure',
  },
];

interface ConditionsPeriodComponent {
  previousPage: () => void;
}

const ConditionsPeriodComponent: FC<
  ConditionsPeriodComponent & InjectedFormProps<object, ConditionsPeriodComponent>
> = ({ handleSubmit, previousPage, error, pristine, submitting }) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.wizardPage}
      header={
        <>
          <ProgressBar currentStep={4} totalSteps={5} />
          <h1 className="h1">When was your condition first diagnosed?</h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses="flintActivationForm"
        actions={
          <>
            <CTUI.Button
              type="button"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              text="&larr; Back"
            />
            <CTUI.Form.SubmitButton
              variant="primary"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
              text="Next &rarr;"
            />
          </>
        }
      >
        <Field
          name="conditionsPeriod"
          component={CTUI.Form.RadioGroup}
          options={conditionsPeriodList}
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const ConditionsPeriod = reduxForm<object, ConditionsPeriodComponent>({
  form: PATIENT_ACTIVATION_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ConditionsPeriodComponent);

export { ConditionsPeriod };
