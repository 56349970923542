import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tooltip } from 'Shared/components/tooltip';

import { StatusCellProps, useStatusCell } from './useStatusCell';

import styles from './index.module.scss';

const tooltipOptions = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-10, 10],
      },
    },
  ],
  placement: 'bottom-start' as const,
  classes: styles.tooltip,
};

export const StatusCell: FC<StatusCellProps> = ({ patientId, status }) => {
  const {
    referenceElement,
    setReferenceElement,
    isTooltipShown,
    showToolTip,
    hideToolTip,
    icon,
    brochureLink,
    reportLink,
  } = useStatusCell({
    patientId,
    status,
  });

  return (
    <div className={styles.statusCell} onMouseLeave={hideToolTip}>
      <span onMouseEnter={showToolTip} ref={setReferenceElement}>
        <FontAwesomeIcon icon={icon} className={styles[`status-${status.statusIcon}`]} />
      </span>
      {status.statusLabel}
      {isTooltipShown && (
        <Tooltip referenceElement={referenceElement} options={tooltipOptions}>
          <p className={styles.tooltipMessage}>{status.statusPopup}</p>
          {status.statusIcon === 2 ? (
            <Link to={brochureLink} className={styles.tooltipLink}>
              Let&apos;s change that
            </Link>
          ) : (
            <Link to={reportLink} className={styles.tooltipLink}>
              Review Performance
            </Link>
          )}
        </Tooltip>
      )}
    </div>
  );
};
