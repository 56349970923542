import React, { FC } from 'react';

import { USER_TYPES } from 'Shared/constants';

import { Meta } from './meta';
import { useSignup } from './useSignup';
import { UserTypeSelection } from './wizard/userTypeSelection';
import { WizardClinician } from './wizard/clinician';
import { WizardPatient } from './wizard/patient';

export const Signup: FC = () => {
  const { signupData, passwordRequirements } = useSignup();

  if (!signupData.userType) {
    return (
      <>
        <Meta />
        <UserTypeSelection />
      </>
    );
  }

  if (signupData.userType === USER_TYPES.CLINICIAN) {
    return (
      <>
        <Meta />
        <WizardClinician passwordRequirements={passwordRequirements} signupData={signupData} />
      </>
    );
  }

  return (
    <>
      <Meta />
      <WizardPatient passwordRequirements={passwordRequirements} signupData={signupData} />
    </>
  );
};
