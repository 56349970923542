import React, { FC } from 'react';
import { Wizard } from 'App/login/wizard';
import { Meta } from 'App/login/meta';

export const Login: FC = () => {
  return (
    <>
      <Meta />
      <Wizard />
    </>
  );
};
