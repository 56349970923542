import React, { FC } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faUserCheck, faUnlock } from '@fortawesome/free-solid-svg-icons';

import { SharedModalProps } from './sharedModalProps';

import sharedModalStyles from './sharedModalStyles.module.scss';
import styles from './activateUser.module.scss';

export const ActivateUserModal: FC<SharedModalProps> = ({
  email,
  isLoading,
  handleModalSubmit,
  closeActiveModal,
  modalSubmissionError,
  modalRef,
}) => {
  return (
    <CTUI.Modal
      ref={modalRef}
      header={
        <h1 className="h1">
          <FontAwesomeIcon
            icon={faUserCheck}
            className={classNames(sharedModalStyles.icon, styles.icon)}
          />
          Re-activate a user
        </h1>
      }
      headerClasses={sharedModalStyles.header}
      customClasses={sharedModalStyles.actionCellModal}
      closeButtonHandler={closeActiveModal}
      footer={
        <>
          <CTUI.Form.SubmitButton
            isSubmitting={isLoading}
            isPristine={false}
            onClick={handleModalSubmit}
            icon={faUnlock}
            text="Re-activate user"
          />
          <CTUI.Form.ResetButton
            isSubmitting={isLoading}
            isPristine={false}
            onClick={closeActiveModal}
            text="Cancel"
          />
        </>
      }
      footerClasses={sharedModalStyles.footer}
      error={modalSubmissionError}
    >
      <div className={sharedModalStyles.extraMargin}>
        <h4 className={classNames('h4', sharedModalStyles.message)}>
          You are activating <b>{`<${email}>`}</b> from your user list.
        </h4>
      </div>
    </CTUI.Modal>
  );
};
