import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { CLINICIAN_ROLES } from 'Shared/constants';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { CLINICIAN_SIGNUP_FORM_NAME } from '../../constants';

import sharedStyles from '../shared.module.scss';

const phoneLabel = 'Phone number <i>(for support)</i>';
const rolesOptions = [
  <option value="" disabled={true} key="initialSelection">
    Select an option
  </option>,
  ...Object.entries(CLINICIAN_ROLES).map(([key, value]) => {
    return (
      <option value={key} key={key}>
        {value}
      </option>
    );
  }),
];

interface AdvancedComponentProps {
  previousPage: () => void;
  totalSteps: number;
  isInstitutionBasedSignup: boolean;
}

const AdvancedComponent: FC<
  AdvancedComponentProps & InjectedFormProps<object, AdvancedComponentProps>
> = ({
  handleSubmit,
  previousPage,
  error,
  pristine,
  submitting,
  totalSteps,
  isInstitutionBasedSignup,
}) => {
  const submitLabel = isInstitutionBasedSignup ? 'Finish' : 'Next →';

  return (
    <FloatingPage
      customClasses={sharedStyles.wizardPage}
      header={
        <>
          <ProgressBar currentStep={2} totalSteps={totalSteps} />
          <h1 className="h1">
            Tell us about <b>you</b>
          </h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text={submitLabel}
              variant="primary"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="firstName"
          component={CTUI.Form.Field}
          type="text"
          label="First Name"
          required
          autofocus
        />
        <Field name="lastName" component={CTUI.Form.Field} type="text" label="Last Name" required />
        <Field
          name="role"
          component={CTUI.Form.Select}
          type="select"
          label="What is your role?"
          options={rolesOptions}
          required
        />
        <Field name="phoneNumber" component={CTUI.Form.Field} type="text" label={phoneLabel} />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Advanced = reduxForm<object, AdvancedComponentProps>({
  form: CLINICIAN_SIGNUP_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AdvancedComponent);

export { Advanced };
