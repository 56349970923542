import { useEffect, useState } from 'react';

export const useAudioLevels = () => {
  const [audioLevel, setAudioLevel] = useState(0);
  const [audioContext, setAudioContext] = useState<AudioContext>();
  const [analyser, setAnalyzer] = useState<AnalyserNode>();
  const [source, setSource] = useState<MediaStreamAudioSourceNode>();
  const [animationFrameRequestId, setAnimationFrameRequestId] = useState<number>();

  const getAudioLevel = (analyserLocal: AnalyserNode) => {
    const dataArray = new Uint8Array(analyserLocal.frequencyBinCount);
    analyserLocal.getByteFrequencyData(dataArray);

    const sum = dataArray.reduce((a, b) => a + b, 0);
    const average = sum / dataArray.length;
    setAudioLevel(average);

    const requestId = window.requestAnimationFrame(
      getAudioLevel.bind(getAudioLevel, analyserLocal)
    );
    setAnimationFrameRequestId(requestId);
  };

  const startAnalysis = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContextLocal = new AudioContext();
      const analyserLocal = audioContextLocal.createAnalyser();
      const sourceLocal = audioContextLocal.createMediaStreamSource(stream);
      setAudioContext(audioContextLocal);
      setAnalyzer(analyserLocal);
      setSource(sourceLocal);

      analyserLocal.smoothingTimeConstant = 0.8;
      analyserLocal.fftSize = 1024;

      sourceLocal.connect(analyserLocal);

      getAudioLevel(analyserLocal);
    } catch (e) {
      console.log('Unable to get media stream for audio level analysis', e);
    }
  };

  const stopAnalysis = () => {
    source?.disconnect();
    analyser?.disconnect();
    void audioContext?.close();
    window.cancelAnimationFrame(animationFrameRequestId);
  };

  // useEffect(() => {
  //   if (isRecording) {
  //     void observeRecording();
  //   }
  //
  //   return () => {
  //     console.log('isRecording disconnect');
  //     source?.disconnect();
  //     analyser?.disconnect();
  //     void audioContext?.close();
  //   };
  // }, [isRecording]);
  //
  // useEffect(() => {
  //   if (analyser) {
  //     getAudioLevel();
  //   }
  //   return () => {
  //     console.log('analyser disconnect');
  //     window.cancelAnimationFrame(animationFrameRequestId);
  //   };
  // }, [analyser]);

  return { startAnalysis, stopAnalysis, audioLevel };
};
