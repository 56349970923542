import React, { FC } from 'react';

import { PerformanceReportDomain } from 'Shared/types/shared';

import { ChartFigure } from './chartFigure';
import { ChartDiff } from './chartDiff';

import styles from './chartData.module.scss';

interface ChartDataProps {
  skillArea: PerformanceReportDomain;
}

export const ChartData: FC<ChartDataProps> = ({ skillArea }) => {
  return (
    <div className={styles.chartData}>
      <ChartFigure skillArea={skillArea} />
      <ChartDiff skillArea={skillArea} />
    </div>
  );
};
