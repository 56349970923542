import queryString from 'query-string';
import { Dispatch } from 'redux';

import { getBaseUrl } from 'Shared/utils';
import { fetchWrapper } from 'Shared/fetchWrapper';
import {
  FLINT_ACTIVATION_ACTIVATE_PATIENT,
  FLINT_ACTIVATION_SET_SUBSCRIPTION_TIME,
  FLINT_ACTIVATION_SET_FIRST_NAME,
  FLINT_ACTIVATION_SET_LAST_NAME,
  FLINT_ACTIVATION_SET_EMAIL,
} from 'Shared/types/redux';
import { ActivatePatientResponse } from 'Shared/types/api';

export const activatePatient =
  (data: object, subscriptionTime: string) => async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      { subscriptionTime },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return (await fetchWrapper.post(
      `${getBaseUrl({
        apiSection: 'userSignup',
      })}/api/form/patient/completeAccount?${formattedParams}`,
      {
        dispatch,
        types: FLINT_ACTIVATION_ACTIVATE_PATIENT,
      },
      data,
      {
        isSubmission: true,
        skipAccessToken: true,
      }
    )) as ActivatePatientResponse;
  };

export const setSubscriptionTime = (value: string) => (dispatch: Dispatch) => {
  dispatch({
    type: FLINT_ACTIVATION_SET_SUBSCRIPTION_TIME,
    payload: value,
  });
};

export const setFirstName = (value: string) => (dispatch: Dispatch) => {
  dispatch({
    type: FLINT_ACTIVATION_SET_FIRST_NAME,
    payload: value,
  });
};

export const setLastName = (value: string) => (dispatch: Dispatch) => {
  dispatch({
    type: FLINT_ACTIVATION_SET_LAST_NAME,
    payload: value,
  });
};

export const setEmail = (value: string) => (dispatch: Dispatch) => {
  dispatch({
    type: FLINT_ACTIVATION_SET_EMAIL,
    payload: value,
  });
};
