import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';

import { Tooltip } from 'Shared/components/tooltip';

import { ScheduleComponent } from '../../../shared/scheduleComponent';

import { useEditHomeworkScheduleAction } from './useEditHomeworkScheduleAction';

interface EditHomeworkScheduleActionProps {
  patientId: number;
}

export const EditHomeworkScheduleAction: FC<EditHomeworkScheduleActionProps> = ({ patientId }) => {
  const {
    isTooltipShown,
    showTooltip,
    hideTooltip,
    tooltipRef,
    setTooltipRef,
    shouldShowScheduleComponent,
    showScheduleComponent,
    hideScheduleComponent,
  } = useEditHomeworkScheduleAction();

  return (
    <>
      <span
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onClick={showScheduleComponent}
        ref={setTooltipRef}
        className="actionIcon"
      >
        <FontAwesomeIcon icon={faTasks} />
      </span>
      {isTooltipShown && (
        <Tooltip referenceElement={tooltipRef}>
          <p className="tooltipTitle">MODIFY PATIENT’S HOME EXERCISE PROGRAM</p>
          Patients can practice unlimited Constant Therapy home exercises on their own devices. You
          can add, delete or modify their exercises here.
        </Tooltip>
      )}
      {shouldShowScheduleComponent && (
        <ScheduleComponent patientId={patientId} hideScheduleComponent={hideScheduleComponent} />
      )}
    </>
  );
};
