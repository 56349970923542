import * as d3 from 'd3';
import $ from 'jquery';
import _ from 'lodash';

class BarChart {
  constructor(elementSelector, data, options) {
    const defaultOptions = {
      margin: {
        top: 85,
        right: 20,
        bottom: 40,
        left: 40,
      },
      width: 540,
      height: 210,
      bars: {
        classNames: ['barChart__bar--baseline', 'barChart__bar--latest'],
        colors: ['steelblue', 'steelblue'],
        labels: ['baseline', 'latest'],
        width: 50,
      },
      axes: {
        x: {
          ticks: 2,
          domain: [],
        },
        y: {
          ticks: 5,
          domain: [],
        },
      },
      unit: '',
      displayUnit: '',
      title: '',
      diff: '',
      isInverted: false,
    };
    this.elementSelector = elementSelector;
    this.data = data;
    this.options = _.merge(defaultOptions, options);
    this.draw();
    this.customizeLabels();
  }
  draw() {
    const diff = this.data.length > 1 ? this.data[1].value - this.data[0].value : 0;
    const x = d3.scaleBand().range([0, this.options.width], 0.05).round(true);
    const y = d3.scaleLinear().range([this.options.height, 0]);
    const xAxis = d3
      .axisBottom()
      .scale(x)
      .ticks(this.options.axes.x.ticks)
      .tickFormat((d, i) => {
        return this.options.bars.labels[i];
      });

    const yAxis = d3
      .axisLeft()
      .scale(y)
      .tickFormat((d) => `${d} ${this.options.unit}`);
    if (this.options.axes.y.ticks) {
      yAxis.ticks(this.options.axes.y.ticks);
    }
    if (this.options.axes.y.tickValues) {
      yAxis.tickValues(this.options.axes.y.tickValues);
    }

    const chartClasses = ['barChart'];
    if (diff) {
      if (diff < 0) {
        chartClasses.push('barChart--negative');
      }
      if (this.options.isInverted) {
        chartClasses.push('barChart--inverted');
      }
    }

    const chart = d3
      .select(this.elementSelector)
      .append('svg')
      .attr('class', chartClasses.join(' '))
      .attr('width', this.options.width + this.options.margin.left + this.options.margin.right)
      .attr('height', this.options.height + this.options.margin.top + this.options.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.options.margin.left}, ${this.options.margin.top})`);

    this.data.forEach((d) => {
      d.value = +d.value;
    });

    if (_.isEmpty(this.options.axes.x.domain)) {
      x.domain(this.data.map((d, i) => i));
    } else {
      x.domain(this.options.axes.x.domain);
    }
    if (_.isEmpty(this.options.axes.y.domain)) {
      y.domain([0, d3.max(this.data, (d) => d.value)]);
    }
    {
      if (this.options.isInverted) {
        y.domain(this.options.axes.y.domain.reverse());
      } else {
        y.domain(this.options.axes.y.domain);
      }
    }

    // Draws x-axis line
    chart
      .append('g')
      .attr('class', 'x barChart__axis')
      .attr('transform', `translate(0, ${this.options.height})`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'middle');

    // Draws y-axis line
    chart
      .append('g')
      .attr('class', 'y barChart__axis')
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '.71em')
      .style('text-anchor', 'end');

    // Draws bars
    chart
      .selectAll('bar')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('class', (d, i) => this.options.bars.classNames[i])
      .style('fill', (d, i) => this.options.bars.colors[i])
      .attr('x', (d, i) => {
        return this.data.length > 1
          ? x(i) + this.options.bars.width / 2 + 2
          : (this.options.width - this.options.bars.width) / 2;
      })
      .attr('width', this.options.bars.width || x.rangeBand())
      .attr('y', (d) => y(d.value))
      .attr('height', (d) => this.options.height - y(d.value));

    // Draws marks over bars
    chart
      .selectAll('bar')
      .data(this.data)
      .enter()
      .append('text')
      .text((d) => `${d.value} ${this.options.unit}`)
      .style('text-anchor', 'start')
      .attr('width', this.options.bars.width || x.rangeBand())
      .attr('height', (d) => this.options.height - y(d.value))
      .attr('x', (d, i) => {
        return this.data.length > 1
          ? x(i) + this.options.bars.width / 2 + 9
          : (this.options.width - this.options.bars.width) / 2 + 9;
      })
      .attr('y', (d) => y(d.value) - 5);

    if (this.options.title) {
      this.drawTitle(chart);
    }
    if (this.options.diff) {
      this.drawDiff(chart);
    }
  }
  //TODO: replace absolute values with option.height/option.width calculations
  drawTitle(chart) {
    const titleClass = 'barChart__title';

    chart
      .append('g')
      .attr('class', titleClass)
      .append('text')
      .attr('x', -30)
      .attr('y', -65)
      .text(this.options.title);

    chart.select(`.${titleClass}`).append('text').attr('x', -30).attr('y', -45).text('(Average)');
  }
  //TODO: replace absolute values with option.height/option.width calculations
  drawDiff(chart) {
    const diff =
      this.data.length > 1 ? Math.round((this.data[1].value - this.data[0].value) * 100) / 100 : 0;
    const diffClass = 'barChart__diff';
    const trianglePoints =
      (diff > 0 && !this.options.isInverted) || (diff < 0 && this.options.isInverted)
        ? '145 -63, 150 -68, 155 -63'
        : '145 -68, 150 -63, 155 -68';
    let descriptionOffset = 70;

    if (!diff) {
      return;
    }

    chart
      .append('g')
      .attr('class', diffClass)
      .append('rect')
      .attr('width', 100)
      .attr('height', 50)
      .attr('x', this.options.width - 100)
      .attr('y', -85)
      .attr('fill', 'white');

    chart
      .select(`.${diffClass}`)
      .append('polygon')
      .attr('points', trianglePoints)
      .attr('x', this.options.width - 100)
      .attr('y', -85);

    chart
      .select(`.${diffClass}`)
      .append('text')
      .attr('x', this.options.width - 70)
      .attr('y', -60)
      .text(`${Math.abs(diff)}${this.options.displayUnit}`);

    if (diff > 0 && !this.options.isInverted) {
      chart
        .select(`.${diffClass}`)
        .append('text')
        .attr('x', this.options.width - descriptionOffset)
        .attr('y', -45)
        .text(this.options.diff.positive);
    } else if (diff > 0 && this.options.isInverted) {
      chart
        .select(`.${diffClass}`)
        .append('text')
        .attr('x', this.options.width - descriptionOffset)
        .attr('y', -45)
        .text(this.options.diff.negative);
    } else if (diff < 0 && !this.options.isInverted) {
      chart
        .select(`.${diffClass}`)
        .append('text')
        .attr('x', this.options.width - descriptionOffset)
        .attr('y', -45)
        .text(this.options.diff.negative);
    } else {
      chart
        .select(`.${diffClass}`)
        .append('text')
        .attr('x', this.options.width - descriptionOffset)
        .attr('y', -45)
        .text(this.options.diff.positive);
    }
  }

  /**
   * Adds second label for x-axis tick, displaying date under bar-name.
   * SVG doesn't allow line breaks inside text element, that why we need this
   */
  customizeLabels() {
    $(this.elementSelector)
      .find('.barChart__axis.x .tick')
      .each((index, element) => {
        const mainLabel = $(element).find('text');
        const dateLabel = mainLabel.clone();
        const dateObj = this.data[index].date.dateTime.date;
        const dateParts = [dateObj.month, dateObj.day, dateObj.year];
        dateLabel
          .text(dateParts.join('/'))
          .attr('y', parseInt(mainLabel.attr('y')) + mainLabel[0].getBBox().height);
        $(element).append(dateLabel);
      });
  }

  destroy() {
    $(this.elementSelector).empty();
  }
}

export default BarChart;
