import React, { FC } from 'react';
import classNames from 'classnames';

import { convertDatetimeToUTC } from 'Shared/utils';
import { PerformanceReportAssessmentQuestionIndex } from 'Shared/types/shared';

import styles from './chartLine.module.scss';

interface ChartLineProps {
  kpi: PerformanceReportAssessmentQuestionIndex;
  title: string;
}

export const ChartLine: FC<ChartLineProps> = ({ kpi, title }) => {
  // const lineDate = moment.utc(kpi.date.dateTime, 'X');
  const lineDate = convertDatetimeToUTC(kpi.date.dateTime.date);
  const lineClasses = classNames(styles.chartLine, { [styles[title]]: true });
  const valueClasses = classNames(styles.chartLineValue, { [styles[`emoji-${kpi.label}`]]: true });
  const maxValue = 5;
  const lineWidth = (kpi.value - 1) * 25;

  return (
    <div className={lineClasses}>
      <header className={styles.chartLineHeader}>
        <b className={styles.title}>{title}:</b> ({lineDate.format('MM/DD/YYYY')})
      </header>
      <div className={styles.chartLineFigure}>
        <div className={styles.chartLineFigureValue} style={{ width: `${lineWidth}%` }} />
      </div>
      <div className={valueClasses}>
        <b>{kpi.value}</b> / {maxValue}
      </div>
    </div>
  );
};
