import React, { FC, SyntheticEvent } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faForward, faChartSimple, faLaptopMedical } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SCREEN_MODES } from 'App/therapy/session/useSession';
import { WatchModal } from 'App/therapy/session/header/watchModal';

import { HTML_HEADER_ID } from 'Shared/constants';

import { HeaderProps, useHeader } from './useHeader';
import { SkipModal } from './skipModal';

import styles from './index.module.scss';

const mountingPoint = document.getElementById(HTML_HEADER_ID);

export const Header: FC<HeaderProps> = ({
  therapySession,
  taskTypes,
  activeTaskIndex,
  skipTask,
  activeScreenMode,
  setScheduleId,
}) => {
  const {
    skipModalRef,
    isSkipTaskModalShown,
    showSkipModal,
    hideSkipModal,
    watchModalRef,
    isWatchTutorialModalShown,
    showWatchModal,
    hideWatchModal,
    categoryName,
    categoryInstructionVideoPath,
  } = useHeader({
    therapySession,
    taskTypes,
    activeTaskIndex,
    skipTask,
    activeScreenMode,
    setScheduleId,
  });

  return ReactDOM.createPortal(
    <nav className={styles.header}>
      <ul className={styles.menu}>
        <li>
          <a href="#">
            <FontAwesomeIcon icon={faQuestionCircle} className={styles.icon} />
            Help
          </a>
        </li>
        <li>
          <Link to="/do-therapy">
            <FontAwesomeIcon icon={faChartSimple} className={styles.icon} />
            Home
          </Link>
        </li>
        <li className={styles.category}>
          <a className={styles.active}>
            <FontAwesomeIcon icon={faLaptopMedical} /> {categoryName}
          </a>
        </li>
        {activeScreenMode === SCREEN_MODES.TASKS && (
          <>
            <li>
              <a
                href="#"
                onClick={(e: SyntheticEvent) => {
                  e.preventDefault();
                  showWatchModal();
                }}
              >
                <FontAwesomeIcon icon={faYoutube as IconProp} className={styles.icon} />
                Watch Tutorial
              </a>
              {isWatchTutorialModalShown && (
                <WatchModal
                  modalRef={watchModalRef}
                  hideModal={hideWatchModal}
                  categoryInstructionVideoPath={categoryInstructionVideoPath}
                />
              )}
            </li>
            <li>
              <a
                href="#"
                onClick={(e: SyntheticEvent) => {
                  e.preventDefault();
                  showSkipModal();
                }}
              >
                Skip
                <FontAwesomeIcon icon={faForward} className={styles.iconRight} />
              </a>
              {isSkipTaskModalShown && (
                <SkipModal modalRef={skipModalRef} hideModal={hideSkipModal} skipTask={skipTask} />
              )}
            </li>
          </>
        )}
      </ul>
    </nav>,
    mountingPoint
  );
};
