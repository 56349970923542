import { useCallback, useRef, useState } from 'react';
import { Moment } from 'moment/moment';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

import { DAILY_SESSION_NOTE_DETAILED_TYPE_ID } from 'Shared/constants';

export interface DailySessionNotePopupProps {
  patientId: string;
  closePopupHandler: () => void;
}

export function useDailySessionNotePopup({
  patientId,
  closePopupHandler,
}: DailySessionNotePopupProps) {
  const modalRef = useRef(null);
  const [reportType, setReportType] = useState(DAILY_SESSION_NOTE_DETAILED_TYPE_ID);
  const [date, setDate] = useState<Moment>(null);
  const { isPortrait } = useMobileOrientation();

  const numberOfMonthsToDisplay = isPortrait && isMobileOnly ? 1 : 2;

  const applyChanges = useCallback(() => {
    window.open(
      `/dashboard/patient/${patientId}/daily-session-note/` +
        `${reportType}/${date.format('YYYY-MM-DD')}`
    );
  }, [patientId, reportType, date]);

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      closePopupHandler();
    }
  });

  return {
    modalRef,
    numberOfMonthsToDisplay,
    reportType,
    setReportType,
    date,
    setDate,
    applyChanges,
  };
}
