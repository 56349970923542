import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useMobileOrientation, isMobileOnly } from 'react-device-detect';
import { faCircleNotch, faCircleXmark, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { getPatientSchedule, savePatientSchedule } from 'Shared/actions/schedule';
import { getAllTasksHierarchy, getAllTasksFlat } from 'Shared/actions/task';
import { Spinner } from 'Shared/components/spinner';
import type { TaskType, TaskTypeCategory } from 'Shared/types/task';
import type { Schedule } from 'Shared/types/schedule';

import { useScheduleComponent } from './useScheduleComponent';
import { ActiveScheduleTasksModal } from './activeScheduleTasks/modal';
import { ActiveScheduleTasks } from './activeScheduleTasks';
import { AllTasksModal } from './allTasks/modal';
import { AllTasks } from './allTasks';

import styles from './index.module.scss';

const connector = connect(null, {
  getPatientSchedule,
  savePatientSchedule,
  getAllTasksHierarchy,
  getAllTasksFlat,
});

interface ScheduleModalProps {
  patientId: number;
  hideScheduleComponent: () => void;
  getPatientSchedule?: () => Promise<Schedule>;
  savePatientSchedule?: () => Promise<any>;
  getAllTasksHierarchy?: () => Promise<TaskTypeCategory>;
  getAllTasksFlat?: () => Promise<TaskType[]>;
}

let ScheduleComponent: FC<ScheduleModalProps> = ({
  patientId,
  hideScheduleComponent,
  getPatientSchedule,
  savePatientSchedule,
  getAllTasksHierarchy,
  getAllTasksFlat,
}) => {
  const { isPortrait } = useMobileOrientation();
  const {
    isScheduleLoading,
    scheduledTasksList,
    moveScheduledTasks,
    deleteScheduledTask,
    adjustScheduledTask,
    handleSavePatientSchedule,
    isTasksHierarchyLoading,
    tasksHierarchy,
    modalRef,
    shouldShowTasksHierarchyInPortraitMode,
    showTasksHierarchyInPortraitMode,
    hideTasksHierarchyInPortraitMode,
    appendTasksToScheduledTasksList,
    modalCloseHandler,
    isSavingChanges,
    isPristine,
  } = useScheduleComponent({
    patientId,
    hideScheduleComponent,
    getAllTasksHierarchy,
    getPatientSchedule,
    savePatientSchedule,
    getAllTasksFlat,
  });

  if (isMobileOnly && isPortrait) {
    if (shouldShowTasksHierarchyInPortraitMode) {
      return (
        <AllTasksModal
          tasksHierarchy={tasksHierarchy}
          modalCloseHandler={modalCloseHandler}
          hideTasksHierarchyInPortraitMode={hideTasksHierarchyInPortraitMode}
          appendTasksToScheduledTasksList={appendTasksToScheduledTasksList}
        />
      );
    }

    return (
      <ActiveScheduleTasksModal
        isScheduleLoading={isScheduleLoading}
        scheduledTasksList={scheduledTasksList}
        moveScheduledTasks={moveScheduledTasks}
        deleteScheduledTask={deleteScheduledTask}
        adjustScheduledTask={adjustScheduledTask}
        modalCloseHandler={modalCloseHandler}
        showTasksHierarchyInPortraitMode={showTasksHierarchyInPortraitMode}
        handleSavePatientSchedule={handleSavePatientSchedule}
        isSavingChanges={isSavingChanges}
        isPristine={isPristine}
      />
    );
  }

  return (
    <CTUI.Modal
      ref={modalRef}
      id={styles.scheduleModal}
      size={CTUI.ModalSizes.LARGE}
      closeButtonHandler={() => {
        void modalCloseHandler();
      }}
      header={<h1 className="h1">Modify Patient&apos;s Homework Schedule</h1>}
      footer={
        <>
          <CTUI.Button
            icon={faCircleXmark}
            customClasses={styles.backButton}
            text="Close"
            variant="secondary"
            onClick={() => {
              void modalCloseHandler();
            }}
          />
          <CTUI.Form.SubmitButton
            icon={isSavingChanges ? faCircleNotch : faFloppyDisk}
            customClasses={styles.saveButton}
            text="Save Changes"
            onClick={() => {
              void handleSavePatientSchedule();
            }}
            isPristine={isPristine}
            isSubmitting={isSavingChanges}
          />
        </>
      }
    >
      <div className={styles.bodyContent}>
        {isTasksHierarchyLoading ? (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        ) : (
          <AllTasks tasksHierarchy={tasksHierarchy} />
        )}
        {isScheduleLoading ? (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        ) : (
          <ActiveScheduleTasks
            scheduledTasksList={scheduledTasksList}
            moveScheduledTasks={moveScheduledTasks}
            deleteScheduledTask={deleteScheduledTask}
            adjustScheduledTask={adjustScheduledTask}
          />
        )}
      </div>
    </CTUI.Modal>
  );
};

ScheduleComponent = connector(ScheduleComponent);

export { ScheduleComponent };
