import { useState } from 'react';

export function useEditHomeworkScheduleAction() {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [tooltipRef, setTooltipRef] = useState<HTMLElement>(null);
  const [shouldShowScheduleComponent, setShouldShowScheduleComponent] = useState(false);

  return {
    isTooltipShown,
    showTooltip: () => {
      setIsTooltipShown(true);
    },
    hideTooltip: () => {
      setIsTooltipShown(false);
    },
    tooltipRef,
    setTooltipRef,
    shouldShowScheduleComponent,
    showScheduleComponent: () => {
      setShouldShowScheduleComponent(true);
    },
    hideScheduleComponent: () => {
      setShouldShowScheduleComponent(false);
    },
  };
}
