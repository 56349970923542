import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';

import { useAudio, AudioContentProps } from './useAudio';

import styles from './audio.module.scss';

export const AudioContent: FC<AudioContentProps> = ({ stimulus, resourceUrl }) => {
  const { handleAudioCuePlay } = useAudio({ stimulus, resourceUrl });

  return (
    <div className={classNames(styles.content, styles.audio)}>
      <FontAwesomeIcon
        icon={faCirclePlay}
        title="Play sound"
        onClick={handleAudioCuePlay}
        className={styles.cue}
      />
    </div>
  );
};
