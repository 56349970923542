import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { SubscriptionConfirmation } from 'Shared/types/shared';

import styles from './header.module.scss';

interface ConfirmationHeaderProps {
  subscriptionConfirmation: SubscriptionConfirmation;
}

export const ConfirmationHeader: FC<ConfirmationHeaderProps> = ({ subscriptionConfirmation }) => {
  const pageTitle = subscriptionConfirmation.trialing
    ? `Your free trial will continue for ${subscriptionConfirmation.numTrialDays} days!`
    : 'Your subscription is active!';

  return (
    <div className={styles.confirmationHeader}>
      <CTUI.Font.H1 variant="regular">{pageTitle}</CTUI.Font.H1>
    </div>
  );
};
