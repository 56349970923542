import React, { FC } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { Link } from 'react-router-dom';

import { FloatingPage } from 'Shared/components/layout/floatingPage';

import sharedStyles from './sharedStyles.module.scss';
import styles from './underSurveillance.module.scss';

interface UnderSurveillanceProps {
  downloadHandler: (e: React.MouseEvent) => void;
}

export const UnderSurveillance: FC<UnderSurveillanceProps> = ({ downloadHandler }) => {
  const modalClasses = classNames(sharedStyles.emptyReport, styles.underSurveillance);

  return (
    <FloatingPage
      customClasses={modalClasses}
      header={<h1 className={classNames('h1', sharedStyles.title)}>Hey there!</h1>}
      footer={
        <Link to="/download" className={sharedStyles.link} onClick={downloadHandler}>
          <CTUI.Button text="Download Constant Therapy" customClasses={sharedStyles.button} />
        </Link>
      }
    >
      <p>
        Welcome to Constant Therapy. When your patients do exercises at home you can view a useful
        overview of their progress data.
      </p>
      <p className={styles.figure}>
        <FontAwesomeIcon icon={faChartColumn} className={styles.icon} />
        <b>Nothing to see here yet!</b>
      </p>
      <p className={sharedStyles.hint}>Download the app now to get started!</p>
    </FloatingPage>
  );
};
