import md5 from 'md5';
import { useSelector, useStore } from 'react-redux';
import { Dispatch } from 'redux';
import { DecoratedFormProps } from 'redux-form/lib/reduxForm';
import { useHistory } from 'react-router-dom';

import {
  selectPatientType,
  saveExistingPatient,
  saveNewPatient,
  clearPatientType,
} from 'Shared/actions/addPatient';
import { generateInitialPatientSchedule } from 'Shared/actions/schedule';
import type { User, PATIENT_TYPES } from 'Shared/types/shared';
import { getUserIp } from 'Shared/utils';
import { RootState } from 'Shared/types/shared';
import { AddNewPatientForm, AddExistingPatientForm } from 'Shared/types/forms';
import { getDashboardData } from 'Shared/actions/clinician';

export interface AddPatientProps {
  clinician: User;
}

export function useAddPatient({ clinician }: AddPatientProps) {
  const store = useStore();
  const history = useHistory();
  const addPatientData = useSelector((state: RootState) => state.addPatient);

  const handleSaveExistingPatient = async (
    formData: AddExistingPatientForm,
    formDispatch: Dispatch,
    formProps: DecoratedFormProps
  ) => {
    await saveExistingPatient({ data: formData, clinicianId: clinician.user.userId })(
      store.dispatch
    );

    await getDashboardData()(store.dispatch);

    formProps.destroy();
    clearPatientType()(store.dispatch);

    history.push('/dashboard');
  };

  const handleSaveNewPatient = async (
    formData: AddNewPatientForm,
    formDispatch: Dispatch,
    formProps: DecoratedFormProps
  ) => {
    const clientIp = await getUserIp();
    const data = {
      ...formData,
      clientIpAddress: clientIp,
    };

    const patientDataResponse = await saveNewPatient(
      convertPatientFormDataToOutdatedStructure(data)
    )(formDispatch);

    await generateInitialPatientSchedule(patientDataResponse.data.id)(formDispatch);

    await getDashboardData()(store.dispatch);

    formProps.destroy();
    clearPatientType()(store.dispatch);

    history.push('/dashboard');
  };

  return {
    addPatientData,
    handleSaveExistingPatient,
    handleSaveNewPatient,
    handleSelectPatientType: (patientType: PATIENT_TYPES) => {
      return selectPatientType(patientType)(store.dispatch);
    },
    handleClearPatientType: () => {
      return clearPatientType()(store.dispatch);
    },
  };
}

interface AddNewPatientFormWithIP extends AddNewPatientForm {
  clientIpAddress: string;
}

/**
 * This function converts patient's formData to outdated `paged` structure accepted by backend.
 * Should be deprecated and removed once BE provides updated endpoint
 * @deprecated - Should be deprecated once BE updates `skills` and `disorders` structure
 * @param data
 * @returns {{clientIpAddress: *, password, education, gender: *, requestId, diagnosis: {skills: {'@type': string}[], disorders, page_name: string, since: *}, source: string, platform: string, age: *, username}}
 */
function convertPatientFormDataToOutdatedStructure(data: AddNewPatientFormWithIP) {
  const noDetailsDisordersList = ['apraxia', 'dysarthria'];

  return {
    platform: 'browser',
    clientIpAddress: data.clientIpAddress,
    source: 'browser/clinician_setup',
    requestId: md5(JSON.stringify(data)), //Generates unique requestId based on formData
    username: data.username,
    password: data.password,
    age: data.age,
    gender: data.gender,
    education: data.education,
    diagnosis: {
      page_name: 'Diagnosis',
      skills: data.areas.map((skill) => ({ '@type': `Skill$${skill}` })),
      disorders: data.conditions.map((condition) => {
        if (noDetailsDisordersList.includes(condition)) {
          return { '@type': `Disorder$${condition}` };
        }
        return { '@type': `Disorder$${condition}$other` };
      }),
      since: data.conditionsPeriod,
    },
  };
}
