import { SyntheticEvent, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Dispatch } from 'redux';

import { GA_EVENTS, SUBSCRIPTION } from 'Shared/types/events';
import { FlashMessageType, StripePlan, StripeSubscriptionSession } from 'Shared/types/shared';
import { logEvent } from 'Shared/actions/event';
import { setFlashMessage } from 'Shared/actions/service';
import { getSubscriptionSession } from 'Shared/actions/subscription';
import config from 'Shared/config.json';
import { useQueryParams } from 'Shared/hooks/useQueryParams';

//It's highly recommended to call `loadStripe` outside of React's component lifecycle to avoid
// re-creating it on every render
// @see https://stripe.com/docs/payments/checkout/subscriptions/update-payment-details
const stripePromise = loadStripe(
  config.environment[process.env.ENV as keyof typeof config.environment].stripeKey
);

export interface PlanProps {
  plan: StripePlan;
  token: string;
  emailOrUsername: string;
  coupon?: string;
}

/**
 * This const is used to determine whether coupon is applied to the plan or not.
 * Right now we don't have a direct way to check that so we have to rely on indirect signs like discount-related properties
 * The names of the fields are taken from /shared/types/shared.ts:110:`StripePlan`
 * {@linkcode StripePlan}
 */
const couponRelatedFields = [
  'discountedAmount',
  'discountedAmountDisplay',
  'discountDuration',
  'discountDurationMonths',
];

export function usePlan({ plan, token, emailOrUsername, coupon }: PlanProps) {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = useQueryParams();

  const source = queryParams.get('source');

  const planSelectionHandler = (e: SyntheticEvent) => {
    e.preventDefault();

    let maxAttempts = 2;
    const isCouponAppliedToThePlan = !!Object.keys(plan).filter((fieldName) => {
      return couponRelatedFields.includes(fieldName);
    }).length;

    const subscriptionSessionParams = {
      planId: plan.id,
      token,
      emailOrUsername,
      ...(isCouponAppliedToThePlan && { coupon }),
    };

    const executeSubscription = async (requestParams: typeof subscriptionSessionParams) => {
      setIsLoading(true);
      try {
        const subscriptionSession = (await getSubscriptionSession(requestParams)(
          dispatch
        )) as StripeSubscriptionSession;
        const stripeRef = await stripePromise;
        await stripeRef.redirectToCheckout({ sessionId: subscriptionSession.sessionId });
      } catch (e: unknown) {
        if (maxAttempts) {
          maxAttempts--;
          await new Promise((r) => setTimeout(r, 2000));
          await executeSubscription(requestParams);
          return;
        }
        setFlashMessage({
          message:
            '<b>Unable to proceed to checkout</b><br />' +
            'Please <a href="/contact-us">contact</a> our support team to help you resolve ' +
            'this issue information.',
          type: FlashMessageType.ERROR,
          keepFor: 10,
        });
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    void logEvent({
      page: SUBSCRIPTION.CHOOSE_PLAN,
      data: {
        object_type: 'button',
        object: plan.id,
        source,
        ...subscriptionSessionParams,
      },
      gaEvent: GA_EVENTS.EVENT,
      nonInteraction: false,
    })(dispatch, store.getState.bind(store));

    void executeSubscription(subscriptionSessionParams);
  };

  return { planSelectionHandler, isLoading };
}
