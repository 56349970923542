import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { Link } from 'react-router-dom';

import { SUPPORT } from 'Shared/constants';

import styles from './shared.module.scss';

export const Success: FC = () => {
  return (
    <div className={styles.paymentUpdate}>
      <CTUI.Font.H1 customClasses={styles.title}>Congratulations!</CTUI.Font.H1>
      <CTUI.Font.H2 customClasses={styles.subTitle}>
        You have successfully updated your payment method.
        <br />
        Now let&apos;s check out how you&apos;ve been doing.
      </CTUI.Font.H2>
      <Link to="/dashboard" className={styles.link}>
        <CTUI.Button text="Get My Progress Report" customClasses={styles.getPRButton} />
      </Link>
      <CTUI.Font.H3>
        <b>Questions?</b> Call <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a> for
        assistance.
      </CTUI.Font.H3>
    </div>
  );
};
