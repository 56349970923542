import { useState, useRef, type ChangeEvent } from 'react';
import { type ReactSketchCanvasRef } from 'react-sketch-canvas';

export function useSketchPad() {
  const canvasRef = useRef<ReactSketchCanvasRef>(null);
  const [strokeColor, setStrokeColor] = useState('#000000');
  const [eraseMode, setEraseMode] = useState(false);

  const handleEraserClick = () => {
    setEraseMode(true);
    canvasRef.current?.eraseMode(true);
  };

  const handlePenClick = () => {
    setEraseMode(false);
    canvasRef.current?.eraseMode(false);
  };

  const handleStrokeColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStrokeColor(event.target.value);
  };

  const handleClearClick = () => {
    canvasRef.current?.clearCanvas();
  };

  return {
    canvasRef,
    strokeColor,
    handleStrokeColorChange,
    eraseMode,
    handleEraserClick,
    handlePenClick,
    handleClearClick,
  };
}
