import React, { FC } from 'react';
import moment from 'moment-timezone';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';

import { Tooltip } from 'Shared/components/tooltip';

import { useCalendarDay, CalendarDayProps } from './useCalendarDay';

import styles from './calendarDay.module.scss';

const tooltipOptions = {
  usePortal: true,
  classes: styles.calendarDayTooltip,
  strategy: 'fixed' as const,
  placement: 'top' as const,
};

export const CalendarDay: FC<CalendarDayProps> = ({ day, calendar }) => {
  const {
    isTooltipShown,
    showToolTip,
    hideTooltip,
    referenceElement,
    setReferenceElement,
    activeDay,
    dayClasses,
    dayStyles,
  } = useCalendarDay({ day, calendar });

  return (
    <div
      style={dayStyles}
      className={dayClasses}
      ref={setReferenceElement}
      onMouseEnter={showToolTip}
      onMouseLeave={hideTooltip}
    >
      {day.format('D')}
      {activeDay && isTooltipShown && (
        <Tooltip referenceElement={referenceElement} options={tooltipOptions}>
          <CTUI.Font.Text2 customClasses={classNames(styles.text, styles.title)}>
            {activeDay.inClinic ? 'Clinic' : 'Home'}:{' '}
            {moment(activeDay.date, 'X').format('MM/DD/YYYY')}
          </CTUI.Font.Text2>
          <CTUI.Font.Text3 customClasses={styles.text}>
            {activeDay.completedTaskCount} items completed
          </CTUI.Font.Text3>
          <CTUI.Font.Text3 customClasses={styles.text}>
            {activeDay.duration ? Math.round(activeDay.duration / 60) : 0} mins of therapy
          </CTUI.Font.Text3>
        </Tooltip>
      )}
    </div>
  );
};
