import React, { FC } from 'react';
import { ExpanderComponentProps } from 'react-data-table-component';
import { Types } from '@thelearningcorp/ct-web-packages';

import { Spinner } from 'Shared/components/spinner';
import { User } from 'Shared/types/shared';

import { DataRow } from '../usePatientsListTable';

import { ExpandableRowChart } from './chart';
import { ExpandableRowCalendar } from './calendar';
import { ExpandableRowSchedule } from './schedule';
import { useExpandableRow } from './useExpandableRow';

import styles from './index.module.scss';

interface ExpandableRowProps extends ExpanderComponentProps<DataRow> {
  clinician: User;
}

export const ExpandableRow: FC<ExpandableRowProps> = ({ clinician, data: patientData }) => {
  const { isLoading, patientsExpansions } = useExpandableRow({
    clinician,
    data: patientData as Types.DashboardService.Patient,
  });

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.expandableRow}>
      <ExpandableRowChart
        patientId={patientData.patientId}
        skillAreas={patientsExpansions[patientData.patientId].domains}
      />
      <ExpandableRowCalendar
        patientId={patientData.patientId}
        calendar={patientsExpansions[patientData.patientId].calendar}
      />
      <ExpandableRowSchedule
        patientData={patientData as Types.DashboardService.Patient}
        currentSchedule={patientsExpansions[patientData.patientId].currentSchedule}
      />
    </div>
  );
};
