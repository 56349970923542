import React, { FC, useRef } from 'react';
import { CTUI, useClickOutside } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPieChart } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import demoChartImage from 'Shared/src/images/graph.png';

import styles from './demoChartPopup.module.scss';

interface DemoChartPopupProps {
  closePopupHandler: () => void;
}

export const DemoChartPopup: FC<DemoChartPopupProps> = ({ closePopupHandler }) => {
  const modalRef = useRef(null);

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      closePopupHandler();
    }
  });

  return (
    <CTUI.Modal
      ref={modalRef}
      id={styles.demoChartPopup}
      closeButtonHandler={closePopupHandler}
      header={
        <h1 className={classNames('h1', styles.header)}>
          <FontAwesomeIcon
            icon={faPieChart}
            title="Example Skill Area Chart"
            className={styles.pieChartIcon}
          />
          Example Skill Area Chart
        </h1>
      }
    >
      <img
        className={styles.graphPreview}
        src={demoChartImage}
        alt="Example Skill Areas Chart"
        title="Example Skill Areas Chart"
      />
    </CTUI.Modal>
  );
};
