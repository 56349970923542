import React, { FC } from 'react';
import { useCues } from 'App/therapy/session/taskViewComponent/cues/useCues';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';

import { StimulusCue } from 'Shared/types/shared';

import styles from './index.module.scss';

interface CuesProps {
  cues: StimulusCue[];
  resourceUrl: string;
}

export const Cues: FC<CuesProps> = ({ cues, resourceUrl }) => {
  const { shouldShowCues, handleShowCues, handleClick, shouldShowTextCue } = useCues({
    resourceUrl,
  });

  return (
    <ul className={styles.cuesList}>
      {!shouldShowCues ? (
        <li className={styles.item}>
          <CTUI.Button
            text="Get Help"
            variant="primary-inverted"
            customClasses={styles.button}
            onClick={handleShowCues}
          />
        </li>
      ) : (
        cues.map((cue, index) => {
          return (
            <li className={styles.item} key={index}>
              <CTUI.Button
                text={cue.text || 'Hear word'}
                variant="primary-inverted"
                customClasses={classNames(styles.button, { [styles[cue.type]]: true })}
                onClick={() => handleClick(cue)}
              />
              {shouldShowTextCue && cue.contentType === 'text' && (
                <h2 className={classNames('h2 medium', styles.text)}>{cue.tapToDisplayText}</h2>
              )}
            </li>
          );
        })
      )}
    </ul>
  );
};
