import React, { FC } from 'react';

import { getTherapiesCompositeKey } from 'Shared/utils';
import { PerformanceReportTask } from 'Shared/types/shared';

import styles from './termsList.module.scss';

interface TermsListProps {
  therapies: {
    [key: string]: PerformanceReportTask;
  };
}

export const TermsList: FC<TermsListProps> = ({ therapies }) => {
  return (
    <ul className={styles.termsList}>
      {Object.entries(therapies).map(([key, therapy]) => {
        return Object.entries(therapy.levels).map(([subKey]) => {
          return (
            <li key={getTherapiesCompositeKey(key, subKey)}>
              <span className="term">
                {therapy.displayName}, Level {subKey}
              </span>
              <a href={`#${getTherapiesCompositeKey(key, subKey)}`} className="inPageNavLink">
                View Details
              </a>
            </li>
          );
        });
      })}
    </ul>
  );
};
