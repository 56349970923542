import { SyntheticEvent, useRef, useState } from 'react';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import { TaskTypes } from 'Shared/constants';
import type { EnhancedScheduledTaskType, ScheduledTaskTypeStub } from 'Shared/types/schedule';
import { TaskType } from 'Shared/types/task';

import type { Identifier, XYCoord } from 'dnd-core';

export interface DragItem {
  index: number;
  type: TaskTypes;
  task: TaskType | EnhancedScheduledTaskType | ScheduledTaskTypeStub;
}

export interface TaskTypeItemProps {
  index: number;
  task: EnhancedScheduledTaskType | ScheduledTaskTypeStub;
  moveScheduledTasks: (dragIndex: number, hoverIndex: number, item: DragItem) => void;
  deleteScheduledTask: (index: number) => void;
  adjustScheduledTask: (task: EnhancedScheduledTaskType | ScheduledTaskTypeStub) => void;
}

export enum ViewTypes {
  DEFAULT,
  ADJUST,
  DELETE,
}

export function useTaskTypeItem({
  index,
  task,
  moveScheduledTasks,
  deleteScheduledTask,
  adjustScheduledTask,
}: TaskTypeItemProps) {
  const taskItemTypeRef = useRef<HTMLElement>(null);
  const previewRef = useRef<HTMLLIElement>(null);
  const [viewType, setViewType] = useState<ViewTypes>(ViewTypes.DEFAULT);
  const handleCancelDeleteButtonClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setViewType(ViewTypes.DEFAULT);
  };
  const handleConfirmDeleteButtonClick = (e: SyntheticEvent) => {
    e.preventDefault();
    deleteScheduledTask(index);
  };
  const handleDiscardAdjustmentsButtonClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setViewType(ViewTypes.DEFAULT);
  };
  const handleConfirmAdjustmentsButtonClick = (
    task: EnhancedScheduledTaskType | ScheduledTaskTypeStub
  ) => {
    adjustScheduledTask(task);
    setViewType(ViewTypes.DEFAULT);
  };

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: [
      TaskTypes.TASK_TYPE,
      TaskTypes.SCHEDULED_TASK_TYPE,
      TaskTypes.SCHEDULED_TASK_TYPE_STUB,
    ],
    collect: (monitor: DropTargetMonitor) => ({ handlerId: monitor.getHandlerId() }),
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      if (!taskItemTypeRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = taskItemTypeRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveScheduledTasks(dragIndex, hoverIndex, item);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: TaskTypes.SCHEDULED_TASK_TYPE,
    item: () => ({
      index,
      type: TaskTypes.SCHEDULED_TASK_TYPE,
      task,
    }),
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() }),
  });

  preview(drop(previewRef));
  drag(taskItemTypeRef);

  return {
    taskItemTypeRef,
    previewRef,
    handlerId,
    isDragging,
    viewType,
    setViewType,
    handleCancelDeleteButtonClick,
    handleConfirmDeleteButtonClick,
    handleDiscardAdjustmentsButtonClick,
    handleConfirmAdjustmentsButtonClick,
  };
}
