import React, { FC } from 'react';

import { type ControlsProps, useControls } from './useControls';
import { Choice } from './choice';

import styles from './index.module.scss';

export const Controls: FC<ControlsProps> = ({
  task,
  handleAnswer,
  isInitialAudioInstructionsShouldBePlayed,
}) => {
  const { handleChoiceClick, choicesList } = useControls({
    task,
    handleAnswer,
    isInitialAudioInstructionsShouldBePlayed,
  });

  return (
    <ul
      className={styles.controls}
      style={{
        gridTemplateColumns: `repeat(${task.columns}, 1fr)`,
        gridTemplateRows: `repeat(${task.rows}, fit-content)`,
      }}
    >
      {choicesList.map((choice, key) => {
        return (
          <Choice
            key={key}
            choice={choice}
            index={key}
            resourceUrl={task.resourceUrl}
            handleClick={handleChoiceClick}
          />
        );
      })}
    </ul>
  );
};
