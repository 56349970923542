import { Dispatch } from 'redux';
// eslint-disable-next-line max-len
import { InvitationFormShape } from 'App/enterpriseDashboard/usersList/headerActions/addNewUser/constants';
import queryString from 'query-string';

import { USERS_STATUSES } from 'Shared/constants';
import { getBaseUrl } from 'Shared/utils';
import { fetchWrapper } from 'Shared/fetchWrapper';
import {
  GET_USERS,
  INSTITUTION_RESET_USER_PASSWORD,
  SUSPEND_USER,
  ACTIVATE_USER,
  REMOVE_USER,
  FILTER_USERS,
  INVITE_USER,
  RE_INVITE_USER,
  GET_INSTITUTION,
  SAVE_INSTITUTION,
  INSTITUTION_GET_PASSWORD_REQUIREMENTS,
  DISCHARGE_USER,
} from 'Shared/types/redux';
import { InstitutionExpanded, InstitutionUser, RootState } from 'Shared/types/shared';

export const getUsersList = (institutionId: number) => async (dispatch: Dispatch) => {
  return await fetchWrapper.get(
    `${getBaseUrl({ apiSection: 'license' })}/api/institutions/${institutionId}/users`,
    {
      dispatch,
      types: GET_USERS,
    }
  );
};

export const resetUserPassword =
  (institutionId: number, userId: number) => async (dispatch: Dispatch) => {
    return await fetchWrapper.patch(
      `${getBaseUrl({ apiSection: 'license' })}/api/institutions/${institutionId}/users/${userId}`,
      {
        dispatch,
        types: INSTITUTION_RESET_USER_PASSWORD,
      },
      { password: '' }
    );
  };

export const suspendUser =
  (institutionId: number, userId: number) => async (dispatch: Dispatch) => {
    return await fetchWrapper.patch(
      `${getBaseUrl({ apiSection: 'license' })}/api/institutions/${institutionId}/users/${userId}`,
      {
        dispatch,
        types: SUSPEND_USER,
      },
      { status: USERS_STATUSES.SUSPENDED }
    );
  };

export const activateUser =
  (institutionId: number, userId: number) => async (dispatch: Dispatch) => {
    return await fetchWrapper.patch(
      `${getBaseUrl({ apiSection: 'license' })}/api/institutions/${institutionId}/users/${userId}`,
      {
        dispatch,
        types: ACTIVATE_USER,
      },
      { status: USERS_STATUSES.ACTIVE }
    );
  };

export const removeUser = (institutionId: number, userId: number) => async (dispatch: Dispatch) => {
  return await fetchWrapper.delete(
    `${getBaseUrl({ apiSection: 'license' })}/api/institutions/${institutionId}/users/${userId}`,
    {
      dispatch,
      types: REMOVE_USER,
    }
  );
};

export const dischargeUser =
  (institutionId: number, userId: number) => async (dispatch: Dispatch) => {
    return await fetchWrapper.delete(
      `${getBaseUrl({ apiSection: 'license' })}/api/institutions/${institutionId}/users/${userId}`,
      {
        dispatch,
        types: DISCHARGE_USER,
      }
    );
  };

export const reInviteUser =
  (institutionId: number, userId: number) => async (dispatch: Dispatch) => {
    return await fetchWrapper.post(
      `${getBaseUrl({
        apiSection: 'license',
      })}/api/institutions/${institutionId}/users/${userId}/invitation/send`,
      {
        dispatch,
        types: RE_INVITE_USER,
      }
    );
  };

export const filterUsersList =
  (filteredUsers: InstitutionUser[]) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({
      type: FILTER_USERS,
      payload: { data: filteredUsers || getState().institution.usersList },
    });
  };

interface InviteUserParams extends InvitationFormShape {
  institutionId: number;
}

export const inviteUser =
  ({ institutionId, email, type }: InviteUserParams) =>
  async (dispatch: Dispatch) => {
    return await fetchWrapper.post(
      `${getBaseUrl({ apiSection: 'license' })}/api/institutions/${institutionId}/users`,
      {
        dispatch,
        types: INVITE_USER,
      },
      { email, type },
      { isSubmission: true }
    );
  };

export const getInstitution = (institutionId: number) => async (dispatch: Dispatch) => {
  return await fetchWrapper.get(
    `${getBaseUrl({ apiSection: 'license' })}/api/institutions/${institutionId}`,
    {
      dispatch,
      types: GET_INSTITUTION,
    }
  );
};

export const saveInstitution =
  (institutionId: number, institutionData: InstitutionExpanded) => async (dispatch: Dispatch) => {
    return await fetchWrapper.patch(
      `${getBaseUrl({ apiSection: 'license' })}/api/institutions/${institutionId}`,
      {
        dispatch,
        types: SAVE_INSTITUTION,
      },
      institutionData,
      { isSubmission: true }
    );
  };

/**
 * NOT TO BE CONFUSED BY `getPasswordRequirements` from `user` actions!
 * Gets password requirements for every particular institution
 */
export const getPasswordRequirements = (institutionId: number) => async (dispatch: Dispatch) => {
  return await fetchWrapper.get(
    `${getBaseUrl({
      apiSection: 'service',
    })}/api/institutions/${institutionId}/password/requirements`,
    {
      dispatch,
      types: INSTITUTION_GET_PASSWORD_REQUIREMENTS,
    },
    {
      skipParams: true,
    }
  );
};
