import React, { FC } from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment-timezone';

import { PatientCalendarDay } from 'Shared/types/shared';

import styles from './day.module.scss';

interface ExpandableRowCalendarDayProps {
  day: Moment;
  calendar: PatientCalendarDay[];
}

export const ExpandableRowCalendarDay: FC<ExpandableRowCalendarDayProps> = ({ day, calendar }) => {
  const goalMetBoundary = 30; // Time spent exercising to consider goal met. Could be
  // personalized in future
  const activeDay = calendar.find((calendarDay) =>
    moment.utc(calendarDay.date, 'X').isSame(moment.utc(day), 'day')
  );

  const dayClasses = classNames(styles.expandableRowCalendarDay, {
    [styles.activeDay]: activeDay,
    [styles.isGoalMet]: activeDay && Math.round(activeDay.duration / 60) >= goalMetBoundary, //The goal is met
    // only if patient exercised for more than amount of time passed in `goalMetBoundary`
  });

  return <div className={dayClasses}>{day.format('D')}</div>;
};
