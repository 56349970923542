import queryString from 'query-string';
import { Dispatch } from 'redux';

import { LoginForm } from 'Shared/types/forms';
import { fetchWrapper } from 'Shared/fetchWrapper';
import { getBaseUrl } from 'Shared/utils';
import { DO_USER_LOGIN, OTP_VALIDATE, OTP_RESET, DO_USER_LOGOUT_SUCCESS } from 'Shared/types/redux';
import { LoginOtpResponse } from 'Shared/types/api';

type LoginUsernameData = LoginForm & { ipAddress: string; webLogin: boolean };

export const loginUser = (data: LoginUsernameData) => async (dispatch: Dispatch) => {
  return await fetchWrapper.post(
    `${getBaseUrl()}/api/login/v3`,
    {
      dispatch,
      types: DO_USER_LOGIN,
    },
    data,
    { isSubmission: true }
  );
};

export const validateOtp =
  ({ userId, otp }: { userId: number; otp: string }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        otp,
        webLogin: true,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return (await fetchWrapper.get(
      `${getBaseUrl()}/api/users/${userId}/otp/validate?${formattedParams}`,
      {
        dispatch,
        types: OTP_VALIDATE,
      },
      { isSubmission: true }
    )) as LoginOtpResponse;
  };

export const resetOtp =
  ({ userId, otpResetToken: resetToken }: { userId: number; otpResetToken: string }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        resetToken,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return await fetchWrapper.post(
      `${getBaseUrl()}/api/users/${userId}/otp/reset?${formattedParams}`,
      {
        dispatch,
        types: OTP_RESET,
      }
    );
  };

export const logoutUser = () => (dispatch: Dispatch) => {
  dispatch({ type: DO_USER_LOGOUT_SUCCESS });

  return Promise.resolve();
};
