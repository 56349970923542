import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import { TherapyActivityActions, User, USER_TYPES } from 'Shared/types/shared';
import { loadActivityInitialScreen, loadActivityResumeScreen } from 'Shared/actions/therapy';
import { TherapyActivity } from 'Shared/types/api';

export interface WelcomeProps {
  authUser: User;
  scheduleId?: number;
  setScheduleId: Dispatch<SetStateAction<number>>;
}

export function useWelcome({ authUser, scheduleId, setScheduleId }: WelcomeProps) {
  const store = useStore();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [therapyActivity, setTherapyActivity] = useState<TherapyActivity>();

  const fetchTherapyActivity = async ({
    patientId,
    scheduleId,
  }: {
    patientId: number;
    scheduleId?: number;
  }) => {
    setIsLoading(true);
    try {
      if (scheduleId) {
        const response = await loadActivityResumeScreen({ patientId })(store.dispatch.bind(store));
        setTherapyActivity(response);
      } else {
        const response = await loadActivityInitialScreen({ patientId })(store.dispatch.bind(store));
        setTherapyActivity(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAction =
    ({ action }: { action: TherapyActivityActions }) =>
    () => {
      const startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');
      switch (action) {
        case TherapyActivityActions.START_THERAPY_SESSION:
        case TherapyActivityActions.CONTINUE:
          setScheduleId(therapyActivity?.sessionData.scheduleId);
          history.push(`/do-therapy/${therapyActivity?.sessionData.scheduleId}`);
          break;
        case TherapyActivityActions.EMAIL_REPORT:
          window.open(
            `/dashboard/patient/${authUser.user.userId.toString()}` +
              `/progress-note/${startDate}/${endDate}`
          );
          break;
        default:
          window.alert(`The specified action "${action}" is not yet supported.`);
          break;
      }
    };

  useEffect(() => {
    if (authUser.user.type === USER_TYPES.PATIENT) {
      void fetchTherapyActivity({ patientId: authUser.user.userId, scheduleId });
    }
  }, [authUser, scheduleId]);

  return { isLoading, therapyActivity, handleAction };
}
