import { useRef, useEffect } from 'react';

import BarChart from 'Shared/src/scripts/components/barChart/barChart';
import { PerformanceReportTaskLevel } from 'Shared/types/shared';

const defaultChartOptions = {
  width: 230,
  height: 150,
  margin: {
    left: 50,
  },
  bars: {
    labels: ['Baseline', 'Latest'],
    colors: () => {
      //Do nothing
    },
  },
};

export interface ChartProps {
  level: PerformanceReportTaskLevel;
}

export function useChart({ level }: ChartProps) {
  const accuracyChartRef = useRef(null);
  const latencyChartRef = useRef(null);
  const { accuracy, latency } = level;

  const accuracyData = [accuracy.baseline, accuracy.latest];
  const latencyData = [latency.baseline, latency.latest];
  const isSameAccuracyData =
    accuracy.baseline.value === accuracy.latest.value &&
    accuracy.baseline.date === accuracy.latest.date;
  const isSameLatencyData =
    latency.baseline.value === latency.latest.value &&
    latency.baseline.date === latency.latest.date;
  const accuracyOptions = {
    ...defaultChartOptions,
    title: 'Accuracy',
    className: 'barChart--accuracy',
    diff: {
      positive: 'Increase',
      negative: 'Decrease',
    },
    unit: accuracy.unit,
    displayUnit: accuracy.unit,
    axes: { y: { domain: [accuracy.min, accuracy.max] } },
    isInverted: accuracy.isInverted,
  };
  const latencyOptions = {
    ...defaultChartOptions,
    title: 'Completion time',
    className: 'barChart--latency',
    diff: {
      positive: 'Faster',
      negative: 'Slower',
    },
    unit: latency.unit,
    displayUnit: latency.unit,
    axes: { y: { domain: [latency.min, latency.max], ticks: 5 } },
    isInverted: latency.isInverted,
  };

  //Additional check for accuracy, to display one or two bars
  if (isSameAccuracyData && isSameLatencyData) {
    accuracyData.shift();
    accuracyOptions.bars = {
      labels: ['Current'],
      colors: () => {
        //Do nothing
      },
    };
    latencyData.shift();
    latencyOptions.bars = {
      labels: ['Current'],
      colors: () => {
        //Do nothing
      },
    };
  }

  useEffect(() => {
    const accuracyChart = new BarChart(accuracyChartRef.current, accuracyData, accuracyOptions);
    const latencyChart = new BarChart(latencyChartRef.current, latencyData, latencyOptions);

    return () => {
      accuracyChart.destroy();
      latencyChart.destroy();
    };
  }, [
    accuracyData,
    latencyData,
    accuracyOptions,
    latencyOptions,
    accuracyChartRef.current,
    latencyChartRef.current,
  ]);

  return {
    accuracyChartRef,
    latencyChartRef,
  };
}
