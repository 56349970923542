import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { PerformanceReportDomain } from 'Shared/types/shared';

import { PerformanceAndDischargeDomainItem } from './domainItem';

import sectionStyles from './section.module.scss';

interface ReportDomainsSectionProps {
  domains?: {
    [key: string]: PerformanceReportDomain;
  };
}

export const ReportDomainsSection: FC<ReportDomainsSectionProps> = ({ domains }) => {
  let domainItemsList = (
    <div className={sectionStyles.empty}>
      <FontAwesomeIcon icon={faChartColumn} className={sectionStyles.emptyIcon} />
      <CTUI.Font.H3 customClasses={sectionStyles.emptyTitle}>
        No skill performance yet!
      </CTUI.Font.H3>
      <CTUI.Font.Text customClasses={sectionStyles.emptyDescription}>
        Complete therapy activities at home and then check back here to see your performance across
        different skill areas!
      </CTUI.Font.Text>
    </div>
  );

  if (domains && Object.values(domains).length) {
    domainItemsList = (
      <ul>
        {Object.values(domains).map((domain) => {
          return <PerformanceAndDischargeDomainItem domain={domain} key={domain.systemName} />;
        })}
      </ul>
    );
  }

  return (
    <div className={sectionStyles.section}>
      <CTUI.Font.H2 customClasses={sectionStyles.title}>Skill performance</CTUI.Font.H2>
      {domainItemsList}
    </div>
  );
};
