import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { SUBSCRIBE_BUTTON_STATES } from 'Shared/constants';
import { SubscriptionInfo } from 'Shared/types/shared';

import styles from './subscriptionButton.module.scss';

interface SubscriptionButtonProps {
  subscriptionInfo: SubscriptionInfo;
}

export const SubscriptionButton: FC<SubscriptionButtonProps> = ({ subscriptionInfo }) => {
  if (subscriptionInfo.subscribeButton === SUBSCRIBE_BUTTON_STATES.RESUBSCRIBE) {
    return (
      <Link to="/account" className={styles.link}>
        <CTUI.Button text="Reactivate Subscription" customClasses={styles.button} />
      </Link>
    );
  }

  return (
    <Link to="/dashboard" className={styles.link}>
      <CTUI.Button text="Go to Dashboard" customClasses={styles.button} />
    </Link>
  );
};
