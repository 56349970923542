import { useRef, useState } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

import colors from '../../colors.module.scss';

export function useCell() {
  const modalRef = useRef(null);
  const [isCellDetailsOpen, setIsCellDetailsOpen] = useState(false);

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      setIsCellDetailsOpen(false);
    }
  });

  return {
    modalRef,
    isCellDetailsOpen,
    openCellDetailsHandler: () => {
      setIsCellDetailsOpen(true);
    },
    closeCellDetailsHandler: () => {
      setIsCellDetailsOpen(false);
    },
  };
}

export function getAccuracyColor(percentile: number) {
  if (percentile >= 90) {
    return colors.highColor;
  }
  if (percentile >= 40) {
    return colors.mediumColor;
  }
  return colors.lowColor;
}

export function getLatencyColor(percentile: number) {
  if (percentile >= 80) {
    return colors.highColor;
  }
  if (percentile >= 60) {
    return colors.mediumColor;
  }
  return colors.lowColor;
}
