import React, { ReactNode } from 'react';

import { FfmpegContextProvider } from 'Shared/context/ffmpegContext';
import { AudioContextProvider } from 'Shared/context/audioContext';

import { useTherapyLayout } from './useTherapyLayout';

export const TherapyLayout = ({ children }: { children: ReactNode }) => {
  useTherapyLayout();

  return (
    <FfmpegContextProvider>
      <AudioContextProvider>{children}</AudioContextProvider>
    </FfmpegContextProvider>
  );
};
