import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { getDisplayName, getDownloadDestination } from 'Shared/utils';
import { User } from 'Shared/types/shared';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import styles from './confirmation.module.scss';

interface ConfirmationProps {
  preAuthorizedUser: User;
}

export const Confirmation: FC<ConfirmationProps> = ({ preAuthorizedUser }) => {
  const displayName = getDisplayName({ userObject: preAuthorizedUser });
  const downloadLink = getDownloadDestination(preAuthorizedUser);

  return (
    <FloatingPage
      customClasses={styles.confirmationModal}
      header={<h1 className="h1">Hi, {displayName}!</h1>}
      footer={
        <a href={downloadLink} className={styles.link}>
          <CTUI.Button text="Download Constant Therapy" customClasses={styles.button} />
        </a>
      }
    >
      <p className={styles.message}>Welcome to Constant Therapy.</p>
      <br />
      <p>
        If you haven&apos;t already installed Constant Therapy on your device, go to the device you
        will use Constant Therapy on and download the app now to get started!
      </p>
    </FloatingPage>
  );
};
