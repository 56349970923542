import React, { SyntheticEvent, useMemo, useState } from 'react';

import { CA_PROVINCES_LIST, COUNTRIES_LIST, US_STATES_LIST } from 'Shared/constants';

//See COUNTRIES_LIST.code
const usCode = 'US';
const caCode = 'CA';

interface InstitutionFormProps {
  initialValues?: {
    country?: string;
  };
  reset: () => void;
}

export function useInstitutionForm({ initialValues, reset }: InstitutionFormProps) {
  const [shouldShowStatesList, setShouldShowStatesList] = useState(
    initialValues?.country === usCode
  );
  const [shouldShowProvincesList, setShouldShowProvincesList] = useState(
    initialValues?.country === caCode
  );

  const handleCountryChange = (e: SyntheticEvent, newValue: string) => {
    setShouldShowStatesList(newValue === usCode);
    setShouldShowProvincesList(newValue === caCode);
  };

  const handleFormReset = () => {
    reset();
    setShouldShowStatesList(initialValues?.country === usCode);
    setShouldShowProvincesList(initialValues?.country === caCode);
  };

  const countriesOptions = useMemo(() => {
    return [
      <option value="" disabled={true} key="initialSelection">
        Select an option
      </option>,
      ...COUNTRIES_LIST.map(({ name, code }) => {
        return (
          <option key={code} value={code}>
            {name}
          </option>
        );
      }),
    ];
  }, []);

  const statesOptions = useMemo(() => {
    return [
      <option value="" disabled={true} key="initialSelection">
        Select state
      </option>,
      ...Object.entries(US_STATES_LIST).map(([key, value]) => {
        return (
          <option key={key} value={key}>
            {value}
          </option>
        );
      }),
    ];
  }, []);

  const provincesOptions = useMemo(() => {
    return [
      <option value="" disabled={true} key="initialSelection">
        Select province
      </option>,
      ...Object.entries(CA_PROVINCES_LIST).map(([key, value]) => {
        return (
          <option key={key} value={key}>
            {value}
          </option>
        );
      }),
    ];
  }, []);

  return {
    countriesOptions,
    statesOptions,
    provincesOptions,
    handleCountryChange,
    handleFormReset,
    shouldShowStatesList,
    shouldShowProvincesList,
  };
}
