import React, { FC } from 'react';
import classNames from 'classnames';

import { type TaskViewMultipleChoice } from 'Shared/types/shared';

import { type TaskTypeProps } from '../../types';
import { Stimulus } from '../../stimulus';

import { useMultipleChoice } from './useMultipleChoice';
import { Choices } from './choices';

import sharedStyles from '../index.module.scss';
import styles from './index.module.scss';

export const MultipleChoice: FC<TaskTypeProps<TaskViewMultipleChoice>> = ({
  task,
  isAnswered,
  handleAnswer,
  isInitialAudioInstructionsShouldBePlayed,
}) => {
  const { hasPlayed, setHasPlayed } = useMultipleChoice({ task });

  return (
    <div className={classNames(styles.multipleChoice, sharedStyles.taskType)}>
      <Stimulus task={task} setHasPlayed={setHasPlayed} />
      {task.instructions.choiceCoverText && !hasPlayed ? (
        <p>{task.instructions.choiceCoverText}</p>
      ) : (
        <Choices task={task} isAnswered={isAnswered} handleAnswer={handleAnswer} />
      )}
    </div>
  );
};
