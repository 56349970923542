import React, { FC } from 'react';
import classNames from 'classnames';

import { Spinner } from 'Shared/components/spinner';

import { Header } from './header';
import { Instructions } from './instructions';
import { ProgressBar } from './progressBar';
import { TaskViewComponent } from './taskViewComponent';
import { SCREEN_MODES, SessionProps, useSession } from './useSession';

import sharedStyles from '../index.module.scss';
import styles from './index.module.scss';

export const Session: FC<SessionProps> = ({
  authUser,
  scheduleId,
  setScheduleId,
  activeTaskIndex,
  setActiveTaskIndex,
}) => {
  const {
    isLoading,
    therapySession,
    taskTypes,
    solveTask,
    skipTask,
    activeScreenMode,
    setActiveScreenMode,
  } = useSession({
    authUser,
    scheduleId,
    setScheduleId,
    activeTaskIndex,
    setActiveTaskIndex,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={classNames(sharedStyles.therapy, styles.session)}>
      <Header
        therapySession={therapySession}
        taskTypes={taskTypes}
        activeTaskIndex={activeTaskIndex}
        skipTask={skipTask}
        activeScreenMode={activeScreenMode}
        setScheduleId={setScheduleId}
      />
      {activeScreenMode === SCREEN_MODES.INSTRUCTIONS ? (
        <Instructions
          therapySession={therapySession}
          taskTypes={taskTypes}
          activeTaskIndex={activeTaskIndex}
          setActiveScreenMode={setActiveScreenMode}
        />
      ) : (
        <TaskViewComponent
          key={activeTaskIndex}
          task={therapySession.taskViews[activeTaskIndex]}
          oldTask={therapySession.tasks[activeTaskIndex]}
          solveTask={solveTask}
        />
      )}
      <ProgressBar
        therapySession={therapySession}
        taskTypes={taskTypes}
        activeTaskIndex={activeTaskIndex}
      />
    </div>
  );
};
