import React, { FC } from 'react';
import classNames from 'classnames';

import { DownloadAppList } from 'Shared/components/downloadAppList';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { Meta } from './meta';
import { useStart, StartProps } from './useStart';

import styles from './index.module.scss';

/**
 * More info on `start`-related conditions can be found in description
 * @see {@link https://www.pivotaltracker.com/n/projects/2167259/stories/163621990}
 */
export const Start: FC<StartProps> = ({ authUser }) => {
  const { userType } = useStart({ authUser });

  return (
    <FloatingPage
      customClasses={styles.startPage}
      header={
        <h1 className={classNames('h1', styles.title)}>
          Download the Constant Therapy app to your smartphone or tablet
        </h1>
      }
    >
      <Meta />
      <DownloadAppList userType={userType} />
    </FloatingPage>
  );
};
