import { useRef, useState } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

export function useFilters() {
  const wrapperRef = useRef(null);
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);

  useClickOutside(wrapperRef, () => {
    if (wrapperRef.current) {
      setIsTooltipShown(false);
    }
  });

  return {
    isTooltipShown,
    toggleToolTip: () => {
      setIsTooltipShown(!isTooltipShown);
    },
    wrapperRef,
    referenceElement,
    setReferenceElement,
  };
}
