import React, { FC } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { USER_TYPES } from 'Shared/constants';
import { User } from 'Shared/types/shared';

import styles from './details.module.scss';

interface DetailsProps {
  authUser: User;
}

export const Details: FC<DetailsProps> = ({ authUser }) => {
  return (
    <div className={styles.details}>
      <dl className={styles.info}>
        <dt className="text medium">Name</dt>
        <dd>
          {authUser.user.firstName} {authUser.user.lastName}
        </dd>
        <dt className="text medium">Username</dt>
        <dd>{authUser.user.username}</dd>
        <dt className="text medium">Email</dt>
        <dd>{authUser.user.email}</dd>
      </dl>
      <p className={classNames('text', 'medium', styles.membership)}>
        {/* eslint-disable-next-line max-len */}
        {authUser.user.type === USER_TYPES.PATIENT
          ? 'Valued Member since'
          : 'Valued Partner since'}{' '}
        {moment(authUser.user.createDate, 'X').format('MMM Do, YYYY')}
      </p>
    </div>
  );
};
