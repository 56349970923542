import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Forgot your password? No worries — we've got you 
  covered! Follow the prompts on this page to reset your password and get back to your 
  Constant Therapy program.`;
  return (
    <Helmet>
      <title>Reset Password | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
