import React, { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './floatingPage.module.scss';

interface FloatingPageProps {
  header?: ReactNode;
  footer?: ReactNode;
  customClasses?: string;
}

export const FloatingPage: FC<PropsWithChildren<FloatingPageProps>> = ({
  header,
  children,
  footer,
  customClasses,
}) => {
  const modalClasses = classNames(styles.floatingPage, customClasses);

  return (
    <div className={modalClasses}>
      <div className="header">{header}</div>
      <div className="body">{children}</div>
      <div className="footer">{footer}</div>
    </div>
  );
};
