import { useEffect, useState } from 'react';
import { reset } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { changeUserPassword, getPasswordRequirements, getUserData } from 'Shared/actions/user';
import { setFlashMessage } from 'Shared/actions/service';
import { FlashMessageType, PasswordRequirements, User } from 'Shared/types/shared';
import type { ChangePasswordForm } from 'Shared/types/forms';

export interface PasswordComponentProps {
  authUser: User;
}

export function usePassword({ authUser }: PasswordComponentProps) {
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [passwordRequirements, setPasswordRequirements] = useState<PasswordRequirements>();

  const handleChangeUserPassword = async (formData: ChangePasswordForm) => {
    await changeUserPassword({
      ...formData,
      userId: authUser.user.userId,
    })(dispatch);

    //We have to re-fetch user in order to update Redux-store and trigger all related modules
    // to react on new data
    await getUserData()(dispatch);

    setFlashMessage({
      message: 'Your password has been successfully changed.',
      type: FlashMessageType.SUCCESS,
      keepFor: 5,
    })(dispatch);

    //Dispatches action responsible for clearing form values
    dispatch(reset('changePasswordForm'));

    history.push('/dashboard');
  };

  const fetchPasswordRequirements = async () => {
    try {
      const passwordRequirements = (await getPasswordRequirements({
        userId: authUser.user.userId,
      })(dispatch)) as PasswordRequirements;
      setPasswordRequirements(passwordRequirements);
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchPasswordRequirements();
  }, []);

  return {
    isLoading,
    handleChangeUserPassword,
    passwordRequirements,
  };
}
