import React, { FC } from 'react';
import classNames from 'classnames';

import { SUBSCRIBE_BUTTON_STATES } from 'Shared/constants';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { useSubscribedUserModal, SubscribedUserModalProps } from './useSubscribedUserModal';
import { SubscriptionButton } from './subscriptionButton';

import styles from './index.module.scss';

export const SubscribedUserModal: FC<SubscribedUserModalProps> = ({
  displayName,
  subscriptionInfo,
  isAuthorized,
}) => {
  useSubscribedUserModal({
    displayName,
    subscriptionInfo,
    isAuthorized,
  });

  return (
    <FloatingPage
      customClasses={styles.subscribedUserModal}
      header={<h1 className={classNames('h1', styles.title)}>Welcome, {displayName}</h1>}
      footer={<SubscriptionButton subscriptionInfo={subscriptionInfo} />}
    >
      <p>
        <span dangerouslySetInnerHTML={{ __html: subscriptionInfo.subscriptionBlurb }} />
      </p>
      {subscriptionInfo.subscribeButton === SUBSCRIBE_BUTTON_STATES.RESUBSCRIBE &&
        !isAuthorized && <p>Please login into your account and reactivate subscription.</p>}
    </FloatingPage>
  );
};
