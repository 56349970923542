import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { SUPPORT } from 'Shared/constants';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import styles from './tokenInvalid.module.scss';

export const TokenInvalid: FC = () => {
  return (
    <FloatingPage
      customClasses={styles.tokenInvalidModal}
      header={<h1 className="h1">Oops! It looks like this link is no longer valid.</h1>}
    >
      <p>
        To log in to Constant Therapy <Link to="/login">click here</Link>. Need help? Give the
        Constant Therapy Support Team a call at{' '}
        <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a> or email{' '}
        <a href={`mailto:${SUPPORT.EMAIL}`}>{SUPPORT.EMAIL}</a>.
      </p>
    </FloatingPage>
  );
};
