import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Ensure seamless access to your Constant Therapy 
  program by keeping your payment details up-to-date here. It’s quick and easy!`;
  return (
    <Helmet>
      <title>Update Your Payment Details | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
