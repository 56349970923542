import queryString from 'query-string';
import { Dispatch } from 'redux';

import { fetchWrapper } from 'Shared/fetchWrapper';
import { getBaseUrl } from 'Shared/utils';
import { TaskType, TaskTypeCategory } from 'Shared/types/task';
import { GET_ALL_TASKS } from 'Shared/types/redux';

export const getAllTasksFlat = () => async (dispatch: Dispatch) => {
  const formattedParams = queryString.stringify(
    {
      token: localStorage.getItem('accessToken'),
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  return (await fetchWrapper.get(`${getBaseUrl()}/api/task/types?${formattedParams}`, {
    dispatch,
    types: GET_ALL_TASKS,
  })) as Promise<TaskType[]>;
};

export const getAllTasksHierarchy = () => async (dispatch: Dispatch) => {
  const formattedParams = queryString.stringify(
    {
      token: localStorage.getItem('accessToken'),
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  return (await fetchWrapper.get(`${getBaseUrl()}/api/task/types/hierarchy?${formattedParams}`, {
    dispatch,
    types: GET_ALL_TASKS,
  })) as Promise<TaskTypeCategory>;
};
