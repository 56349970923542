import React, { FC } from 'react';
import classNames from 'classnames';

import { Spinner } from 'Shared/components/spinner';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { UnsubscribeForm } from './form';
import { useStart } from './useStart';

import sharedStyles from '../shared.module.scss';

export const Start: FC = () => {
  const { isLoading, unsubscribeReasons, handleUnsubscribe } = useStart();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <FloatingPage
      customClasses={sharedStyles.unsubscribe}
      header={<h1 className={classNames('h1', sharedStyles.title)}>Cancel Subscription</h1>}
    >
      <h4 className={classNames('h4', sharedStyles.hint)}>We&apos;re sorry to see you go.</h4>
      <UnsubscribeForm onSubmit={handleUnsubscribe} unsubscribeReasons={unsubscribeReasons} />
    </FloatingPage>
  );
};
