import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import { DOCUMENT_TITLE_BASE } from 'Shared/constants';

import styles from './pageError.module.scss';

const defaultTitle = '404. Page Not Found.';
const defaultMessage = 'Oh no, something is wrong, please contact our support team';

interface PageErrorProps {
  error?: {
    title?: string;
    message?: string;
  };
}

export const PageError: FC<PageErrorProps> = ({ error }) => {
  const title = error?.title || defaultTitle;
  const message = error?.message || defaultMessage;

  return (
    <>
      <Helmet>
        <title>
          {title} - {DOCUMENT_TITLE_BASE}
        </title>
      </Helmet>
      <FloatingPage header={<h1 className="h1">{title}</h1>} customClasses={styles.errorPage}>
        <p dangerouslySetInnerHTML={{ __html: message }} />
      </FloatingPage>
    </>
  );
};
