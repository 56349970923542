import React, { FC, SyntheticEvent } from 'react';

import { USER_TYPES } from 'Shared/types/shared';

import styles from './filterByUserType.module.scss';

const filters = [
  {
    title: 'All',
    type: null,
  },
  {
    title: 'Clinicians',
    type: USER_TYPES.CLINICIAN,
  },
  {
    title: 'Patients',
    type: USER_TYPES.PATIENT,
  },
];

interface FilterByUserTypeProps {
  type: USER_TYPES;
  filterByType: (e: SyntheticEvent, type: USER_TYPES) => void;
}

export const FilterByUserType: FC<FilterByUserTypeProps> = ({ type, filterByType }) => {
  return (
    <ul className={styles.filterByUserType}>
      <li>Show:</li>
      {filters.map((filterItem) => {
        return (
          <li key={filterItem.title}>
            <a
              href="#"
              onClick={(e) => filterByType(e, filterItem.type)}
              className={type === filterItem.type ? styles.active : ''}
            >
              {filterItem.title}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
