import React, { FC } from 'react';
import classNames from 'classnames';

import { SubscriptionPlansResponse } from 'Shared/types/shared';

import styles from './couponMessage.module.scss';

interface CouponMessageProps {
  subscriptionPlansData: SubscriptionPlansResponse;
}

export const CouponMessage: FC<CouponMessageProps> = ({ subscriptionPlansData }) => {
  const blockClasses = classNames(styles.couponMessage, {
    [styles.success]: subscriptionPlansData.couponMessageSuccess,
    [styles.error]: !subscriptionPlansData.couponMessageSuccess,
  });

  return (
    <div className={blockClasses}>
      <h4
        className="h4"
        dangerouslySetInnerHTML={{ __html: subscriptionPlansData.couponMessage }}
      />
    </div>
  );
};
