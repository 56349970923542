import { useState, useEffect } from 'react';
import isempty from 'lodash.isempty';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { logEvent } from 'Shared/actions/event';
import { getPatientReport } from 'Shared/actions/patient';
import { USER_TYPES } from 'Shared/constants';
import { DASHBOARD } from 'Shared/types/events';
import { RootState, User } from 'Shared/types/shared';

import { instanceOfPerformanceReport } from '../../shared/reportUtils';

export interface ReportProps {
  surveillanceMode?: boolean;
  patient: User;
}

export function useReport({ surveillanceMode, patient }: ReportProps) {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const report = useSelector((state: RootState) => state.patient.report);
  const reportError = useSelector((state: RootState) => state.patient.reportError);
  const isEmptyReport =
    report &&
    instanceOfPerformanceReport(report) &&
    !Object.entries(report.sections).some(([key, value]) => !isempty(value));
  const [isLoading, setIsLoading] = useState(true);

  const fetchPatientReport = async () => {
    try {
      await getPatientReport({ patientId: patient.user.userId })(dispatch);
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
      await logEvent({
        page: DASHBOARD.DASHBOARD,
        data: {
          userType: USER_TYPES.PATIENT,
          isTrialing:
            !surveillanceMode && !patient.subscriptionState?.permissions?.canViewExpandedDashboard,
          surveillanceMode,
        },
      })(dispatch, store.getState.bind(store));
    }
  };

  useEffect(() => {
    void fetchPatientReport();
  }, [patient]);

  return {
    isEmptyReport,
    canViewExpandedDashboard:
      surveillanceMode || patient.subscriptionState?.permissions?.canViewExpandedDashboard,
    isLoading,
    report,
    reportError,
  };
}
