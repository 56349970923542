import React, { FC } from 'react';

import { PATIENT_TYPES } from 'Shared/types/shared';

import { PatientTypeSelection } from './patientTypeSelection';
import { WizardNewPatient } from './wizard/newPatient';
import { WizardExistingPatient } from './wizard/existingPatient';
import { AddPatientProps, useAddPatient } from './useAddPatient';

export const AddPatient: FC<AddPatientProps> = ({ clinician }) => {
  const {
    addPatientData,
    handleSaveExistingPatient,
    handleSaveNewPatient,
    handleSelectPatientType,
    handleClearPatientType,
  } = useAddPatient({
    clinician,
  });

  if (!addPatientData.patientType) {
    return <PatientTypeSelection selectPatientType={handleSelectPatientType} />;
  }

  if (addPatientData.patientType === PATIENT_TYPES.NEW) {
    return (
      <WizardNewPatient
        handleSaveNewPatient={handleSaveNewPatient}
        clearPatientType={handleClearPatientType}
      />
    );
  } else {
    return (
      <WizardExistingPatient
        handleSaveExistingPatient={handleSaveExistingPatient}
        clearPatientType={handleClearPatientType}
      />
    );
  }
};
