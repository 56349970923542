import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Dispatch } from 'redux';

import { PARTNER_LOGO_STORAGE_NAME } from 'Shared/constants';
import type { DefaultActivationInvitation } from 'Shared/types/shared';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { acceptInvitation } from 'Shared/actions/user';

import { handlePatientActivation } from '../shared/utils';

export function useDefaultActivation() {
  const dispatch: Dispatch = useDispatch();
  const queryParams = useQueryParams();
  // Sometimes the URL looks like this /activate/?t=011066f1-a964-40ff-b649-6c664fa4d580/1/0100018b8666bbe2-f99d6d54-9d05-40e7-bf7e-d8fc30d4f267-000000/mZqX37A0hmVcIBYqjgCXg1naznk=346,
  // so we need to trim `?t=` param by `/` to get a proper value
  const activationToken = queryParams.has('t') ? queryParams.get('t').split('/')[0] : '';

  const [isLoading, setIsLoading] = useState(!!activationToken);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>(
    !activationToken && 'Unable to find your invitation, are you sure you have one?'
  );
  const [redirectParams, setRedirectParams] = useState<string>();
  const [invitationData, setInvitationData] = useState<DefaultActivationInvitation>();

  const handleAcceptInvitation = async (activationToken: string) => {
    try {
      setIsLoading(true);
      const { data: responseData } = (await acceptInvitation(activationToken)(dispatch)) as {
        data: DefaultActivationInvitation;
      };

      setSuccessMessage(responseData.data.message);
      setInvitationData(responseData);

      setRedirectParams(
        queryString.stringify(
          {
            userType: responseData.userType,
            email: responseData.email,
            institutionId: responseData.data.institutionId,
            invitationToken: activationToken,
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        )
      );

      //Adds `partnerLogo` based on data returned within invitation's response institution-data object
      //@see {https://constanttherapy.atlassian.net/browse/WEBAPP-441}
      if (responseData.data.logo_image_path) {
        sessionStorage.setItem(PARTNER_LOGO_STORAGE_NAME, responseData.data.logo_image_path);
      }
    } catch (e: unknown) {
      setErrorMessage(e.toString());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activationToken) {
      void handleAcceptInvitation(activationToken);
    }
  }, [activationToken]);

  return {
    isLoading,
    successMessage,
    errorMessage,
    invitationData,
    redirectParams,
    handlePatientActivation: handlePatientActivation({ dispatch }),
  };
}
