import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { User } from 'Shared/types/shared';

import { Meta } from './meta';
import { DashboardNavigation } from './dashboardNavigation';
import { Welcome } from './welcome';
import { UsersList } from './usersList';
import { Institution } from './institution';
import { NoAccess } from './noAccess';

import styles from './index.module.scss';

interface EnterpriseDashboardProps {
  authUser: User;
}

export const EnterpriseDashboard: FC<EnterpriseDashboardProps> = ({ authUser }) => {
  if (!authUser?.institutionInfo.institutionAdmin) {
    return <NoAccess />;
  }

  return (
    <div className={styles.enterpriseDashboard}>
      <Meta />
      <DashboardNavigation />
      <Switch>
        <Route exact path="/enterprise-dashboard/">
          <Welcome authUser={authUser} />
        </Route>
        <Route exact path="/enterprise-dashboard/users">
          <UsersList institutionId={authUser.institutionInfo.institutionId} />
        </Route>
        <Route exact path="/enterprise-dashboard/institution">
          <Institution institutionId={authUser.institutionInfo.institutionId} />
        </Route>
      </Switch>
    </div>
  );
};
