import { TableColumn } from 'react-data-table-component';
import moment from 'moment-timezone';
import React from 'react';

import { DataRow } from './usePatientsListTable';
import { StatusCell } from './statusCell';
import { AverageActivityCell } from './averageActivityCell';

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'User name',
    selector: (row) => row.patientUsername,
    sortable: true,
  },
  {
    name: 'Status',
    cell: (row) => <StatusCell status={row.status} patientId={row.patientId} />,
    sortable: true,
    sortFunction: (itemA, itemB) => itemA.status.sortOrder - itemB.status.sortOrder,
  },
  {
    name: 'Last activity',
    selector: (row) => row.dateLastActive,
    format: (row) =>
      row.dateLastActive ? moment(row.dateLastActive, 'X').fromNow() : 'No activity',
    sortable: true,
  },
  {
    name: 'Home exercises',
    selector: (row) => row.homeExercises,
    format: (row) => row.homeExercises.toLocaleString(),
    sortable: true,
  },
  {
    name: 'Clinic exercises',
    selector: (row) => row.clinicExercises,
    format: (row) => row.clinicExercises.toLocaleString(),
    sortable: true,
  },
  {
    name: 'Average activity',
    selector: (row) => row.daysActivePerWeek,
    right: true,
    grow: 2,
    // eslint-disable-next-line react/display-name
    cell: (row) => (
      <AverageActivityCell patientId={row.patientId} daysActivePerWeek={row.daysActivePerWeek} />
    ),
    sortable: true,
  },
];
