import React, { FC } from 'react';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';

import { STIMULUS_CONTENT_TYPES } from 'Shared/types/shared';

import type { EnhancedPlaceholder, ItemShape } from '../types';

import styles from './index.module.scss';

interface PlaceholderProps {
  placeholder: EnhancedPlaceholder;
  accept: string[];
  onDrop: (item: ItemShape) => void;
  resourceUrl: string;
}

export const Placeholder: FC<PlaceholderProps> = ({ placeholder, accept, onDrop, resourceUrl }) => {
  const [{ isOver }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <li
      className={classNames(styles.placeholder, {
        [styles.isOver]: isOver,
        [styles.isOccupied]: placeholder.isOccupied,
      })}
      ref={drop}
    >
      {!placeholder.contentType && ''}
      {placeholder.contentType && placeholder.contentType === STIMULUS_CONTENT_TYPES.IMAGE && (
        <img src={placeholder.contentValue} className={styles.image} />
      )}
      {placeholder.contentType && placeholder.contentType === STIMULUS_CONTENT_TYPES.TEXT && (
        <h2 className="h2 medium">{placeholder.contentValue}</h2>
      )}
    </li>
  );
};
