import { useContext, useEffect, useState } from 'react';

import { AudioContext } from 'Shared/context/audioContext';
import correctSound from 'Shared/src/themes/2023/sounds/correct_answer.mp3';
import wrongSound from 'Shared/src/themes/2023/sounds/wrong_answer.mp3';

import { AnswerResult } from '../useTaskView';

import styles from './index.module.scss';

const delay = 1;

export interface AnswerResultComponentProps {
  answerResult: AnswerResult;
}

export function useAnswerResult({ answerResult }: AnswerResultComponentProps) {
  const { isPlaying, playAudioSequence, stopAudioSequence } = useContext(AudioContext);

  const [shouldShowInterval, setShouldShowInterval] = useState<number>(0);

  useEffect(() => {
    if (shouldShowInterval && shouldShowInterval > 0) {
      const timer = window.setTimeout(() => {
        setShouldShowInterval(shouldShowInterval - 1);
      }, delay * 1000);

      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [shouldShowInterval]);

  useEffect(() => {
    if (answerResult) {
      setShouldShowInterval(
        parseInt(styles.showAnimationDuration) +
          parseInt(styles.showAnimationDelay) +
          parseInt(styles.hideAnimationDuration) +
          parseInt(styles.hideAnimationDelay) -
          delay * 2 //Delay needs to be deducted twice from the final value because first and last iterations of the counter happen with delay of 1000ms
      );
    }
  }, [answerResult]);

  useEffect(() => {
    if (answerResult) {
      if (isPlaying) {
        stopAudioSequence();
      }
      const sound = new Audio(
        (answerResult === AnswerResult.WRONG ? wrongSound : correctSound) as string
      );
      sound.loop = false;
      playAudioSequence({ audioElementsList: [sound] });
    }
  }, [answerResult]);

  return { shouldShowInterval };
}
