import React, { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { RouteAccessRestrictions, USER_SUBSCRIPTION_STATUSES, SUPPORT } from 'Shared/constants';
import { Spinner } from 'Shared/components/spinner';
import { User } from 'Shared/types/shared';
import { PageError } from 'Shared/components/pageError';

import { useRouteRenderer } from './useRouteRenderer';

interface RouteRendererProps {
  Layout: FC<{ children: ReactNode }>;
  Module: FC<{ authUser?: User }>;
  access: RouteAccessRestrictions;
}

export const RouteRenderer = ({ Layout, Module, access }: RouteRendererProps) => {
  const { isLoading, authUser, formattedParams } = useRouteRenderer();

  if (!authUser && !isLoading && access === RouteAccessRestrictions.PRIVATE) {
    return <Redirect to={`/login?${formattedParams}`} />;
  }

  if (authUser && access === RouteAccessRestrictions.ONLY_PUBLIC) {
    return <Redirect to="/dashboard" />;
  }

  //This condition ensures we make redirect only once
  if (
    authUser &&
    authUser.subscriptionState?.status === USER_SUBSCRIPTION_STATUSES.CLINICIAN_SETUP &&
    document.location.pathname !== '/activate/clinician-setup'
  ) {
    return <Redirect to={`/activate/clinician-setup?${formattedParams}`} />;
  }

  return (
    <Layout>
      {isLoading ? (
        <Spinner />
      ) : (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <PageError
                error={{
                  title: 'Oh no, something is wrong :-/',
                  message: `Please reach out to Customer Support at
          <a href="tel:${SUPPORT.PHONE}">${SUPPORT.PHONE_FORMATTED}</a> or
          <a href="mailto:${SUPPORT.EMAIL}">${SUPPORT.EMAIL}</a> to proceed.
          We apologize for the inconvenience.`,
                }}
              />
            );
          }}
        >
          <Module authUser={authUser} />
        </ErrorBoundary>
      )}
    </Layout>
  );
};
