import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faUserXmark, faXmark } from '@fortawesome/free-solid-svg-icons';

import { SharedModalProps } from './sharedModalProps';

import sharedModalStyles from './sharedModalStyles.module.scss';
import styles from './removeUser.module.scss';

export const RemoveUserModal: FC<SharedModalProps> = ({
  email,
  isLoading,
  handleModalSubmit,
  closeActiveModal,
  modalSubmissionError,
  modalRef,
}) => {
  const [isConfirmationCheckboxActive, setConfirmationCheckboxActive] = useState(false);

  return (
    <CTUI.Modal
      ref={modalRef}
      header={
        <h1 className="h1">
          <FontAwesomeIcon
            icon={faUserXmark}
            className={classNames(sharedModalStyles.icon, styles.icon)}
          />
          Remove user
        </h1>
      }
      headerClasses={sharedModalStyles.header}
      customClasses={sharedModalStyles.actionCellModal}
      closeButtonHandler={closeActiveModal}
      footer={
        <>
          <CTUI.Form.SubmitButton
            isSubmitting={isLoading}
            isPristine={!isConfirmationCheckboxActive}
            onClick={handleModalSubmit}
            icon={faXmark}
            text="Remove user"
          />
          <CTUI.Form.ResetButton
            isSubmitting={isLoading}
            isPristine={false}
            onClick={closeActiveModal}
            text="Cancel"
          />
        </>
      }
      footerClasses={sharedModalStyles.footer}
      error={modalSubmissionError}
    >
      <div className={sharedModalStyles.extraMargin}>
        <h4 className={classNames('h4', sharedModalStyles.message)}>
          You are removing <b>{`<${email}>`}</b> from your user list.
        </h4>
        <p className={styles.message}>
          <FontAwesomeIcon icon={faInfoCircle} className={styles.infoIcon} />
          This will remove all data associated with this account. If you decide to reactivate this
          user later, their data will no longer be accessible and cannot be recovered.
        </p>
        <div className={styles.confirmationCheckbox}>
          <label>
            <input
              type="checkbox"
              checked={isConfirmationCheckboxActive}
              onChange={() => setConfirmationCheckboxActive(!isConfirmationCheckboxActive)}
            />
            <span>
              I understand that all data tied to this user will be lost once the user is removed.
            </span>
          </label>
        </div>
      </div>
    </CTUI.Modal>
  );
};
