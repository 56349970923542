import React, { FC } from 'react';
import classNames from 'classnames';
import { Stimulus } from 'App/therapy/session/taskViewComponent/stimulus';
import { Controls } from 'App/therapy/session/taskViewComponent/types/SequenceCompletion/controls';

import { type TaskViewSequenceCompletion } from 'Shared/types/shared';

import { type TaskTypeProps } from '../../types';

import sharedStyles from '../index.module.scss';
import styles from './index.module.scss';

export const SequenceCompletion: FC<TaskTypeProps<TaskViewSequenceCompletion>> = ({
  task,
  oldTask,
  isAnswered,
  handleAnswer,
  resetAnswer,
  isInitialAudioInstructionsShouldBePlayed,
}) => {
  return (
    <div className={classNames(styles.sequenceCompletion, sharedStyles.taskType)}>
      {Boolean(task.stimulus && task.stimulus.length) && <Stimulus task={task} />}
      <Controls task={task} handleAnswer={handleAnswer} />
    </div>
  );
};
