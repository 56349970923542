import React, { FC, useState } from 'react';
import { FormSubmitHandler } from 'redux-form';

import type { User } from 'Shared/types/shared';

import { Basic } from '../shared/patientFlow/basic';
import { Advanced } from '../shared/patientFlow/advanced';
import { Conditions } from '../shared/patientFlow/conditions';
import { ConditionsPeriod } from '../shared/patientFlow/conditionsPeriod';
import { Areas } from '../shared/patientFlow/areas';

interface WizardPatientProps {
  handlePatientActivation: FormSubmitHandler;
  activationData: {
    firstName: string;
    lastName: string;
    subscriptionTime: string;
    email: string;
  };
  preAuthorizedUser: User;
}

export const WizardPatient: FC<WizardPatientProps> = ({
  handlePatientActivation,
  activationData,
  preAuthorizedUser,
}) => {
  const [page, setPage] = useState(1);

  switch (page) {
    case 1:
      return (
        <Basic
          onSubmit={() => setPage(page + 1)}
          initialValues={{
            ...activationData,
            username: preAuthorizedUser.user.username,
          }}
        />
      );
    case 2:
      return (
        <Advanced
          onSubmit={() => setPage(page + 1)}
          previousPage={() => setPage(page - 1)}
          initialValues={activationData}
        />
      );
    case 3:
      return (
        <Conditions
          onSubmit={() => setPage(page + 1)}
          previousPage={() => setPage(page - 1)}
          initialValues={activationData}
        />
      );
    case 4:
      return (
        <ConditionsPeriod
          onSubmit={() => setPage(page + 1)}
          previousPage={() => setPage(page - 1)}
          initialValues={activationData}
        />
      );
    case 5:
      return (
        <Areas
          onSubmit={handlePatientActivation}
          previousPage={() => setPage(page - 1)}
          initialValues={activationData}
        />
      );
    default:
      return null;
  }
};
