import React, { FC } from 'react';

import { PerformanceReportDomainIndex } from 'Shared/types/shared';

import styles from './chartFigure.module.scss';

interface ChartMilestoneProps {
  nextMilestone: {
    value: number;
    description: string;
  };
  latest: PerformanceReportDomainIndex;
}

export const ChartMilestone: FC<ChartMilestoneProps> = ({ nextMilestone, latest }) => {
  const milestoneStyles = { left: `${nextMilestone.value ? nextMilestone.value : latest.value}%` };

  return <div className={styles.milestone} style={milestoneStyles} />;
};
