import React, { FC } from 'react';

import { PasswordRequirements, SignupData } from 'Shared/types/shared';

import { Basic } from './basic';
import { Advanced } from './advanced';
import { Extra } from './extra';
import { useWizard } from './useWizard';

interface WizardClinicianProps {
  passwordRequirements: PasswordRequirements;
  signupData: SignupData;
}

export const WizardClinician: FC<WizardClinicianProps> = ({ passwordRequirements, signupData }) => {
  const { page, totalSteps, isInstitutionBasedSignup, handleSubmit, handlePrevious } = useWizard({
    signupData,
  });

  switch (page) {
    case 1:
      return (
        <Basic
          onSubmit={handleSubmit}
          initialValues={signupData}
          previousPage={handlePrevious}
          passwordRequirements={passwordRequirements}
          totalSteps={totalSteps}
          isInstitutionBasedSignup={isInstitutionBasedSignup}
        />
      );
    case 2:
      return (
        <Advanced
          onSubmit={handleSubmit}
          previousPage={handlePrevious}
          totalSteps={totalSteps}
          isInstitutionBasedSignup={isInstitutionBasedSignup}
        />
      );
    case 3:
      return (
        <Extra onSubmit={handleSubmit} previousPage={handlePrevious} totalSteps={totalSteps} />
      );
    default:
      return null;
  }
};
