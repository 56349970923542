import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

import styles from './empty.module.scss';

export const ScheduleEmpty: FC = () => {
  return (
    <div className={styles.scheduleEmpty}>
      <FontAwesomeIcon icon={faLightbulb} className={styles.icon} />
      <h4 className={styles.title}>This patient is not exercising at home.</h4>
      <p className={styles.description}>
        Did you know that patients who use Constant Therapy at home get an average of
        <br />
        four times more therapy?
      </p>
    </div>
  );
};
