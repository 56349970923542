import React, { FC } from 'react';
import classNames from 'classnames';

import { VideoStimulus } from 'Shared/types/shared';

import styles from './video.module.scss';

interface VideoContentProps {
  stimulus: VideoStimulus;
  resourceUrl: string;
}

export const VideoContent: FC<VideoContentProps> = ({ stimulus, resourceUrl }) => {
  return (
    <div className={classNames(styles.content, styles.video)}>
      <video className={styles.preview} width="100%" controls={true}>
        <source src={resourceUrl + stimulus.videoPath}></source>
      </video>
    </div>
  );
};
