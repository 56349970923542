import { useState } from 'react';
import md5 from 'md5';
import { faCheckCircle, faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { ClinicianDashboardStatusType } from 'Shared/types/shared';

export interface StatusCellProps {
  patientId: number;
  status: ClinicianDashboardStatusType;
}

const statusIconsMap = [faCheckCircle, faPlayCircle, faExclamationTriangle];

export function useStatusCell({ patientId, status }: StatusCellProps) {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const icon = statusIconsMap[status.statusIcon - 1];
  const brochureLink = `/dashboard/request-brochure/${md5(
    patientId.toString()
  )}/popupRequestBrochureView`;
  const reportLink = `/dashboard/patient/${md5(patientId.toString())}/report`;

  return {
    referenceElement,
    setReferenceElement,
    isTooltipShown,
    showToolTip: () => {
      setIsTooltipShown(true);
    },
    hideToolTip: () => {
      setIsTooltipShown(false);
    },
    icon,
    brochureLink,
    reportLink,
  };
}
