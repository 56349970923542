import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { getUserSubscription, getUserDataNoAuth } from 'Shared/actions/user';
import { getDisplayName } from 'Shared/utils';
import { RootState, User } from 'Shared/types/shared';
import { useQueryParams } from 'Shared/hooks/useQueryParams';

export function useNonAuthUserFlow() {
  const dispatch: Dispatch = useDispatch();
  const subscriptionInfo = useSelector((state: RootState) => state.user.subscription);
  const subscriptionError = useSelector((state: RootState) => state.user.errors.subscription);
  const [isLoading, setIsLoading] = useState(true);
  const [preAuthorizedUser, setPreAuthorizedUser] = useState<User>();
  const queryParams = useQueryParams();

  const token = queryParams.get('token');
  const emailOrUsername = queryParams.get('emailOrUsername');
  const displayName: string = getDisplayName({
    userObject: preAuthorizedUser,
    username: emailOrUsername,
  });

  const fetchUserInfoAndSubscription = async () => {
    try {
      setIsLoading(true);
      if (token) {
        const userData = (await getUserDataNoAuth(token)(dispatch)) as User;
        await getUserSubscription({ token })(dispatch);
        setPreAuthorizedUser(userData);
      }
      if (emailOrUsername) {
        await getUserSubscription({ emailOrUsername })(dispatch);
      }
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchUserInfoAndSubscription();
  }, [token, emailOrUsername]);

  return {
    isLoading,
    displayName,
    subscriptionInfo,
    token,
    emailOrUsername,
    subscriptionError,
  };
}
