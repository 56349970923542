import React, { FC, RefObject } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';

import { Spinner } from 'Shared/components/spinner';

import { Data } from './data';
import { Chart } from './chart';
import { Screenshot } from './screenshot';
import { useCellDetails, UseCellDetailsProps } from './useDetails';

import styles from './index.module.scss';

interface CellDetailsProps extends UseCellDetailsProps {
  modalRef: RefObject<HTMLDivElement>;
  closePopupHandler: () => void;
}

export const Details: FC<CellDetailsProps> = ({ patient, cell, modalRef, closePopupHandler }) => {
  const { isLoading, therapySessionResponses, selectedResponse, setSelectedResponse } =
    useCellDetails({
      patient,
      cell,
    });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CTUI.Modal
      ref={modalRef}
      id={styles.details}
      header={<h1 className={classNames('h1', styles.title)}>{cell.taskDetail}</h1>}
      headerClasses={styles.detailsHeader}
      closeButtonHandler={closePopupHandler}
    >
      <div className={styles.detailsBody}>
        <Data cell={cell} />
        {therapySessionResponses?.length > 0 && (
          <Chart
            therapySessionResponses={therapySessionResponses}
            setSelectedResponse={setSelectedResponse}
          />
        )}
        {selectedResponse && (
          <Screenshot selectedResponse={selectedResponse} taskName={cell.taskDetail} />
        )}
      </div>
    </CTUI.Modal>
  );
};
