import React, { FC } from 'react';

import { PerformanceReportAssessmentQuestion } from 'Shared/types/shared';

import { ChartLine } from './chartLine';
import { ChartLegend } from './chartLegend';

import styles from './chart.module.scss';

interface ChartProps {
  question: PerformanceReportAssessmentQuestion;
}

export const Chart: FC<ChartProps> = ({ question }) => {
  return (
    <div className={styles.chart}>
      <ChartLine kpi={question.baseline} title="baseline" />
      <ChartLine kpi={question.latest} title="latest" />
      <ChartLegend labels={question.labels} />
    </div>
  );
};
