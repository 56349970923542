import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Activate your account on this page specifically 
  tailored for Flint patients. Easy, step-by-step instructions take you through the setup process 
  to get your Constant Therapy journey started as quickly as possible!`;
  return (
    <Helmet>
      <title>Flint Patient Activation Page | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
