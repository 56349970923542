import { SyntheticEvent, useState } from 'react';
import { Types } from '@thelearningcorp/ct-web-packages';

export interface ExpandableRowScheduleProps {
  currentSchedule: Types.DashboardService.PatientExpansionScheduleItem[];
  patientData: Types.DashboardService.Patient;
}

export function useExpandableRowSchedule({
  currentSchedule,
  patientData,
}: ExpandableRowScheduleProps) {
  const [shouldShowScheduleComponent, setShouldShowScheduleComponent] = useState(false);
  const trimmedCurrentSchedule = currentSchedule.slice(0, 10);
  const shouldShowSchedule = Boolean(patientData.homeExercises) && Boolean(currentSchedule.length);

  return {
    trimmedCurrentSchedule,
    shouldShowSchedule,
    shouldShowScheduleComponent,
    showScheduleComponent: (e: SyntheticEvent) => {
      e.preventDefault();
      setShouldShowScheduleComponent(true);
    },
    hideScheduleComponent: () => {
      setShouldShowScheduleComponent(false);
    },
  };
}
