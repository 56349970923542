import { GET_SUBSCRIPTION_SESSION, RETRIAL } from 'Shared/types/redux';

const initialState = { errors: {} };

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_SESSION.success:
      return {
        ...state,
        session: action.payload,
      };
    case RETRIAL.success:
      return {
        ...state,
        retrialData: action.payload,
      };
    case RETRIAL.fail:
      return {
        ...state,
        errors: {
          ...state.errors,
          retrialData: action.payload,
        },
      };
    default:
      return state;
  }
}
