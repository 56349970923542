import React, { FC } from 'react';

import {
  PerformanceReportAssessment,
  PerformanceReportDomain,
  PerformanceReportTask,
} from 'Shared/types/shared';

import { ReportQuickNavigationSection } from './quickNavigation';
import { ReportDomainsSection } from './domains';
import { ReportTasksSection } from './tasks';
import { ReportAssessmentsSection } from './asessments';

interface PerformanceAndDischargeReportProps {
  sections: {
    assessments?: PerformanceReportAssessment[];
    domains?: {
      [key: string]: PerformanceReportDomain;
    };
    tasks?: {
      [key: string]: PerformanceReportTask;
    };
  };
}

export const PerformanceAndDischargeReport: FC<PerformanceAndDischargeReportProps> = ({
  sections: { domains, tasks, assessments },
}) => {
  return (
    <>
      <ReportQuickNavigationSection domains={domains} tasks={tasks} />
      <ReportDomainsSection domains={domains} />
      <ReportTasksSection tasks={tasks} />
      <ReportAssessmentsSection assessments={assessments} />
    </>
  );
};
