import React, { useMemo } from 'react';

import { genderList, educationList } from '../../shared/constants';

export function useAdvanced() {
  const genderOptions = useMemo(
    () => [
      <option value="" disabled={true} key="initialSelection">
        Select an option
      </option>,
      ...Object.entries(genderList).map(([key, value]) => {
        return (
          <option value={key} key={key}>
            {value}
          </option>
        );
      }),
    ],
    []
  );

  const educationOptions = useMemo(
    () => [
      <option value="" disabled={true} key="initialSelection">
        Select an option
      </option>,
      ...Object.entries(educationList).map(([key, value]) => {
        return (
          <option value={key} key={key}>
            {value}
          </option>
        );
      }),
    ],
    []
  );

  return { genderOptions, educationOptions };
}
