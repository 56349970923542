import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { Link } from 'react-router-dom';

import { SUPPORT } from 'Shared/constants';

import { useMain, MainProps } from './useMain';

import styles from './shared.module.scss';

export const Main: FC<MainProps> = ({ authUser }) => {
  const { displayName, handleOpenStripeDialog } = useMain({ authUser });

  return (
    <div className={styles.paymentUpdate}>
      <CTUI.Font.H1 customClasses={styles.title}>Welcome, {displayName}!</CTUI.Font.H1>
      <CTUI.Font.H2 customClasses={styles.subTitle}>
        You are just a few steps away from updating your payment info.
      </CTUI.Font.H2>
      <CTUI.Button
        text="Update Payment Info"
        onClick={handleOpenStripeDialog}
        customClasses={styles.updatePaymentButton}
      />
      <CTUI.Font.H3>
        <b>Questions?</b> Call <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a> for
        assistance.
      </CTUI.Font.H3>
      <CTUI.Font.Text className={styles.terms}>
        Monthly and annual subscriptions automatically renew at period end.
        <br />
        You can cancel subscription renewals at any time by visiting{' '}
        <Link to="/account">My Account</Link> page or by calling{' '}
        <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a>.
      </CTUI.Font.Text>
    </div>
  );
};
