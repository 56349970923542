import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { logEvent } from 'Shared/actions/event';
import { SUBSCRIPTION } from 'Shared/types/events';
import { useQueryParams } from 'Shared/hooks/useQueryParams';

export function useFail() {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const queryParams = useQueryParams();

  const source = queryParams.get('source');

  useEffect(() => {
    void logEvent({ page: SUBSCRIPTION.FAIL, data: { source } })(
      dispatch,
      store.getState.bind(store)
    );
  }, []);
}
