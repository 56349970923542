import React, { FC } from 'react';

import { TherapySessionResponse } from 'Shared/types/shared';
import { getTherapySessionResponseScreenshot } from 'Shared/actions/patient';

import styles from './screenshot.module.scss';

interface ScreenshotProps {
  selectedResponse: TherapySessionResponse;
  taskName: string;
}

export const Screenshot: FC<ScreenshotProps> = ({ selectedResponse, taskName }) => {
  return (
    <div className={styles.screenshot}>
      <img
        src={getTherapySessionResponseScreenshot({
          patientId: selectedResponse.patientId,
          responseId: selectedResponse.responseId,
        })}
        className={styles.image}
      />
      <ul className={styles.info}>
        <li className={styles.item}>
          <span className={styles.title}>Task Type:</span> {taskName}
        </li>
        <li className={styles.item}>
          <span className={styles.title}>Response:</span>{' '}
          {selectedResponse.accuracy ? 'Correct' : 'Skipped'}
        </li>
        <li className={styles.item}>
          <span className={styles.title}>Time:</span>{' '}
          {Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
            selectedResponse.latency
          )}{' '}
          sec
        </li>
      </ul>
    </div>
  );
};
