import { useContext, useEffect } from 'react';

import type { AudioStimulus } from 'Shared/types/shared';
import { AudioContext } from 'Shared/context/audioContext';

export interface AudioComponentProps {
  stimulus: AudioStimulus;
  resourceUrl: string;
}

export function useAudio({ stimulus, resourceUrl }: AudioComponentProps) {
  const { playAudioSequence, stopAudioSequence } = useContext(AudioContext);

  useEffect(() => {
    if (stimulus) {
      stopAudioSequence();
      playAudioSequence({ audioElementsList: [new Audio(resourceUrl + stimulus.audioPaths[0])] });
    }
  }, [stimulus]);
}
