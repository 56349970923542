import React, { useCallback, useRef, useState } from 'react';

import type { TherapySessionResponse } from 'Shared/types/api';
import { TaskType } from 'Shared/types/task';

import { SCREEN_MODES } from '../useSession';

export interface InstructionsProps {
  therapySession: TherapySessionResponse;
  taskTypes: TaskType[];
  activeTaskIndex: number;
  setActiveScreenMode: React.Dispatch<React.SetStateAction<SCREEN_MODES>>;
}

export function useInstructions({
  therapySession,
  taskTypes,
  activeTaskIndex,
  setActiveScreenMode,
}: InstructionsProps) {
  const categoryInstructionVideoPath = taskTypes.find(
    (type) => type.defaultViewClassName === therapySession.taskViews[activeTaskIndex].templateName
  ).instructionalVideoPath;
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isVideoShown, setIsVideoShown] = useState(false);

  const handleStartClick = () => {
    setActiveScreenMode(SCREEN_MODES.TASKS);
  };

  const handlePlayClick = useCallback(() => {
    setIsVideoShown(true);
    void videoRef.current.play();
  }, [videoRef.current]);

  return {
    categoryInstructionVideoPath,
    handleStartClick,
    videoRef,
    isVideoShown,
    handlePlayClick,
  };
}
