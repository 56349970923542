import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Meta } from './meta';
import { Main } from './main';
import { Guide } from './guide';

export const ForgotPassword: FC = () => {
  return (
    <>
      <Meta />
      <Switch>
        <Route exact path="/forgot-password">
          <Main />
        </Route>
        <Route exact path="/forgot-password/guide">
          <Guide />
        </Route>
      </Switch>
    </>
  );
};
