import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Types } from '@thelearningcorp/ct-web-packages';
import { Dispatch } from 'redux';

import { getDashboardPatientExpansion } from 'Shared/actions/clinician';
import { RootState, User } from 'Shared/types/shared';

import { ScheduleContext } from '../shared/scheduleComponent/scheduleContextProvider';

interface UseExpandableRowProps {
  clinician: User;
  data: Types.DashboardService.Patient;
}

export function useExpandableRow({ clinician, data: patientData }: UseExpandableRowProps) {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const patientsExpansions = useSelector((state: RootState) => state.clinician.patientsExpansions);
  const [isLoading, setIsLoading] = useState(true);

  const { isChangedDuringSession, setIsChangedDuringSession } = useContext(ScheduleContext);

  const fetchPatientDashboardExpansion = async (
    userId: number,
    patientId: number,
    forceCache: boolean
  ) => {
    try {
      setIsLoading(true);
      await getDashboardPatientExpansion(userId, patientId)(
        dispatch,
        store.getState.bind(store),
        forceCache
      );
    } catch (e: unknown) {
      throw new Error(e as string);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (clinician && patientData) {
      void fetchPatientDashboardExpansion(clinician.user.userId, patientData.patientId, false);
    }
  }, [clinician, patientData]);

  useEffect(() => {
    if (isChangedDuringSession) {
      void fetchPatientDashboardExpansion(clinician.user.userId, patientData.patientId, true);
      setIsChangedDuringSession(false);
    }
  }, [clinician, patientData, isChangedDuringSession]);

  return { isLoading, patientsExpansions };
}
