import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';

import { getDomainsKey, getTherapiesCompositeKey } from 'Shared/utils';
import { PerformanceReportDomain, PerformanceReportTask } from 'Shared/types/shared';

import sectionStyles from './section.module.scss';
import styles from './quickNavigation.module.scss';

interface ReportQuickNavigationSectionProps {
  domains?: {
    [key: string]: PerformanceReportDomain;
  };
  tasks?: {
    [key: string]: PerformanceReportTask;
  };
}

export const ReportQuickNavigationSection: FC<ReportQuickNavigationSectionProps> = ({
  domains,
  tasks,
}) => {
  if (
    !domains ||
    !tasks ||
    !(Boolean(Object.values(domains).length) || Boolean(Object.values(tasks).length))
  ) {
    return null;
  }

  return (
    <div className={classNames(sectionStyles.section, styles.quickNavigationSection)}>
      <CTUI.Font.H2 customClasses={sectionStyles.title}>Therapy performance</CTUI.Font.H2>
      {Boolean(Object.values(domains).length) && (
        <div className={styles.subSection}>
          <CTUI.Font.H4 variant="medium">Skill performance</CTUI.Font.H4>
          <ul className={styles.list}>
            {Object.values(domains).map((domain) => {
              return (
                <li key={domain.displayName}>
                  <CTUI.Font.Text customClasses={styles.itemText}>
                    <a href={`#${getDomainsKey(domain.displayName)}`} className="inPageNavLink">
                      {domain.displayName}
                    </a>
                  </CTUI.Font.Text>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {Boolean(Object.values(tasks).length) && (
        <div className={styles.subSection}>
          <CTUI.Font.H4 variant="medium">Therapy level and activity</CTUI.Font.H4>
          <ul className={styles.list}>
            {Object.values(tasks).map((therapy) => {
              return Object.entries(therapy.levels).map(([levelKey]) => {
                return (
                  <li key={getTherapiesCompositeKey(therapy.displayName, levelKey)}>
                    <CTUI.Font.Text customClasses={styles.itemText}>
                      <a
                        href={`#${getTherapiesCompositeKey(therapy.displayName, levelKey)}`}
                        className="inPageNavLink"
                      >
                        {therapy.displayName}, level {levelKey}
                      </a>
                    </CTUI.Font.Text>
                  </li>
                );
              });
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
