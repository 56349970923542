import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { defaultValidators } from 'Shared/validators';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { PATIENT_ACTIVATION_FORM_NAME } from 'Shared/types/forms';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import sharedStyles from '../shared.module.scss';

interface AdvancedComponentProps {
  previousPage: () => void;
}

const AdvancedComponent: FC<
  AdvancedComponentProps & InjectedFormProps<object, AdvancedComponentProps>
> = ({ handleSubmit, previousPage, error, pristine, submitting }) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.wizardPage}
      header={
        <>
          <ProgressBar currentStep={2} totalSteps={5} />
          <h1 className="h1">Tell us about yourself!</h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses="flintActivationForm"
        actions={
          <>
            <CTUI.Button
              type="button"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              text="&larr; Back"
            />
            <CTUI.Form.SubmitButton
              variant="primary"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
              text="Next &rarr;"
            />
          </>
        }
      >
        <Field
          name="firstName"
          component={CTUI.Form.Field}
          type="text"
          label="First Name"
          required
          autofocus
        />
        <Field name="lastName" component={CTUI.Form.Field} type="text" label="Last Name" required />
        <Field
          name="age"
          component={CTUI.Form.Number}
          type="number"
          label={'Age <i>(helps estimate therapy duration)</i>'}
          validate={[defaultValidators.decimal.bind(defaultValidators)]}
          customClasses={sharedStyles.oneHalf}
          min={5}
          max={130}
          required
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Advanced = reduxForm<object, AdvancedComponentProps>({
  form: PATIENT_ACTIVATION_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AdvancedComponent);

export { Advanced };
