import { Dispatch } from 'redux';
import queryString from 'query-string';

import { fetchWrapper } from 'Shared/fetchWrapper';
import { getBaseUrl } from 'Shared/utils';
import { Schedule, EnhancedScheduledTaskType, ScheduledTaskTypeStub } from 'Shared/types/schedule';
import {
  GET_PATIENT_SCHEDULE,
  SAVE_PATIENT_SCHEDULE,
  GENERATE_INITIAL_PATIENT_SCHEDULE,
} from 'Shared/types/redux';

export const getPatientSchedule = (patientId: string | number) => async (dispatch: Dispatch) => {
  const formattedParams = queryString.stringify(
    {
      token: localStorage.getItem('accessToken'),
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  return (await fetchWrapper.get(
    `${getBaseUrl()}/api/patient/${patientId}/schedule/active?${formattedParams}`,
    {
      dispatch,
      types: GET_PATIENT_SCHEDULE,
    }
  )) as Schedule;
};

export const savePatientSchedule =
  (
    patientId: string | number,
    scheduledTaskTypes: (EnhancedScheduledTaskType | ScheduledTaskTypeStub)[]
  ) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return (await fetchWrapper.post(
      `${getBaseUrl()}/api/patient/${patientId}/schedule/add?${formattedParams}`,
      {
        dispatch,
        types: SAVE_PATIENT_SCHEDULE,
      },
      { scheduledTaskTypes }
    )) as Promise<{ ctdata: Schedule; ctmsgs: object }>;
  };

export const generateInitialPatientSchedule =
  (patientId: string | number) => async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return fetchWrapper.get(
      `${getBaseUrl()}/api/patient/${patientId}/schedule/generate?${formattedParams}`,
      {
        dispatch,
        types: GENERATE_INITIAL_PATIENT_SCHEDULE,
      }
    );
  };
