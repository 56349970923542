import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { Link } from 'react-router-dom';

import { SUPPORT, TRIALING_PERIOD } from 'Shared/constants';
import { Spinner } from 'Shared/components/spinner';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { Meta } from './meta';
import { useRetrial } from './useRetrial';

import styles from './index.module.scss';

export const Retrial: FC = () => {
  const { isLoading, isExtended, mailSubject } = useRetrial();

  if (isLoading) {
    return <Spinner />;
  }

  if (isExtended) {
    return (
      <FloatingPage
        customClasses={styles.retrialPage}
        header={<h1 className="h1">Your Trial Has Been Extended for {TRIALING_PERIOD} Days!</h1>}
        footer={
          <>
            <Link to="/login" className={styles.link}>
              <CTUI.Button text="Login" customClasses={styles.button} />
            </Link>
            <Link to="/forgot_password" className={styles.link}>
              <CTUI.Button
                text="Reset Password"
                variant="secondary"
                customClasses={styles.button}
              />
            </Link>
          </>
        }
      >
        <Meta />
        <p>
          What&apos;s next? You may want to <b>login</b> to proceed with remaining tasks or{' '}
          <b>reset your password</b> in case you forgot it:
        </p>
      </FloatingPage>
    );
  }

  return (
    <FloatingPage
      customClasses={styles.retrialPage}
      header={<h1 className="h1">Extend Your Trial</h1>}
      footer={
        <>
          <Link to="/login" className={styles.link}>
            <CTUI.Button text="Login" customClasses={styles.button} />
          </Link>
          <Link to="/forgot-password" className={styles.link}>
            <CTUI.Button text="Reset Password" variant="secondary" customClasses={styles.button} />
          </Link>
        </>
      }
    >
      <Meta />
      <p>
        Please reach out to Customer Support at{' '}
        <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a> or{' '}
        <a href={`mailto:${SUPPORT.EMAIL}?subject=${mailSubject}`}>{SUPPORT.EMAIL}</a> if you would
        like to extend your Constant Therapy trial period.
      </p>
    </FloatingPage>
  );
};
