import queryString from 'query-string';
import { omitBy } from 'lodash';
import { isEmpty } from 'lodash';
import { Dispatch } from 'redux';

import { fetchWrapper } from 'Shared/fetchWrapper';
import {
  GET_SUBSCRIPTION_SESSION,
  GET_SUBSCRIPTION_PLANS,
  RETRIAL,
  UPDATE_PAYMENT_DETAILS,
  REACTIVATE_SUBSCRIPTION,
  GET_SUBSCRIPTION_CONFIRMATION,
  CANCEL_SUBSCRIPTION,
} from 'Shared/types/redux';
import { getBaseUrl } from 'Shared/utils';
import { SubscriptionPlansResponse } from 'Shared/types/shared';

interface getSubscriptionSessionParams {
  planId: string;
  coupon?: string;
  token?: string;
  emailOrUsername?: string;
}

export const getSubscriptionSession =
  ({ planId, coupon = '', token = '', emailOrUsername = '' }: getSubscriptionSessionParams) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        sessionId: '{CHECKOUT_SESSION_ID}',
        coupon,
      },
      {
        skipNull: true,
        skipEmptyString: true,
        encode: false,
      }
    );

    return await fetchWrapper.post(
      `${getBaseUrl({ apiSection: 'dashboard' })}/api/create-checkout`,
      {
        dispatch,
        types: GET_SUBSCRIPTION_SESSION,
      },
      omitBy(
        {
          token,
          plan: planId,
          coupon,
          emailOrUsername,
          successURL: document.location.origin + `/subscribe/success?${formattedParams}`,
          cancelURL: document.location.href,
        },
        (value) => isEmpty(value)
      ),
      { skipAccessToken: true }
    );
  };

interface SubscriptionPlansParams {
  emailOrUsername: string;
  couponCode: string;
  referrerPage: string;
}

export const getSubscriptionPlans =
  ({ emailOrUsername, couponCode, referrerPage }: SubscriptionPlansParams) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        emailOrUsername,
        couponCode,
        referrerPage,
      },
      {
        skipNull: true,
        skipEmptyString: true,
        encode: false,
      }
    );

    const response = (await fetchWrapper.get(
      `${getBaseUrl({ apiSection: 'dashboard' })}/api/subscription/plans?${formattedParams}`,
      {
        dispatch,
        types: GET_SUBSCRIPTION_PLANS,
      }
    )) as { data: SubscriptionPlansResponse };

    return response.data;
  };

export const getSubscriptionConfirmation =
  ({ sessionId }: { sessionId: string }) =>
  async (dispatch: Dispatch) => {
    const processedResponse = await fetchWrapper.get(
      // eslint-disable-next-line max-len
      `${getBaseUrl({
        apiSection: 'dashboard',
      })}/api/subscription/confirmation?sessionId=${sessionId}`,
      {
        dispatch,
        types: GET_SUBSCRIPTION_CONFIRMATION,
      }
    );
    return processedResponse.data;
  };

export const retrial =
  ({ token }: { token: string }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      { token },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return await fetchWrapper.post(
      // eslint-disable-next-line max-len
      `${getBaseUrl({ apiSection: 'dashboard' })}/api/retrial?${formattedParams}`,
      {
        dispatch,
        types: RETRIAL,
      }
    );
  };

export const updatePaymentDetails =
  ({ stripeToken }: { stripeToken: { id: number; email: string } }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
        wkekl: stripeToken.id,
        email: stripeToken.email,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return await fetchWrapper.put(
      `${getBaseUrl({ apiSection: 'dashboard' })}/api/subscribe?${formattedParams}`,
      {
        dispatch,
        types: UPDATE_PAYMENT_DETAILS,
      }
    );
  };

export const reactivateSubscription = () => async (dispatch: Dispatch) => {
  return await fetchWrapper.patch(
    `${getBaseUrl({ apiSection: 'dashboard' })}/api/subscribe`,
    {
      dispatch,
      types: REACTIVATE_SUBSCRIPTION,
    },
    { action: 'resume' }
  );
};

export const cancelSubscription =
  ({ reason }: { reason: string }) =>
  async (dispatch: Dispatch) => {
    return await fetchWrapper.patch(
      `${getBaseUrl({ apiSection: 'dashboard' })}/api/subscribe`,
      {
        dispatch,
        types: CANCEL_SUBSCRIPTION,
      },
      {
        reason,
        action: 'cancel',
      },
      { isSubmission: true }
    );
  };
