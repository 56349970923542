import React, { FC, SyntheticEvent } from 'react';

import { PopperMenu } from 'Shared/components/layout/popperMenu';

import { Launcher } from './launcher';
import { Dropdown } from './dropdown';

interface DocumentationExportProps {
  showProgressPopup: (e: SyntheticEvent) => void;
  showDailySessionPopup: (e: SyntheticEvent) => void;
  showDischargePopup: (e: SyntheticEvent) => void;
}

export const DocumentationExportMenu: FC<DocumentationExportProps> = ({
  showProgressPopup,
  showDailySessionPopup,
  showDischargePopup,
}) => {
  return (
    <PopperMenu
      renderLauncher={Launcher}
      renderMenu={({ popper, toggleMenuVisibility }) => {
        return (
          <Dropdown
            popper={popper}
            toggleMenuVisibility={toggleMenuVisibility}
            showProgressPopup={showProgressPopup}
            showDailySessionPopup={showDailySessionPopup}
            showDischargePopup={showDischargePopup}
          />
        );
      }}
    />
  );
};
