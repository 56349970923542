import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `You hold the keys to managing clinicians and 
  patients connected to your organization. Monitor activity, coordinate operations, and optimize 
  the therapeutic process from our Enterprise Dashboard.`;
  return (
    <Helmet>
      <title>Enterprise Dashboard | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
