import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faBars } from '@fortawesome/free-solid-svg-icons';

import { LauncherProps } from 'Shared/components/layout/popperMenu';

import styles from './launcher.module.scss';

export const Launcher: FC<LauncherProps> = ({
  launcherRef,
  setReferenceElement,
  isMenuShown,
  toggleMenuVisibility,
}) => {
  return (
    <div className={styles.launcher} onClick={toggleMenuVisibility} ref={launcherRef}>
      <FontAwesomeIcon icon={faBars} className={styles.launchIcon} />
      <span>Performance & Documentation</span>
      <FontAwesomeIcon
        icon={isMenuShown ? faAngleUp : faAngleDown}
        className={styles.toggleIcon}
        ref={setReferenceElement}
      />
    </div>
  );
};
