import React, { FC } from 'react';

import { DPRRawDataElement, User } from 'Shared/types/shared';

import { AccuracyLatency } from '../../constants';

import { Details } from './details';
import { getAccuracyColor, getLatencyColor, useCell } from './useCell';

import styles from '../index.module.scss';

interface CellProps {
  patient: User;
  accuracyLatencyToggle: AccuracyLatency;
  cell: DPRRawDataElement;
}

export const Cell: FC<CellProps> = ({ patient, accuracyLatencyToggle, cell }) => {
  const { modalRef, isCellDetailsOpen, openCellDetailsHandler, closeCellDetailsHandler } =
    useCell();

  let extraProps = {};
  if (accuracyLatencyToggle === AccuracyLatency.accuracy && cell) {
    extraProps = {
      style: {
        background: `${getAccuracyColor(cell.accuracyPercentile)}`,
        cursor: 'pointer',
      },
      onClick: openCellDetailsHandler,
    };
  }
  if (accuracyLatencyToggle === AccuracyLatency.latency && cell) {
    extraProps = {
      style: {
        background: `${getLatencyColor(cell.latencyPercentile)}`,
        cursor: 'pointer',
      },
      onClick: openCellDetailsHandler,
    };
  }

  return (
    <>
      <div className={styles.col} {...extraProps}>
        {cell && cell[accuracyLatencyToggle]}
        {cell && (accuracyLatencyToggle === AccuracyLatency.accuracy ? '%' : 's')}
      </div>
      {isCellDetailsOpen && cell && (
        <Details
          patient={patient}
          cell={cell}
          modalRef={modalRef}
          closePopupHandler={closeCellDetailsHandler}
        />
      )}
    </>
  );
};
