import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Spinner } from 'Shared/components/spinner';
import { TherapyActivityActions } from 'Shared/types/shared';

import { Header } from './header';
import { useWelcome, WelcomeProps } from './useWelcome';

import sharedStyles from '../index.module.scss';
import styles from './index.module.scss';

export const Welcome: FC<WelcomeProps> = ({ authUser, scheduleId, setScheduleId }) => {
  const { isLoading, therapyActivity, handleAction } = useWelcome({
    authUser,
    scheduleId,
    setScheduleId,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div
      className={classNames(sharedStyles.therapy, styles.welcome)}
      style={{
        backgroundImage: `url(${
          therapyActivity.resourceUrl + therapyActivity.screenData.imagePath
        }), linear-gradient(to right, #F7F7F8 35%, white 35%)`,
      }}
    >
      <Header />
      <div className={styles.greetings}>
        <h2
          className={classNames('h2', styles.header)}
          dangerouslySetInnerHTML={{ __html: therapyActivity.screenData.messageText1 }}
        />
        <p
          className={styles.subHeader}
          dangerouslySetInnerHTML={{ __html: therapyActivity.screenData.messageText2 }}
        />
        <ul className={styles.buttonsList}>
          {therapyActivity.screenData.showSubscribe && (
            <li>
              <Link to="/subscribe" className={styles.link}>
                <CTUI.Button text="Subscribe" customClasses={styles.button} />
              </Link>
            </li>
          )}
          {therapyActivity.screenData.primaryButton && (
            <li>
              <CTUI.Button
                text={therapyActivity.screenData.primaryButton.text}
                customClasses={styles.button}
                onClick={handleAction({
                  action: therapyActivity.screenData.primaryButton.action,
                })}
              />
            </li>
          )}
          {therapyActivity.screenData?.secondaryButton && (
            <li>
              <CTUI.Button
                text={
                  therapyActivity.screenData.secondaryButton.action ===
                  TherapyActivityActions.EMAIL_REPORT
                    ? 'Export report'
                    : therapyActivity.screenData.secondaryButton.text
                }
                customClasses={styles.button}
                onClick={handleAction({
                  action: therapyActivity.screenData.secondaryButton.action,
                })}
              />
            </li>
          )}
        </ul>
      </div>
      <div className={styles.statistics}>
        <p dangerouslySetInnerHTML={{ __html: therapyActivity.therapyData.daysText }} />
        <ul className={styles.daysList}>
          {therapyActivity.therapyData.daysList.map((day, key) => {
            return (
              <li
                key={key}
                className={classNames(styles.day, {
                  [styles.activeDay]: day,
                  [styles.currentDay]: moment().weekday() === key,
                  [styles.pastDay]: moment().weekday() > key,
                })}
              >
                {moment().weekday(key).format('ddd')}
              </li>
            );
          })}
        </ul>
        <div className={styles.note}>
          <FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />
          <span dangerouslySetInnerHTML={{ __html: therapyActivity.therapyData.assessmentText }} />
        </div>
        <div className={styles.accuracy}>
          <b>Accuracy:</b> {therapyActivity.therapyData.accuracy.text}{' '}
          <img
            src={`${therapyActivity.resourceUrl + therapyActivity.therapyData.accuracy.imagePath}`}
            alt={therapyActivity.therapyData.accuracy.text}
            className={styles.emoji}
          />
        </div>
      </div>
    </div>
  );
};
