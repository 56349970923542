import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { PerformanceReportAssessment } from 'Shared/types/shared';

import { PerformanceAndDischargeAssessmentSectionItem } from './assessmentSectionItem';

import sectionStyles from './section.module.scss';

interface ReportAssessmentsSectionProps {
  assessments: PerformanceReportAssessment[];
}

export const ReportAssessmentsSection: FC<ReportAssessmentsSectionProps> = ({
  assessments: assessmentsSections,
}) => {
  let assessmentsSectionsList = [
    <div className={sectionStyles.empty} key={0}>
      <FontAwesomeIcon icon={faSmile} className={sectionStyles.emptyIcon} />
      <CTUI.Font.H3 customClasses={sectionStyles.emptyTitle}>
        It’s a bit empty in here…
      </CTUI.Font.H3>
      <CTUI.Font.Text customClasses={sectionStyles.emptyDescription}>
        When patients answer assessment questions in their patient app, their responses will be
        tracked here.
      </CTUI.Font.Text>
    </div>,
  ];

  if (assessmentsSections && assessmentsSections.length) {
    assessmentsSectionsList = assessmentsSections.map((section) => {
      return (
        <PerformanceAndDischargeAssessmentSectionItem
          assessmentSection={section}
          key={section.id}
        />
      );
    });
  }

  return (
    <div className={sectionStyles.section}>
      <CTUI.Font.H2 customClasses={sectionStyles.title}>Assessment Results</CTUI.Font.H2>
      {assessmentsSectionsList}
    </div>
  );
};
