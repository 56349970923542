import React, { FC } from 'react';

import { Spinner } from 'Shared/components/spinner';

import { PlansListHeader } from './header';
import { PlansListBody } from './body';
import { PlansListFooter } from './footer';
import { PlansListProps, usePlansList } from './usePlansList';
import { CouponForm } from './couponForm';
import { CouponMessage } from './couponMessage';

import styles from './index.module.scss';

export const PlansList: FC<PlansListProps> = ({ displayName, token }) => {
  const {
    isLoading,
    isProgressMail,
    isSignupFlow,
    coupon,
    emailOrUsername,
    subscriptionPlansData,
    handleChoosePlanLater,
    handleCouponSubmit,
    shouldSkipCoupon,
  } = usePlansList({
    displayName,
    token,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.plansList}>
      <PlansListHeader
        displayName={displayName}
        isProgressMail={isProgressMail}
        subscriptionPlansData={subscriptionPlansData}
      />
      {subscriptionPlansData.couponMessage && (
        <CouponMessage subscriptionPlansData={subscriptionPlansData} />
      )}
      <PlansListBody
        coupon={!shouldSkipCoupon && coupon}
        token={token}
        emailOrUsername={emailOrUsername}
        subscriptionPlans={subscriptionPlansData.plans}
      />
      <CouponForm
        initialValues={{ ...(!shouldSkipCoupon && { coupon }) }}
        onSubmit={handleCouponSubmit}
      />
      <PlansListFooter isSignupFlow={isSignupFlow} handleChoosePlanLater={handleChoosePlanLater} />
    </div>
  );
};
