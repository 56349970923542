import React, { FC } from 'react';

import { SubscriptionConfirmation } from 'Shared/types/shared';

import { ConfirmationHeader } from './header';
import { ConfirmationBody } from './body';
import { ConfirmationFooter } from './footer';

import styles from './index.module.scss';

export interface ConfirmationProps {
  subscriptionConfirmation: SubscriptionConfirmation;
}

export const Confirmation: FC<ConfirmationProps> = ({ subscriptionConfirmation }) => {
  return (
    <div className={styles.confirmation}>
      <ConfirmationHeader subscriptionConfirmation={subscriptionConfirmation} />
      <ConfirmationBody subscriptionConfirmation={subscriptionConfirmation} />
      <ConfirmationFooter />
    </div>
  );
};
