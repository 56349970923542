import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { getDisplayName } from 'Shared/utils';
import { User } from 'Shared/types/shared';

import { AccountMenu } from './accountMenu';
import { useAuthBlock } from './useAuthBlock';

import styles from './authBlock.module.scss';

interface AuthBlockProps {
  authUser: User;
}

export const AuthBlock: FC<AuthBlockProps> = ({ authUser }) => {
  const { actionRef, toggleMenuVisibility, setReferenceElement, isMenuShown, popper } =
    useAuthBlock();

  return (
    <div className={styles.authBlock}>
      <button className={styles.displayName} ref={actionRef} onClick={toggleMenuVisibility}>
        <FontAwesomeIcon icon={faUserCircle} className={styles.accountIcon} />
        <CTUI.Font.Text>{getDisplayName({ userObject: authUser })}</CTUI.Font.Text>
        <FontAwesomeIcon
          icon={isMenuShown ? faAngleUp : faAngleDown}
          className={styles.pointer}
          ref={setReferenceElement}
        />
      </button>
      {isMenuShown && (
        <AccountMenu
          authUser={authUser}
          popper={popper}
          toggleMenuVisibility={toggleMenuVisibility}
        />
      )}
    </div>
  );
};
