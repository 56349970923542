import React, { FC } from 'react';

import { Spinner } from 'Shared/components/spinner';

import { AuthUserFlow } from './authUserFlow';
import { NonAuthUserFlow } from './nonAuthUserFlow';
import { useMain } from './useMain';

export const Main: FC = () => {
  const { authUser, isSkipToStripeLoading } = useMain();

  if (isSkipToStripeLoading) {
    return <Spinner />;
  }

  if (authUser) {
    return <AuthUserFlow />;
  }

  return <NonAuthUserFlow />;
};
