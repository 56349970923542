import React, { FC } from 'react';
import classNames from 'classnames';

import type { InstitutionUser } from 'Shared/types/shared';

import styles from './statusCell.module.scss';

export const StatusCell: FC<InstitutionUser> = ({ statusDisplay }) => {
  return (
    <div className={styles.statusCell}>
      <span
        className={classNames(styles.status, { [styles[statusDisplay]]: styles[statusDisplay] })}
      />
      {statusDisplay}
    </div>
  );
};
