import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';

import { Spinner } from 'Shared/components/spinner';
import { User } from 'Shared/types/shared';

import { DetailedPerformanceContext } from '../context';

import { Empty } from './empty';
import { Cell } from './cell';

import styles from './index.module.scss';

interface TableProps {
  patient: User;
}

export const Table: FC<TableProps> = ({ patient }) => {
  const { isLoading, reportData, accuracyLatencyToggle, tasksSelectedList } = useContext(
    DetailedPerformanceContext
  );

  const rowWidth = reportData
    ? `${
        parseInt(styles.firstColWidth) +
        parseInt(styles.colWidth) * reportData.table?.columnList.length
      }px`
    : '100%';

  //Initial data preparation to reduce further complexity
  const rowsToSkip = tasksSelectedList?.filter((task) => !task.selected);

  if (isLoading) {
    return <Spinner />;
  }

  if (!Object.values(reportData).length) {
    return <Empty />;
  }

  return (
    <div className={styles.customTable}>
      <div className={styles.row} style={{ width: rowWidth }}>
        <div className={classNames(styles.col, styles.first, styles.headCol)}>Task Name</div>
        {reportData.table.columnList.map((col, key) => {
          return (
            <div className={classNames(styles.col, styles.headCol)} key={key}>
              {moment({ ...col.startDate, month: col.startDate.month - 1 }).format('MM/DD/YYYY')}-
              {moment({ ...col.endDate, month: col.endDate.month - 1 }).format('MM/DD/YYYY')}
            </div>
          );
        })}
      </div>
      {reportData.table.rowList.map((row, rowKey) => {
        //Checks if a row is in the skip list
        if (
          rowsToSkip &&
          rowsToSkip.find(
            (rts) =>
              rts.taskTypeSystemName === row.taskTypeSystemName && rts.taskLevel === row.taskLevel
          )
        ) {
          return null;
        }

        return (
          <div className={styles.row} style={{ width: rowWidth }} key={rowKey}>
            <div className={classNames(styles.col, styles.first)}>{row.taskDetail}</div>
            {reportData.table.columnList.map((col, colKey) => {
              return (
                <Cell
                  key={colKey}
                  patient={patient}
                  accuracyLatencyToggle={accuracyLatencyToggle}
                  cell={reportData.table.array[rowKey][colKey]}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
