import moment from 'moment-timezone';
import { useState } from 'react';
import classNames from 'classnames';
import { Moment } from 'moment';

import { PatientCalendarDay } from 'Shared/types/shared';

import styles from './calendarDay.module.scss';

// https://constanttherapy.atlassian.net/browse/WEBAPP-514
// This variable was initially imported from './colors.module.scss', but I had to convert to
// this approach because of https://cssnano.co/docs/optimisations/colormin/ which is applied
// only during production-build procedure
const colors = {
  homeTherapyMinColor: 'rgb(214, 201, 235)',
  homeTherapyMaxColor: 'rgb(155, 123, 206)',
};

export interface CalendarDayProps {
  day: Moment;
  calendar: PatientCalendarDay[];
}

export function useCalendarDay({ day, calendar }: CalendarDayProps) {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);

  const dayStyles: { backgroundColor?: string } = {};
  const activeDay = calendar.find((calendarDay) =>
    moment.utc(calendarDay.date, 'X').isSame(moment.utc(day), 'day')
  );
  const dayClasses = classNames(styles.calendarDay, {
    [styles.inClinic]: activeDay && activeDay.inClinic,
  });

  if (activeDay && !activeDay.inClinic) {
    const minutesDuration = Math.round(activeDay.duration / 60) || 0;
    dayStyles.backgroundColor = `rgb(${calculateColorByTaskCount(minutesDuration).toString()})`;
  }

  return {
    isTooltipShown,
    showToolTip: () => {
      setIsTooltipShown(true);
    },
    hideTooltip: () => {
      setIsTooltipShown(false);
    },
    referenceElement,
    setReferenceElement,
    activeDay,
    dayClasses,
    dayStyles,
  };
}

/**
 * Calculates rgb-color values depending on passed `minsDuration` parameter and min/max color values
 * range
 * @param {number} minutesDuration
 * 30 minutes is the maximum time
 * @returns {Array} - list of calculated rgb-values
 */
function calculateColorByTaskCount(minutesDuration: number): number[] {
  const rgbMinCode = colors.homeTherapyMinColor
    .match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/)
    .slice(1, 4)
    .map((x) => parseInt(x));
  const rgbMaxCode = colors.homeTherapyMaxColor
    .match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/)
    .slice(1, 4)
    .map((x) => parseInt(x));
  const maxMeaningfulMinutes = 30;

  return rgbMinCode.map((code, key) => {
    const coefficient = (code - rgbMaxCode[key]) / maxMeaningfulMinutes;

    return Math.max(code - coefficient * minutesDuration, rgbMaxCode[key]);
  });
}
