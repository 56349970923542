import React, { FC, RefObject, SyntheticEvent } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { EnhancedScheduledTaskType, ScheduledTaskTypeStub } from 'Shared/types/schedule';

import type { Identifier } from 'dnd-core';

import styles from './index.module.scss';

interface DeleteViewProps {
  task: EnhancedScheduledTaskType | ScheduledTaskTypeStub;
  previewRef: RefObject<HTMLLIElement>;
  taskTypeItemClasses: string;
  handlerId: Identifier;
  handleConfirmDeleteButtonClick: (e: SyntheticEvent) => void;
  handleCancelDeleteButtonClick: (e: SyntheticEvent) => void;
}

export const DeleteView: FC<DeleteViewProps> = ({
  task,
  previewRef,
  taskTypeItemClasses,
  handlerId,
  handleConfirmDeleteButtonClick,
  handleCancelDeleteButtonClick,
}) => {
  return (
    <li ref={previewRef} className={taskTypeItemClasses} data-handler-id={handlerId}>
      <div className={styles.data}>
        <CTUI.Font.Text>{task.displayName}</CTUI.Font.Text>
        <CTUI.Font.Text3 customClasses={styles.levelCount}>
          (Level {task.taskLevel}, {task.taskCount} times)
        </CTUI.Font.Text3>
      </div>
      <div className={styles.deleteConfirmation}>
        <span>Delete?</span>
        <ul className={styles.actions}>
          <li className={styles.action}>
            <a href="#" onClick={handleConfirmDeleteButtonClick}>
              Yes
            </a>
          </li>
          <li className={styles.action}>
            <a href="#" onClick={handleCancelDeleteButtonClick}>
              No
            </a>
          </li>
        </ul>
      </div>
    </li>
  );
};
