import React, { FC, useState } from 'react';
import classNames from 'classnames';

import noChartIcon from 'Shared/src/themes/2019/images/icList.svg';

import { DemoChartPopup } from './demoChartPopup';

import styles from './empty.module.scss';

export const ChartEmpty: FC = () => {
  const [isPopupShown, setIsPopupShown] = useState(false);

  return (
    <div className={styles.chartEmpty}>
      <img src={noChartIcon as string} alt="No Data" title="No Data" />
      <h4 className={classNames('h4', styles.title)}>No data!</h4>
      <p className={styles.description}>
        Once your client begins completing homework activities within the patient app, their data
        will show up here.
        <br />
        <a
          href="#"
          className="yeahBabe"
          onClick={(e) => {
            e.preventDefault();
            setIsPopupShown(true);
          }}
        >
          See an example skill area chart
        </a>
      </p>
      {isPopupShown && <DemoChartPopup closePopupHandler={() => setIsPopupShown(false)} />}
    </div>
  );
};
