import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Spinner } from 'Shared/components/spinner';
import { SUBSCRIBE_BUTTON_STATES } from 'Shared/constants';

import { SubscribedUserModal } from './subscribedUserModal';
import { PlansList } from './plansList';
import { useAuthUserFlow } from './useAuthUserFlow';
import { ValidateUserModal } from './validateUserModal';

export const AuthUserFlow: FC = () => {
  const history = useHistory();
  const { isLoading, displayName, subscriptionInfo, token, subscriptionError } = useAuthUserFlow();

  if (isLoading) {
    return <Spinner />;
  }

  if (subscriptionError) {
    return <ValidateUserModal externalError={subscriptionError} />;
  }

  if (subscriptionInfo.subscribeButton === SUBSCRIBE_BUTTON_STATES.UPDATE) {
    history.push('/payment_update');
    return null;
  }

  if (subscriptionInfo.subscribeButton === SUBSCRIBE_BUTTON_STATES.SUBSCRIBE) {
    return <PlansList displayName={displayName} token={token} />;
  }

  return (
    <SubscribedUserModal
      displayName={displayName}
      subscriptionInfo={subscriptionInfo}
      isAuthorized={true}
    />
  );
};
