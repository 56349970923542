import React, { FC, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

import { PerformanceReportDomain } from 'Shared/types/shared';

import { TermsList } from './termsList';
import { Subscribe2Unlock } from './subscribe2Unlock';
import { Item } from './item';

import styles from './index.module.scss';

interface SkillAreasProps {
  skillAreas: {
    [key: string]: PerformanceReportDomain;
  };
  canViewExpandedDashboard: boolean;
}

export const SkillAreas: FC<SkillAreasProps> = ({ skillAreas, canViewExpandedDashboard }) => {
  const shouldDisplayTopDivision = Object.keys(skillAreas).length > 2;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className={styles.skillAreasSection}>
      {shouldDisplayTopDivision && (
        <div className={styles.topDivision}>
          <TermsList skillAreas={skillAreas} />
          <div className={styles.chartWrapper}>
            <div ref={componentRef}>
              <CTUI.BrainFingerprint skillAreas={Object.values(skillAreas)} />
            </div>
            <CTUI.Button
              type="button"
              onClick={handlePrint}
              icon={faPrint}
              text="Print Chart"
              customClasses={styles.printButton}
            />
          </div>
        </div>
      )}
      {!canViewExpandedDashboard ? (
        <Subscribe2Unlock />
      ) : (
        <ul className="skillAreas">
          {Object.entries(skillAreas).map(([key, skillArea]) => {
            return <Item key={key} skillArea={skillArea} />;
          })}
        </ul>
      )}
    </div>
  );
};
