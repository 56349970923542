import React, { FC } from 'react';
import classNames from 'classnames';

import { PerformanceReportDomain } from 'Shared/types/shared';

import { ChartLine } from './chartLine';
import { ChartMilestone } from './chartMilestone';

import styles from './chartFigure.module.scss';

interface ChartFigureProps {
  skillArea: PerformanceReportDomain;
}

export const ChartFigure: FC<ChartFigureProps> = ({
  skillArea: { baseline, latest, nextMilestone },
}) => {
  const baselineStyles = { width: baseline.value ? `${baseline.value}%` : '2px' };
  const latestStyles = { width: latest.value ? `${latest.value}%` : '2px' };

  const diff = latest.value - baseline.value;
  const baseLineClassName = classNames(styles.line, styles.baseline);
  const latestLineClassName = classNames(styles.line, styles.latest, {
    [styles.negative]: diff < 0,
  });

  return (
    <div className={styles.chartFigure}>
      <ChartLine
        lineClasses={baseLineClassName}
        lineStyles={baselineStyles}
        lineValue={baseline.value}
      />
      <ChartLine
        lineClasses={latestLineClassName}
        lineStyles={latestStyles}
        lineValue={latest.value}
      />
      <ChartMilestone nextMilestone={nextMilestone} latest={latest} />
    </div>
  );
};
