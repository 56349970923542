import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { Link } from 'react-router-dom';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import { LOGIN_FORM_NAME } from 'Shared/types/forms';

import sharedStyles from './shared.module.scss';

const UsernameComponent: FC<InjectedFormProps> = ({
  error,
  handleSubmit,
  pristine,
  submitting,
}) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.loginWizardPage}
      header={
        <h1 className="h1">
          Welcome back! <br />
          Log in to continue.
        </h1>
      }
      footer={
        <>
          <span className={sharedStyles.hint}>Don’t have an account yet? Join us!</span>
          <Link to="/signup" className={sharedStyles.link}>
            <CTUI.Button
              text="Sign up"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
            />
          </Link>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        customClasses="usernameForm"
        error={error}
        actions={
          <CTUI.Form.SubmitButton
            isSubmitting={submitting}
            isPristine={pristine}
            text="Next &rarr;"
            customClasses={sharedStyles.button}
          />
        }
      >
        <Field
          name="username"
          label="Email or Username"
          component={CTUI.Form.Field}
          type="text"
          required
          autofocus
          autoComplete="on"
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Username = reduxForm({
  form: LOGIN_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(UsernameComponent);

export { Username };
