import React, { FC } from 'react';

import { Spinner } from 'Shared/components/spinner';

import { Username } from './username';
import { Password } from './password';
import { Otp } from './otp';
import { useWizard } from './useWizard';

export const Wizard: FC = () => {
  const { page, handleUsernameSubmit, handlePasswordSubmit, handleOtpSubmit, handlePrevious } =
    useWizard();

  switch (page) {
    case 1:
      return <Username onSubmit={handleUsernameSubmit} />;
    case 2:
      return <Password onSubmit={handlePasswordSubmit} previousPage={handlePrevious} />;
    case 3:
      return <Otp onSubmit={handleOtpSubmit} previousPage={handlePrevious} />;
    default:
      return <Spinner />;
  }
};
