import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { getUserDataNoAuth } from 'Shared/actions/user';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { User } from 'Shared/types/shared';

import { handlePatientActivation } from '../shared/utils';

export function useActivation() {
  const dispatch: Dispatch = useDispatch();
  const queryParams = useQueryParams();
  const token = queryParams.get('token');

  const [isLoading, setIsLoading] = useState(false);
  const [preAuthorizedUser, setPreAuthorizedUser] = useState<User>();

  const fetchUserDataNoAuth = async (token: string) => {
    try {
      setIsLoading(true);
      const userInfo = (await getUserDataNoAuth(token)(dispatch)) as User;
      setPreAuthorizedUser(userInfo);
    } catch (e: unknown) {
      throw new Error(e.toString());
    } finally {
      setIsLoading(false);
    }
  };

  //Handles pre-authorized logic
  useEffect(() => {
    if (token) {
      void fetchUserDataNoAuth(token);
    }
  }, [token]);

  return {
    isLoading,
    preAuthorizedUser,
    handlePatientActivation: handlePatientActivation({
      dispatch,
      subscriptionTime: queryParams.get('subscriptionTime'),
    }),
    activationData: {
      firstName: queryParams.get('firstName'),
      lastName: queryParams.get('lastName'),
      subscriptionTime: queryParams.get('subscriptionTime'),
      email: queryParams.get('email'),
      username: queryParams.has('username')
        ? queryParams.get('username')
        : queryParams.has('email'),
    },
  };
}
