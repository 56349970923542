import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import _ from 'lodash';

import rootReducer from 'Shared/reducers';

const initialState = {};

const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(...middleware)
  )
);

//Subscribes to any state changes in order to reflect those on sessionStorage for `signup` module
let currentSignUpState, currentActivateState;
store.subscribe(() => {
  //Checks whether previous state is NOT deep equal with the current one so we need to update our sessionStorage
  if (!_.isEqual(currentSignUpState, store.getState().signup)) {
    sessionStorage.setItem('signup', JSON.stringify(store.getState().signup));
  }
  currentSignUpState = store.getState().signup;

  //Checks whether previous state is NOT deep equal with the current one so we need to update our sessionStorage
  if (!_.isEqual(currentActivateState, store.getState().activate)) {
    sessionStorage.setItem('activate', JSON.stringify(store.getState().activate));
  }
  currentActivateState = store.getState().activate;
});

export default store;
