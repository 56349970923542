import { SyntheticEvent, useState } from 'react';

import { SkipReason, SkipType } from 'Shared/types/task';

export interface useSkipModalProps {
  hideModal: () => void;
  skipTask: ({ type, reason }: { type: SkipType; reason: SkipReason }) => Promise<void>;
}

export function useSkipModal({ hideModal, skipTask }: useSkipModalProps) {
  const [action, setAction] = useState<SkipType>();
  const [reason, setReason] = useState<SkipReason>();

  const handleActionClick = (e: SyntheticEvent & { target: HTMLButtonElement }) => {
    setAction(e.target.value as SkipType);
  };

  const handleReasonClick = (e: SyntheticEvent & { target: HTMLButtonElement }) => {
    setReason(e.target.value as SkipReason);
    void skipTask({ type: action, reason: e.target.value as SkipReason });
    hideModal();
  };

  return {
    action,
    reason,
    handleActionClick,
    handleReasonClick,
  };
}
