import React, { FC } from 'react';

import { PerformanceReportAssessmentQuestion } from 'Shared/types/shared';

import { Chart } from './chart';

import styles from './item.module.scss';

interface ItemProps {
  question: PerformanceReportAssessmentQuestion;
}

export const Item: FC<ItemProps> = ({ question }) => {
  return (
    <li className={styles.item}>
      <header className={styles.header}>
        <h3 className={styles.title}>{question.questionText}</h3>
      </header>
      <Chart question={question} />
    </li>
  );
};
