import React, { FC, useState } from 'react';

import { Tooltip } from 'Shared/components/tooltip';

import { ActionsSubCell } from './actionsSubCell';

import styles from './index.module.scss';

const tooltipOptions = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-10, 10],
      },
    },
  ],
  placement: 'bottom-start' as const,
};

interface AverageActivityCellProps {
  patientId: number;
  daysActivePerWeek: number;
}

export const AverageActivityCell: FC<AverageActivityCellProps> = ({
  patientId,
  daysActivePerWeek,
}) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);

  return (
    <div className={styles.averageActivityCell}>
      <span
        ref={setReferenceElement}
        onMouseEnter={() => setIsTooltipShown(true)}
        onMouseLeave={() => setIsTooltipShown(false)}
        className={styles.cellValue}
      >
        {daysActivePerWeek} days / wk
      </span>
      {isTooltipShown && (
        <Tooltip referenceElement={referenceElement} options={tooltipOptions}>
          Activity per week averaged across the last 30 days of using the app.
        </Tooltip>
      )}
      <ActionsSubCell patientId={patientId} />
    </div>
  );
};
