import React, { FC } from 'react';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { ImageStimulus } from 'Shared/types/shared';

import { useImage } from './useImage';

import styles from './image.module.scss';

interface ImageContentProps {
  stimulus: ImageStimulus;
  resourceUrl: string;
}

export const ImageContent: FC<ImageContentProps> = ({ stimulus, resourceUrl }) => {
  const { modalRef, isFullscreenPreviewShown, showFullscreenPreview, hideFullscreenPreview } =
    useImage();

  return (
    <div className={classNames(styles.content, styles.image)}>
      {isFullscreenPreviewShown ? (
        <CTUI.Modal
          ref={modalRef}
          id={styles.fullscreenPreviewModal}
          size={CTUI.ModalSizes.LARGE}
          closeButtonHandler={hideFullscreenPreview}
        >
          <img
            src={resourceUrl + stimulus.imagePath}
            className={styles.fullscreenPreview}
            onClick={showFullscreenPreview}
          />
        </CTUI.Modal>
      ) : (
        <img
          src={resourceUrl + stimulus.imagePath}
          className={styles.preview}
          onClick={showFullscreenPreview}
        />
      )}
      <p className={styles.label}>Tap to see it bigger</p>
    </div>
  );
};
