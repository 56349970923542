import { useRef, useState } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';
import { isMobile } from 'react-device-detect';

export function useFiltersSet() {
  const modalRef = useRef(null);
  const dateRangeWrapperRef = useRef(null);
  const tasksFilterWrapperRef = useRef(null);
  const [isDateRangeTooltipShown, setIsDateRangeTooltipShown] = useState(false);
  const [isTasksFilterTooltipShown, setIsTasksFilterTooltipShown] = useState(false);
  const [dateRangeReferenceElement, setDateRangeReferenceElement] = useState<HTMLElement>(null);
  const [tasksFilterReferenceElement, setTasksFilterReferenceElement] = useState<HTMLElement>(null);

  const closeModalsHandler = () => {
    setIsDateRangeTooltipShown(false);
    setIsTasksFilterTooltipShown(false);
  };

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      closeModalsHandler();
    }
  });

  useClickOutside(dateRangeWrapperRef, () => {
    if (dateRangeWrapperRef && !isMobile) {
      setIsDateRangeTooltipShown(false);
    }
  });

  useClickOutside(tasksFilterWrapperRef, () => {
    if (tasksFilterWrapperRef && !isMobile) {
      setIsTasksFilterTooltipShown(false);
    }
  });

  return {
    dateRangeWrapperRef,
    dateRangeReferenceElement,
    setDateRangeReferenceElement,
    isDateRangeTooltipShown,
    toggleDateRangeToolTip: () => {
      setIsDateRangeTooltipShown(!isDateRangeTooltipShown);
    },
    tasksFilterWrapperRef,
    tasksFilterReferenceElement,
    setTasksFilterReferenceElement,
    isTasksFilterTooltipShown,
    toggleTasksFilterToolTip: () => {
      setIsTasksFilterTooltipShown(!isTasksFilterTooltipShown);
    },
    modalRef,
    closeModalsHandler,
  };
}
