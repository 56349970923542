import React, { FC } from 'react';

import { PerformanceReportDomain } from 'Shared/types/shared';

import { Chart } from './chart';

import styles from './item.module.scss';

interface ItemProps {
  skillArea: PerformanceReportDomain;
}

export const Item: FC<ItemProps> = ({ skillArea }) => {
  return (
    <li className={styles.item}>
      <header className={styles.header}>
        <h3 className={styles.title} id={encodeURIComponent(skillArea.displayName)}>
          {skillArea.displayName}
        </h3>
        <dl className={styles.description}>
          <dt>Description:</dt>
          <dd>{skillArea.description}</dd>
        </dl>
      </header>
      <Chart skillArea={skillArea} />
    </li>
  );
};
