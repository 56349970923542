import React, { FC } from 'react';

import type { EnhancedScheduledTaskType, ScheduledTaskTypeStub } from 'Shared/types/schedule';

import { DragItem } from './taskTypeItem/useTaskTypeItem';
import { NoSchedule } from './noSchedule';
import { ActiveScheduleTasksList } from './list';

interface ActiveScheduleTasksProps {
  scheduledTasksList: (EnhancedScheduledTaskType | ScheduledTaskTypeStub)[];
  moveScheduledTasks: (dragIndex: number, hoverIndex: number, item: DragItem) => void;
  deleteScheduledTask: (index: number) => void;
  adjustScheduledTask: (task: EnhancedScheduledTaskType | ScheduledTaskTypeStub) => void;
}

export const ActiveScheduleTasks: FC<ActiveScheduleTasksProps> = (props) => {
  if (!props.scheduledTasksList.length) {
    return <NoSchedule moveScheduledTasks={props.moveScheduledTasks} />;
  }

  return <ActiveScheduleTasksList {...props} />;
};
