export const VALIDATE_USER_FORM_NAME = 'validateUserForm';

export interface ValidateUserForm {
  emailOrUsername: string;
}

export const COUPON_FORM_NAME = 'couponForm';

export interface CouponForm {
  coupon: string;
}
