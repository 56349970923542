import md5 from 'md5';
import { Dispatch } from 'redux';
import { DecoratedFormProps } from 'redux-form/lib/reduxForm';

import { getUserIp } from 'Shared/utils';
import { activatePatient } from 'Shared/actions/flintActivation';
import { ActivatePatientForm } from 'Shared/types/forms';

export function handlePatientActivation({
  dispatch,
  subscriptionTime,
}: {
  dispatch: Dispatch;
  subscriptionTime?: string;
}) {
  return async (
    formData: ActivatePatientForm,
    formDispatch: Dispatch,
    formProps: DecoratedFormProps
  ) => {
    try {
      const clientIp = await getUserIp();
      const data = { ...formData, clientIpAddress: clientIp };
      const activationResponse = await activatePatient(
        convertPatientFormDataToOutdatedStructure(data),
        subscriptionTime
      )(dispatch);
      formProps.destroy();
      localStorage.setItem('accessToken', activationResponse.data.token);
      window.location.assign('/dashboard');
    } catch (e: unknown) {
      throw new Error(e.toString());
    }
  };
}

/**
 * This function converts patient's formData to outdated `paged` structure accepted by backend.
 * Should be deprecated and removed once BE provides updated endpoint
 * @deprecated - Should be deprecated once BE updates `skills` and `disorders` structure
 * @param formData
 * @returns {{pages: [{password, page_name: string, accept_eula: boolean, email, username}, {firstName, lastName: *, page_name: string, age}, {skills: {'@type': string}[], disorders, page_name: string, since: *}], source: string, request_id: *, platform: (string|*|string)}}
 */
function convertPatientFormDataToOutdatedStructure(
  formData: ActivatePatientForm & { clientIpAddress: string }
) {
  const noDetailsDisordersList = ['apraxia', 'dysarthria'];

  return {
    platform: 'browser',
    client_ip_address: formData.clientIpAddress,
    source: 'browser/flint_activation',
    request_id: md5(JSON.stringify(formData)), //Generates unique requestId based on formData
    pages: [
      {
        page_name: 'Account',
        accept_eula: false,
        email: formData.email,
        username: formData.username,
        password: formData.password,
      },
      {
        page_name: 'Demographics',
        age: formData.age,
        firstName: formData.firstName,
        lastName: formData.lastName,
      },
      {
        page_name: 'Diagnosis',
        skills: formData.areas.map((skill) => ({ '@type': `Skill$${skill}` })),
        disorders: formData.conditions.map((condition) => {
          if (noDetailsDisordersList.includes(condition)) {
            return { '@type': `Disorder$${condition}` };
          }
          return { '@type': `Disorder$${condition}$other` };
        }),
        since: formData.conditionsPeriod,
      },
    ],
  };
}
