import queryString from 'query-string';
import { Dispatch } from 'redux';

import { fetchWrapper } from 'Shared/fetchWrapper';
import { getBaseUrl } from 'Shared/utils';
import {
  SET_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGE,
  VALIDATE_EMAIL,
  VALIDATE_USERNAME,
} from 'Shared/types/redux';
import { FlashMessage } from 'Shared/types/shared';
import { ValidateEmailResponse, ValidateUsernameResponse } from 'Shared/types/api';

export const setFlashMessage = (value: FlashMessage) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_FLASH_MESSAGE,
    payload: value,
  });
};

export const clearFlashMessage = () => (dispatch: Dispatch) => {
  dispatch({ type: CLEAR_FLASH_MESSAGE });
};

export const validateEmail =
  (email: string) =>
  async (dispatch: Dispatch): Promise<ValidateEmailResponse> => {
    const formattedParams = queryString.stringify(
      { email },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return fetchWrapper.get(`${getBaseUrl()}/api/validateemail?${formattedParams}`, {
      dispatch,
      types: VALIDATE_EMAIL,
    });
  };

export const validateUsername =
  (username: string) =>
  async (dispatch: Dispatch): Promise<ValidateUsernameResponse> => {
    const formattedParams = queryString.stringify(
      { username },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return fetchWrapper.get(`${getBaseUrl()}/api/validateusername?${formattedParams}`, {
      dispatch,
      types: VALIDATE_USERNAME,
    });
  };
