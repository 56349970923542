import React, { FC } from 'react';
import classNames from 'classnames';

import type { InstitutionUser } from 'Shared/types/shared';

import styles from './previewCell.module.scss';

export const PreviewCell: FC<InstitutionUser> = ({
  preview,
  firstName,
  lastName,
  statusDisplay,
}) => {
  const fullName = [firstName, lastName].filter((e) => e);
  const previewAlt = fullName.length
    ? fullName.reduce((acc, e) => acc + e.substring(0, 1), '')
    : '?';
  const imageClasses = classNames(styles.preview, previewAlt.length ? '' : styles.noName);
  const statusClasses = classNames(styles.statusDisplay, {
    [styles[statusDisplay]]: statusDisplay,
  });
  /* The following line was added as a fix for IE11 issue by
  <joy.dmello@constanttherapy.com> on 2022-06-14 at 10:03 a.m. */
  const extraProps = { src: preview, alt: previewAlt };

  return (
    <div className={styles.previewCell}>
      <span
        title={fullName.length ? fullName.join(' ') : ' '}
        className={imageClasses}
        {...extraProps}
      ></span>
      <span className={statusClasses} />
    </div>
  );
};
