import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import styles from './noData.module.scss';

export function NoData() {
  return (
    <div className={styles.noData}>
      <FontAwesomeIcon icon={faHandshake} className={styles.icon} />
      <CTUI.Font.H2 customClasses={styles.title}>Better Together</CTUI.Font.H2>
      <div className={styles.description}>
        Click the <FontAwesomeIcon icon={faPlusCircle} className={styles.icon} /> icon in the table
        header above to get
        <br />
        started inviting users. As you add new users, their
        <br />
        activity will appear here.
      </div>
    </div>
  );
}
