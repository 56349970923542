import React, { FC } from 'react';
import classNames from 'classnames';

import { PerformanceReportAssessmentQuestionLabel } from 'Shared/types/shared';

import styles from './chartLegend.module.scss';

interface ChartLegendProps {
  labels: PerformanceReportAssessmentQuestionLabel[];
}

export const ChartLegend: FC<ChartLegendProps> = ({ labels }) => {
  return (
    <div className={styles.assessmentChartLegend}>
      <div className={styles.leftPadding} />
      <ul className={styles.labelsList}>
        {labels.map((label) => {
          const lineWidth = (label.value - 1) * 25;
          const labelClasses = classNames(styles.label, {
            [styles[`label--${label.value}`]]: true,
          });

          return (
            <li key={label.value} className={labelClasses} style={{ left: `${lineWidth}%` }}>
              <span>{label.text}</span>
            </li>
          );
        })}
      </ul>
      <div className={styles.rightPadding} />
    </div>
  );
};
