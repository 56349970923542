import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUserCheck } from '@fortawesome/free-solid-svg-icons';

import { PATIENT_TYPES } from 'Shared/types/shared';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import styles from './patientTypeSelection.module.scss';

interface PatientTypeSelectionProps {
  selectPatientType: (patientType: PATIENT_TYPES) => void;
}

export const PatientTypeSelection: FC<PatientTypeSelectionProps> = ({ selectPatientType }) => {
  return (
    <FloatingPage
      customClasses={classNames(styles.patientTypeSelection)}
      header={<h1 className="h1">Please tell us about your patient</h1>}
    >
      <CTUI.Button
        variant="secondary"
        customClasses={styles.button}
        onClick={() => selectPatientType(PATIENT_TYPES.NEW)}
      >
        <FontAwesomeIcon icon={faUserPlus} className={styles.icon} />
        <h2 className={classNames('h2', styles.h2)}>New Patient</h2>
        <h3 className="h3">
          A patient under your care who does not yet have a Constant Therapy account.
        </h3>
      </CTUI.Button>
      <h4 className={classNames('h4', styles.splitter)}>OR</h4>
      <CTUI.Button
        variant="primary"
        customClasses={styles.button}
        onClick={() => selectPatientType(PATIENT_TYPES.EXISTING)}
      >
        <FontAwesomeIcon icon={faUserCheck} className={styles.icon} />
        <h2 className={classNames('h2', styles.h2)}>Existing Patient</h2>
        <h3 className="h3">
          A patient under your care who already has a Constant Therapy account.
        </h3>
      </CTUI.Button>
    </FloatingPage>
  );
};
