import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Download the Constant Therapy app today to ignite 
  your brain rehabilitation journey with a personalized program designed to improve your
cognitive and language skills.`;
  return (
    <Helmet>
      <title>Download the Constant Therapy App | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
