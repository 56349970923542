import React, { FC } from 'react';

interface CompleteAccountProps {
  isFlintCustomer: boolean;
}

export const CompleteAccount: FC<CompleteAccountProps> = ({ isFlintCustomer }) => {
  if (isFlintCustomer) {
    return <p>Please complete your signup process following the link in email from Flint</p>;
  }

  return <p>Please complete signing up for your account via the Constant Therapy app</p>;
};
