import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { isMobile } from 'react-device-detect';
import { DesktopMenu } from 'App/dashboard/patientDashboard/dashboardNavigation/desktopMenu';
import { MobileMenu } from 'App/dashboard/patientDashboard/dashboardNavigation/mobileMenu';
import { DailySessionNotePopup } from 'App/dashboard/shared/dailySessionNotePopup';

import { HTML_HEADER_ID } from 'Shared/constants';

import { ProgressAndDischargePopup } from '../../shared/progressAndDischargePopup';

import { useDashboardNavigation } from './useDashboardNavigation';

import styles from './index.module.scss';

const mountingPoint = document.getElementById(HTML_HEADER_ID);

interface DashboardNavigationProps {
  patientId?: string;
  surveillanceMode?: boolean;
}

export const DashboardNavigation: FC<DashboardNavigationProps> = ({
  patientId,
  surveillanceMode,
}) => {
  const {
    isProgressPopupShown,
    isDailySessionPopupShown,
    isDischargePopupShown,
    showProgressPopup,
    hideProgressPopup,
    showDailySessionPopup,
    hideDailySessionPopup,
    showDischargePopup,
    hideDischargePopup,
  } = useDashboardNavigation();

  return ReactDOM.createPortal(
    <>
      <nav className={styles.dashboardNavigation}>
        {isMobile ? (
          <MobileMenu
            showProgressPopup={showProgressPopup}
            surveillanceMode={surveillanceMode}
            patientId={patientId}
          />
        ) : (
          <DesktopMenu
            showProgressPopup={showProgressPopup}
            showDailySessionPopup={showDailySessionPopup}
            showDischargePopup={showDischargePopup}
            surveillanceMode={surveillanceMode}
            patientId={patientId}
          />
        )}
      </nav>
      {isDailySessionPopupShown && (
        <DailySessionNotePopup patientId={patientId} closePopupHandler={hideDailySessionPopup} />
      )}
      {isProgressPopupShown && (
        <ProgressAndDischargePopup
          patientId={patientId}
          closePopupHandler={hideProgressPopup}
          isProgressNote={true}
        />
      )}
      {isDischargePopupShown && (
        <ProgressAndDischargePopup
          patientId={patientId}
          closePopupHandler={hideDischargePopup}
          isProgressNote={false}
        />
      )}
    </>,
    mountingPoint
  );
};
