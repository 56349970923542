import { Dispatch } from 'redux';

import { validateEmail, validateUsername } from 'Shared/actions/service';

interface AsyncValidateType {
  (
    values: {
      email?: string;
      username?: string;
    },
    dispatch: Dispatch,
    props: {
      initialValues?: {
        email?: string;
        institutionId?: number;
      };
    },
    field: string
  ): Promise<any>;
}

export const asyncValidate: AsyncValidateType = async (values, dispatch, props, field) => {
  switch (field) {
    case 'email': {
      //TODO: This is a bad way to disable validation but I can't find a better solution right now
      if (props.initialValues && props.initialValues.email && props.initialValues.institutionId) {
        return Promise.resolve();
      }
      const response = await validateEmail(values[field])(dispatch);
      if (response.exists && response.exists !== 'false') {
        switch (response.exists) {
          case 'true':
            throw { [field]: 'The email provided is already in use' };
          case 'invalid':
            throw { [field]: 'The email provided is invalid' };
        }
      } else if (response.message) {
        throw { [field]: response.message };
      } else if (response.error) {
        throw { [field]: response.error };
      }
      break;
    }
    case 'username': {
      const response_1 = await validateUsername(values[field])(dispatch);
      if (response_1.exists && response_1.exists !== 'false') {
        switch (response_1.exists) {
          case 'true':
            throw { [field]: 'The username provided is already in use' };
          case 'invalid':
            throw { [field]: 'The username provided is invalid' };
        }
      } else if (response_1.message) {
        throw { [field]: response_1.message };
      } else if (response_1.error) {
        throw { [field]: response_1.error };
      }
      break;
    }
    default:
      return Promise.resolve();
  }
};
