import { useRef, useState } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

export function useImage() {
  const modalRef = useRef(null);
  const [isFullscreenPreviewShown, setIsFullscreenPreviewShown] = useState(false);

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      setIsFullscreenPreviewShown(false);
    }
  });

  return {
    modalRef,
    isFullscreenPreviewShown,
    showFullscreenPreview: () => {
      setIsFullscreenPreviewShown(true);
    },
    hideFullscreenPreview: () => {
      setIsFullscreenPreviewShown(false);
    },
  };
}
