import { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';

import { RootState, User } from 'Shared/types/shared';
import { getDashboardData } from 'Shared/actions/clinician';

export interface ClinicianDashboardProps {
  clinician: User;
}

export function useClinicianDashboard({ clinician }: ClinicianDashboardProps) {
  const store = useStore();
  const dashboardData = useSelector((state: RootState) => state.clinician.dashboardData);
  const dashboardError = useSelector((state: RootState) => state.clinician.dashboardError);
  const [isLoading, setIsLoading] = useState(true);

  const fetchDashboardData = async () => {
    try {
      await getDashboardData()(store.dispatch);
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchDashboardData();
  }, [clinician]);

  return { isLoading, dashboardData, dashboardError };
}
