import { USER_TYPES } from 'Shared/types/shared';

export const INVITATION_FORM_NAME = 'INVITATION_FORM';

export interface InvitationFormShape {
  email: string;
  type: USER_TYPES;
  verified?: boolean;
  sendEmail?: boolean;
  institutionId: number;
}
