import { SET_FLASH_MESSAGE, CLEAR_FLASH_MESSAGE } from 'Shared/types/redux';

/**
 * Preset for flashMessage object
 * type - one of the possible types ['success', 'error', 'warning', 'info']
 * keepFor - amount of seconds to stay shown
 * @type {{keepFor: number, type: string}}
 */
const flashMessageDefaults = {
  type: 'info',
  keepFor: 5,
};

export default function (state = {}, action) {
  switch (action.type) {
    case SET_FLASH_MESSAGE:
      return {
        ...state,
        flashMessage: { ...flashMessageDefaults, ...action.payload },
      };
    case CLEAR_FLASH_MESSAGE:
      return {
        ...state,
        flashMessage: false,
      };
    default:
      return state;
  }
}
