import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { getInstitution, saveInstitution } from 'Shared/actions/institution';
import { setFlashMessage } from 'Shared/actions/service';
import { FlashMessageType, InstitutionExpanded, RootState } from 'Shared/types/shared';

export interface InstitutionProps {
  institutionId: number;
}

export function useInstitution({ institutionId }: InstitutionProps) {
  const dispatch: Dispatch = useDispatch();
  const institution = useSelector((state: RootState) => state.institution.institution);
  const [isLoading, setIsLoading] = useState(true);

  const handleSaveInstitution = async (formData: InstitutionExpanded) => {
    await saveInstitution(institutionId, formData)(dispatch);
    setFlashMessage({
      message: 'Institution info was successfully updated.',
      type: FlashMessageType.SUCCESS,
      keepFor: 5,
    })(dispatch);
  };

  const fetchInstitution = useCallback(async () => {
    try {
      setIsLoading(true);
      await getInstitution(institutionId)(dispatch);
    } catch (e: unknown) {
      throw new Error(e.toString());
    } finally {
      setIsLoading(false);
    }
  }, [institutionId]);

  useEffect(() => {
    void fetchInstitution();
  }, []);

  return {
    isLoading,
    institution,
    handleSaveInstitution,
  };
}
