import { useCallback, useContext, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { AnswerResult } from 'App/therapy/session/taskViewComponent/useTaskView';

import correctSound from 'Shared/src/themes/2023/sounds/correct_answer.mp3';
import wrongSound from 'Shared/src/themes/2023/sounds/wrong_answer.mp3';
import { AudioContext } from 'Shared/context/audioContext';
import type { TaskViewBoardCompletion, TaskViewChoice } from 'Shared/types/shared';

import { EnhancedChoice } from './types';

/**
 * Number of seconds for a choice to stay highlighted
 * Should be equal to the animation time
 */
const HIGHLIGHT_DURATION = 2;

export interface ControlsProps {
  task: TaskViewBoardCompletion;
  handleAnswer: (result: AnswerResult) => void;
  isInitialAudioInstructionsShouldBePlayed: boolean;
}

export function useControls({
  task,
  handleAnswer,
  isInitialAudioInstructionsShouldBePlayed,
}: ControlsProps) {
  const { stopAudioSequence, playAudioSequence, isPlaying } = useContext(AudioContext);

  const [choicesList, setChoicesList] = useState<EnhancedChoice[]>(enhanceChoices(task.choices));
  const [answersLeft, setAnswersLeft] = useState<number>(task.answers.length);
  const [isFirstClicked, setIsFirstClicked] = useState<boolean>(false);
  const [wasHintShown, setWasHintShown] = useState(false);

  const handleAlternatingChoices = (index: number) => {
    const isCorrectClicked = task.answers.find(
      (answer) => answer.correctValue === choicesList[index].value
    );

    if (!isCorrectClicked || choicesList[index].isPlaced) {
      return;
    }

    stopAudioSequence();

    if (isFirstClicked && isEqual(task.stimulus[1], choicesList[index].stimulus)) {
      playAudioSequence({ audioElementsList: [new Audio(correctSound as string)] });
      setIsFirstClicked(false);
      setAnswersLeft(answersLeft - 1);

      setChoicesList((prevState) => {
        return Object.assign([], prevState, {
          [index]: { ...prevState[index], isPlaced: true },
        });
      });

      return;
    }

    if (!isFirstClicked && isEqual(task.stimulus[0], choicesList[index].stimulus)) {
      playAudioSequence({ audioElementsList: [new Audio(correctSound as string)] });
      setIsFirstClicked(true);
      setAnswersLeft(answersLeft - 1);

      setChoicesList((prevState) => {
        return Object.assign([], prevState, {
          [index]: { ...prevState[index], isPlaced: true },
        });
      });

      return;
    }

    playAudioSequence({ audioElementsList: [new Audio(wrongSound as string)] });
  };

  const handlePatternRecreation = (index: number) => {
    if (
      task.answers[task.answers.length - answersLeft].correctValue === task.choices[index].value
    ) {
      playAudioSequence({ audioElementsList: [new Audio(correctSound as string)] });
      setChoicesList((prevState) => {
        return Object.assign([], prevState, {
          [index]: { ...prevState[index], isPlaced: true },
        });
      });
      setAnswersLeft(answersLeft - 1);
      return;
    }

    playAudioSequence({ audioElementsList: [new Audio(wrongSound as string)] });
  };

  /**
   * This function is a generic entry point for any `choice` click event which
   * contains extra conditions where each condition drastically changes logic and user experience
   * @param index
   */
  const handleChoiceClick = (index: number) => {
    if (!task.stimulus.length) {
      handlePatternRecreation(index);
      return;
    }

    if (task.stimulus.length > 1) {
      handleAlternatingChoices(index);
    }
  };

  const highlightSequence = useCallback(() => {
    (function highlightChoice(index: number) {
      setChoicesList((prevState) => {
        return prevState.map((choice) => {
          if (choice.value === task.answers[index].correctValue) {
            return { ...choice, isHighlighted: true };
          } else {
            return { ...choice, isHighlighted: false };
          }
        });
      });

      //No need to run this if the index is out of the list boundaries
      if (index + 1 < task.answers.length) {
        window.setTimeout(() => {
          highlightChoice(index + 1);
        }, HIGHLIGHT_DURATION * 1000);
      }
    })(0);
  }, [choicesList]);

  useEffect(() => {
    if (task) {
      setAnswersLeft(task.answers.length);
      setChoicesList(enhanceChoices(task.choices));
      setWasHintShown(false);
    }
  }, [task]);

  /**
   * Serves for the initial tiles highlighting
   * `task.allowHints` - this is the indication that we have tiles to highlight
   * `isInitialAudioInstructionsShouldBePlayed` - external state that indicates whether initial audio instruction should be played
   * `isPlaying` - context-based value indicating if anything is playing right now
   * `wasHintShown` - local state that controls if hint was shown once
   */
  useEffect(() => {
    if (
      task.allowHints &&
      !isInitialAudioInstructionsShouldBePlayed &&
      !isPlaying &&
      !wasHintShown
    ) {
      highlightSequence();
      setWasHintShown(true);
    }
  }, [task, isPlaying, isInitialAudioInstructionsShouldBePlayed, wasHintShown]);

  useEffect(() => {
    if (!answersLeft) {
      handleAnswer(AnswerResult.CORRECT);
    }
  }, [answersLeft]);

  return {
    handleChoiceClick,
    choicesList,
  };
}

function enhanceChoices(items: TaskViewChoice[]) {
  return items.map((item) => ({ ...item, isPlaced: false, isHighlighted: false }));
}
