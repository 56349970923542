import React, { FC } from 'react';
import classNames from 'classnames';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import { SUPPORT } from 'Shared/constants';
import alreadyActivatedImage from 'Shared/src/themes/2019/images/icAccountActivatedAlready.svg';

import styles from './error.module.scss';

interface ErrorProps {
  errorMessage?: string;
}

export const Error: FC<ErrorProps> = ({
  errorMessage = 'The invitation has expired, please re-request invitation one more time',
}) => {
  return (
    <FloatingPage
      customClasses={styles.defaultActivationErrorPage}
      header={
        <h3 className={classNames('h3', styles.title)}>
          <img
            src={alreadyActivatedImage as string}
            alt="Unable to activate your account"
            title="Unable to activate your account"
            className={styles.icon}
          />
          {errorMessage}
        </h3>
      }
    >
      <p className={styles.message}>
        Please contact customer support at{' '}
        <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a> or{' '}
        <a href={`mailto:${SUPPORT.EMAIL}`}>{SUPPORT.EMAIL}</a> if you need further assistance!
      </p>
    </FloatingPage>
  );
};
