import React, { FC, SyntheticEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './actionButton.module.scss';

interface ActionButtonProps {
  clickHandler: (e: SyntheticEvent) => void;
}

export const ActionButton: FC<ActionButtonProps> = ({ clickHandler }) => {
  return (
    <a href="#" onClick={clickHandler} className={styles.actionButton}>
      <FontAwesomeIcon icon={faPlusCircle} className={styles.icon} />
      Add new user
    </a>
  );
};
