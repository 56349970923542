import { useLocation } from 'react-router-dom';
import md5 from 'md5';

import { getDisplayName } from 'Shared/utils';
import { ClinicianDashboard } from 'Shared/types/shared';

export interface DashboardNavigationProps {
  dashboardData: ClinicianDashboard;
}

export function useDashboardNavigation({ dashboardData }: DashboardNavigationProps) {
  const { pathname } = useLocation();
  const patientIdIndex = 2;
  const patientId = pathname.replace(/^\/+/g, '').split('/')[patientIdIndex];
  const patient = dashboardData.Patients.find(
    (patient) => md5(patient.patientId.toString()) === patientId
  );
  const displayName = getDisplayName({
    userObject: null,
    username: patient && patient.patientUsername,
    email: patient && patient.email,
  });

  const shouldHideNavigation = !(
    pathname.includes('report') ||
    pathname.includes('detailed-performance') ||
    pathname.includes('calendar') ||
    pathname.includes('add-patient')
  );

  const isAddingPatient = pathname.includes('add-patient');

  return {
    shouldHideNavigation,
    isAddingPatient,
    patientId,
    displayName,
  };
}
