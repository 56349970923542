import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

import { getTherapiesCompositeKey } from 'Shared/utils';
import { PerformanceReportTask } from 'Shared/types/shared';

import { PerformanceAndDischargeTaskItem } from './taskItem';

import sectionStyles from './section.module.scss';

interface ReportTasksSectionProps {
  tasks: {
    [key: string]: PerformanceReportTask;
  };
}

export const ReportTasksSection: FC<ReportTasksSectionProps> = ({ tasks }) => {
  let taskItemsList = (
    <div className={sectionStyles.empty}>
      <FontAwesomeIcon icon={faCogs} className={sectionStyles.emptyIcon} />
      <CTUI.Font.H3 customClasses={sectionStyles.emptyTitle}>
        No therapy activities yet!
      </CTUI.Font.H3>
      <CTUI.Font.Text customClasses={sectionStyles.emptyDescription}>
        Get started with therapy activities at home and then check back here to see your
        performance!
      </CTUI.Font.Text>
    </div>
  );

  if (tasks && Object.values(tasks).length) {
    taskItemsList = (
      <ul>
        {Object.values(tasks).map((therapy) => {
          return Object.entries(therapy.levels).map(([levelKey, level]) => {
            return (
              <PerformanceAndDischargeTaskItem
                key={getTherapiesCompositeKey(therapy.displayName, levelKey)}
                therapy={therapy}
                levelKey={levelKey}
                level={level}
              />
            );
          });
        })}
      </ul>
    );
  }

  return (
    <div className={sectionStyles.section}>
      <CTUI.Font.H2 customClasses={sectionStyles.title}>Therapy activity performance</CTUI.Font.H2>
      {taskItemsList}
    </div>
  );
};
