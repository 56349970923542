import React, { FC } from 'react';
import classNames from 'classnames';

import { PerformanceReportSummary } from 'Shared/types/shared';

import styles from './reportSummary.module.scss';

const formattedSummary = {
  accuracy: {
    title: 'Accuracy',
    summary: () => 'Recent Average',
    format: (value: number) => `${Math.round(value * 100)}%`,
  },
  tasksCompleted: {
    title: 'Total Exercises Completed',
    summary: () => 'Exercises',
    format: (value: number) => value.toLocaleString(undefined, { minimumFractionDigits: 0 }),
  },
  daysActivePerWeek: {
    title: 'Average Activity',
    summary: (value: number) => (value < 3 ? 'Recommended: at least 3 days/week' : 'Days per week'),
    format: (value: number) => (value < 3 ? `${value} days/week` : value),
  },
};

interface ReportSummaryProps {
  summary: PerformanceReportSummary;
}

export const ReportSummary: FC<ReportSummaryProps> = ({ summary }) => {
  return (
    <ul className={styles.reportSummary}>
      {Object.entries(formattedSummary).map(([key, summaryItem]) => {
        return (
          <li key={key} className={styles.tile}>
            <h3 className={classNames('h3', styles.title)}>{summaryItem.title}</h3>
            <p className={styles.value}>
              {summaryItem.format(summary[key as keyof PerformanceReportSummary])}
            </p>
            <p className={styles.summary}>
              {summaryItem.summary(summary[key as keyof PerformanceReportSummary])}
            </p>
          </li>
        );
      })}
    </ul>
  );
};
