import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { SUBSCRIBE_BUTTON_STATES } from 'Shared/constants';

import styles from './subscriptionButton.module.scss';

interface SubscriptionButtonProps {
  subscribeButton: SUBSCRIBE_BUTTON_STATES;
  handleReactivateSubscription: (e: React.SyntheticEvent) => void;
}

export const SubscriptionButton: FC<SubscriptionButtonProps> = ({
  subscribeButton,
  handleReactivateSubscription,
}) => {
  switch (subscribeButton) {
    case SUBSCRIBE_BUTTON_STATES.UPDATE:
      return (
        <Link to="/payment_update/" className={styles.subscriptionButton}>
          <CTUI.Button text="Update Payment Method" />
        </Link>
      );
    case SUBSCRIBE_BUTTON_STATES.CANCEL:
      return (
        <Link to="/unsubscribe" className={styles.subscriptionButton}>
          <CTUI.Button text="Cancel my subscription" />
        </Link>
      );
    case SUBSCRIBE_BUTTON_STATES.RESUBSCRIBE:
      return (
        <CTUI.Button
          text="Reactivate Subscription"
          variant="secondary"
          onClick={handleReactivateSubscription}
          customClasses={styles.subscriptionButton}
        />
      );
    case SUBSCRIBE_BUTTON_STATES.HIDE:
      return null;
  }

  return (
    <Link to="/subscribe/?source=account" className={styles.subscriptionButton}>
      <CTUI.Button text="Subscribe" variant="secondary" />
    </Link>
  );
};
