import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import styles from './nonAuthBlock.module.scss';

export const NonAuthBlock: FC = () => {
  return (
    <ul className={styles.nonAuthBlock}>
      <li>
        <Link to="/login" title="Log In" className={styles.link}>
          <CTUI.Button text="Log In" variant="secondary-inverted" customClasses={styles.button} />
        </Link>
      </li>
      <li>
        <Link to="/signup" title="Sign Up" className={styles.link}>
          <CTUI.Button text="Sign Up" variant="primary-inverted" customClasses={styles.button} />
        </Link>
      </li>
    </ul>
  );
};
