import React, { ChangeEvent, FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import styles from './search.module.scss';

interface SearchProps {
  searchTerm: string;
  doSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Search: FC<SearchProps> = ({ searchTerm, doSearch }) => {
  return (
    <div className={styles.search}>
      <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
      <input
        type="text"
        placeholder="Search for users"
        className={styles.searchInput}
        value={searchTerm}
        onChange={doSearch}
      />
    </div>
  );
};
