import { type SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type Dispatch as ReduxDispatch } from 'redux';

import { useAudioRecorder } from 'Shared/hooks/useAudioRecorder';
import { useAudioLevels } from 'Shared/hooks/useAudioLevels';
import { RootState, TaskView } from 'Shared/types/shared';
import { recognizeSpeech } from 'Shared/actions/therapy';
import type {
  SpeechRecognitionResponse,
  TherapySessionResponseOldTaskStructure,
} from 'Shared/types/api';
import { FfmpegContext } from 'Shared/context/ffmpegContext';

import { AnswerResult } from '../../useTaskView';

interface useProductionProps {
  task: TaskView;
  oldTask: TherapySessionResponseOldTaskStructure;
  handleAnswer: (result: AnswerResult) => void;
  resetAnswer: () => void;
}

/**
 * Important Note for local development:
 * @see https://stackoverflow.com/questions/40144036/javascript-getusermedia-using-chrome-with-localhost-without-https
 */
export function useProduction({ task, oldTask, handleAnswer, resetAnswer }: useProductionProps) {
  const dispatch: ReduxDispatch = useDispatch();
  const authUser = useSelector((state: RootState) => state.user.data);
  const [microphoneAccessFailedError, setMicrophoneAccessFailedError] =
    useState<DOMException>(null);

  const { startRecording, stopRecording, isPaused, isRecording, recordingBlob, mediaRecorder } =
    useAudioRecorder(
      {
        sampleRate: 16000,
        sampleSize: 16,
        channelCount: 1,
      },
      setMicrophoneAccessFailedError,
      {
        mimeType: 'audio/webm;codecs=pcm',
      }
    );
  const { startAnalysis, stopAnalysis, audioLevel } = useAudioLevels();
  const [isRecognizing, setIsRecognizing] = useState(false);

  const { transcode } = useContext(FfmpegContext);

  const handleReplay = async () => {
    const audio = new Audio(URL.createObjectURL(recordingBlob));
    await audio.play();
  };

  const handleRetry = () => {
    resetAnswer();
  };

  //Part 1 of 3
  const handleStop = () => {
    stopRecording();
    stopAnalysis();
    setIsRecognizing(true);
  };

  //Part 2 of 3
  useEffect(() => {
    if (recordingBlob) {
      void handleRecognition(recordingBlob);
    }
  }, [recordingBlob]);

  //Part 3 of 3
  const handleRecognition = async (recordingBlob: Blob) => {
    try {
      const recording = await transcode({ inputFile: recordingBlob });
      const recognitionResponse = (await recognizeSpeech({
        patientId: authUser.user.userId,
        taskTypeId: oldTask.taskId,
        words: [task.stimulus[0].text],
        soundPaths: [task.stimulus[0].audioPaths[0]],
        task: JSON.stringify(oldTask),
        recording,
      })(dispatch)) as SpeechRecognitionResponse;
      if (recognitionResponse.correct) {
        handleAnswer(AnswerResult.CORRECT);
      } else {
        handleAnswer(AnswerResult.WRONG);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsRecognizing(false);
    }
  };

  const handleMarkCorrect = (e: SyntheticEvent & { target: HTMLButtonElement }) => {
    e.preventDefault();
    handleAnswer(AnswerResult.CORRECT);
  };

  return {
    handleStart: () => {
      startRecording();
      void startAnalysis();
    },
    handleStop,
    isRecording,
    isRecognizing,
    microphoneAccessFailedError,
    audioLevel,
    handleReplay,
    handleRetry,
    handleMarkCorrect,
  };
}
