import React, { FC } from 'react';

import { getTherapiesCompositeKey } from 'Shared/utils';
import { PerformanceReportTask, PerformanceReportTaskLevel } from 'Shared/types/shared';

import { CuesList } from './cuesList';
import { Chart } from './chart';

import styles from './item.module.scss';

interface ItemProps {
  subKey: string;
  therapy: PerformanceReportTask;
  level: PerformanceReportTaskLevel;
}

export const Item: FC<ItemProps> = ({ subKey, therapy, level }) => {
  return (
    <li className={styles.therapyItem}>
      <header className={styles.header}>
        <h3 className={styles.title} id={getTherapiesCompositeKey(therapy.displayName, subKey)}>
          Therapy Activity
        </h3>
        <h4 className={styles.subTitle}>
          {therapy.displayName}, Level {subKey}
        </h4>
        <CuesList level={level} />
        <dl className={styles.data}>
          {therapy.activityDescription?.length && (
            <>
              <dt>Description</dt>
              <dd>{therapy.activityDescription}</dd>
            </>
          )}
          <dt>Skill</dt>
          <dd>{therapy.skillAreas.join()}</dd>
        </dl>
      </header>
      <Chart level={level} />
    </li>
  );
};
