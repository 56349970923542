import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { ClinicianDashboardSummary } from 'Shared/types/shared';

import styles from './statsBlock.module.scss';

interface StatsTotalPatientsProps {
  Summary: ClinicianDashboardSummary;
}

export const StatsTotalPatients: FC<StatsTotalPatientsProps> = ({
  Summary: { numPatients, numAddedPatients, numDischargedPatients },
}) => {
  return (
    <div className={styles.statsBlock}>
      <CTUI.Font.Text customClasses={styles.counter}>{numPatients.toLocaleString()}</CTUI.Font.Text>
      <span className={styles.placement}>
        <FontAwesomeIcon icon={faUser} className={styles.mainIcon} />
      </span>
      <CTUI.Font.H4 customClasses={styles.title}>Total Patients</CTUI.Font.H4>
      <CTUI.Font.Text2 customClasses={styles.diff}>
        {Boolean(numAddedPatients) && (
          <>
            <span>
              <FontAwesomeIcon icon={faCaretUp} /> {numAddedPatients.toLocaleString()}
            </span>{' '}
            added
          </>
        )}
        {Boolean(numAddedPatients) && Boolean(numDischargedPatients) && ', '}
        {Boolean(numDischargedPatients) && (
          <>
            {/* Forces following lines to wrap properly :\ */}
            <span className={styles.diffDecrease}>
              {numDischargedPatients.toLocaleString()}
            </span>{' '}
            discharged in past week
          </>
        )}
      </CTUI.Font.Text2>
    </div>
  );
};
