import {
  FLINT_ACTIVATION_SET_SUBSCRIPTION_TIME,
  FLINT_ACTIVATION_SET_FIRST_NAME,
  FLINT_ACTIVATION_SET_LAST_NAME,
  FLINT_ACTIVATION_SET_EMAIL,
} from 'Shared/types/redux';

export default function (state = {}, action) {
  switch (action.type) {
    case FLINT_ACTIVATION_SET_SUBSCRIPTION_TIME:
      return {
        ...state,
        subscriptionTime: action.payload,
      };
    case FLINT_ACTIVATION_SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case FLINT_ACTIVATION_SET_LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      };
    case FLINT_ACTIVATION_SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    default:
      return state;
  }
}
