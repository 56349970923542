import React, { CSSProperties, Dispatch, FC, SetStateAction } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faPrint } from '@fortawesome/free-solid-svg-icons';

import { CopyToClipboardTooltip } from './copyToClipboardTooltip';

import styles from './actions.module.scss';

interface NoteActionsProps {
  textToCopy: string;
  handleCopy: () => void;
  handlePrint: () => void;
  setReferenceElement: React.Dispatch<React.SetStateAction<HTMLElement>>;
  isCopied: boolean;
  popper: {
    ref: Dispatch<SetStateAction<HTMLElement>>;
    style: CSSProperties;
    attributes: object;
  };
  arrow: {
    ref: Dispatch<SetStateAction<HTMLElement>>;
    style: CSSProperties;
  };
}

export const NoteActions: FC<NoteActionsProps> = ({
  textToCopy,
  handleCopy,
  handlePrint,
  setReferenceElement,
  isCopied,
  popper,
  arrow,
}) => {
  return (
    <ul className={styles.noteActions}>
      <li>
        <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
          <div>
            <span ref={setReferenceElement}>
              <FontAwesomeIcon icon={faClipboard} /> Copy
              {isCopied && <CopyToClipboardTooltip popper={popper} arrow={arrow} />}
            </span>
          </div>
        </CopyToClipboard>
      </li>
      <li>
        <div onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </div>
      </li>
    </ul>
  );
};
