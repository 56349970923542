import React, { FC } from 'react';
import classNames from 'classnames';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';

import { ADD_PATIENT_FORM_NAME, areasList } from '../../shared/constants';

import stepStyles from '../../shared/step.module.scss';

interface AreasComponentProps {
  previousPage: () => void;
}

const AreasComponent: FC<AreasComponentProps & InjectedFormProps<object, AreasComponentProps>> = ({
  handleSubmit,
  previousPage,
  error,
  pristine,
  submitting,
}) => {
  return (
    <FloatingPage
      customClasses={stepStyles.step}
      header={
        <>
          <ProgressBar currentStep={5} totalSteps={5} />
          <h1 className={classNames('h1', stepStyles.title)}>
            Which areas do you want to improve? Check all that apply.
          </h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses={stepStyles.form}
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="primary-inverted"
              customClasses={stepStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text="Finish"
              variant="primary"
              customClasses={stepStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="areas"
          component={CTUI.Form.CheckboxGroup}
          options={areasList}
          type="checkboxGroup"
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Areas = reduxForm<object, AreasComponentProps>({
  form: ADD_PATIENT_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AreasComponent);

export { Areas };
