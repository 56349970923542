import React, { FC } from 'react';

import { PerformanceReportTask } from 'Shared/types/shared';

import { TermsList } from './termsList';
import { Item } from './item';

import styles from './index.module.scss';

interface TherapiesProps {
  therapies: {
    [key: string]: PerformanceReportTask;
  };
}

export const Therapies: FC<TherapiesProps> = ({ therapies }) => {
  return (
    <div className={styles.therapiesSection}>
      <div className={styles.topDivision}>
        <TermsList therapies={therapies} />
      </div>
      <ul className="therapies">
        {Object.entries(therapies).map(([key, therapy]) => {
          return Object.entries(therapy.levels).map(([subKey, level]) => {
            return (
              <Item key={`${key}-${subKey}`} subKey={subKey} therapy={therapy} level={level} />
            );
          });
        })}
      </ul>
    </div>
  );
};
