import React, { FC } from 'react';
import DataTable from 'react-data-table-component';

import { User } from 'Shared/types/shared';

import { TableEmpty } from './empty';
import { ExpandableRow } from './expandableRow';
import { usePatientsListTable, UsePatientsListTableProps } from './usePatientsListTable';
import { columns } from './columnsDefinition';
import { ScheduleContextProvider } from './shared/scheduleComponent/scheduleContextProvider';

import styles from './index.module.scss';

export const PatientsListTable: FC<UsePatientsListTableProps & { clinician: User }> = ({
  clinician,
  Patients,
  StatusTypes,
  searchText,
}) => {
  const { data } = usePatientsListTable({ Patients, StatusTypes, searchText });

  if (!Patients.length) {
    return <TableEmpty />;
  }

  // This is insane, but `.patientsListTableWrapper` is needed for a single purpose - it gives ability
  // to style table's pagination row inside a related file and not elsewhere, please see an
  // additional comment in styles-file
  return (
    <ScheduleContextProvider>
      <div className={styles.patientsListTableWrapper}>
        <DataTable
          className={styles.patientsListTable}
          data={data}
          columns={columns}
          noHeader={true}
          expandableRows={true}
          expandableRowsComponent={ExpandableRow}
          expandableRowsComponentProps={{ clinician }}
          pagination={true}
          keyField={'patientId'}
          defaultSortFieldId={1}
          defaultSortAsc={false}
        />
      </div>
    </ScheduleContextProvider>
  );
};
