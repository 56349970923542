import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { ACTIVATION } from 'Shared/types/events';
import { activatePatientHomeworkInvitation } from 'Shared/actions/patient';
import { setFlashMessage } from 'Shared/actions/service';
import { logEvent } from 'Shared/actions/event';
import { getUserDataNoAuth } from 'Shared/actions/user';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { FlashMessageType, User } from 'Shared/types/shared';
import { ActivatePatientHWInvitationForm } from 'Shared/types/forms';

export function useActivation() {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const queryParams = useQueryParams();
  const token = queryParams.get('token');
  const source = queryParams.get('source');
  const clinicianId = queryParams.get('source');
  const action = queryParams.get('clinicianAction');

  const [isLoading, setIsLoading] = useState(true);
  const [preAuthorizedUser, setPreAuthorizedUser] = useState<User>();
  const [isActivated, setIsActivated] = useState(false);

  const handleActivation = useCallback(
    async (formData: ActivatePatientHWInvitationForm) => {
      await activatePatientHomeworkInvitation({
        patientId: preAuthorizedUser.user.userId,
        clinicianId,
        patientToken: token,
        values: formData,
      })(dispatch);

      setIsActivated(true);
      setFlashMessage({
        message: 'Your account has been successfully set up.',
        type: FlashMessageType.SUCCESS,
        keepFor: 5,
      })(dispatch);
    },
    [activatePatientHomeworkInvitation, preAuthorizedUser, clinicianId, token, dispatch]
  );

  const fetchUserDataNoAuth = async (token: string, source: string) => {
    try {
      setIsLoading(true);
      const userInfo = (await getUserDataNoAuth(token)(dispatch)) as User;
      setPreAuthorizedUser(userInfo);

      if (userInfo.user.email && userInfo.user.acceptedEULA) {
        setIsActivated(true);
      }

      await logEvent({
        page: ACTIVATION.HW_INVITE,
        data: { source },
        userId: userInfo.user.userId,
      })(dispatch, store.getState.bind(store));
    } catch (e: unknown) {
      await logEvent({
        page: ACTIVATION.HW_INVITE,
        data: { source },
      })(dispatch, store.getState.bind(store));
      throw new Error(e.toString());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      void fetchUserDataNoAuth(token, source);
    }
  }, [token, source]);

  return {
    isLoading,
    preAuthorizedUser,
    isActivated,
    handleActivation,
    action,
  };
}
