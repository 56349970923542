import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { getBaseUrl } from 'Shared/utils';
import { defaultValidators } from 'Shared/validators';
import { asyncValidate } from 'Shared/components/form/asyncValidation';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { PasswordRequirements } from 'Shared/types/shared';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { PATIENT_SIGNUP_FORM_NAME } from '../../constants';

import sharedStyles from 'App/signup/wizard/shared.module.scss';

const eulaLabel = `I agree to the <a href="${
  getBaseUrl() + '/api/user/eula'
}" target="_blank" rel="noreferrer">terms of use</a>`;

interface AdvancedComponentProps {
  previousPage: () => void;
  totalSteps: number;
  passwordRequirements: PasswordRequirements;
  isInstitutionBasedSignup?: boolean;
}

const AdvancedComponent: FC<
  AdvancedComponentProps & InjectedFormProps<object, AdvancedComponentProps>
> = ({
  handleSubmit,
  previousPage,
  totalSteps,
  error,
  pristine,
  submitting,
  passwordRequirements,
  isInstitutionBasedSignup,
}) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.wizardPage}
      header={
        <>
          <ProgressBar currentStep={isInstitutionBasedSignup ? 1 : 2} totalSteps={totalSteps} />
          <h1 className="h1">
            Set up <b>your account</b>.
          </h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses="signupForm"
        actions={
          <>
            {!isInstitutionBasedSignup && (
              <CTUI.Button
                text="&larr; Back"
                variant="primary-inverted"
                customClasses={sharedStyles.button}
                onClick={previousPage}
                disabled={submitting}
                type="button"
              />
            )}
            <CTUI.Form.SubmitButton
              text="Next &rarr;"
              variant="primary"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="email"
          component={CTUI.Form.Email}
          type="email"
          label="Email address"
          validate={[defaultValidators.email.bind(defaultValidators)]}
          readOnly={isInstitutionBasedSignup}
          required
          autofocus
        />
        <Field
          name="careGiver"
          component={CTUI.Form.Checkbox}
          type="checkbox"
          label="This is a family member who will manage the account"
        />
        <Field
          name="password"
          component={CTUI.Form.Password}
          type="password"
          label="Create a password"
          required
          extraClass={isInstitutionBasedSignup ? 'withIndicator' : ''}
          passwordRequirements={passwordRequirements}
        />
        <Field
          name="acceptedEula"
          component={CTUI.Form.Checkbox}
          type="checkbox"
          label={eulaLabel}
          required
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Advanced = reduxForm<object, AdvancedComponentProps>({
  form: PATIENT_SIGNUP_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  asyncValidate,
  asyncBlurFields: ['email'],
})(AdvancedComponent);

export { Advanced };
