import React, { FC } from 'react';
import classNames from 'classnames';

import { type AudioComponentProps, useAudio } from './useAudio';

import sharedStyles from './shared.module.scss';
import styles from './audio.module.scss';

export const AudioComponent: FC<AudioComponentProps> = ({ stimulus, resourceUrl }) => {
  useAudio({ stimulus, resourceUrl });

  return <div className={classNames(sharedStyles.stimulus, styles.audio)} />;
};
