import React, { FC, useRef } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';

import type { EnhancedScheduledTaskType, ScheduledTaskTypeStub } from 'Shared/types/schedule';
import { TaskTypes } from 'Shared/constants';

import { TaskTypeItem } from './taskTypeItem';
import { DragItem } from './taskTypeItem/useTaskTypeItem';

import styles from './list.module.scss';

interface ActiveScheduleTasksListProps {
  scheduledTasksList: (EnhancedScheduledTaskType | ScheduledTaskTypeStub)[];
  moveScheduledTasks: (dragIndex: number, hoverIndex: number, item: DragItem) => void;
  deleteScheduledTask: (index: number) => void;
  adjustScheduledTask: (task: EnhancedScheduledTaskType | ScheduledTaskTypeStub) => void;
}

export const ActiveScheduleTasksList: FC<ActiveScheduleTasksListProps> = ({
  scheduledTasksList,
  moveScheduledTasks,
  deleteScheduledTask,
  adjustScheduledTask,
}) => {
  const sortableWrapperRef = useRef<HTMLUListElement>(null);

  const [, drop] = useDrop<DragItem, void>(
    {
      accept: [TaskTypes.TASK_TYPE],
      drop: (item: DragItem, monitor: DropTargetMonitor) => {
        if (!sortableWrapperRef.current) {
          return;
        }
        // This one checks if draggable item was already dropped by placing it into a sorting order.
        if (monitor.didDrop()) {
          return;
        }
        // Normally items are gonna be added to the sortable list of scheduled tasks.
        // This particular logic covers the case when that list is small and user drops items into a free area beneath it
        // as a result we want to place new item at the very bottom of that list
        moveScheduledTasks(scheduledTasksList.length + 1, scheduledTasksList.length + 1, item);
      },
    },
    [scheduledTasksList]
  );

  drop(sortableWrapperRef);

  return (
    <ul className={styles.activeScheduleTasks} ref={sortableWrapperRef}>
      {scheduledTasksList.map(
        (task: EnhancedScheduledTaskType | ScheduledTaskTypeStub, index: number) => {
          let key = 'id' in task ? task.id : task.temporaryId;
          if (!key && 'taskOrder' in task) {
            key = task.taskOrder;
          }
          return (
            <TaskTypeItem
              key={key}
              index={index}
              task={task}
              moveScheduledTasks={moveScheduledTasks}
              deleteScheduledTask={deleteScheduledTask}
              adjustScheduledTask={adjustScheduledTask}
            />
          );
        }
      )}
    </ul>
  );
};
