import React, { FC } from 'react';

import {
  PerformanceReportAssessment,
  PerformanceReportDomain,
  PerformanceReportTask,
} from 'Shared/types/shared';

import { ReportTabs } from './reportTabs';
import { ReportSections } from './reportSections';

import styles from './index.module.scss';

interface ReportDetailsProps {
  sections: {
    assessments?: PerformanceReportAssessment[];
    domains?: {
      [key: string]: PerformanceReportDomain;
    };
    tasks?: {
      [key: string]: PerformanceReportTask;
    };
  };
  canViewExpandedDashboard: boolean;
}

export const ReportDetails: FC<ReportDetailsProps> = ({ sections, canViewExpandedDashboard }) => {
  return (
    <div className={styles.reportDetails}>
      {Object.keys(sections).length ? (
        <>
          <ReportTabs sections={sections} />
          <ReportSections sections={sections} canViewExpandedDashboard={canViewExpandedDashboard} />
        </>
      ) : (
        <div className={styles.noData}>No report data available for the last 30 days</div>
      )}
    </div>
  );
};
