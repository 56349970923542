import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserMd } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { USER_TYPES } from 'Shared/types/shared';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { useUserTypeSelection } from './useUserTypeSelection';

import styles from './userTypeSelection.module.scss';

export const UserTypeSelection: FC = () => {
  const { handleClick } = useUserTypeSelection();

  return (
    <FloatingPage
      customClasses={styles.userTypeSelection}
      header={<h1 className="h1">Please tell us about yourself.</h1>}
    >
      <CTUI.Button
        variant="secondary"
        customClasses={styles.button}
        onClick={() => handleClick(USER_TYPES.PATIENT)}
      >
        <FontAwesomeIcon icon={faUser} className={styles.icon} />
        <h2 className={classNames('h2', styles.h2)}>Patient or Individual</h2>
        <h3 className="h3">For individuals to use Constant Therapy for personal rehabilitation</h3>
      </CTUI.Button>
      <h4 className={classNames('h4', styles.splitter)}>OR</h4>
      <CTUI.Button
        variant="primary"
        customClasses={styles.button}
        onClick={() => handleClick(USER_TYPES.CLINICIAN)}
      >
        <FontAwesomeIcon icon={faUserMd} className={styles.icon} />
        <h2 className={classNames('h2', styles.h2)}>Healthcare Professional</h2>
        <h3 className="h3">
          For healthcare professionals who provide rehabilitation services to patients
        </h3>
      </CTUI.Button>
    </FloatingPage>
  );
};
