import { SyntheticEvent, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';
import { VirtualElement } from '@popperjs/core';

export function usePopperMenu() {
  const launcherRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<Element | VirtualElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);
  const [isMenuShown, setIsMenuShown] = useState(false);
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 15],
          },
        },
      ],
    }
  );

  const toggleMenuVisibility = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsMenuShown(!isMenuShown);
  };

  useClickOutside(launcherRef, (e: MouseEvent) => {
    const popperClasses =
      popperElement &&
      popperElement.classList.toString() &&
      `.${popperElement.classList.toString().split(' ').join('.')}`;

    //We don't want to close menu if click happens when overlay is in place
    if (
      isMenuShown &&
      popperElement &&
      e.target instanceof HTMLElement &&
      !e.target.closest(popperClasses)
    ) {
      setIsMenuShown(false);
    }
  });

  return {
    launcherRef,
    setReferenceElement,
    isMenuShown,
    toggleMenuVisibility,
    popper: {
      ref: setPopperElement,
      style: popperStyles.popper,
      attributes: popperAttributes.popper,
    },
  };
}
