import { SELECT_PATIENT_TYPE, CLEAR_PATIENT_TYPE } from 'Shared/types/redux';
import { PATIENT_TYPES } from 'Shared/types/shared';

export default function (state = {}, action: { type: string; payload: PATIENT_TYPES }) {
  switch (action.type) {
    case SELECT_PATIENT_TYPE:
    case CLEAR_PATIENT_TYPE:
      return {
        ...state,
        patientType: action.payload,
      };
    default:
      return state;
  }
}
