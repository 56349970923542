import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

import { Spinner } from 'Shared/components/spinner';
import { USER_TYPES } from 'Shared/types/shared';

import { Meta } from './meta';
import { Success } from './success';
import { Error } from './error';
import { useDefaultActivation } from './useActivation';
import { WizardPatient } from './wizardPatient';

export const DefaultActivation: FC = () => {
  const {
    isLoading,
    successMessage,
    errorMessage,
    invitationData,
    redirectParams,
    handlePatientActivation,
  } = useDefaultActivation();

  if (isLoading) {
    return <Spinner />;
  }

  if (invitationData) {
    if (invitationData.redirect === 'confirmation') {
      return (
        <>
          <Meta />
          <Success successMessage={successMessage} userType={invitationData.userType} />
        </>
      );
    }

    if (invitationData.userType === USER_TYPES.CLINICIAN) {
      return <Redirect to={{ pathname: '/signup', search: `?${redirectParams}` }} />;
    }

    return (
      <>
        <Meta />
        <WizardPatient
          handlePatientActivation={handlePatientActivation}
          activationData={{
            email: invitationData.email,
            username: invitationData.email,
            institutionId: invitationData.data.institutionId,
          }}
        />
      </>
    );
  }

  return <Error errorMessage={errorMessage} />;
};
