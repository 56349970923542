import { useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import md5 from 'md5';

import { ClinicianDashboard, User } from 'Shared/types/shared';

export interface ViewPatientProps {
  dashboardData: ClinicianDashboard;
}

interface ViewPatientParams {
  patientId?: string;
}

export function useViewPatient({ dashboardData }: ViewPatientProps) {
  const { path } = useRouteMatch();
  const { patientId } = useParams<ViewPatientParams>();
  const patient = useMemo(() => {
    const matchedPatient = dashboardData.Patients.find((patientBrief) => {
      return md5(patientBrief.patientId.toString()) === patientId;
    });

    return {
      user: {
        userId: matchedPatient.patientId,
        username: matchedPatient.patientUsername,
        email: matchedPatient.email,
      },
    } as User;
  }, [patientId, dashboardData.Patients]);

  return {
    path,
    patient,
  };
}
