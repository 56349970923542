import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';
import moment from 'moment-timezone';

import { StripePlanDiscountUnit } from 'Shared/types/shared';

import { PlanProps, usePlan } from './usePlan';

import styles from './plan.module.scss';

const DISCOUNT_AMOUNT_CONVERSION = {
  [StripePlanDiscountUnit.PERCENTAGE]: '%',
  [StripePlanDiscountUnit.USD]: '$',
};

export const Plan: FC<PlanProps> = ({ plan, token, emailOrUsername, coupon }) => {
  const { isLoading, planSelectionHandler } = usePlan({
    plan,
    token,
    emailOrUsername,
    coupon,
  });

  return (
    <div className={styles.plan} aria-disabled={isLoading}>
      <h3 className={styles.title}>{plan.title}</h3>
      <div className={styles.body}>
        {plan.discountedAmount && (
          <h4 className={classNames('h4', styles.oldPrice)}>{plan.amountDisplay}</h4>
        )}
        <h1 className={classNames('h1', styles.price)}>
          {plan.discountedAmountDisplay || plan.amountDisplay}
        </h1>
        {plan.discountDurationMonths && (
          <p className={styles.discountInfo}>
            {plan.discountUnit === StripePlanDiscountUnit.USD
              ? `${DISCOUNT_AMOUNT_CONVERSION[plan.discountUnit]}${plan.discountAmount}`
              : `${plan.discountAmount}${DISCOUNT_AMOUNT_CONVERSION[plan.discountUnit]}`}{' '}
            off for {moment.duration(plan.discountDurationMonths, 'months').humanize()}
          </p>
        )}
        <p className={styles.description}>{plan.savingsDescription || plan.productDescription}</p>
        <CTUI.Form.SubmitButton
          text="Get started"
          isSubmitting={isLoading}
          isPristine={false}
          customClasses={styles.button}
          onClick={planSelectionHandler}
        />
      </div>
    </div>
  );
};
