import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { STIMULUS_CONTENT_TYPES } from 'Shared/types/shared';

import { type ChoiceProps, useChoice } from './useChoice';
import { TextComponent } from './stimulus/text';
import { ImageComponent } from './stimulus/image';

import styles from './index.module.scss';

export const Choice: FC<ChoiceProps> = ({ index, choice, resourceUrl }) => {
  const { drag, isDragging, handleClick } = useChoice({ index, choice, resourceUrl });

  return (
    <li
      className={classNames(styles.item, {
        [styles.isDragging]: isDragging,
        [styles.isPlaced]: choice.isPlaced,
      })}
      onClick={handleClick}
      ref={drag}
    >
      <FontAwesomeIcon icon={faGripVertical} className={styles.icon} />
      {choice.stimulus.contentType === STIMULUS_CONTENT_TYPES.TEXT && (
        <TextComponent stimulus={choice.stimulus} />
      )}
      {choice.stimulus.contentType === STIMULUS_CONTENT_TYPES.IMAGE && (
        <ImageComponent stimulus={choice.stimulus} resourceUrl={resourceUrl} />
      )}
    </li>
  );
};
