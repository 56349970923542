import React, { FC } from 'react';

import { USERS_LEADSOURCES } from 'Shared/constants';

import { useEmptyReport, EmptyReportProps } from './useEmptyReport';
import { UnderSurveillance } from './underSurveillance';
import { FlintLed } from './flintLed';
import { Default } from './default';

export const EmptyReport: FC<EmptyReportProps> = ({ surveillanceMode }) => {
  const { downloadHandler, displayName, authUser } = useEmptyReport({ surveillanceMode });

  if (surveillanceMode) {
    return <UnderSurveillance downloadHandler={downloadHandler} />;
  }

  if (authUser.user.leadSourceDetails === USERS_LEADSOURCES.flint) {
    return <FlintLed displayName={displayName} />;
  }

  return <Default downloadHandler={downloadHandler} displayName={displayName} />;
};
