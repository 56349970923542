import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Main } from './main';
import { Success } from './success';
import { Fail } from './fail';

export const Subscribe: FC = (props: any) => {
  return (
    <Switch>
      <Route exact path="/subscribe/">
        <Main {...props} />
      </Route>
      <Route exact path="/subscribe/success">
        <Success {...props} />
      </Route>
      <Route exact path="/subscribe/fail">
        <Fail {...props} />
      </Route>
    </Switch>
  );
};
