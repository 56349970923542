import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './spinner.module.scss';

export const Spinner: FC = () => {
  return (
    <div className={styles['sk-folding-cube']}>
      <div className={classNames(styles['sk-cube'], styles['sk-cube1'])} />
      <div className={classNames(styles['sk-cube'], styles['sk-cube2'])} />
      <div className={classNames(styles['sk-cube'], styles['sk-cube4'])} />
      <div className={classNames(styles['sk-cube'], styles['sk-cube3'])} />
    </div>
  );
};
