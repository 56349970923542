import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { InstitutionUser } from 'Shared/types/shared';

import { ActionsCellMenu } from './menu';
import { useActionCell } from './useActionCell';

import styles from './index.module.scss';

interface ActionsCellProps extends InstitutionUser {
  institutionId: number;
}

export const ActionsCell: FC<ActionsCellProps> = (props) => {
  const { cellRef, setMenuTriggerElement, popper, isMenuShown, toggleMenu, hideMenu } =
    useActionCell();

  return (
    <div className={styles.actionsCell} ref={cellRef}>
      <span ref={setMenuTriggerElement} onClick={toggleMenu} className={styles.menuTriggerElement}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </span>
      {isMenuShown && <ActionsCellMenu {...props} popper={popper} hideMenu={hideMenu} />}
    </div>
  );
};
