import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { User } from 'Shared/types/shared';

import { Meta } from './meta';
import { Main } from './main';
import { Success } from './success';
import { Fail } from './fail';

interface PaymentUpdateProps {
  authUser: User;
}

export const PaymentUpdate: FC<PaymentUpdateProps> = ({ authUser }) => {
  return (
    <>
      <Meta />
      <Switch>
        <Route exact path="/payment_update/">
          <Main authUser={authUser} />
        </Route>
        <Route exact path="/payment_update/success">
          <Success />
        </Route>
        <Route exact path="/payment_update/fail">
          <Fail />
        </Route>
      </Switch>
    </>
  );
};
