import React, { FC } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faEraser, faPen } from '@fortawesome/free-solid-svg-icons';

import sketchBackground from 'Shared/src/themes/2023/images/therapyTasks/lines.svg';

import { useSketchPad } from './useSketchPad';

import styles from './index.module.scss';

export const SketchPad: FC = () => {
  const {
    canvasRef,
    strokeColor,
    handleStrokeColorChange,
    eraseMode,
    handlePenClick,
    handleEraserClick,
    handleClearClick,
  } = useSketchPad();

  return (
    <div className={styles.sketchPad}>
      <ReactSketchCanvas
        ref={canvasRef}
        width="350px"
        height="400px"
        strokeWidth={3}
        strokeColor={strokeColor}
        backgroundImage={sketchBackground as string}
        className={styles.canvas}
      />
      <div className={styles.controls}>
        <input
          type="color"
          value={strokeColor}
          onChange={handleStrokeColorChange}
          className={styles.button}
        />
        <button disabled={!eraseMode} onClick={handlePenClick} className={styles.button}>
          <FontAwesomeIcon icon={faPen} />
        </button>
        <button disabled={eraseMode} onClick={handleEraserClick} className={styles.button}>
          <FontAwesomeIcon icon={faEraser} />
        </button>
        <button onClick={handleClearClick} className={styles.button}>
          <FontAwesomeIcon icon={faArrowsRotate} />
        </button>
      </div>
    </div>
  );
};
