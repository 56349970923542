import React, { FC } from 'react';

import { Spinner } from 'Shared/components/spinner';
import { User, USER_TYPES } from 'Shared/types/shared';

import { SubscriptionButton } from './subscriptionButton';
import { useSubscriptionInfo } from './useSubscriptionInfo';

interface SubscriptionInfoProps {
  authUser: User;
}

export const SubscriptionInfo: FC<SubscriptionInfoProps> = ({ authUser }) => {
  const { isLoading, subscriptionMessage, subscribeButton, handleReactivateSubscription } =
    useSubscriptionInfo();

  if (isLoading) {
    return <Spinner />;
  }

  if (authUser.user.type === USER_TYPES.CLINICIAN) {
    return (
      <p>
        Thank you for choosing Constant Therapy. You can use the application with an unlimited
        number patients with your free Clinician Account.
      </p>
    );
  }

  if (subscriptionMessage) {
    return (
      <>
        <p dangerouslySetInnerHTML={{ __html: subscriptionMessage }} />
        <SubscriptionButton
          subscribeButton={subscribeButton}
          handleReactivateSubscription={handleReactivateSubscription}
        />
      </>
    );
  }

  return (
    <p>
      We were unable to determine your current subscription status. Please{' '}
      <a href="/contact-us">contact</a> our support team for additional information.
    </p>
  );
};
