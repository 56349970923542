import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Welcome to your Clinician Dashboard, where you can 
  manage existing patients, connect with new ones, adjust homework schedules, review progress 
  reports, and view session notes--all in one place. Seamlessly manage your professional 
  commitments to optimize patient care and reduce burnout.`;
  return (
    <Helmet>
      <title>Clinician Dashboard | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
