import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { HTML_HEADER_ID } from 'Shared/constants';

import styles from './dashboardNavigation.module.scss';

export const ADMIN_DASHBOARD_ROUTES = [
  {
    title: 'Enterprise Dashboard',
    path: '/enterprise-dashboard',
    isExact: true,
  },
  {
    title: 'Users',
    path: '/enterprise-dashboard/users',
    isExact: true,
  },
  {
    title: 'Institution Settings',
    path: '/enterprise-dashboard/institution',
    isExact: true,
  },
  // {
  //   title: 'Subscription',
  //   path: '/dashboard/subscription',
  //   isExact: true,
  // },
];

const mountingPoint = document.getElementById(HTML_HEADER_ID);

export const DashboardNavigation: FC = () => {
  const { pathname } = useLocation();
  const navigationClassnames = classNames(
    styles.dashboardNavigation,
    styles.dashboardNavigationAdmin
  );

  if (mountingPoint) {
    return ReactDOM.createPortal(
      <nav className={navigationClassnames} aria-label="Breadcrumb">
        <ul className={styles.breadcrumbs}>
          {ADMIN_DASHBOARD_ROUTES.map(({ title, path, isExact }) => {
            if (pathname.includes(path)) {
              if (pathname === path) {
                return (
                  <li aria-current="page" key={path}>
                    <CTUI.Font.Text2>{title}</CTUI.Font.Text2>
                  </li>
                );
              }

              return (
                <li key={path}>
                  <CTUI.Font.Text2>
                    <NavLink to={path} exact={isExact}>
                      {title}
                    </NavLink>
                  </CTUI.Font.Text2>
                </li>
              );
            }
          })}
        </ul>
      </nav>,
      mountingPoint
    );
  }

  return <></>;
};
