import React, { FC } from 'react';

import { type TaskViewNumberProblemSolving } from 'Shared/types/shared';

import { Stimulus } from '../../stimulus';
import { type TaskTypeProps } from '../../types';

import { Controls } from './controls';

import styles from './index.module.scss';

export const NumberProblemSolving: FC<TaskTypeProps<TaskViewNumberProblemSolving>> = ({
  task,
  isAnswered,
  handleAnswer,
  isInitialAudioInstructionsShouldBePlayed,
}) => {
  return (
    <div className={styles.numberProblemSolving}>
      <Stimulus task={task} />
      <Controls showCalculator={task.showCalculator} showScratchpad={task.showScratchpad} />
    </div>
  );
};
