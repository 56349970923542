import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { TaskType, TaskTypeCategory } from 'Shared/types/task';

import { CategoryItem } from './categoryItem';
import { TaskTypeItem } from './taskTypeItem';

import styles from './index.module.scss';

interface AllTasksProps {
  tasksHierarchy: TaskTypeCategory;
  selectedItems?: TaskType[];
  toggleItemSelection?: (item: TaskType) => void;
}

export const AllTasks: FC<AllTasksProps> = ({
  tasksHierarchy,
  selectedItems,
  toggleItemSelection,
}) => {
  return (
    <ul className={styles.allTasks}>
      {traverseTasksHierarchy(tasksHierarchy.children, selectedItems, toggleItemSelection)}
    </ul>
  );
};

function traverseTasksHierarchy(
  taskTypeCategories: TaskTypeCategory[] | TaskType[],
  selectedItems?: TaskType[],
  toggleItemSelection?: (item: TaskType) => void
) {
  return taskTypeCategories.map((taskTypeCategory: TaskTypeCategory | TaskType) => {
    if ('children' in taskTypeCategory) {
      return (
        <CategoryItem key={taskTypeCategory.systemName} taskTypeCategory={taskTypeCategory}>
          <ul>
            {traverseTasksHierarchy(taskTypeCategory.children, selectedItems, toggleItemSelection)}
          </ul>
        </CategoryItem>
      );
    }

    return (
      <TaskTypeItem
        key={uuidv4()}
        index={taskTypeCategory.typeId}
        task={taskTypeCategory}
        selectedItems={selectedItems}
        toggleItemSelection={toggleItemSelection}
      />
    );
  });
}
