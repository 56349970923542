import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Dispatch } from 'redux';

import { resetOtp } from 'Shared/actions/auth';
import { setFlashMessage } from 'Shared/actions/service';
import { FlashMessageType } from 'Shared/types/shared';
import { LOGIN_FORM_NAME, LoginForm } from 'Shared/types/forms';

export function useOtp() {
  const dispatch: Dispatch = useDispatch();

  const initialTimeout = 60;
  const [timeoutValue, setTimeoutValue] = useState(initialTimeout);
  const [isOtpReset, setIsOtpReset] = useState(false);
  const loginFormData = useSelector(getFormValues(LOGIN_FORM_NAME)) as LoginForm;

  const handleResetOtp = (e: SyntheticEvent) => {
    e.preventDefault();
    void sendOtpReset();
  };

  const sendOtpReset = async () => {
    try {
      await resetOtp({ userId: loginFormData.userId, otpResetToken: loginFormData.otpResetToken })(
        dispatch
      );
      setIsOtpReset(true);
    } catch (e: unknown) {
      setFlashMessage({
        message:
          'Unable to resend verification code. Please start over by entering username' +
          ' and password.',
        type: FlashMessageType.ERROR,
        keepFor: 5,
      })(dispatch);
    }
  };

  useEffect(() => {
    let timeoutInterval: ReturnType<typeof setInterval> = null;

    if (!isOtpReset) {
      return clearInterval(timeoutInterval);
    }

    timeoutInterval = setInterval(() => {
      setTimeoutValue((current) => current - 1);
    }, 1000);

    return () => {
      clearInterval(timeoutInterval);
    };
  }, [isOtpReset]);

  useEffect(() => {
    if (timeoutValue === 0) {
      setIsOtpReset(false);
      setTimeoutValue(initialTimeout);
    }
  }, [timeoutValue]);

  return {
    isOtpReset,
    handleResetOtp,
    timeoutValue,
  };
}
