import React, { FC } from 'react';
import 'react-dates/initialize';
import { DayPicker } from 'react-dates';

import { Spinner } from 'Shared/components/spinner';

import { CalendarDay } from './calendarDay';
import { CalendarInfo } from './calendarInfo';
import { ErrorCalendar } from './errorCalendar';
import { useCalendar, CalendarProps } from './useCalendar';

import styles from './index.module.scss';

export const Calendar: FC<CalendarProps> = ({ patient }) => {
  const { isLoading, calendar, calendarError, initialVisibleMonth, numberOfMonthsToDisplay } =
    useCalendar({ patient });

  if (isLoading) {
    return <Spinner />;
  }

  if (calendarError) {
    return <ErrorCalendar calendarError={calendarError} />;
  }

  //The wrapper-block is needed to create custom styling without breaking `DayPicker's` responsive styles and logic
  return (
    <div className={styles.calendar}>
      <DayPicker
        noBorder={true}
        daySize={47}
        numberOfMonths={numberOfMonthsToDisplay}
        initialVisibleMonth={initialVisibleMonth}
        hideKeyboardShortcutsPanel={true}
        renderDayContents={(day) => <CalendarDay day={day} calendar={calendar} />}
        renderCalendarInfo={() => <CalendarInfo />}
      />
    </div>
  );
};
