import { useState, useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { getUnsubscribeReasons } from 'Shared/actions/user';
import { cancelSubscription } from 'Shared/actions/subscription';
import { UnsubscribeReasons } from 'Shared/types/shared';

import { UnsubscribeForm } from '../constants';

export function useStart() {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [unsubscribeReasons, setUnsubscribeReasons] = useState<UnsubscribeReasons>();

  const handleUnsubscribe = async (formData: UnsubscribeForm) => {
    await cancelSubscription({
      reason: formData.reasonOTHER || formData.reason,
    })(dispatch);
    history.push('/unsubscribe/guide');
  };

  const fetchUnsubscribeReasons = async () => {
    try {
      const unsubscribeReasons = (await getUnsubscribeReasons()(
        dispatch,
        store.getState.bind(store)
      )) as UnsubscribeReasons;
      setUnsubscribeReasons(unsubscribeReasons);
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchUnsubscribeReasons();
  }, []);

  return {
    isLoading,
    unsubscribeReasons,
    handleUnsubscribe,
  };
}
