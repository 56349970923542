import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
// eslint-disable-next-line max-len
import { DashboardNavigation as PatientNavigation } from 'App/dashboard/patientDashboard/dashboardNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { HTML_HEADER_ID } from 'Shared/constants';

import { useDashboardNavigation, DashboardNavigationProps } from './useDashboardNavigation';

import styles from './index.module.scss';

const mountingPoint = document.getElementById(HTML_HEADER_ID);

export const DashboardNavigation: FC<DashboardNavigationProps> = ({ dashboardData }) => {
  const { shouldHideNavigation, isAddingPatient, patientId, displayName } = useDashboardNavigation({
    dashboardData,
  });

  if (shouldHideNavigation) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <nav className={styles.dashboardNavigation}>
        <ul className={styles.breadcrumbs} aria-label="Breadcrumb">
          <li>
            <NavLink to={'/dashboard'}>My patients</NavLink>
          </li>
          <li className={styles.separator}>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>
          <li aria-current="page">{isAddingPatient ? 'Add patient' : displayName}</li>
        </ul>
      </nav>
      {!isAddingPatient && <PatientNavigation patientId={patientId} surveillanceMode={true} />}
    </>,
    mountingPoint
  );
};
