import queryString from 'query-string';
import { Dispatch } from 'redux';

import { fetchWrapper } from 'Shared/fetchWrapper';
import { GET_DASHBOARD_DATA, GET_DASHBOARD_PATIENT_EXPANSION } from 'Shared/types/redux';
import { RootState } from 'Shared/types/shared';

import { getBaseUrl } from '../utils';

export const getDashboardData = () => async (dispatch: Dispatch) => {
  const formattedParams = queryString.stringify(
    {
      token: localStorage.getItem('accessToken'),
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  return await fetchWrapper.get(
    `${getBaseUrl({ apiSection: 'dashboard' })}/api/clinician/patientdashboard?${formattedParams}`,
    {
      dispatch,
      types: GET_DASHBOARD_DATA,
    }
  );
};

export const getDashboardPatientExpansion =
  (userId: number, patientId: number) =>
  async (dispatch: Dispatch, getState: () => RootState, forceCache = false) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
        patientId,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    if (
      !forceCache &&
      getState().clinician &&
      getState().clinician.patientsExpansions &&
      getState().clinician.patientsExpansions[patientId]
    ) {
      dispatch({ type: GET_DASHBOARD_PATIENT_EXPANSION.start });

      dispatch({
        type: GET_DASHBOARD_PATIENT_EXPANSION.success,
        payload: {
          [patientId]: getState().clinician.patientsExpansions[patientId],
        },
      });

      return Promise.resolve(getState().clinician.dashboardData);
    }

    return await fetchWrapper
      .get(
        `${getBaseUrl({
          apiSection: 'dashboard',
        })}/api/clinician/patientdashboard/expansion?${formattedParams}`,
        {
          dispatch,
          types: GET_DASHBOARD_PATIENT_EXPANSION,
        }
      )
      .then(
        (result) => {
          dispatch({
            type: GET_DASHBOARD_PATIENT_EXPANSION.success,
            payload: {
              [patientId]: result,
            },
          });
          return Promise.resolve(result);
        },
        (error: unknown) => {
          dispatch({
            type: GET_DASHBOARD_PATIENT_EXPANSION.fail,
            payload: error,
          });
          return Promise.reject(error);
        }
      );
  };
