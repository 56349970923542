import React, {
  CSSProperties,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
} from 'react';

import { usePopperMenu } from './usePopperMenu';

export interface LauncherProps {
  launcherRef: React.Ref<any>;
  setReferenceElement: React.Ref<any>;
  isMenuShown: boolean;
  toggleMenuVisibility: (e: SyntheticEvent) => void;
}

export interface DropdownProps {
  popper: { ref: Dispatch<SetStateAction<HTMLElement>>; style: CSSProperties; attributes: object };
  toggleMenuVisibility: (e: SyntheticEvent) => void;
}

interface PopperMenuProps {
  renderLauncher: (launcherProps: LauncherProps) => ReactNode;
  renderMenu: (dropdownProps: DropdownProps) => ReactNode;
}

export const PopperMenu: FC<PopperMenuProps> = ({ renderLauncher, renderMenu }) => {
  const { launcherRef, setReferenceElement, popper, isMenuShown, toggleMenuVisibility } =
    usePopperMenu();

  return (
    <>
      {renderLauncher({ launcherRef, setReferenceElement, isMenuShown, toggleMenuVisibility })}
      {isMenuShown && renderMenu({ popper, toggleMenuVisibility })}
    </>
  );
};
