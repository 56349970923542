import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { Meta } from './meta';
import { HowAreYourTasksWizard } from './wizard';

export const Survey: FC = () => {
  return (
    <Switch>
      <Route exact path="/survey/campaign/how_are_your_tasks">
        <Meta />
        <HowAreYourTasksWizard {...queryString.parse(document.location.search)} />
      </Route>
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};
