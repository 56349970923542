import { useEffect } from 'react';

import styles from './index.module.scss';

export function useMainLayout() {
  useEffect(() => {
    document.body.classList.add(styles.mainLayout);
    return () => {
      document.body.classList.remove(styles.mainLayout);
    };
  }, []);
}
