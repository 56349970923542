import React, { FC } from 'react';

import styles from './chartScale.module.scss';

export const ChartScale: FC = () => {
  return (
    <div className={styles.chartScale}>
      <span className={styles.begin}>0</span>
      <span className={styles.end}>100</span>
    </div>
  );
};
