import React, {
  ChangeEvent,
  createContext,
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import {
  AccuracyLatency,
  ColumnType,
} from 'App/dashboard/patientDashboard/detailedPerformance/constants';
import { Moment } from 'moment-timezone';

import { DPRRow, User } from 'Shared/types/shared';
import { DetailedPerformanceReportResponse } from 'Shared/types/api';
import { getDetailedPerformanceReport } from 'Shared/actions/patient';

interface DetailedReportContextProps {
  reportData?: DetailedPerformanceReportResponse;
  isLoading: boolean;
  accuracyLatencyToggle: AccuracyLatency;
  handleAccuracyLatencyChange: () => void;
  columnType: ColumnType;
  handleColumnTypeChange: (e: ChangeEvent) => void;
  dateRange: string;
  handleDateRangeChange: (startDate: Moment, endDate: Moment) => void;
  tasksSelectedList: (DPRRow & { selected: boolean })[];
  handleTasksSelectedListChange: (
    affectedTaskTypeSystemNamesLevels: string[]
  ) => (e: SyntheticEvent) => void;
}

export const DetailedPerformanceContext = createContext<DetailedReportContextProps>(null);

interface DetailedPerformanceProviderProps {
  patient: User;
  children: ReactNode;
}

export const DetailedPerformanceProvider: FC<DetailedPerformanceProviderProps> = ({
  patient,
  children,
}) => {
  const dispatch: Dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [reportData, setReportData] = useState<DetailedPerformanceReportResponse>();
  const [accuracyLatencyToggle, setAccuracyLatencyToggle] = useState<AccuracyLatency>(
    AccuracyLatency.accuracy
  );
  const [columnType, setColumnType] = useState<ColumnType>(ColumnType.bySchedule);
  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>();
  const [dateRange, setDateRange] = useState<string>('Since Therapy Start');
  const [tasksSelectedList, setTasksSelectedList] = useState<(DPRRow & { selected: boolean })[]>();

  const handleAccuracyLatencyChange = () => {
    setAccuracyLatencyToggle((prevState) =>
      prevState === AccuracyLatency.accuracy ? AccuracyLatency.latency : AccuracyLatency.accuracy
    );
  };

  const handleColumnTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setColumnType(e.target.value as ColumnType);
  };

  const handleDateRangeChange = (startDate: Moment, endDate: Moment) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setDateRange(`${startDate.format('MM/DD/YY')}-${endDate.format('MM/DD/YY')}`);
  };

  const handleTasksSelectedListChange = (affectedTaskTypeSystemNamesLevels: string[]) => {
    return () => {
      setTasksSelectedList((prevState) => {
        return prevState.map((task) => {
          if (
            affectedTaskTypeSystemNamesLevels.includes(
              `${task.taskTypeSystemName}${task.taskLevel}`
            )
          ) {
            return { ...task, selected: !task.selected };
          }
          return task;
        });
      });
    };
  };

  const fetchDetailedPerformanceReport = async ({
    columnType,
    startDate,
    endDate,
  }: {
    columnType: ColumnType;
    startDate: Moment;
    endDate: Moment;
  }) => {
    setIsLoading(true);
    try {
      const reportResponse = await getDetailedPerformanceReport({
        patientId: patient.user.userId,
        columnType,
        startDate: startDate?.format('X'),
        endDate: endDate?.format('X'),
      })(dispatch);
      setReportData(reportResponse);
      setTasksSelectedList(
        reportResponse.table.rowList.map((row) => {
          return { ...row, selected: true };
        })
      );
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchDetailedPerformanceReport({ columnType, startDate, endDate });
  }, [patient, columnType, startDate, endDate]);

  return (
    <DetailedPerformanceContext.Provider
      value={{
        isLoading,
        reportData,
        accuracyLatencyToggle,
        handleAccuracyLatencyChange,
        columnType,
        handleColumnTypeChange,
        dateRange,
        handleDateRangeChange,
        tasksSelectedList,
        handleTasksSelectedListChange,
      }}
    >
      {children}
    </DetailedPerformanceContext.Provider>
  );
};
