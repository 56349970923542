import React, { FC } from 'react';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { NoScheduleProps, useNoSchedule } from './useNoSchedule';

import styles from './noSchedule.module.scss';

export const NoSchedule: FC<NoScheduleProps> = ({ moveScheduledTasks }) => {
  const { isPortrait } = useMobileOrientation();
  const { noScheduleRef, isOver } = useNoSchedule({ moveScheduledTasks });
  const blockClasses = classNames(styles.noSchedule, { [styles.isOver]: isOver });

  return (
    <div className={blockClasses} ref={noScheduleRef}>
      <CTUI.Font.H2 customClasses={styles.title}>No Homework</CTUI.Font.H2>
      {isMobileOnly && isPortrait ? (
        <CTUI.Font.Text customClasses={styles.description}>
          Use <b>Add more tasks</b> button to choose tasks and create homework for this client.
        </CTUI.Font.Text>
      ) : (
        <CTUI.Font.Text customClasses={styles.description}>
          Drag&apos;n&apos;Drop <b>task types</b> from the left panel to
          <br />
          create homework for this client.
        </CTUI.Font.Text>
      )}
    </div>
  );
};
