import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { USER_TYPES } from 'Shared/types/shared';

import { InvitationForm } from './invitationForm';
import { useInvitationPopup } from './useInvitationPopup';

import type { InvitationFormShape } from './constants';

import styles from './invitationPopup.module.scss';

interface InvitationPopupProps {
  submitHandler: (formData: InvitationFormShape) => Promise<any>;
  hidePopup: () => void;
  institutionId: number;
}

export const InvitationPopup: FC<InvitationPopupProps> = ({
  submitHandler,
  hidePopup,
  institutionId,
}) => {
  const { modalRef } = useInvitationPopup({ hidePopup });

  return (
    <CTUI.Modal
      ref={modalRef}
      id={styles.invitationPopup}
      closeButtonHandler={hidePopup}
      header={
        <h1 className={classNames('h1', styles.title)}>
          <FontAwesomeIcon icon={faUserPlus} className={styles.icon} />
          Add new user
        </h1>
      }
    >
      <InvitationForm
        onSubmit={submitHandler}
        initialValues={{ institutionId, type: USER_TYPES.CLINICIAN }}
      />
    </CTUI.Modal>
  );
};
