import { useContext, useEffect, useState } from 'react';

import { STIMULUS_CONTENT_TYPES, TaskViewChoice, TaskViewPlaceholder } from 'Shared/types/shared';
import { AudioContext } from 'Shared/context/audioContext';
import correctSound from 'Shared/src/themes/2023/sounds/correct_answer.mp3';
import wrongSound from 'Shared/src/themes/2023/sounds/wrong_answer.mp3';

import { AnswerResult } from '../../../useTaskView';

import type { EnhancedChoice, EnhancedPlaceholder, ItemShape } from './types';

interface UseControlsProps {
  choices: TaskViewChoice[];
  placeholders: TaskViewPlaceholder[];
  handleAnswer: (result: AnswerResult) => void;
  resourceUrl: string;
}

interface UseControlsShape {
  itemsList: EnhancedChoice[];
  placeholdersList: EnhancedPlaceholder[];
  handleDrop: (index: number, item: ItemShape) => void;
}

export function useControls({
  choices,
  placeholders,
  handleAnswer,
  resourceUrl,
}: UseControlsProps): UseControlsShape {
  const { playAudioSequence, stopAudioSequence } = useContext(AudioContext);

  const [itemsList, setItemsList] = useState<EnhancedChoice[]>(enhanceChoices(choices));
  const [placeholdersList, setPlaceholdersList] = useState<EnhancedPlaceholder[]>(
    enhancePlaceholders(placeholders, resourceUrl)
  );

  const handleDrop = (placeholderIndex: number, item: ItemShape) => {
    stopAudioSequence();

    if (placeholdersList[placeholderIndex].correctValue === item.name) {
      setItemsList((prevState) => {
        return Object.assign([], prevState, {
          [item.index]: { ...prevState[item.index], isPlaced: true },
        });
      });
      setPlaceholdersList((prevState) => {
        return Object.assign([], prevState, {
          [placeholderIndex]: {
            ...prevState[placeholderIndex],
            isOccupied: true,
            contentType: item.contentType,
            contentValue: item.contentValue,
          },
        });
      });
      playAudioSequence({ audioElementsList: [new Audio(correctSound as string)] });
    } else {
      playAudioSequence({ audioElementsList: [new Audio(wrongSound as string)] });
    }
  };

  useEffect(() => {
    setItemsList(enhanceChoices(choices));
    setPlaceholdersList(enhancePlaceholders(placeholders, resourceUrl));
  }, [choices, placeholders, resourceUrl]);

  useEffect(() => {
    if (isEveryPlaceholderFilledCorrectly(placeholdersList)) {
      handleAnswer(AnswerResult.CORRECT);
    }
  }, [placeholdersList]);

  return {
    itemsList,
    placeholdersList,
    handleDrop,
  };
}

function enhanceChoices(choices: TaskViewChoice[]) {
  return choices.map((choice) => ({ ...choice, isPlaced: false }));
}

function enhancePlaceholders(placeholders: TaskViewPlaceholder[], resourceUrl: string) {
  return placeholders.map((placeholder) => ({
    ...placeholder,
    isOccupied: false,
    ...(placeholder.prefilledChoice && {
      contentType: placeholder.prefilledChoice.stimulus.contentType,
      contentValue:
        placeholder.prefilledChoice.stimulus.contentType === STIMULUS_CONTENT_TYPES.IMAGE
          ? resourceUrl + placeholder.prefilledChoice.stimulus.imagePath
          : placeholder.prefilledChoice.stimulus.text,
    }),
  }));
}

function isEveryPlaceholderFilledCorrectly(placeholdersList: EnhancedPlaceholder[]) {
  return placeholdersList
    .filter((placeholder) => placeholder.correctValue)
    .every((placeholder) => placeholder.isOccupied); //This flag is guaranteed to be set only when the correct value is in place
}
