import { useState } from 'react';

export function useTherapy() {
  const [scheduleId, setScheduleId] = useState<number>();
  const [activeTaskIndex, setActiveTaskIndex] = useState(0);

  return {
    scheduleId,
    setScheduleId,
    activeTaskIndex,
    setActiveTaskIndex,
  };
}
