import React, { FC, RefObject, SyntheticEvent, useState } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { EnhancedScheduledTaskType, ScheduledTaskTypeStub } from 'Shared/types/schedule';

import type { Identifier } from 'dnd-core';

import styles from './index.module.scss';

interface AdjustViewProps {
  task: EnhancedScheduledTaskType | ScheduledTaskTypeStub;
  previewRef: RefObject<HTMLLIElement>;
  taskTypeItemClasses: string;
  handlerId: Identifier;
  handleConfirmAdjustmentsButtonClick: (
    task: EnhancedScheduledTaskType | ScheduledTaskTypeStub
  ) => void;
  handleDiscardAdjustmentsButtonClick: (e: SyntheticEvent) => void;
}

export const AdjustView: FC<AdjustViewProps> = ({
  task,
  previewRef,
  taskTypeItemClasses,
  handlerId,
  handleConfirmAdjustmentsButtonClick,
  handleDiscardAdjustmentsButtonClick,
}) => {
  const [formData, setFormData] = useState({ ...task });
  const handleFormSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    handleConfirmAdjustmentsButtonClick(formData);
  };

  return (
    <li ref={previewRef} className={taskTypeItemClasses} data-handler-id={handlerId}>
      <CTUI.Form.Form
        handleSubmit={handleFormSubmit}
        actions={
          <div className={styles.adjustConfirmation}>
            <span>Update?</span>
            <ul className={styles.actions}>
              <li className={styles.action}>
                <button type="submit">Yes</button>
              </li>
              <li className={styles.action}>
                <a href="#" onClick={handleDiscardAdjustmentsButtonClick}>
                  No
                </a>
              </li>
            </ul>
          </div>
        }
      >
        <div className={styles.formBody}>
          <div className={styles.input}>
            <label htmlFor="taskLevel">Level</label>
            <select
              name="taskLevel"
              value={formData.taskLevel}
              onChange={(e) => setFormData({ ...formData, taskLevel: parseInt(e.target.value) })}
            >
              {Array.from({ length: task.maxLevel }, (element, index) => {
                return (
                  <option value={index + 1} key={index + 1}>
                    {index + 1}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={styles.input}>
            <label htmlFor="taskCount">Times</label>
            <select
              name="taskCount"
              value={formData.taskCount}
              onChange={(e) => setFormData({ ...formData, taskCount: parseInt(e.target.value) })}
            >
              {Array.from({ length: 25 }, (element, index) => {
                return (
                  <option value={index + 1} key={index + 1}>
                    {index + 1}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </CTUI.Form.Form>
    </li>
  );
};
