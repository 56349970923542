import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';

import { getTherapySessionResponses } from 'Shared/actions/patient';
import { DPRRawDataElement, TherapySessionResponse, User } from 'Shared/types/shared';

export interface UseCellDetailsProps {
  patient: User;
  cell: DPRRawDataElement;
}

export function useCellDetails({ patient, cell }: UseCellDetailsProps) {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [therapySessionResponses, setTherapySessionResponses] =
    useState<TherapySessionResponse[]>();
  const [selectedResponse, setSelectedResponse] = useState<TherapySessionResponse>();

  const fetchCellDetails = async ({ patient, cell }: UseCellDetailsProps) => {
    setIsLoading(true);
    try {
      const response = await getTherapySessionResponses({
        patientId: patient.user.userId,
        sessionIds: cell.sessionIds,
      })(store.dispatch);
      setTherapySessionResponses(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchCellDetails({ patient, cell });
  }, [patient, cell]);

  return {
    isLoading,
    therapySessionResponses,
    selectedResponse,
    setSelectedResponse,
  };
}
