import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { updatePaymentDetails } from 'Shared/actions/subscription';
import { use3rdParty } from 'Shared/hooks/use3rdParty';
import { User } from 'Shared/types/shared';
import { getDisplayName } from 'Shared/utils';
import config from 'Shared/config.json';
import { StripeCheckout } from 'Shared/constants';

export interface MainProps {
  authUser: User;
}

export function useMain({ authUser }: MainProps) {
  const displayName = getDisplayName({ userObject: authUser });
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();
  const [stripeCheckout, setStripeCheckout] = useState<StripeCheckout>();

  const handleUpdatePaymentDetails = async (stripeToken: { id: number; email: string }) => {
    try {
      await updatePaymentDetails({ stripeToken })(dispatch);
      history.push('/payment_update/success');
    } catch (e: unknown) {
      history.push('/payment_update/fail');
    }
  };

  const handleOpenStripeDialog = useCallback(() => {
    stripeCheckout.open({
      description: 'Update Payment Info',
    });
  }, [stripeCheckout]);

  use3rdParty({
    scriptUrl: 'https://checkout.stripe.com/checkout.js',
    onLoadHandler: () => {
      setStripeCheckout(
        window.StripeCheckout.configure({
          key: config.environment[process.env.ENV as keyof typeof config.environment].stripeKey,
          name: 'Constant Therapy',
          image: '//s3.amazonaws.com/CT-image/ct-logo-64.png',
          allowRememberMe: false,
          panelLabel: 'Update Card Details',
          token: handleUpdatePaymentDetails,
        })
      );
    },
  });

  useEffect(() => {
    if (stripeCheckout) {
      handleOpenStripeDialog();
    }
  }, [stripeCheckout]);

  return { displayName, handleOpenStripeDialog };
}
