import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faSquareXTwitter,
  faFacebookSquare,
  faPinterestSquare,
  faLinkedin,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';

import { HTML_FOOTER_ID, WEBSITE_URL } from 'Shared/constants';

import styles from './index.module.scss';

const mountingPoint = document.getElementById(HTML_FOOTER_ID);

const socialNetworksLinks = [
  { title: 'X (Twitter)', url: 'https://twitter.com/ConstantTherapy', icon: faSquareXTwitter },
  { title: 'Facebook', url: 'https://www.facebook.com/ConstantTherapy', icon: faFacebookSquare },
  {
    title: 'Pinterest',
    url: 'https://www.pinterest.com/constantthe0461/',
    icon: faPinterestSquare,
  },
  { title: 'LinkedIn', url: 'https://www.linkedin.com/company/constant-therapy', icon: faLinkedin },
  {
    title: 'Youtube',
    url: 'https://www.youtube.com/channel/UCJT65S6EvNEsb10w2zrESIQ',
    icon: faYoutubeSquare,
  },
];

const menuLinks = [
  { title: 'Pricing', url: `${WEBSITE_URL}/constant-therapy/get-started/#pricing` },
  { title: 'Support', url: `${WEBSITE_URL}/constant-therapy/support/` },
  { title: 'EULA', url: `${WEBSITE_URL}/eula/` },
  { title: 'Privacy Policy', url: `${WEBSITE_URL}/privacy/` },
  { title: 'Terms of Use', url: `${WEBSITE_URL}/term/` },
];

export const Footer: FC = () => {
  return ReactDOM.createPortal(
    <div className={styles.footer}>
      <nav className={styles.menuLinks}>
        <ul>
          {menuLinks.map((link, key) => {
            return (
              <li key={key}>
                <a href={link.url} title={link.title} target="_blank" rel="noreferrer">
                  {link.title}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
      <ul className={styles.socialNetworksLinks}>
        {socialNetworksLinks.map((link, key) => {
          return (
            <li key={key}>
              <a
                href={link.url}
                title={`Follow us on ${link.title}`}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={link.icon as IconProp} />
              </a>
            </li>
          );
        })}
      </ul>
      <div className={styles.disclaimer}>
        Constant Therapy does not provide rehabilitation services and does not guarantee
        improvements in brain function.
        <br />
        CT provides tools for self-help and tools for patients to work with their clinicians.
        <br />© {new Date().getFullYear()} Constant Therapy. All right reserved.
      </div>
    </div>,
    mountingPoint
  );
};
