import {
  GET_USER_SUBSCRIPTION,
  CLEAR_USER_SUBSCRIPTION_CACHE,
  GET_USER_INSTITUTIONS,
  GET_PASSWORD_REQUIREMENTS,
  GET_USER_DATA,
  GET_UNSUBSCRIBE_REASONS,
  DO_NOT_CONTACT,
} from 'Shared/types/redux';

const initialState = { errors: {} };

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_DATA.success:
      return {
        ...state,
        data: action.payload,
      };
    case GET_USER_SUBSCRIPTION.success:
      return {
        ...state,
        subscription: action.payload,
      };
    case GET_USER_SUBSCRIPTION.fail:
      return {
        ...state,
        errors: {
          ...state.errors,
          subscription: action.payload,
        },
      };
    case CLEAR_USER_SUBSCRIPTION_CACHE:
      return Object.entries(state).reduce((accumulator, [key, value]) => {
        if (key !== 'subscription') {
          accumulator[key] = value;
        }
        return accumulator;
      }, {});
    case GET_UNSUBSCRIBE_REASONS.success:
      return {
        ...state,
        unsubscribeReasons: action.payload,
      };
    case GET_USER_INSTITUTIONS.success:
      return {
        ...state,
        institutions: action.payload.data,
      };
    case GET_USER_INSTITUTIONS.fail:
      return {
        ...state,
        errors: {
          ...state.errors,
          institutions: action.payload.data,
        },
      };
    case GET_PASSWORD_REQUIREMENTS.success:
      return {
        ...state,
        passwordRequirements: action.payload,
      };
    case DO_NOT_CONTACT.success:
      return {
        ...state,
        doNotContactData: action.payload,
      };
    case DO_NOT_CONTACT.fail:
      return {
        ...state,
        errors: {
          ...state.errors,
          doNotContactData: action.payload,
        },
      };
    default:
      return state;
  }
}
