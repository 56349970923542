import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import authReducer from './auth';
import signupReducer from './signup';
import flintActivationReducer from './flintActivation';
import addPatientReducer from './addPatient';
import userReducer from './user';
import serviceReducer from './service';
import subscriptionReducer from './subscription';
import institutionReducer from './institution';
import clinicianReducer from './clinician';
import patientReducer from './patient';

export default combineReducers({
  form: formReducer,
  auth: authReducer,
  signup: signupReducer,
  addPatient: addPatientReducer,
  user: userReducer,
  flintActivation: flintActivationReducer,
  service: serviceReducer,
  subscription: subscriptionReducer,
  institution: institutionReducer,
  clinician: clinicianReducer,
  patient: patientReducer,
});
