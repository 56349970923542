import React, { Dispatch, FC, SetStateAction } from 'react';
import { AudioPlayerContent } from 'App/therapy/session/taskViewComponent/stimulus/audioPlayer';

import { TaskView, STIMULUS_CONTENT_TYPES } from 'Shared/types/shared';

import { TextContent } from './text';
import { HtmlContent } from './html';
import { ImageContent } from './image';
import { AudioContent } from './audio';
import { VideoContent } from './video';

import styles from './index.module.scss';

//TODO: setHasPlayed - is a temporary solution for the newly discovered functionality, needs to be refactored once codebase is stabilized
export const Stimulus: FC<{ task: TaskView; setHasPlayed?: Dispatch<SetStateAction<boolean>> }> = ({
  task,
  setHasPlayed,
}) => {
  return (
    <div className={styles.stimulus}>
      {task.stimulus.map((item, index) => {
        if (item.contentType === STIMULUS_CONTENT_TYPES.TEXT) {
          return <TextContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />;
        }
        if (item.contentType === STIMULUS_CONTENT_TYPES.HTML) {
          return <HtmlContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />;
        }
        if (item.contentType === STIMULUS_CONTENT_TYPES.IMAGE) {
          return <ImageContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />;
        }
        if (item.contentType === STIMULUS_CONTENT_TYPES.AUDIO) {
          return <AudioContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />;
        }
        if (item.contentType === STIMULUS_CONTENT_TYPES.VIDEO) {
          return <VideoContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />;
        }
        if (item.contentType === STIMULUS_CONTENT_TYPES.AUDIO_PLAYER) {
          return (
            <AudioPlayerContent
              stimulus={item}
              resourceUrl={task.resourceUrl}
              key={index}
              setHasPlayed={setHasPlayed}
            />
          );
        }
      })}
    </div>
  );
};
