import { SyntheticEvent, useState } from 'react';

export function useDashboardNavigation() {
  const [isProgressPopupShown, setIsProgressPopupShown] = useState(false);
  const [isDailySessionPopupShown, setIsDailySessionPopupShown] = useState(false);
  const [isDischargePopupShown, setIsDischargePopupShown] = useState(false);

  return {
    isProgressPopupShown,
    isDailySessionPopupShown,
    isDischargePopupShown,
    showProgressPopup: (e: SyntheticEvent) => {
      e.preventDefault();
      setIsProgressPopupShown(true);
    },
    hideProgressPopup: () => {
      setIsProgressPopupShown(false);
    },
    showDailySessionPopup: (e: SyntheticEvent) => {
      e.preventDefault();
      setIsDailySessionPopupShown(true);
    },
    hideDailySessionPopup: () => {
      setIsDailySessionPopupShown(false);
    },
    showDischargePopup: (e: SyntheticEvent) => {
      e.preventDefault();
      setIsDischargePopupShown(true);
    },
    hideDischargePopup: () => {
      setIsDischargePopupShown(false);
    },
  };
}
