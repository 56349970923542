import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { SkipReason, SkipType } from 'Shared/types/task';

import { useSkipModal, useSkipModalProps } from './useSkipModal';

import styles from './skipModal.module.scss';

const SkipModalActions = {
  [SkipType.SINGLE_ITEM]: 'Skip this item',
  [SkipType.SKIP_ALL]: 'Skip all like this one',
};

const SkipModalReasons = {
  [SkipReason.TOO_HARD]: 'Too hard',
  [SkipReason.TOO_EASY]: 'Too easy',
  [SkipReason.TASK_ERROR]: 'There is an error',
  [SkipReason.DONT_LIKE]: "I don't like it",
  [SkipReason.OTHER]: 'Other',
};

interface SkipModalProps extends useSkipModalProps {
  modalRef: React.Ref<HTMLDivElement>;
}

export const SkipModal: FC<SkipModalProps> = ({ modalRef, hideModal, skipTask }) => {
  const { action, handleActionClick, handleReasonClick } = useSkipModal({
    hideModal,
    skipTask,
  });
  const header = action ? (
    <h1 className="h1">Why do you want to skip it?</h1>
  ) : (
    <h1 className="h1">What would you like to do?</h1>
  );

  return (
    <CTUI.Modal ref={modalRef} id={styles.skipModal} closeButtonHandler={hideModal} header={header}>
      {action ? (
        <ul className={styles.buttonsList}>
          {Object.entries(SkipModalReasons).map(([value, title]) => {
            return (
              <li key={value}>
                <CTUI.Button
                  text={title}
                  value={value}
                  onClick={handleReasonClick}
                  customClasses={styles.button}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className={styles.buttonsList}>
          {Object.entries(SkipModalActions).map(([value, title]) => {
            return (
              <li key={value}>
                <CTUI.Button
                  text={title}
                  value={value}
                  onClick={handleActionClick}
                  customClasses={styles.button}
                />
              </li>
            );
          })}
        </ul>
      )}
    </CTUI.Modal>
  );
};
