import React, { FC } from 'react';
import classNames from 'classnames';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { asyncValidate } from 'Shared/components/form/asyncValidation';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { ADD_PATIENT_FORM_NAME } from '../../shared/constants';

import stepStyles from '../../shared/step.module.scss';

interface BasicComponentProps {
  previousPage: () => void;
}

const BasicComponent: FC<BasicComponentProps & InjectedFormProps<object, BasicComponentProps>> = ({
  handleSubmit,
  previousPage,
  error,
  pristine,
  submitting,
}) => {
  return (
    <FloatingPage
      customClasses={stepStyles.step}
      header={
        <>
          <ProgressBar currentStep={1} totalSteps={5} />
          <h1 className={classNames('h1', stepStyles.title)}>
            Let&apos;s create an account and get started.
          </h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses={stepStyles.form}
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="primary-inverted"
              customClasses={stepStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text="Next &rarr;"
              variant="primary"
              customClasses={stepStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="username"
          component={CTUI.Form.Field}
          type="text"
          label="Client's username or email"
          required
          autofocus
          autoComplete="off"
        />
        <Field
          name="password"
          component={CTUI.Form.Password}
          type="password"
          label="Password"
          required
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Basic = reduxForm<object, BasicComponentProps>({
  form: ADD_PATIENT_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  asyncValidate,
  asyncBlurFields: ['username'],
})(BasicComponent);

export { Basic };
