import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { getUserSubscription } from 'Shared/actions/user';
import { SubscriptionInfo } from 'Shared/types/shared';
import { reactivateSubscription } from 'Shared/actions/subscription';
import { SUBSCRIBE_BUTTON_STATES } from 'Shared/constants';

export function useSubscriptionInfo() {
  const dispatch: Dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionMessage, setSubscriptionMessage] = useState('');
  const [subscribeButton, setSubscribeButton] = useState<SUBSCRIBE_BUTTON_STATES>();

  const reactivateSubscriptionAsync = async () => {
    setIsLoading(true);
    try {
      const response = (await reactivateSubscription()(dispatch)) as { data: SubscriptionInfo };
      const subscriptionInfo = response.data;
      setSubscriptionMessage(subscriptionInfo.subscriptionBlurb.replace(/\+/gi, ' '));
      setSubscribeButton(subscriptionInfo.subscribeButton);
    } catch (e: unknown) {
      if (typeof e === 'object' && 'message' in e) {
        setSubscriptionMessage(
          `Unable to reactivate subscription due to the following error: ${e.message as string}`
        );
      } else {
        console.log(e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserSubscription = async () => {
    try {
      const subscriptionInfo = (await getUserSubscription({
        token: localStorage.getItem('accessToken'),
      })(dispatch)) as SubscriptionInfo;
      setSubscriptionMessage(subscriptionInfo.subscriptionBlurb.replace(/\+/gi, ' '));
      setSubscribeButton(subscriptionInfo.subscribeButton);
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchUserSubscription();
  }, []);

  return {
    isLoading,
    subscriptionMessage,
    subscribeButton,
    handleReactivateSubscription: (e: React.SyntheticEvent) => {
      e.preventDefault();
      void reactivateSubscriptionAsync();
    },
  };
}
