import React, { FC } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { REPORT_TYPES } from 'Shared/constants';
import { Spinner } from 'Shared/components/spinner';
import { User } from 'Shared/types/shared';

import { instanceOfPerformanceReport } from '../../shared/reportUtils';

import { useNote } from './shared/useNote';
import { NoteActions } from './shared/actions';
import { PerformanceAndDischargeReport } from './shared/performanceAndDischargeReport';

import sharedStyles from './shared/note.module.scss';

interface ProgressNoteProps {
  patient: User;
}

export const ProgressNote: FC<ProgressNoteProps> = ({ patient }) => {
  const {
    isLoading,
    report,
    startDate,
    endDate,
    componentRef,
    textToCopy,
    handleCopy,
    isCopied,
    handlePrint,
    popper,
    arrow,
    setReferenceElement,
  } = useNote({
    patient,
    reportType: REPORT_TYPES.PROGRESS_NOTE,
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (!instanceOfPerformanceReport(report)) {
    return null; //TODO:
  }

  return (
    <div className={sharedStyles.note} ref={componentRef}>
      <header className={sharedStyles.preHeader}>
        <CTUI.Font.H2 customClasses={sharedStyles.preHeaderTitle}>
          About the Constant Therapy App
        </CTUI.Font.H2>
        <NoteActions
          textToCopy={textToCopy}
          handleCopy={handleCopy}
          handlePrint={handlePrint}
          setReferenceElement={setReferenceElement}
          isCopied={isCopied}
          popper={popper}
          arrow={arrow}
        />
        <CTUI.Font.Text>
          Cognitive, language and speech therapy at home, on your schedule. For patients recovering
          from stroke and TBI, or living with aphasia, dementia and other neurological conditions.
        </CTUI.Font.Text>
      </header>
      <header className={sharedStyles.header}>
        <CTUI.Font.H1>
          <FontAwesomeIcon icon={faFileLines} className={sharedStyles.mainIcon} />
          Progress note
        </CTUI.Font.H1>
        <CTUI.Font.H2 customClasses={sharedStyles.headerSubTitle}>
          This report is to help therapists or other healthcare providers update their records.
        </CTUI.Font.H2>
        <CTUI.Font.H4 variant="medium">User activity report - compared to baseline</CTUI.Font.H4>
      </header>
      <header className={sharedStyles.patientInfo}>
        <CTUI.Font.H4 variant="medium" customClasses={sharedStyles.patientInfoTitle}>
          User name: {report.patientName}
        </CTUI.Font.H4>
        <CTUI.Font.Text>
          Constant Therapy exercises completed between{' '}
          <b>{moment(startDate).format('MMMM D, YYYY')} and </b>
          <b>{moment(endDate).format('MMMM D, YYYY')}</b>
        </CTUI.Font.Text>
      </header>
      <PerformanceAndDischargeReport sections={report.sections} />
    </div>
  );
};
