import React, { FC } from 'react';

import { PerformanceReportTaskLevel } from 'Shared/types/shared';

import { CuesListItem } from './item';

import styles from './index.module.scss';

interface CuesListProps {
  level: PerformanceReportTaskLevel;
}

export const CuesList: FC<CuesListProps> = ({
  level: {
    baselineCues,
    latestCues,
    accuracy: { baseline, latest },
  },
}) => {
  const isSameAccuracy = baseline.value === latest.value && baseline.date === latest.date;
  const maxBaselineCues =
    baselineCues && baselineCues.reduce((acc, cue) => Math.max(acc, cue.numCues), 0);
  const maxLatestCues =
    latestCues && latestCues.reduce((acc, cue) => Math.max(acc, cue.numCues), 0);

  if (!baselineCues.length && !latestCues.length) {
    return null;
  }

  return (
    <ul className={styles.cuesList}>
      {isSameAccuracy && (
        <CuesListItem type="baseline" cuesAmount={maxBaselineCues} cuesTitle="Current" />
      )}
      {!isSameAccuracy && Boolean(maxBaselineCues) && (
        <CuesListItem type="baseline" cuesAmount={maxBaselineCues} cuesTitle="Baseline" />
      )}
      {!isSameAccuracy && Boolean(maxLatestCues) && (
        <CuesListItem type="latest" cuesAmount={maxLatestCues} cuesTitle="Latest" />
      )}
    </ul>
  );
};
