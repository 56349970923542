import { DragSourceMonitor, useDrag } from 'react-dnd';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { TaskTypes } from 'Shared/constants';
import type { TaskType } from 'Shared/types/task';

export interface TaskTypeItemProps {
  index: number;
  task: TaskType;
  selectedItems?: TaskType[];
  toggleItemSelection?: (item: TaskType) => void;
}

export function useTaskTypeItem({ index, task }: TaskTypeItemProps) {
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const taskItemTypeRef = useRef<HTMLElement>(null);
  const previewRef = useRef<HTMLLIElement>(null);
  const [shouldRenderVideo, setShouldRenderVideo] = useState(false);
  const [shouldReOpenVideo, setShouldReOpenVideo] = useState(false);

  const handlePlayVideoClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!videoElementRef.current) {
      setShouldRenderVideo(true);
    } else {
      setShouldReOpenVideo(true);
    }
  };

  const [{ isDragging }, drag, preview] = useDrag({
    type: TaskTypes.TASK_TYPE,
    item: () => ({
      id: task.typeId,
      index,
      type: TaskTypes.TASK_TYPE,
      task,
    }),
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() }),
  });

  useEffect(() => {
    if (videoElementRef.current) {
      videoElementRef.current.onfullscreenchange = () => {
        if (!document.fullscreenElement) {
          videoElementRef.current.pause();
          setShouldReOpenVideo(false);
        }
      };

      if (shouldRenderVideo || shouldReOpenVideo) {
        if (videoElementRef.current.requestFullscreen) {
          // Normal browsers
          void videoElementRef.current.requestFullscreen();
        } else if (videoElementRef.current.webkitRequestFullscreen) {
          // Safari Desktop 16.4+ browser
          try {
            void videoElementRef.current.webkitRequestFullscreen();
          } catch (e) {
            console.log('webkitRequestFullscreen failed', e);
          }
        } else if (videoElementRef.current.webkitEnterFullscreen) {
          // Safari iOS + Safari Desktop 16.3-
          videoElementRef.current.addEventListener('loadedmetadata', () => {
            try {
              void videoElementRef.current.webkitEnterFullscreen();
            } catch (e) {
              console.log('webkitEnterFullscreen failed', e);
            }
          });
        }
      }

      return () => {
        videoElementRef.current.pause();
        videoElementRef.current.onfullscreenchange = undefined;
        setShouldReOpenVideo(false);
      };
    }
  }, [videoElementRef, shouldRenderVideo, shouldReOpenVideo]);

  preview(previewRef);
  drag(taskItemTypeRef);

  return {
    taskItemTypeRef,
    previewRef,
    isDragging,
    videoElementRef,
    handlePlayVideoClick,
    shouldRenderVideo,
  };
}
