import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import md5 from 'md5';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { logEvent } from 'Shared/actions/event';
import { DASHBOARD } from 'Shared/types/events';
import { ClinicianDashboard, User } from 'Shared/types/shared';

export interface RequestBrochureProps {
  clinician?: User;
  dashboardData?: ClinicianDashboard;
}

interface RequestBrochureParams {
  patientId?: string;
  sourceView?: string;
}

export function useRequestBrochure({ dashboardData }: RequestBrochureProps) {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();

  // It's possible that none of these
  // params are present because of the email-link that comes in a pattern like this:
  // https://www.constanttherapy.com/dashboard/request-brochure?$(analytics)&token=$(email_access_token)
  const { patientId = '', sourceView } = useParams<RequestBrochureParams>();

  const matchedPatient = useMemo(() => {
    if (dashboardData && patientId.length) {
      return dashboardData.Patients.find(
        (patient) => md5(patient.patientId.toString()) === patientId
      );
    } else if (patientId) {
      return { patientId };
    } else {
      return null;
    }
  }, [patientId, dashboardData]);

  const downloadHandler = () => {
    void logEvent({
      page: DASHBOARD.REQUEST_BROCHURE.DOWNLOAD,
      ...//Optional assignment of data-field if matchedPatient is found
      (matchedPatient && { data: { patientId: matchedPatient.patientId } }),
    })(dispatch, store.getState.bind(store));
  };
  const playVideoHandler = () => {
    void logEvent({
      page: DASHBOARD.REQUEST_BROCHURE.PLAY_VIDEO,
      ...//Optional assignment of data-field if matchedPatient is found
      (matchedPatient && { data: { patientId: matchedPatient.patientId } }),
    })(dispatch, store.getState.bind(store));
  };

  useEffect(() => {
    void logEvent({
      page: DASHBOARD.REQUEST_BROCHURE.VIEW,
      data: {
        sourceView,
        ...//Optional assignment of data-field if matchedPatient is found
        (matchedPatient && { patientId: matchedPatient.patientId }),
      },
    })(dispatch, store.getState.bind(store));
  }, [matchedPatient]);

  return {
    downloadHandler,
    playVideoHandler,
  };
}
