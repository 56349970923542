import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

import { useBackToTop } from './useBackToTop';

import styles from './index.module.scss';

export const BackToTop: FC = () => {
  const { scrollToTop, shouldShowBackToTop } = useBackToTop();

  if (!shouldShowBackToTop) {
    return null;
  }

  return (
    <button onClick={scrollToTop} className={styles.backToTop}>
      <span className={styles.text}>Back to Top</span>
      <FontAwesomeIcon icon={faArrowCircleUp} />
    </button>
  );
};
