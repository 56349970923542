import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { defaultValidators } from 'Shared/validators';
import { asyncValidate } from 'Shared/components/form/asyncValidation';
import { getBaseUrl } from 'Shared/utils';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { PasswordRequirements, SignupData } from 'Shared/types/shared';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { CLINICIAN_SIGNUP_FORM_NAME } from '../../constants';

import sharedStyles from '../shared.module.scss';

const eulaLabel = `I agree to the <a href="${
  getBaseUrl() + '/api/user/eula'
}" target="_blank" rel="noreferrer" class="yeahBabe">terms of use</a>`;

interface BasicComponentProps {
  previousPage: () => void;
  passwordRequirements: PasswordRequirements;
  totalSteps: number;
  initialValues: SignupData;
  isInstitutionBasedSignup: boolean;
}

const BasicComponent: FC<BasicComponentProps & InjectedFormProps<object, BasicComponentProps>> = ({
  handleSubmit,
  previousPage,
  error,
  pristine,
  submitting,
  passwordRequirements,
  initialValues,
  totalSteps,
  isInstitutionBasedSignup,
}) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.wizardPage}
      header={
        <>
          <ProgressBar currentStep={1} totalSteps={totalSteps} />
          <h1 className="h1">
            {isInstitutionBasedSignup ? 'Activate my account' : 'Create an account'}
          </h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        actions={
          <>
            {isInstitutionBasedSignup && (
              <CTUI.Button
                text="&larr; Back"
                variant="primary-inverted"
                customClasses={sharedStyles.button}
                onClick={previousPage}
                disabled={submitting}
                type="button"
              />
            )}
            <CTUI.Form.SubmitButton
              text="Next &rarr;"
              variant="primary"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="email"
          component={CTUI.Form.Email}
          type="email"
          label="Email address"
          validate={[defaultValidators.email.bind(defaultValidators)]}
          readOnly={isInstitutionBasedSignup}
          required
          autofocus
        />
        <Field
          name="password"
          component={CTUI.Form.Password}
          type="password"
          label="Create a password"
          required
          extraClass={initialValues.institutionId ? 'withIndicator' : ''}
          passwordRequirements={passwordRequirements}
        />
        <Field
          name="acceptedEula"
          component={CTUI.Form.Checkbox}
          type="checkbox"
          label={eulaLabel}
          required
        />
        <Field name="invitationToken" component={CTUI.Form.Field} type="hidden" />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Basic = reduxForm<object, BasicComponentProps>({
  form: CLINICIAN_SIGNUP_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  asyncValidate,
  asyncBlurFields: ['email'],
})(BasicComponent);

export { Basic };
