import React, { FC } from 'react';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileLines } from '@fortawesome/free-solid-svg-icons';

import {
  useProgressAndDischargePopup,
  ProgressAndDischargePopupProps,
} from './useProgressAndDischargePopup';

import sharedStyles from '../sharedModalProps.module.scss';
import styles from './index.module.scss';

export const ProgressAndDischargePopup: FC<ProgressAndDischargePopupProps> = ({
  patientId,
  closePopupHandler,
  isProgressNote = true,
}) => {
  const title = isProgressNote
    ? 'Edit date range for progress note'
    : 'Edit date range for discharge summary';

  const { modalRef, numberOfMonths, applyChanges } = useProgressAndDischargePopup({
    patientId,
    closePopupHandler,
    isProgressNote,
  });

  return (
    <CTUI.Modal
      ref={modalRef}
      id={styles.progressAndDischargePopup}
      header={
        <h1 className={classNames('h1', sharedStyles.title)}>
          <FontAwesomeIcon icon={faFileLines} className={sharedStyles.titleIcon} /> {title}
        </h1>
      }
      size={CTUI.ModalSizes.LARGE}
      customClasses={sharedStyles.reportsModal}
      closeButtonHandler={closePopupHandler}
    >
      <p>
        This note is intended to help you with documentation. You can type in or select the date
        range of data to include below.
      </p>
      <i className={sharedStyles.divider} />
      <div className={classNames(sharedStyles.calendarWrap, styles.calendarWrap)}>
        <p className={sharedStyles.subtitle}>Choose date range:</p>
        <CTUI.DateRangeSelector
          submitButton={{ onClick: applyChanges, icon: faEye, text: 'See Report' }}
          dayPickerRangeControllerProps={{ numberOfMonths, orientation: 'horizontal' }}
        />
      </div>
    </CTUI.Modal>
  );
};
