import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { getBaseUrl, getDisplayName } from 'Shared/utils';
import { defaultValidators } from 'Shared/validators';
import { asyncValidate } from 'Shared/components/form/asyncValidation';
import { User } from 'Shared/types/shared';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import styles from './activationForm.module.scss';

interface ActivationFormComponentProps {
  preAuthorizedUser: User;
  initialValues: {
    email: string;
    action: string;
  };
}

const eulaLabel = `I agree to the <a href="${
  getBaseUrl() + '/api/user/eula'
}" target="_blank" rel="noreferrer">terms of use</a>`;

const ActivationFormComponent: FC<
  ActivationFormComponentProps & InjectedFormProps<object, ActivationFormComponentProps>
> = ({ preAuthorizedUser, handleSubmit, error, pristine, submitting, initialValues }) => {
  const displayName = getDisplayName({ userObject: preAuthorizedUser });

  return (
    <FloatingPage
      customClasses={styles.homeworkInvitationActivationModal}
      header={
        <h1 className="h1">
          {displayName}, your clinician has recommended Constant Therapy for home practice.
        </h1>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses={styles.homeworkInvitationActivationForm}
        actions={
          <CTUI.Form.SubmitButton
            variant="primary"
            isSubmitting={submitting}
            isPristine={pristine}
            text="Finish"
            customClasses={styles.button}
          />
        }
      >
        <Field
          name="email"
          component={CTUI.Form.Email}
          type="email"
          label="Enter your email address to get started"
          validate={[defaultValidators.email.bind(defaultValidators)]}
          required
          readOnly={!!initialValues.email}
          autofocus={!initialValues.email}
          tabIndex={!initialValues.email ? 0 : -1}
        />
        <Field
          name="careGiver"
          component={CTUI.Form.Checkbox}
          type="checkbox"
          label="This is a family member who will manage the account"
        />
        <Field
          name="password"
          component={CTUI.Form.Password}
          type="password"
          label="Create a password"
          required
        />
        <Field
          name="acceptedEULA"
          component={CTUI.Form.Checkbox}
          type="checkbox"
          label={eulaLabel}
          required
        />
        <Field name="action" component={CTUI.Form.Field} type="hidden" />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const ActivationForm = reduxForm<object, ActivationFormComponentProps>({
  form: 'homeworkInvitationActivationForm',
  asyncValidate,
  asyncBlurFields: ['email'],
})(ActivationFormComponent);

export { ActivationForm };
