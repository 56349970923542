import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

import { Schedule, EnhancedScheduledTaskType, ScheduledTaskTypeStub } from 'Shared/types/schedule';
import { TaskType, TaskTypeCategory } from 'Shared/types/task';
import { TaskTypes } from 'Shared/constants';

import { DragItem } from './activeScheduleTasks/taskTypeItem/useTaskTypeItem';

export interface UseScheduleModalProps {
  patientId: number;
  hideScheduleComponent: () => void;
  getPatientSchedule: (patientId: string | number) => Promise<Schedule>;
  savePatientSchedule: (
    patientId: string | number,
    scheduledTaskTypes: (EnhancedScheduledTaskType | ScheduledTaskTypeStub)[]
  ) => Promise<{ ctdata: Schedule; ctmsgs: object }>;
  getAllTasksHierarchy: () => Promise<TaskTypeCategory>;
  getAllTasksFlat: () => Promise<TaskType[]>;
}

const confirmationMessage = 'Save changes before exit?';

export function useScheduleComponent({
  patientId,
  hideScheduleComponent,
  getAllTasksHierarchy,
  getPatientSchedule,
  savePatientSchedule,
  getAllTasksFlat,
}: UseScheduleModalProps) {
  const [isScheduleLoading, setIsScheduleLoading] = useState(true);
  const [isTasksHierarchyLoading, setIsTasksHierarchyLoading] = useState(true);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [scheduledTasksList, setScheduledTasksList] = useState<
    (EnhancedScheduledTaskType | ScheduledTaskTypeStub)[]
  >([]);
  const [tasksHierarchy, setTasksHierarchy] = useState<TaskTypeCategory>(null);
  const [allTasksList, setAllTasksList] = useState<TaskType[]>(null);
  const [shouldShowTasksHierarchyInPortraitMode, setShowTasksHierarchyInPortraitMode] =
    useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const modalRef = useRef(null);

  const modalCloseHandler = async () => {
    if (isPristine) {
      hideScheduleComponent();
      return;
    }

    if (window.confirm(confirmationMessage)) {
      await handleSavePatientSchedule();
    }

    hideScheduleComponent();
  };

  useClickOutside(modalRef, () => {
    // The handler should be executed only when we have this particular modal in place
    if (modalRef.current) {
      void modalCloseHandler();
    }
  });

  const moveScheduledTasks = useCallback(
    (dragIndex: number, hoverIndex: number, item: DragItem) => {
      setScheduledTasksList(
        (prevScheduledTasks: (EnhancedScheduledTaskType | ScheduledTaskTypeStub)[]) => {
          const nextScheduledTasks = [...prevScheduledTasks];
          nextScheduledTasks.splice(dragIndex, 1);
          if (item.type === TaskTypes.TASK_TYPE) {
            nextScheduledTasks.splice(
              hoverIndex,
              0,
              convertTaskTypeToScheduledTaskTypeStub(item.task as TaskType)
            ); // This one APPENDS and sorts items from allTasks list to the activeScheduleTasks list
          } else {
            nextScheduledTasks.splice(hoverIndex, 0, prevScheduledTasks.at(dragIndex)); // This one sorts items inside activeScheduleTasks list
          }
          return nextScheduledTasks;
        }
      );
      setIsPristine(false);
    },
    []
  );
  const deleteScheduledTask = useCallback((index: number) => {
    setScheduledTasksList(
      (prevScheduledTasks: (EnhancedScheduledTaskType | ScheduledTaskTypeStub)[]) => {
        const nextScheduledTasks = [...prevScheduledTasks];
        nextScheduledTasks.splice(index, 1);
        return nextScheduledTasks;
      }
    );
    setIsPristine(false);
  }, []);
  const adjustScheduledTask = useCallback(
    (adjustedTask: EnhancedScheduledTaskType | ScheduledTaskTypeStub) => {
      setScheduledTasksList(
        (prevScheduledTasks: (EnhancedScheduledTaskType | ScheduledTaskTypeStub)[]) => {
          const nextScheduledTasks = [...prevScheduledTasks];
          const affectedIndex = nextScheduledTasks.findIndex((task) => {
            if ('id' in task && 'id' in adjustedTask) {
              return task.id === adjustedTask.id;
            }
            if ('temporaryId' in task && 'temporaryId' in adjustedTask) {
              return task.temporaryId === adjustedTask.temporaryId;
            }
            return false;
          });
          nextScheduledTasks.splice(affectedIndex, 1, adjustedTask);
          return nextScheduledTasks;
        }
      );
      setIsPristine(false);
    },
    []
  );
  const appendTasksToScheduledTasksList = useCallback((items: TaskType[]) => {
    setScheduledTasksList(
      (prevScheduledTasks: (EnhancedScheduledTaskType | ScheduledTaskTypeStub)[]) => {
        return prevScheduledTasks.concat(items.map(convertTaskTypeToScheduledTaskTypeStub));
      }
    );
    setIsPristine(false);
  }, []);

  const fetchPatientSchedule = useCallback(async () => {
    try {
      const activeScheduleResponse = await getPatientSchedule(patientId);

      //TODO: this is a temporary fix that should be removed once https://constanttherapy.atlassian.net/browse/WEBAPP-459 is done
      const activeScheduleResponseScheduledTaskTypes =
        activeScheduleResponse.scheduledTaskTypes || [];

      const allTasksResponse = await getAllTasksFlat();
      const enhancedScheduledTasksList = activeScheduleResponseScheduledTaskTypes.map(
        (taskType): EnhancedScheduledTaskType => {
          return {
            ...taskType,
            maxLevel: allTasksResponse.find((item) => item.typeId === taskType.taskTypeId).maxLevel,
          };
        }
      );
      setAllTasksList(allTasksResponse);
      setScheduledTasksList(enhancedScheduledTasksList.sort((a, b) => a.taskOrder - b.taskOrder));
      setIsPristine(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsScheduleLoading(false);
    }
  }, [patientId]);

  const fetchTasksHierarchy = useCallback(async () => {
    try {
      const tasksHierarchyResponse = await getAllTasksHierarchy();
      setTasksHierarchy(tasksHierarchyResponse);
    } catch (e) {
      console.log(e);
    } finally {
      setIsTasksHierarchyLoading(false);
    }
  }, []);

  const handleSavePatientSchedule = useCallback(async () => {
    try {
      setIsSavingChanges(true);
      const activeScheduleResponse = await savePatientSchedule(patientId, scheduledTasksList);
      const enhancedScheduledTasksList = activeScheduleResponse.ctdata?.scheduledTaskTypes.map(
        (taskType): EnhancedScheduledTaskType => {
          return {
            ...taskType,
            maxLevel: allTasksList.find((item) => item.typeId === taskType.taskTypeId).maxLevel,
          };
        }
      );
      setScheduledTasksList(enhancedScheduledTasksList);
      setIsPristine(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSavingChanges(false);
    }
  }, [patientId, scheduledTasksList, allTasksList]);

  useEffect(() => {
    void fetchPatientSchedule();
    void fetchTasksHierarchy();
  }, []);

  return {
    isScheduleLoading,
    scheduledTasksList,
    moveScheduledTasks,
    deleteScheduledTask,
    adjustScheduledTask,
    handleSavePatientSchedule,
    isTasksHierarchyLoading,
    tasksHierarchy,
    modalRef,
    shouldShowTasksHierarchyInPortraitMode,
    showTasksHierarchyInPortraitMode: () => {
      setShowTasksHierarchyInPortraitMode(true);
    },
    hideTasksHierarchyInPortraitMode: () => {
      setShowTasksHierarchyInPortraitMode(false);
    },
    appendTasksToScheduledTasksList,
    modalCloseHandler,
    isSavingChanges,
    isPristine,
  };
}

function convertTaskTypeToScheduledTaskTypeStub(taskType: TaskType): ScheduledTaskTypeStub {
  return {
    temporaryId: uuidv4(),
    displayName: taskType.displayName,
    taskCount: 10,
    taskLevel: 1,
    maxLevel: taskType.maxLevel,
    taskTypeId: taskType.typeId,
  };
}
