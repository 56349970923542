import React, { FC, Fragment } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { defaultValidators } from 'Shared/validators';
import { asyncValidate } from 'Shared/components/form/asyncValidation';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { PATIENT_ACTIVATION_FORM_NAME } from 'Shared/types/forms';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import sharedStyles from '../shared.module.scss';

const BasicComponent: FC<InjectedFormProps> = ({ handleSubmit, error, pristine, submitting }) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.wizardPage}
      header={
        <>
          <ProgressBar currentStep={1} totalSteps={5} />
          <h1 className="h1">Welcome! Let’s start creating your customized therapy plan.</h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses="flintActivationForm"
        actions={
          <CTUI.Form.SubmitButton
            text="Next &rarr;"
            variant="primary-inverted"
            customClasses={sharedStyles.button}
            isSubmitting={submitting}
            isPristine={pristine}
          />
        }
      >
        <Field
          name="email"
          component={CTUI.Form.Email}
          type="email"
          label="Email address"
          validate={[defaultValidators.email.bind(defaultValidators)]}
          required
          readOnly
          autofocus
        />
        <Field
          name="username"
          component={CTUI.Form.Field}
          type="text"
          label="Username"
          required
          readOnly
          autofocus
        />
        <Field
          name="password"
          component={CTUI.Form.Password}
          type="password"
          label="Create a password"
          required
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Basic = reduxForm({
  form: PATIENT_ACTIVATION_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  asyncValidate,
  asyncBlurFields: ['email'],
})(BasicComponent);

export { Basic };
