import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { COUPON_FORM_NAME } from '../constants';

import styles from './couponForm.module.scss';

const CouponFormComponent: FC<InjectedFormProps> = ({
  handleSubmit,
  error,
  pristine,
  submitting,
}) => {
  return (
    <div className="couponFormWrapper">
      <CTUI.Form.Form
        customClasses={styles.couponForm}
        error={error}
        handleSubmit={handleSubmit}
        actions={
          <CTUI.Form.SubmitButton
            variant="secondary"
            isSubmitting={submitting}
            isPristine={pristine}
            customClasses={styles.submitButton}
            text="Apply"
          />
        }
        actionsWrapperClasses={styles.actions}
      >
        <Field
          name="coupon"
          label="Promo code"
          component={CTUI.Form.Field}
          customClasses={styles.field}
          placeholder="promo code here"
          type="text"
          autoFocus={true}
          autoComplete="on"
        />
      </CTUI.Form.Form>
    </div>
  );
};

const CouponForm = reduxForm({
  form: COUPON_FORM_NAME,
})(CouponFormComponent);

export { CouponForm };
