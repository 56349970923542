import { useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useCalendlyEventListener } from 'react-calendly';
import { Dispatch } from 'redux';

import { logEvent } from 'Shared/actions/event';
import { LANDING_PAGES } from 'Shared/types/events';
import { US_STATES_LIST } from 'Shared/constants';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { getUserDataNoAuth } from 'Shared/actions/user';
import { User } from 'Shared/types/shared';

interface DigitalClinicForm {
  email: string;
  state: string;
}

interface EventData {
  page: string;
  data?: DigitalClinicForm;
  userId?: string | number;
  token?: string;
}

const SUPPORTED_STATES_LIST = [
  US_STATES_LIST.FL,
  US_STATES_LIST.MA,
  US_STATES_LIST.NY,
  US_STATES_LIST.CA,
  US_STATES_LIST.TX,
];

export function useDigitalClinic() {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const [isSupportedState, setIsSupportedState] = useState(false);
  const [formData, setFormData] = useState<DigitalClinicForm>();
  const queryParams = useQueryParams();

  const handleDigitalClinicFormSubmit = async (formData: DigitalClinicForm) => {
    const eventData: EventData = {
      page: LANDING_PAGES.DIGITAL_CLINIC_SUBMIT,
      data: formData,
      userId: null,
      token: null,
    };

    if (queryParams.has('token')) {
      eventData.token = queryParams.get('token');
      try {
        const userData = (await getUserDataNoAuth(queryParams.get('token'))(dispatch)) as User;
        eventData.userId = userData.user.userId;
        eventData.data.email = userData.user.email;
      } catch (e: unknown) {
        console.warn(e);
      }
    }

    const result = (await logEvent(eventData)(
      dispatch,
      store.getState.bind(store)
    )) as Promise<any>;

    setFormData(formData);
    setIsSupportedState(SUPPORTED_STATES_LIST.includes(US_STATES_LIST[formData.state]));

    return result;
  };

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      await logEvent({
        page: LANDING_PAGES.DIGITAL_CLINIC_SCHEDULE,
        data: { ...formData, schedule: e.data.payload },
      })(dispatch, store.getState.bind(store));
    },
  });

  return { handleDigitalClinicFormSubmit, formData, isSupportedState };
}
