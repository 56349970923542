import React, { FC } from 'react';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock, faPauseCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { SharedModalProps } from './sharedModalProps';

import sharedModalStyles from './sharedModalStyles.module.scss';
import styles from './suspendUser.module.scss';

export const SuspendUserModal: FC<SharedModalProps> = ({
  email,
  isLoading,
  handleModalSubmit,
  closeActiveModal,
  modalSubmissionError,
  modalRef,
}) => {
  return (
    <CTUI.Modal
      ref={modalRef}
      header={
        <h1 className="h1">
          <FontAwesomeIcon
            icon={faUserLock}
            className={classNames(sharedModalStyles.icon, styles.icon)}
          />
          Suspend user
        </h1>
      }
      headerClasses={sharedModalStyles.header}
      customClasses={sharedModalStyles.actionCellModal}
      closeButtonHandler={closeActiveModal}
      footer={
        <>
          <CTUI.Form.SubmitButton
            isSubmitting={isLoading}
            isPristine={false}
            onClick={handleModalSubmit}
            icon={faPauseCircle}
            text="Suspend user"
          />
          <CTUI.Form.ResetButton
            isSubmitting={isLoading}
            isPristine={false}
            onClick={closeActiveModal}
            text="Cancel"
          />
        </>
      }
      footerClasses={sharedModalStyles.footer}
      error={modalSubmissionError}
    >
      <div className={sharedModalStyles.extraMargin}>
        <h4 className={classNames('h4', sharedModalStyles.message)}>
          You are suspending <b>{`<${email}>`}</b> from your user list.
        </h4>
        <p className={styles.message}>
          <FontAwesomeIcon icon={faInfoCircle} className={styles.infoIcon} />
          This will freeze their account. If you decide to reactivate this user later, their data
          will still be accessible so they can continue where they left off.
        </p>
      </div>
    </CTUI.Modal>
  );
};
