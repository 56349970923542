import { useState } from 'react';
import {
  ProgressBarCategory,
  ProgressBarCategoryStatus,
} from 'App/therapy/session/progressBar/useProgressBar';

import styles from './categoryBar.module.scss';

export interface CategoryBarProps {
  category: ProgressBarCategory;
  baseCategoryBarWidth: number;
}

export function useCategoryBar({ category, baseCategoryBarWidth }: CategoryBarProps) {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [tooltipRef, setTooltipRef] = useState<HTMLElement>(null);

  const categoryStyles = {
    width: `${baseCategoryBarWidth}px`,
    ...(category.progressionStatus === ProgressBarCategoryStatus.CURRENT && {
      background: `linear-gradient(to right, ${styles.doneColor} 0%, ${styles.doneColor} ${
        (category.activeIndex / category.count) * 100
      }%, ${styles.todoColor} ${(category.activeIndex / category.count) * 100}%, ${
        styles.todoColor
      } ${((category.count - category.activeIndex) / category.count) * 100}%)`,
    }),
  };

  return {
    isTooltipShown,
    showTooltip: () => {
      setIsTooltipShown(true);
    },
    hideTooltip: () => {
      setIsTooltipShown(false);
    },
    tooltipRef,
    setTooltipRef,
    categoryStyles,
  };
}
