import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay, faCirclePause } from '@fortawesome/free-regular-svg-icons';
import { faForward, faBackward, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { useAudioPlayer, AudioPlayerContentProps } from './useAudioPlayer';

import styles from './audioPlayer.module.scss';

export const AudioPlayerContent: FC<AudioPlayerContentProps> = ({
  stimulus,
  resourceUrl,
  setHasPlayed,
}) => {
  const {
    audioPlayerContent,
    handlePlay,
    handleSkipToTime,
    isPlaying,
    hasStarted,
    hasFinished,
    playbackTime,
    playbackDuration,
    handleSeekChange,
  } = useAudioPlayer({
    stimulus,
    resourceUrl,
    setHasPlayed,
  });
  const shouldDisableControls = !hasStarted || (hasStarted && !hasFinished);
  const isCurrentTrackPlaying = hasStarted && isPlaying;
  const shouldShowReplay = !isPlaying && playbackTime === playbackDuration;
  const hasNoSkip = stimulus.audioSegments.length === 1 && stimulus.audioSegments[0] === 0;

  return (
    <div className={classNames(styles.content, styles.audioPlayer)} ref={audioPlayerContent}>
      <div className={styles.topControls}>
        <FontAwesomeIcon
          icon={faBackward}
          title="Skip backward"
          onClick={handleSkipToTime('backward')}
          className={classNames(styles.skipBackward, {
            [styles.disabled]: hasNoSkip || shouldDisableControls,
          })}
        />
        <FontAwesomeIcon
          icon={
            shouldShowReplay ? faRotateRight : isCurrentTrackPlaying ? faCirclePause : faCirclePlay
          }
          title={isCurrentTrackPlaying ? 'Pause audio' : 'Play audio'}
          onClick={handlePlay}
          className={styles.cue}
        />
        <FontAwesomeIcon
          icon={faForward}
          title="Skip forward"
          onClick={handleSkipToTime('forward')}
          className={classNames(styles.skipForward, {
            [styles.disabled]: hasNoSkip || shouldDisableControls,
          })}
        />
      </div>
      <input
        type="range"
        max={playbackDuration}
        value={playbackTime}
        className={classNames({ [styles.rangeReadonly]: shouldDisableControls })}
        onChange={handleSeekChange}
        id="playerProgress"
      />
    </div>
  );
};
