import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { FormSubmitHandler } from 'redux-form';

import { Basic } from './basic';

interface WizardExistingPatientProps {
  clearPatientType: () => void;
  handleSaveExistingPatient: FormSubmitHandler;
}

export const WizardExistingPatient: FC<WizardExistingPatientProps> = ({
  clearPatientType,
  handleSaveExistingPatient,
}) => {
  return <Basic onSubmit={handleSaveExistingPatient} previousPage={() => clearPatientType()} />;
};

WizardExistingPatient.propTypes = {
  clearPatientType: PropTypes.func.isRequired,
  handleSaveExistingPatient: PropTypes.func.isRequired,
};
