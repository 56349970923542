import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { Link } from 'react-router-dom';

import { SUPPORT } from 'Shared/constants';

import styles from './footer.module.scss';

export const ConfirmationFooter: FC = () => {
  return (
    <div className={styles.confirmationFooter}>
      <CTUI.Font.H4 customClasses={styles.description}>
        You can cancel your subscription at any time. To cancel, go to{' '}
        <Link to="/account">My Account</Link> or call{' '}
        <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a>.{' '}
        <span>
          If you cancel an annual plan before the plan year has ended, you will not receive a refund
          for the unused portion of the plan.{' '}
        </span>
      </CTUI.Font.H4>
    </div>
  );
};
