import React, { Dispatch, FC, RefObject, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faMinusCircle, faBars } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { EnhancedScheduledTaskType, ScheduledTaskTypeStub } from 'Shared/types/schedule';

import { ViewTypes } from './useTaskTypeItem';

import type { Identifier } from 'dnd-core';

import styles from './index.module.scss';

interface DefaultViewProps {
  task: EnhancedScheduledTaskType | ScheduledTaskTypeStub;
  taskItemTypeRef: RefObject<HTMLElement>;
  previewRef: RefObject<HTMLLIElement>;
  taskTypeItemClasses: string;
  handlerId: Identifier;
  setViewType: Dispatch<SetStateAction<ViewTypes>>;
}

export const DefaultView: FC<DefaultViewProps> = ({
  task,
  taskItemTypeRef,
  previewRef,
  taskTypeItemClasses,
  handlerId,
  setViewType,
}) => {
  return (
    <li ref={previewRef} className={taskTypeItemClasses} data-handler-id={handlerId}>
      <FontAwesomeIcon
        icon={faMinusCircle}
        className={styles.deleteIcon}
        title="Delete Task"
        onClick={() => setViewType(ViewTypes.DELETE)}
      />
      <div className={styles.data}>
        <CTUI.Font.Text>{task.displayName}</CTUI.Font.Text>
        <CTUI.Font.Text3 customClasses={styles.levelCount}>
          (Level {task.taskLevel}, {task.taskCount} times)
        </CTUI.Font.Text3>
      </div>
      <FontAwesomeIcon
        icon={faCogs}
        className={styles.settingsIcon}
        title="Adjust level and items count"
        onClick={() => setViewType(ViewTypes.ADJUST)}
      />
      <span ref={taskItemTypeRef}>
        <FontAwesomeIcon
          icon={faBars}
          className={styles.dragIcon}
          title="Drag and Drop to change position"
        />
      </span>
    </li>
  );
};
