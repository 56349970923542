import {
  GET_INSTITUTION,
  GET_USERS,
  FILTER_USERS,
  SUSPEND_USER,
  ACTIVATE_USER,
  REMOVE_USER,
  INVITE_USER,
  RE_INVITE_USER,
} from '../types/redux';
import { USERS_STATUSES } from '../constants';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INSTITUTION.success:
      return {
        ...state,
        institution: action.payload.data,
      };
    case GET_INSTITUTION.fail:
      return {
        ...state,
        institutionError: action.payload.data,
      };
    case GET_USERS.success:
      return {
        ...state,
        usersList: action.payload.data,
        filteredUsersList: action.payload.data,
      };
    case GET_USERS.fail:
      return {
        ...state,
        usersListError: action.payload.data,
      };
    case FILTER_USERS: {
      return {
        ...state,
        filteredUsersList: action.payload.data,
      };
    }
    case SUSPEND_USER.success:
      return {
        ...state,
        usersList: state.usersList.map((user) => {
          if (user.id === action.payload.data.id) {
            return {
              ...user,
              statusDisplay: USERS_STATUSES.SUSPENDED,
            };
          }
          return user;
        }),
        filteredUsersList: state.filteredUsersList.map((user) => {
          if (user.id === action.payload.data.id) {
            return {
              ...user,
              statusDisplay: USERS_STATUSES.SUSPENDED,
            };
          }
          return user;
        }),
      };
    case ACTIVATE_USER.success:
      return {
        ...state,
        usersList: state.usersList.map((user) => {
          if (user.id === action.payload.data.id) {
            return {
              ...user,
              statusDisplay: USERS_STATUSES.ACTIVE,
            };
          }
          return user;
        }),
        filteredUsersList: state.filteredUsersList.map((user) => {
          if (user.id === action.payload.data.id) {
            return {
              ...user,
              statusDisplay: USERS_STATUSES.ACTIVE,
            };
          }
          return user;
        }),
      };
    case REMOVE_USER.success:
      return {
        ...state,
        usersList: state.usersList.filter((user) => user.id !== action.payload.data.id),
        filteredUsersList: state.filteredUsersList.filter(
          (user) => user.id !== action.payload.data.id
        ),
      };
    case INVITE_USER.success:
      return {
        ...state,
        usersList: [
          ...state.usersList,
          ...(state.usersList.find((user) => user.id === action.payload.data.id)
            ? []
            : [action.payload.data]),
        ],
        filteredUsersList: [
          ...state.filteredUsersList,
          ...(state.filteredUsersList.find((user) => user.id === action.payload.data.id)
            ? []
            : [action.payload.data]),
        ],
      };
    case RE_INVITE_USER.success:
    default:
      return state;
  }
}
