import { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

import { SUBSCRIPTION } from 'Shared/types/events';
import { FlashMessageType, RootState, StripeSubscriptionSession } from 'Shared/types/shared';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { getSubscriptionSession } from 'Shared/actions/subscription';
import { logEvent } from 'Shared/actions/event';
import { setFlashMessage } from 'Shared/actions/service';
import config from 'Shared/config.json';

// It's highly recommended to call `loadStripe` outside of React's component lifecycle to avoid
// re-creating it on every render
// @see https://stripe.com/docs/payments/checkout/subscriptions/update-payment-details
const stripePromise = loadStripe(
  config.environment[process.env.ENV as keyof typeof config.environment].stripeKey
);

export function useMain() {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const authUser = useSelector((state: RootState) => state.user.data);
  const queryParams = useQueryParams();

  const [isSkipToStripeLoading, setIsSkipToStripeLoading] = useState(false);

  const source = queryParams.get('source');
  const planId = queryParams.get('planid');
  const token = queryParams.get('token');
  const emailOrUsername = queryParams.get('emailOrUsername');
  const coupon = queryParams.get('cc');

  const sendLogEvent = async () => {
    await logEvent({ page: SUBSCRIPTION.SUBSCRIPTION, data: { source } })(
      dispatch,
      store.getState.bind(store)
    );
  };

  const skipPlanSelectionLogicAndSubscribe = async () => {
    const subscriptionSessionParams = {
      planId,
      token,
      emailOrUsername,
      coupon,
    };

    setIsSkipToStripeLoading(true);

    try {
      const subscriptionSession = (await getSubscriptionSession(subscriptionSessionParams)(
        dispatch
      )) as StripeSubscriptionSession;
      const stripeRef = await stripePromise;
      await stripeRef.redirectToCheckout({ sessionId: subscriptionSession.sessionId });
    } catch (e: unknown) {
      console.log(e);
      setFlashMessage({
        message:
          '<b>Unable to proceed to checkout</b><br />' +
          'Please <a href="/contact-us">contact</a> our support team to help you resolve ' +
          'this issue information.',
        type: FlashMessageType.ERROR,
        keepFor: 10,
      });
    } finally {
      setIsSkipToStripeLoading(false);
    }
  };

  useEffect(() => {
    if (planId) {
      void skipPlanSelectionLogicAndSubscribe();
    } else {
      void sendLogEvent();
    }
  }, [planId]);

  return { authUser, isSkipToStripeLoading };
}
