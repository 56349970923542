import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';

import { retrial } from 'Shared/actions/subscription';
import { logEvent } from 'Shared/actions/event';
import { RETRIAL } from 'Shared/types/events';
import { useQueryParams } from 'Shared/hooks/useQueryParams';

const mailSubject = 'Constant Therapy trial period extension request';

export function useRetrial() {
  const store = useStore();
  const queryParams = useQueryParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isExtended, setIsExtended] = useState(false);
  const token = queryParams.get('token');

  const fetchRetrial = async (token: string) => {
    try {
      await retrial({ token })(store.dispatch);
      setIsExtended(true);
    } catch (e: unknown) {
      console.log(e);
      setIsExtended(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void logEvent({ page: RETRIAL, token })(store.dispatch, store.getState.bind(store));
    void fetchRetrial(token);
  }, [token]);

  return {
    isLoading,
    isExtended,
    mailSubject,
  };
}
