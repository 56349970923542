import React, { FC } from 'react';
import classNames from 'classnames';
import { Stimulus } from 'App/therapy/session/taskViewComponent/stimulus';

import type { TaskView, TaskViewBoardCompletion } from 'Shared/types/shared';

import { type TaskTypeProps } from '../../types';

import { Controls } from './controls';

import sharedStyles from '../index.module.scss';
import styles from './index.module.scss';

export const BoardCompletion: FC<TaskTypeProps<TaskView>> = ({
  task,
  oldTask,
  isAnswered,
  handleAnswer,
  resetAnswer,
  isInitialAudioInstructionsShouldBePlayed,
}) => {
  return (
    <div className={classNames(sharedStyles.taskType, styles.boardCompletion)}>
      {Boolean(task.stimulus && task.stimulus.length) && <Stimulus task={task} />}
      <Controls
        task={task as TaskViewBoardCompletion}
        handleAnswer={handleAnswer}
        isInitialAudioInstructionsShouldBePlayed={isInitialAudioInstructionsShouldBePlayed}
      />
    </div>
  );
};
