import React, { FC } from 'react';
import { isSafari } from 'react-device-detect';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import { InstructionsProps, useInstructions } from './useInstructions';

import styles from './index.module.scss';

export const Instructions: FC<InstructionsProps> = ({
  therapySession,
  taskTypes,
  activeTaskIndex,
  setActiveScreenMode,
}) => {
  const {
    categoryInstructionVideoPath,
    handleStartClick,
    videoRef,
    isVideoShown,
    handlePlayClick,
  } = useInstructions({
    therapySession,
    taskTypes,
    activeTaskIndex,
    setActiveScreenMode,
  });
  return (
    <div className={styles.instructions}>
      <div className={styles.videoWrapper}>
        <video
          controls={true}
          playsInline={isSafari}
          autoPlay={isSafari}
          className={styles.video}
          ref={videoRef}
        >
          <source
            src={`https://ct-res.s3.amazonaws.com/${categoryInstructionVideoPath}`}
            type="video/mp4"
          />
        </video>
        {!isVideoShown && (
          <div className={styles.overlay}>
            <CTUI.Button
              text="Play"
              icon={faPlay}
              variant="primary-inverted"
              customClasses={styles.play}
              onClick={handlePlayClick}
            />
          </div>
        )}
      </div>
      <div className={styles.description}>
        <h2 className="h2">
          Press <b className="h2 bold">Play</b> to see a video of the exercise.
        </h2>
        <h2 className="h2">
          Press <b className="h2 bold">Start</b> to begin
        </h2>
        <CTUI.Button text="Start" onClick={handleStartClick} customClasses={styles.start} />
      </div>
    </div>
  );
};
