import React, { FC } from 'react';
import classNames from 'classnames';

import { StripePlan } from 'Shared/types/shared';

import { Plan } from './plan';

import styles from './body.module.scss';

interface PlansListBodyProps {
  coupon?: string;
  token?: string;
  emailOrUsername?: string;
  subscriptionPlans: StripePlan[];
}

export const PlansListBody: FC<PlansListBodyProps> = ({
  coupon,
  token,
  emailOrUsername,
  subscriptionPlans,
}) => {
  return (
    <div className={styles.plansListBody}>
      {subscriptionPlans && (
        <div className={styles.plans}>
          {subscriptionPlans.map((plan, index) => {
            return (
              <Plan
                plan={plan}
                key={index}
                token={token}
                emailOrUsername={emailOrUsername}
                coupon={coupon}
              />
            );
          })}
        </div>
      )}
      <h3 className={classNames('h3', styles.fsahsaSavings)}>
        We accept health savings account (HSA) and flexible spending account (FSA) cards*
      </h3>
    </div>
  );
};
