import React, { FC } from 'react';
import { Field, InjectedFormProps, DecoratedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import { SURVEY_CAMPAIGN_FORM_NAME } from 'Shared/types/forms';

import { ProgressBar } from '../progressBar';

import sharedStyles from '../shared.module.scss';
import styles from './howAreYourExercisesFeeling.module.scss';

export const difficultyOptions = [
  {
    value: 'easy',
    label: 'Too Easy',
  },
  {
    value: 'perfect',
    label: 'Just Right',
  },
  {
    value: 'hard',
    label: 'Too Hard',
  },
];

const HowAreYourExercisesFeelingComponent: FC<DecoratedFormProps & InjectedFormProps> = ({
  handleSubmit,
  error,
  submit,
}) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.surveyPage}
      header={
        <>
          <ProgressBar currentStep={1} totalSteps={2} />
          <h1 className="h1">How are your exercises feeling?</h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error as string}
        customClasses={styles.howAreYourExercisesFeelingForm}
        actions={<></>}
      >
        <Field
          name="difficulty"
          component={CTUI.Form.RadioGroup}
          type="radioGroup"
          options={difficultyOptions}
          onChange={submit}
          customClasses={styles.radioGroup}
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const HowAreYourExercisesFeeling = reduxForm({
  form: SURVEY_CAMPAIGN_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(HowAreYourExercisesFeelingComponent);

export { HowAreYourExercisesFeeling };
