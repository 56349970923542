import { TherapySessionResponseOldTaskStructure } from 'Shared/types/api';

import { type AnswerResult } from '../useTaskView';

import { BoardCompletion } from './BoardCompletion';
import { MemoryBoard } from './MemoryBoard';
import { MemorySequence } from './MemorySequence';
import { MultipleChoice } from './MultipleChoice';
import { NumberProblemSolving } from './NumberProblemSolving';
import { Production } from './Production';
import { SequenceCompletion } from './SequenceCompletion';

export {
  BoardCompletion,
  MemoryBoard,
  MemorySequence,
  MultipleChoice,
  NumberProblemSolving,
  Production,
  SequenceCompletion,
};

export interface TaskTypeProps<P> {
  task: P;
  oldTask: TherapySessionResponseOldTaskStructure;
  isAnswered: boolean;
  handleAnswer: (result: AnswerResult) => void;
  resetAnswer: () => void;
  isInitialAudioInstructionsShouldBePlayed: boolean;
}
