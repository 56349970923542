import React, { FC } from 'react';
import classNames from 'classnames';

import { TaskTypeItemProps, useTaskTypeItem, ViewTypes } from './useTaskTypeItem';
import { AdjustView } from './adjustView';
import { DeleteView } from './deleteView';
import { DefaultView } from './defaultView';

import styles from './index.module.scss';

export const TaskTypeItem: FC<TaskTypeItemProps> = ({
  index,
  task,
  moveScheduledTasks,
  deleteScheduledTask,
  adjustScheduledTask,
}) => {
  const {
    taskItemTypeRef,
    previewRef,
    handlerId,
    isDragging,
    viewType,
    setViewType,
    handleCancelDeleteButtonClick,
    handleConfirmDeleteButtonClick,
    handleDiscardAdjustmentsButtonClick,
    handleConfirmAdjustmentsButtonClick,
  } = useTaskTypeItem({
    index,
    task,
    moveScheduledTasks,
    deleteScheduledTask,
    adjustScheduledTask,
  });

  const taskTypeItemClasses = classNames(styles.taskTypeItem, {
    [styles.isDragging]: isDragging,
    [styles.deleteView]: viewType === ViewTypes.DELETE,
    [styles.adjustView]: viewType === ViewTypes.ADJUST,
  });

  if (viewType === ViewTypes.DELETE) {
    return (
      <DeleteView
        task={task}
        previewRef={previewRef}
        taskTypeItemClasses={taskTypeItemClasses}
        handlerId={handlerId}
        handleConfirmDeleteButtonClick={handleConfirmDeleteButtonClick}
        handleCancelDeleteButtonClick={handleCancelDeleteButtonClick}
      />
    );
  }

  if (viewType === ViewTypes.ADJUST) {
    return (
      <AdjustView
        task={task}
        previewRef={previewRef}
        taskTypeItemClasses={taskTypeItemClasses}
        handlerId={handlerId}
        handleDiscardAdjustmentsButtonClick={handleDiscardAdjustmentsButtonClick}
        handleConfirmAdjustmentsButtonClick={handleConfirmAdjustmentsButtonClick}
      />
    );
  }

  return (
    <DefaultView
      task={task}
      taskItemTypeRef={taskItemTypeRef}
      previewRef={previewRef}
      taskTypeItemClasses={taskTypeItemClasses}
      handlerId={handlerId}
      setViewType={setViewType}
    />
  );
};
