import React, { FC } from 'react';
import classNames from 'classnames';

import { SUPPORT } from 'Shared/constants';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import styles from './errorCalendar.module.scss';

interface ErrorCalendarProps {
  calendarError: string | { message?: string };
}

export const ErrorCalendar: FC<ErrorCalendarProps> = ({ calendarError }) => {
  let errorMessage = 'Unable to get calendar data';
  if (calendarError && typeof calendarError === 'string') {
    errorMessage = calendarError;
  }
  if (calendarError && typeof calendarError === 'object') {
    errorMessage = calendarError.message;
  }

  return (
    <FloatingPage
      customClasses={styles.errorCalendar}
      header={<h1 className={classNames('h1', styles.title)}>Calendar Error</h1>}
    >
      <p>{errorMessage}</p>
      <br />
      <p>
        Please reach out to Customer Support at{' '}
        <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a> or{' '}
        <a href={`mailto:${SUPPORT.EMAIL}`}>{SUPPORT.EMAIL}</a> if the error persists.
      </p>
    </FloatingPage>
  );
};
