import React, { FC, Fragment } from 'react';

import { PerformanceReportAssessment } from 'Shared/types/shared';

import { Item } from './item';

import styles from './assessments.module.scss';

interface AssessmentsProps {
  assessments: PerformanceReportAssessment[];
}

export const Assessments: FC<AssessmentsProps> = ({ assessments: assessmentsSections }) => {
  return (
    <div className={styles.assessmentsSection}>
      <header className={styles.topDivision}>
        <h2 className={styles.sectionTitle}>Assessment Results</h2>
        <p className={styles.sectionDescription}>
          Below you can see how your client has rated themselves on our skill-based self-assessments
          within the Constant Therapy Program.
        </p>
      </header>
      {assessmentsSections.map((section) => {
        return (
          <Fragment key={section.id}>
            <div className={styles.subSection}>
              <h3 className={styles.subSectionTitle}>{section.title}</h3>
              <dl className={styles.subSectionDescription}>
                <dt>Description:</dt>
                <dd>{section.description}</dd>
              </dl>
            </div>
            <ul className="questions">
              {section.questions.map((question) => {
                return <Item key={question.id} question={question} />;
              })}
            </ul>
          </Fragment>
        );
      })}
    </div>
  );
};
