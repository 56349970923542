import { Dispatch } from 'redux';
import queryString from 'query-string';
import fetch from 'isomorphic-fetch';

import { fetchWrapper } from 'Shared/fetchWrapper';
import { getBaseUrl } from 'Shared/utils';
import {
  SCHEDULED_SESSION,
  THERAPY_ACTIVITY_INITIAL_SCREEN,
  THERAPY_ACTIVITY_RESUME_SCREEN,
  THERAPY_ACTIVITY_FINALIZE,
  THERAPY_COMPLETE_TASK,
  THERAPY_RECOGNIZE_SPEECH,
} from 'Shared/types/redux';
import {
  TherapyActivity,
  TherapySessionResponse,
  TherapySessionResponseOldTaskStructure,
} from 'Shared/types/api';
import { TaskDoneRequestBody } from 'Shared/types/task';

export const loadActivityInitialScreen =
  ({ patientId }: { patientId: number }) =>
  async (dispatch: Dispatch) => {
    return (await fetchWrapper.get(`${getBaseUrl()}/api/patient/${patientId}/nextActivity`, {
      dispatch,
      types: THERAPY_ACTIVITY_INITIAL_SCREEN,
    })) as TherapyActivity;
  };

export const loadActivityResumeScreen =
  ({ patientId }: { patientId: number }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        previousActivity: 'TAPPED_HOME_IN_TASK',
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return (await fetchWrapper.get(
      `${getBaseUrl()}/api/patient/${patientId}/nextActivity?${formattedParams}`,
      {
        dispatch,
        types: THERAPY_ACTIVITY_RESUME_SCREEN,
      }
    )) as TherapyActivity;
  };

export const activityFinalize =
  ({ patientId }: { patientId: number }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        previousActivity: 'TAPPED_HOMESCREEN_BUTTON',
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return (await fetchWrapper.get(
      `${getBaseUrl()}/api/patient/${patientId}/nextActivity?${formattedParams}`,
      {
        dispatch,
        types: THERAPY_ACTIVITY_FINALIZE,
      }
    )) as TherapyActivity;
  };

export const startScheduledSession =
  ({ patientId, scheduleId }: { patientId: number; scheduleId: number }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
        scheduleId,
        os: 1,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return (await fetchWrapper.get(
      `${getBaseUrl()}/api/patient/${patientId}/session/start/SCHEDULED?${formattedParams}`,
      { dispatch, types: SCHEDULED_SESSION }
    )) as TherapySessionResponse;
  };

export const completeTask =
  ({ patientId, requestBody }: { patientId: number; requestBody: TaskDoneRequestBody[] }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return await fetchWrapper.post(
      `${getBaseUrl()}/api/patient/${patientId}/responses?${formattedParams}`,
      { dispatch, types: THERAPY_COMPLETE_TASK },
      requestBody
    );
  };

interface RecognizeSpeechParams {
  patientId: number;
  taskTypeId: number;
  words: string[];
  soundPaths: string[];
  task: string;
  recording: Blob;
}

export const recognizeSpeech =
  ({ patientId, taskTypeId, words, soundPaths, task, recording }: RecognizeSpeechParams) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
        sampleRate: 16000,
        taskTypeId,
        words: words.join(),
        soundPaths: soundPaths.join(),
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    const body = new FormData();
    body.append('task', task);
    body.append('recording', recording);

    return await fetchWrapper.post(
      `${getBaseUrl()}/api/patient/${patientId}/task/speech-recognition_v3?${formattedParams}`,
      { dispatch, types: THERAPY_RECOGNIZE_SPEECH },
      body,
      { headers: {} }
    );
  };
