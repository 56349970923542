import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import md5 from 'md5';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { ScheduleComponent } from '../../shared/scheduleComponent';

import { ScheduleEmpty } from './empty';
import { useExpandableRowSchedule, ExpandableRowScheduleProps } from './useExpandableRowSchedule';

import sharedStyles from '../widget.module.scss';
import styles from './index.module.scss';

export const ExpandableRowSchedule: FC<ExpandableRowScheduleProps> = ({
  currentSchedule,
  patientData,
}) => {
  const {
    trimmedCurrentSchedule,
    shouldShowSchedule,
    shouldShowScheduleComponent,
    showScheduleComponent,
    hideScheduleComponent,
  } = useExpandableRowSchedule({
    currentSchedule,
    patientData,
  });
  const scheduleClasses = classNames(sharedStyles.widget, styles.schedule, {
    [styles.withSchedule]: shouldShowSchedule,
  });

  return (
    <div className={scheduleClasses}>
      <h4 className="h4">Current Homework Schedule</h4>
      {shouldShowSchedule && (
        <>
          <ol className={styles.list}>
            {trimmedCurrentSchedule.map((scheduleItem) => {
              return (
                <li className={styles.item} key={scheduleItem.taskName}>
                  {scheduleItem.isFailing && (
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      title="We recommend assigning an easier task in this domain"
                      className={styles.iconFailing}
                    />
                  )}
                  {scheduleItem.taskName}
                </li>
              );
            })}
            {trimmedCurrentSchedule.length !== currentSchedule.length && (
              <li className={styles.extra}>
                <a href="#">
                  + {currentSchedule.length - trimmedCurrentSchedule.length} other tasks
                </a>
              </li>
            )}
          </ol>
          <a
            title="Edit Schedule"
            href="#"
            className={classNames('yeahBabe', sharedStyles.link, styles.editLink)}
            onClick={showScheduleComponent}
          >
            Edit Schedule{' '}
            <FontAwesomeIcon icon={faChevronRight} className={sharedStyles.chevronRightIcon} />
          </a>
          {shouldShowScheduleComponent && (
            <ScheduleComponent
              patientId={patientData.patientId}
              hideScheduleComponent={hideScheduleComponent}
            />
          )}
        </>
      )}
      {!shouldShowSchedule && (
        <>
          <ScheduleEmpty />
          <Link
            to={`/dashboard/request-brochure/${md5(
              patientData.patientId.toString()
            )}/requestBrochureView`}
            className={classNames('yeahBabe', sharedStyles.link)}
          >
            Learn More{' '}
            <FontAwesomeIcon icon={faChevronRight} className={sharedStyles.chevronRightIcon} />
          </Link>
        </>
      )}
    </div>
  );
};
