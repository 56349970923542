import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { getDownloadDestination } from 'Shared/utils';
import { logLinkRouterEvent } from 'Shared/actions/event';
import { USER_TYPES } from 'Shared/types/shared';
import { User } from 'Shared/types/shared';

export interface DownloadProps {
  authUser: User;
}

export function useDownload({ authUser }: DownloadProps) {
  const dispatch: Dispatch = useDispatch();
  const { pathname } = useLocation();

  let downloadDestination = getDownloadDestination(authUser);

  if (pathname.includes('/download-clinician')) {
    downloadDestination = getDownloadDestination({ user: { type: USER_TYPES.CLINICIAN } } as User);
  }

  if (pathname.includes('/download-patient')) {
    downloadDestination = getDownloadDestination({ user: { type: USER_TYPES.PATIENT } } as User);
  }

  const handleEventLogging = async (downloadDestination: string) => {
    try {
      await logLinkRouterEvent(downloadDestination)(dispatch);
    } finally {
      document.location.assign(downloadDestination);
    }
  };

  useEffect(() => {
    void handleEventLogging(downloadDestination);
  }, [downloadDestination]);
}
