import { useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { logLinkRouterEvent } from 'Shared/actions/event';
import { User, USER_TYPES } from 'Shared/types/shared';

export interface StartProps {
  authUser: User;
}

export function useStart({ authUser }: StartProps) {
  const dispatch: Dispatch = useDispatch();
  const queryParams = queryString.parse(location.search);
  const userType = (queryParams.userType as USER_TYPES) || (authUser && authUser.user.type);

  useEffect(() => {
    void logLinkRouterEvent('start')(dispatch);
  }, []);

  return { userType };
}
