import { useState } from 'react';

export function useDailySessionNoteAction() {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [tooltipRef, setTooltipRef] = useState<HTMLElement>(null);
  const [shouldShowDailySessionPopup, setShouldShowDailySessionPopup] = useState(false);

  return {
    isTooltipShown,
    showTooltip: () => {
      setIsTooltipShown(true);
    },
    hideTooltip: () => {
      setIsTooltipShown(false);
    },
    tooltipRef,
    setTooltipRef,
    shouldShowDailySessionPopup,
    showDailySessionPopup: () => {
      setShouldShowDailySessionPopup(true);
    },
    hideDailySessionPopup: () => {
      setShouldShowDailySessionPopup(false);
    },
  };
}
