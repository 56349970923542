import React, { FC } from 'react';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { SharedModalProps } from './sharedModalProps';

import sharedModalStyles from './sharedModalStyles.module.scss';
import styles from './reInviteUser.module.scss';

export const ReInviteUserModal: FC<SharedModalProps> = ({
  email,
  isLoading,
  handleModalSubmit,
  closeActiveModal,
  modalSubmissionError,
  modalRef,
}) => {
  return (
    <CTUI.Modal
      ref={modalRef}
      header={
        <h1 className="h1">
          <FontAwesomeIcon
            icon={faUserPlus}
            className={classNames(sharedModalStyles.icon, styles.icon)}
          />
          Re-Invite User
        </h1>
      }
      headerClasses={sharedModalStyles.header}
      customClasses={sharedModalStyles.actionCellModal}
      closeButtonHandler={closeActiveModal}
      footer={
        <>
          <CTUI.Form.SubmitButton
            isSubmitting={isLoading}
            isPristine={false}
            onClick={handleModalSubmit}
            icon={faPaperPlane}
            text="Re-send invitation"
          />
          <CTUI.Form.ResetButton
            isSubmitting={isLoading}
            isPristine={false}
            onClick={closeActiveModal}
            text="Cancel"
          />
        </>
      }
      footerClasses={sharedModalStyles.footer}
      error={modalSubmissionError}
    >
      <div className={sharedModalStyles.extraMargin}>
        <h4 className={classNames('h4', sharedModalStyles.message)}>
          You are about to re-invite user <b>{`<${email}>`}</b>. Are you sure you want to proceed?
        </h4>
      </div>
    </CTUI.Modal>
  );
};
