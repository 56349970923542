import React, { FC, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileText } from '@fortawesome/free-regular-svg-icons';

import { DocumentationExportMenu } from './documenationExportMenu';

import styles from './index.module.scss';

interface DesktopMenuProps {
  showProgressPopup: (e: SyntheticEvent) => void;
  showDailySessionPopup: (e: SyntheticEvent) => void;
  showDischargePopup: (e: SyntheticEvent) => void;
  surveillanceMode?: boolean;
  patientId?: string;
}

export const DesktopMenu: FC<DesktopMenuProps> = ({
  showProgressPopup,
  showDailySessionPopup,
  showDischargePopup,
  surveillanceMode,
  patientId,
}) => {
  const routeBase =
    surveillanceMode && patientId ? `/dashboard/patient/${patientId}` : '/dashboard';

  return (
    <ul className={styles.desktopMenu}>
      <li>
        <NavLink
          to={routeBase}
          isActive={(match, location) => {
            return !!match || location.pathname.includes(`${routeBase}/report`);
          }}
          activeClassName={styles.active}
          exact
        >
          Performance Summary
        </NavLink>
      </li>
      <li>
        <NavLink to={`${routeBase}/detailed-performance`} activeClassName={styles.active} exact>
          Detailed Performance
        </NavLink>
      </li>
      <li>
        <NavLink to={`${routeBase}/calendar`} activeClassName={styles.active} exact>
          Calendar
        </NavLink>
      </li>
      <li className={styles.documentationExportLink}>
        {surveillanceMode ? (
          <DocumentationExportMenu
            showProgressPopup={showProgressPopup}
            showDailySessionPopup={showDailySessionPopup}
            showDischargePopup={showDischargePopup}
          />
        ) : (
          <a href="#" onClick={showProgressPopup}>
            <FontAwesomeIcon icon={faFileText} />
            <span>Progress Note</span>
          </a>
        )}
      </li>
    </ul>
  );
};
