import React, { ReactNode } from 'react';

import { BackToTop } from './backToTop';
import { Footer } from './footer';
import { Header } from './header';
import { FlashMessage } from './flashMessage';
import { useMainLayout } from './useMainLayout';

export const MainLayout = ({ children }: { children: ReactNode }) => {
  useMainLayout();

  return (
    <>
      <Header />
      <FlashMessage />
      {children}
      <BackToTop />
      <Footer />
    </>
  );
};
