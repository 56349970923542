import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';
import { SCREEN_MODES } from 'App/therapy/session/useSession';

import type { TherapySessionResponse } from 'Shared/types/api';
import { SkipReason, SkipType, TaskType } from 'Shared/types/task';

export interface HeaderProps {
  therapySession: TherapySessionResponse;
  taskTypes: TaskType[];
  activeTaskIndex: number;
  skipTask: ({ type, reason }: { type: SkipType; reason: SkipReason }) => Promise<void>;
  activeScreenMode: SCREEN_MODES;
  setScheduleId: Dispatch<SetStateAction<number>>;
}

export function useHeader({
  therapySession,
  taskTypes,
  activeTaskIndex,
  activeScreenMode,
}: HeaderProps) {
  const skipModalRef = useRef(null);
  const watchModalRef = useRef(null);
  const [isSkipTaskModalShown, setIsSkipTaskModalShown] = useState(false);
  const [isWatchTutorialModalShown, setIsWatchTutorialModalShown] = useState(false);

  const activeCategory = taskTypes.find(
    (type) => type.defaultViewClassName === therapySession.taskViews[activeTaskIndex].templateName
  );

  const categoryName = activeCategory.displayName;
  const categoryInstructionVideoPath = activeCategory.instructionalVideoPath;

  useClickOutside(skipModalRef, () => {
    if (skipModalRef.current) {
      setIsSkipTaskModalShown(false);
    }
  });

  useClickOutside(watchModalRef, () => {
    if (watchModalRef.current) {
      setIsWatchTutorialModalShown(false);
    }
  });

  return {
    skipModalRef,
    isSkipTaskModalShown,
    showSkipModal: () => {
      setIsSkipTaskModalShown(true);
    },
    hideSkipModal: () => {
      setIsSkipTaskModalShown(false);
    },
    watchModalRef,
    isWatchTutorialModalShown,
    showWatchModal: () => {
      setIsWatchTutorialModalShown(true);
    },
    hideWatchModal: () => {
      setIsWatchTutorialModalShown(false);
    },
    categoryName,
    categoryInstructionVideoPath,
  };
}
