import React, { FC } from 'react';
import { Cues } from 'App/therapy/session/taskViewComponent/cues';

import { Header } from './header';
import { Footer } from './footer';
import { AnswerResultComponent } from './answerResult';
import * as Types from './types';
import { type TaskViewComponentProps, useTaskViewComponent } from './useTaskView';

import styles from './index.module.scss';

export const TaskViewComponent: FC<TaskViewComponentProps> = ({ task, oldTask, solveTask }) => {
  const {
    isAnswered,
    answerResult,
    handleAnswer,
    resetAnswer,
    isPlaying,
    handlePlayAudioSequenceClick,
    isInitialAudioInstructionsShouldBePlayed,
  } = useTaskViewComponent({
    task,
    oldTask,
  });
  const viewType = task.type as keyof typeof Types;

  return (
    <div className={styles.taskView}>
      <Header
        title={task.instructions.text}
        isAudioSequencePlaying={isPlaying}
        playAudioSequence={handlePlayAudioSequenceClick}
      />
      <div className={styles.body}>
        {Boolean(task.stimulus && task.stimulus.length && task.stimulus[0].cues.length) && (
          <Cues cues={task.stimulus[0].cues} resourceUrl={task.resourceUrl} />
        )}
        {React.createElement(Types[viewType], {
          task,
          oldTask,
          isAnswered,
          handleAnswer,
          resetAnswer,
          isInitialAudioInstructionsShouldBePlayed,
        })}
      </div>
      <Footer isAnswered={isAnswered} solveTask={solveTask} />
      <AnswerResultComponent answerResult={answerResult} />
    </div>
  );
};
