import React, { FC } from 'react';
import classNames from 'classnames';

import type { TaskViewSequenceCompletion } from 'Shared/types/shared';

import { useControls } from './useControls';
import { Choice } from './choice';
import { Placeholder } from './placeholder';

import type { AnswerResult } from '../../../useTaskView';

import styles from './index.module.scss';
import templateSpecificStyles from './templateSpecific.module.scss';

interface ControlsProps {
  task: TaskViewSequenceCompletion;
  handleAnswer: (result: AnswerResult) => void;
}

export const Controls: FC<ControlsProps> = ({
  task: { resourceUrl, choices, choicesLayout, placeholders, placeholdersLayout, templateName },
  handleAnswer,
}) => {
  const { itemsList, placeholdersList, handleDrop } = useControls({
    choices,
    placeholders,
    handleAnswer,
    resourceUrl,
  });

  return (
    <div
      className={classNames(styles.controls, {
        [templateSpecificStyles[templateName]]: true,
      })}
    >
      <ul
        className={classNames(styles.itemsList, templateSpecificStyles.itemsList, {
          [styles[choicesLayout]]: true,
        })}
      >
        {itemsList.map((choice, key) => {
          return <Choice key={key} index={key} choice={choice} resourceUrl={resourceUrl} />;
        })}
      </ul>
      <ul
        className={classNames(styles.placeholdersList, templateSpecificStyles.placeholdersList, {
          [styles[placeholdersLayout]]: true,
        })}
      >
        {placeholdersList.map((placeholder, key) => {
          return (
            <Placeholder
              key={key}
              placeholder={placeholder}
              accept={choices.map((choice) => choice.value)}
              onDrop={(item) => handleDrop(key, item)}
              resourceUrl={resourceUrl}
            />
          );
        })}
      </ul>
    </div>
  );
};
