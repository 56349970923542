import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';

import { USER_TYPES } from 'Shared/types/shared';

import { INVITATION_FORM_NAME } from './constants';

import styles from './invitationForm.module.scss';

const InvitationFormComponent: FC<InjectedFormProps> = ({
  error,
  handleSubmit,
  pristine,
  reset,
  submitting,
}) => {
  return (
    <CTUI.Form.Form
      name="invitationForm"
      handleSubmit={handleSubmit}
      customClasses={styles.invitationForm}
      error={error}
      actionsWrapperClasses={styles.actions}
      actions={
        <>
          <CTUI.Form.SubmitButton
            isSubmitting={submitting}
            isPristine={pristine}
            text="Send Invite"
            icon={faPaperPlane}
            customClasses={styles.submitButton}
          />
          <CTUI.Form.ResetButton
            isSubmitting={submitting}
            isPristine={pristine}
            text="Reset"
            customClasses={styles.resetButton}
            onClick={reset}
          />
        </>
      }
    >
      <p className={styles.description}>
        <CTUI.Font.Text>
          Once you click `Send Invite`, the user will receive an email and instructions how to reset
          (set) their password to obtain access to the application and start using Constant Therapy.
        </CTUI.Font.Text>
      </p>
      <Field
        name="email"
        type="email"
        label="Email"
        required
        autoFocus
        autoComplete="on"
        component={CTUI.Form.Field}
      />
      <Field
        name="type"
        type="select"
        label="User Type"
        required
        component={CTUI.Form.Select}
        options={[
          <option value={USER_TYPES.CLINICIAN} key={USER_TYPES.CLINICIAN}>
            {USER_TYPES.CLINICIAN}
          </option>,
          <option value={USER_TYPES.PATIENT} key={USER_TYPES.PATIENT}>
            {USER_TYPES.PATIENT}
          </option>,
        ]}
      />
      <Field name="institutionId" type="hidden" required component={CTUI.Form.Field} />
    </CTUI.Form.Form>
  );
};

const InvitationForm = reduxForm({ form: INVITATION_FORM_NAME })(InvitationFormComponent);

export { InvitationForm };
