import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faCircleArrowLeft, faCirclePlus } from '@fortawesome/free-solid-svg-icons';

import { TaskType, TaskTypeCategory } from 'Shared/types/task';
import { Spinner } from 'Shared/components/spinner';

import { useAllTasksModal } from './useModal';

import { AllTasks } from '.';

import styles from './modal.module.scss';

interface AllTasksModalProps {
  tasksHierarchy: TaskTypeCategory;
  modalCloseHandler: () => Promise<void>;
  hideTasksHierarchyInPortraitMode: () => void;
  appendTasksToScheduledTasksList: (items: TaskType[]) => void;
}

export const AllTasksModal: FC<AllTasksModalProps> = ({
  modalCloseHandler,
  tasksHierarchy,
  hideTasksHierarchyInPortraitMode,
  appendTasksToScheduledTasksList,
}) => {
  const { modalRef, selectedItems, toggleItemSelection } = useAllTasksModal({
    modalCloseHandler,
  });

  return (
    <CTUI.Modal
      ref={modalRef}
      id={styles.allTasksModal}
      size={CTUI.ModalSizes.LARGE}
      header={<h1 className="h1">All Tasks</h1>}
      closeButtonHandler={() => {
        void modalCloseHandler();
      }}
      footer={
        <>
          <CTUI.Button
            icon={faCircleArrowLeft}
            customClasses={styles.addMoreTaskButton}
            text="Back"
            variant="secondary"
            onClick={hideTasksHierarchyInPortraitMode}
          />
          <CTUI.Button
            icon={faCirclePlus}
            customClasses={styles.saveChangesButton}
            text={`Add ${selectedItems.length} tasks`}
            disabled={!selectedItems.length}
            onClick={() => {
              appendTasksToScheduledTasksList(selectedItems);
              hideTasksHierarchyInPortraitMode();
            }}
          />
        </>
      }
    >
      <div className={styles.bodyContent}>
        {tasksHierarchy ? (
          <AllTasks
            tasksHierarchy={tasksHierarchy}
            selectedItems={selectedItems}
            toggleItemSelection={toggleItemSelection}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </CTUI.Modal>
  );
};
