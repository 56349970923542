import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DefaultActivation } from './default';
import { FlintActivation } from './flint';
import { HomeworkInvitationActivation } from './homeworkInvitation';
import { ClinicianSetup } from './clinicianSetup';

export const Activate: FC = (props: any) => {
  return (
    <Switch>
      <Route
        exact
        path="/activate/"
        render={(routerProps) => <DefaultActivation {...routerProps} {...props} />}
      />
      <Route
        exact
        path="/activate/flint"
        render={(routerProps) => <FlintActivation {...routerProps} {...props} />}
      />
      <Route
        exact
        path="/activate/homework-invitation"
        render={(routerProps) => <HomeworkInvitationActivation {...routerProps} {...props} />}
      />
      <Route
        exact
        path="/activate/clinician-setup"
        render={(routerProps) => <ClinicianSetup {...routerProps} {...props} />}
      />
    </Switch>
  );
};
