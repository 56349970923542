import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment-timezone';
import { Dispatch } from 'redux';

import { getPatientCalendar } from 'Shared/actions/patient';
import { RootState, User } from 'Shared/types/shared';

export interface CalendarProps {
  patient: User;
}

export function useCalendar({ patient }: CalendarProps) {
  const dispatch: Dispatch = useDispatch();
  const calendar = useSelector((state: RootState) => state.patient.calendar);
  const calendarError = useSelector((state: RootState) => state.patient.calendarError);
  const [isLoading, setIsLoading] = useState(true);
  const initialVisibleMonth = () => moment().subtract(2, 'months');
  const numberOfMonthsToDisplay = isMobileOnly ? 1 : 3;

  const fetchPatientCalendar = async ({ patient }: { patient: User }) => {
    try {
      await getPatientCalendar({ patientId: patient.user.userId })(dispatch);
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchPatientCalendar({ patient });
  }, [patient]);

  return {
    isLoading,
    calendar,
    calendarError,
    initialVisibleMonth,
    numberOfMonthsToDisplay,
  };
}
