import React, { FC } from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { PATIENT_ACTIVATION_FORM_NAME } from 'Shared/types/forms';

import sharedStyles from 'App/activate/shared/shared.module.scss';

const areasList = [
  {
    value: 'auditory_comprehension',
    label: 'Listening & understanding',
  },
  {
    value: 'attention',
    label: 'Staying focused',
  },
  {
    value: 'executive_functioning',
    label: 'Planning & organizing',
  },
  {
    value: 'visual',
    label: 'Understanding what you see',
  },
  {
    value: 'reading',
    label: 'Reading',
  },
  {
    value: 'quantitative',
    label: 'Using math & numbers',
  },
  {
    value: 'memory',
    label: 'Remembering',
  },
  {
    value: 'writing',
    label: 'Writing',
  },
  {
    value: 'speaking',
    label: 'Speaking',
  },
];

interface AreasComponentProps {
  previousPage: () => void;
}

const AreasComponent: FC<AreasComponentProps & InjectedFormProps<object, AreasComponentProps>> = ({
  handleSubmit,
  previousPage,
  error,
  pristine,
  submitting,
}) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.wizardPage}
      header={
        <>
          <ProgressBar currentStep={5} totalSteps={5} />
          <h1 className="h1">Which areas do you want to improve? Check all that apply.</h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses="flintActivationForm"
        actions={
          <>
            <CTUI.Button
              type="button"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              text="&larr; Back"
            />
            <CTUI.Form.SubmitButton
              variant="primary"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
              text="Finish"
            />
          </>
        }
      >
        <Field
          name="areas"
          component={CTUI.Form.CheckboxGroup}
          options={areasList}
          type="checkboxGroup"
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Areas = reduxForm<object, AreasComponentProps>({
  form: PATIENT_ACTIVATION_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AreasComponent);

export { Areas };
