import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';

import { User } from 'Shared/types/shared';

import { Meta } from './meta';
import { MenuComponent } from './menu';
import { Profile } from './profile';
import { PasswordComponent } from './password';
import { Navigation } from './navigation';

import styles from './index.module.scss';

interface AccountProps {
  authUser: User;
}

export const Account: FC<AccountProps> = ({ authUser }) => {
  const { isPortrait } = useMobileOrientation();

  return (
    <div className={styles.account}>
      <Meta />
      {isMobileOnly && isPortrait ? (
        <Navigation authUser={authUser} />
      ) : (
        <MenuComponent authUser={authUser} />
      )}
      <Switch>
        <Route exact path="/account/">
          <Profile authUser={authUser} />
        </Route>
        <Route exact path="/account/password">
          <PasswordComponent authUser={authUser} />
        </Route>
      </Switch>
    </div>
  );
};
