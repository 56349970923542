import { Dispatch } from 'redux';
import { ClinicianSignupForm, PatientSignupForm } from 'App/signup/constants';

import { fetchWrapper } from 'Shared/fetchWrapper';
import { getBaseUrl } from 'Shared/utils';
import {
  SIGNUP_SELECT_USER_TYPE,
  SIGNUP_CLEAR_USER_TYPE,
  SIGNUP_SET_EMAIL,
  SIGNUP_SET_INSTITUTION_ID,
  SIGNUP_SET_INVITATION_TOKEN,
  SIGNUP_SET_SOURCE,
  SIGNUP_CLINICIAN_SIGNUP,
  SIGNUP_PATIENT_SIGNUP,
} from 'Shared/types/redux';
import { USER_TYPES } from 'Shared/types/shared';
import { SignupResponse } from 'Shared/types/api';

export const selectUserType = (userType: USER_TYPES) => (dispatch: Dispatch) => {
  dispatch({
    type: SIGNUP_SELECT_USER_TYPE,
    payload: userType,
  });
};

export const clearUserType = () => (dispatch: Dispatch) => {
  dispatch({
    type: SIGNUP_CLEAR_USER_TYPE,
    payload: null,
  });
};

export const setSource = (source: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SIGNUP_SET_SOURCE,
    payload: source,
  });
};

export const setEmail = (email: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SIGNUP_SET_EMAIL,
    payload: email,
  });
};

export const setInvitationToken = (invitationToken: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SIGNUP_SET_INVITATION_TOKEN,
    payload: invitationToken,
  });
};

export const setInstitutionId = (institutionId: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SIGNUP_SET_INSTITUTION_ID,
    payload: institutionId,
  });
};

type ClinicianSignupData = Omit<ClinicianSignupForm, 'userType' | 'institutionId'> & {
  platform: string;
  clientIpAddress: string;
  requestId: string;
};

export const signupClinician = (data: ClinicianSignupData) => async (dispatch: Dispatch) => {
  return (await fetchWrapper.post(
    `${getBaseUrl({ apiSection: 'userSignup' })}/api/clinician/create`,
    {
      dispatch,
      types: SIGNUP_CLINICIAN_SIGNUP,
    },
    data,
    { isSubmission: true, skipAccessToken: true }
  )) as SignupResponse;
};

type PatientSignupData = Omit<PatientSignupForm, 'userType' | 'institutionId'> & {
  platform: string;
  clientIpAddress: string;
  requestId: string;
};

export const signupPatient = (data: PatientSignupData) => async (dispatch: Dispatch) => {
  return (await fetchWrapper.post(
    `${getBaseUrl({ apiSection: 'userSignup' })}/api/patient/create`,
    {
      dispatch,
      types: SIGNUP_PATIENT_SIGNUP,
    },
    data,
    { isSubmission: true, skipAccessToken: true }
  )) as SignupResponse;
};
