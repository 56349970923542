import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { User, USER_TYPES } from 'Shared/types/shared';
import { PageError } from 'Shared/components/pageError';

import { useTherapy } from './useTherapy';
import { Session } from './session';
import { Welcome } from './welcome';

interface TherapyProps {
  authUser: User;
}

export const Therapy: FC<TherapyProps> = ({ authUser }) => {
  const { scheduleId, setScheduleId, activeTaskIndex, setActiveTaskIndex } = useTherapy();

  if (authUser.user.type !== USER_TYPES.PATIENT) {
    return (
      <PageError
        error={{ title: 'Access denied', message: 'This section is available for patients only!' }}
      />
    );
  }

  return (
    <Switch>
      <Route
        exact
        path="/do-therapy/"
        render={(routeProps) => (
          <Welcome
            {...routeProps}
            authUser={authUser}
            scheduleId={scheduleId}
            setScheduleId={setScheduleId}
          />
        )}
      />
      <Route
        exact
        path="/do-therapy/:scheduleId"
        render={(routeProps) => (
          <Session
            {...routeProps}
            authUser={authUser}
            scheduleId={scheduleId}
            setScheduleId={setScheduleId}
            activeTaskIndex={activeTaskIndex}
            setActiveTaskIndex={setActiveTaskIndex}
          />
        )}
      />
    </Switch>
  );
};
