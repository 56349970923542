import { useRef, useState } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

export function useSubscribe2Unlock() {
  const [shouldShowModal, setShouldShowModal] = useState(true);
  const modalRef = useRef(null);

  const modalCloseHandler = () => {
    setShouldShowModal(false);
  };

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      modalCloseHandler();
    }
  });

  return {
    modalRef,
    shouldShowModal,
    modalCloseHandler,
  };
}
