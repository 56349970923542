import React, { FC, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { HTML_CONTENT_ID } from 'Shared/constants';

import { useTooltip, TooltipProps } from './useTooltip';

import styles from './tooltip.module.scss';

const mountingPoint = document.getElementById(HTML_CONTENT_ID);

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  referenceElement,
  children,
  options = {},
}) => {
  const { popper, arrow } = useTooltip({ referenceElement, options });
  const theme = options.theme || 'dark';
  const tooltipClassNames = classNames(styles.tooltip, options.classes, {
    [styles[theme]]: true,
  });

  const tooltipBody = (
    <div className={tooltipClassNames} ref={popper.ref} style={popper.style} {...popper.attributes}>
      {children}
      <div ref={arrow.ref} style={arrow.style} className={styles.arrow} />
    </div>
  );

  //TODO: need to move into default options
  if (options.usePortal) {
    return ReactDOM.createPortal(tooltipBody, mountingPoint);
  }

  return tooltipBody;
};
