import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { CLINICIAN_SETTING } from 'Shared/constants';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { CLINICIAN_SIGNUP_FORM_NAME } from '../../constants';

import sharedStyles from '../shared.module.scss';
import styles from './extra.module.scss';

const settingOptions = [
  <option value="" disabled={true} key="initialSelection">
    Select an option
  </option>,
  ...Object.entries(CLINICIAN_SETTING).map(([key, value]) => {
    return (
      <option value={key} key={key}>
        {value}
      </option>
    );
  }),
];

interface ExtraComponentProps {
  previousPage: () => void;
  totalSteps: number;
}

const ExtraComponent: FC<ExtraComponentProps & InjectedFormProps<object, ExtraComponentProps>> = ({
  handleSubmit,
  previousPage,
  error,
  pristine,
  submitting,
  totalSteps,
}) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.wizardPage}
      header={
        <>
          <ProgressBar currentStep={3} totalSteps={totalSteps} />
          <h1 className="h1">
            Tell us about <b>your job</b>
          </h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text="Finish"
              variant="primary"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="setting"
          component={CTUI.Form.Select}
          type="select"
          label="What care setting do you work in?"
          options={settingOptions}
          required
          autofocus
        />
        <Field
          name="privatePractice"
          component={CTUI.Form.Checkbox}
          type="checkbox"
          label="In private practice"
        />
        <Field
          name="facilityName"
          component={CTUI.Form.Field}
          type="text"
          label="Facility or business name"
          required
        />
        <Field
          name="mailingAddress"
          component={CTUI.Form.Field}
          type="text"
          label="Street address"
          required
        />
        <Field
          name="zipCode"
          component={CTUI.Form.Field}
          type="text"
          label="Zip or postal code"
          required
          customClasses={styles.zipCode}
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Extra = reduxForm<object, ExtraComponentProps>({
  form: CLINICIAN_SIGNUP_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ExtraComponent);

export { Extra };
