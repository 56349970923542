import React, { FC } from 'react';
import { CategoryBar } from 'App/therapy/session/progressBar/categoryBar';

import { Tooltip } from 'Shared/components/tooltip';

import { useProgressBar, type ProgressBarProps } from './useProgressBar';

import styles from './index.module.scss';

export const ProgressBar: FC<ProgressBarProps> = ({
  therapySession,
  taskTypes,
  activeTaskIndex,
}) => {
  const {
    isTooltipShown,
    showTooltip,
    hideTooltip,
    tooltipRef,
    setTooltipRef,
    categoriesList,
    categoriesListRef,
    baseCategoryBarWidth,
  } = useProgressBar({
    therapySession,
    taskTypes,
    activeTaskIndex,
  });

  return (
    <div className={styles.progressBar}>
      <span
        className={styles.counter}
        ref={setTooltipRef}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {activeTaskIndex + 1} of {therapySession.taskViews.length}
      </span>
      {isTooltipShown && (
        <Tooltip referenceElement={tooltipRef} options={{ placement: 'top' }}>
          {activeTaskIndex + 1} of {therapySession.taskViews.length} Exercises across{' '}
          {categoriesList.length} Task Categories
        </Tooltip>
      )}
      <ul className={styles.list} ref={categoriesListRef}>
        {categoriesList.map((category, index) => {
          return (
            <CategoryBar
              key={index}
              category={category}
              baseCategoryBarWidth={baseCategoryBarWidth}
            />
          );
        })}
      </ul>
    </div>
  );
};
