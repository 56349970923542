import React, { FC } from 'react';

import { ClinicianDashboard, User } from 'Shared/types/shared';

import { PatientsListActions } from './actions';
import { PatientsListStats } from './stats';
import { PatientsListTable } from './table';
import { ZeroHomeExercisesPopup } from './zeroHomeExercisesPopup';
import { usePatientsList } from './usePatientsList';

import styles from './index.module.scss';

interface PatientsListProps {
  clinician: User;
  dashboardData: ClinicianDashboard;
}

export const PatientsList: FC<PatientsListProps> = ({ clinician, dashboardData }) => {
  const { searchText, setSearchText, isPopupShown, hidePopup, downloadPDFClickHandler } =
    usePatientsList({
      dashboardData,
    });

  return (
    <div className={styles.patientsList}>
      <PatientsListActions searchText={searchText} setSearchText={setSearchText} />
      <PatientsListStats Summary={dashboardData.Summary} />
      <PatientsListTable
        clinician={clinician}
        Patients={dashboardData.Patients}
        StatusTypes={dashboardData.StatusTypes}
        searchText={searchText}
      />
      {isPopupShown && (
        <ZeroHomeExercisesPopup
          closePopupHandler={hidePopup}
          downloadPDFClickHandler={downloadPDFClickHandler}
        />
      )}
    </div>
  );
};
