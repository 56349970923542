import React, { FC } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faCircleInfo,
  faCircleCheck,
  faTriangleExclamation,
  faShieldHalved,
} from '@fortawesome/free-solid-svg-icons';

import { useFlashMessage } from './useFlashMessage';

import styles from './index.module.scss';

export const FlashMessage: FC = () => {
  const { closeFlashMessage, message, type } = useFlashMessage();

  if (!message) {
    return null;
  }

  return (
    <div
      className={classNames(styles.flashMessage, {
        [styles[type]]: !!type,
      })}
    >
      {type === 'success' && <FontAwesomeIcon icon={faCircleCheck} className={styles.icon} />}
      {type === 'info' && <FontAwesomeIcon icon={faCircleInfo} className={styles.icon} />}
      {type === 'warning' && (
        <FontAwesomeIcon icon={faTriangleExclamation} className={styles.icon} />
      )}
      {type === 'error' && <FontAwesomeIcon icon={faShieldHalved} className={styles.icon} />}
      <span dangerouslySetInnerHTML={{ __html: message }} />
      <span className={styles.close} onClick={closeFlashMessage}>
        <FontAwesomeIcon icon={faClose} />
      </span>
    </div>
  );
};
