import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import classNames from 'classnames';

import { DO_NOT_CONTACT_FORM_NAME } from './constants';

import styles from './dncForm.module.scss';

const DoNotContactFormComponent: FC<InjectedFormProps> = ({
  handleSubmit,
  error,
  pristine,
  submitting,
}) => {
  return (
    <CTUI.Form.Form handleSubmit={handleSubmit} error={error} customClasses={styles.dncForm}>
      <p className={classNames(styles.text)}>
        You are currently receiving emails from Constant Therapy on topics such as your account, and
        general tips & resources related to living with / managing recovery after a stroke, brain
        injury, dementia or other neurological disorders.
        <br />
        <br />
        <br />
        <br />
        Are you sure you want to stop receiving emails from Constant Therapy?
      </p>
      <Field
        name="optout"
        component={CTUI.Form.Checkbox}
        type="checkbox"
        label="I do not wish to receive any emails from Constant Therapy"
        required={true}
      />
      <CTUI.Form.SubmitButton
        isSubmitting={submitting}
        isPristine={!pristine}
        text="Confirm"
        customClasses={styles.button}
      />
    </CTUI.Form.Form>
  );
};

const DoNotContactForm = reduxForm({
  form: DO_NOT_CONTACT_FORM_NAME,
})(DoNotContactFormComponent);

export { DoNotContactForm };
