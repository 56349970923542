import React, { FC } from 'react';

import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { ClinicianSetupForm } from './form';
import { ClinicianSetupProps, useClinicianSetup } from './useClinicianSetup';

import styles from './index.module.scss';

export const ClinicianSetup: FC<ClinicianSetupProps> = ({ authUser }) => {
  const { handleProfileUpdate, hasCC } = useClinicianSetup({ authUser });
  return (
    <FloatingPage
      header={
        <h1 className="h1">
          {hasCC ? 'Complete account set up and activate your discount' : 'Complete account set up'}
        </h1>
      }
      customClasses={styles.clinicianSetup}
    >
      <ClinicianSetupForm
        onSubmit={handleProfileUpdate}
        initialValues={{
          firstName: authUser.user.firstName,
          lastName: authUser.user.lastName,
          email: authUser.user.email,
          acceptedEULA: authUser.user.acceptedEULA,
        }}
      />
    </FloatingPage>
  );
};
