import React, { FC } from 'react';
import { Types } from '@thelearningcorp/ct-web-packages';

import styles from './scanty.module.scss';

interface ChartScantyProps {
  skillAreas: Types.DashboardService.PatientExpansionDomain[];
}

export const ChartScanty: FC<ChartScantyProps> = ({ skillAreas }) => {
  return (
    <table className={styles.chartScanty}>
      <thead>
        <tr>
          <th>Skill</th>
          <th>Baseline</th>
          <th>Latest</th>
        </tr>
      </thead>
      <tbody>
        {skillAreas.map((skill) => {
          return (
            <tr key={skill.domainName}>
              <td>{skill.domainName}</td>
              <td className={styles.data}>{skill.baseline.value}</td>
              <td className={styles.data}>{skill.latest.value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
