import { SignupData } from 'Shared/types/shared';

export interface ClinicianSignupForm extends SignupData {
  password: string;
  acceptedEula: boolean;
  firstName: string;
  lastName: string;
  role: string;
  phoneNumber?: string;
  setting: string;
  privatePractice?: boolean;
  facilityName: string;
  mailingAddress: string;
  zipCode: string;
}

export interface PatientSignupForm extends SignupData {
  firstName: string;
  lastName: string;
  age: number;
  email: string;
  careGiver: boolean;
  password: string;
  acceptedEula: boolean;
  phoneNumber?: string;
  webClinicianAssisted?: '1' | '0';
  clinicianInfo?: string;
  clinicianEmail?: string;
}

export const PATIENT_SIGNUP_TOTAL_STEPS = {
  INSTITUTION: 2,
  REGULAR: 3,
};

export const CLINICIAN_SIGNUP_TOTAL_STEPS = {
  INSTITUTION: 2,
  REGULAR: 3,
};

export const PATIENT_SIGNUP_FORM_NAME = 'PATIENT_SIGNUP_FORM_NAME';
export const CLINICIAN_SIGNUP_FORM_NAME = 'CLINICIAN_SIGNUP_FORM_NAME';
