import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Discover the power of a clinician-guided recovery 
  journey through our Digital Clinic. Set up a free, virtual consultation with a licensed speech 
  therapist, who can design you a personalized Constant Therapy care plan and answer your most 
  pressing questions. `;
  return (
    <Helmet>
      <title>Free Virtual Consult with a Licensed SLP | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
