import { useEffect, useRef, useState } from 'react';

import { type TherapySessionResponse } from 'Shared/types/api';
import { TaskType } from 'Shared/types/task';

export interface ProgressBarProps {
  therapySession: TherapySessionResponse;
  taskTypes: TaskType[];
  activeTaskIndex: number;
}

export interface ProgressBarCategory {
  activeIndex: number;
  name: string;
  level: number;
  count: number;
  progressionStatus: ProgressBarCategoryStatus;
}

export enum ProgressBarCategoryStatus {
  DONE = 'done',
  CURRENT = 'current',
  PENDING = 'pending',
}

export function useProgressBar({ therapySession, taskTypes, activeTaskIndex }: ProgressBarProps) {
  const categoriesListRef = useRef<HTMLUListElement>(null);
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [tooltipRef, setTooltipRef] = useState<HTMLElement>(null);
  const [baseCategoryBarWidth, setBaseCategoryBarWidth] = useState<number>(10);

  const categoriesList = therapySession.taskViews.reduce((acc, task, index) => {
    const progressionStatus =
      index === activeTaskIndex
        ? ProgressBarCategoryStatus.CURRENT
        : index < activeTaskIndex
        ? ProgressBarCategoryStatus.DONE
        : ProgressBarCategoryStatus.PENDING;

    if (index > 0 && task.templateName === therapySession.taskViews[index - 1].templateName) {
      const currentCategory = acc.pop();
      if (progressionStatus === ProgressBarCategoryStatus.CURRENT) {
        return [
          ...acc,
          {
            ...currentCategory,
            activeIndex: currentCategory.count + 1,
            count: currentCategory.count + 1,
            progressionStatus,
          },
        ];
      }
      return [...acc, { ...currentCategory, count: currentCategory.count + 1 }];
    }

    return [
      ...acc,
      {
        activeIndex: 1,
        name: taskTypes.find((type) => type.defaultViewClassName === task.templateName).displayName,
        level: 0,
        // level: task?.level,
        count: 1,
        progressionStatus,
      },
    ];
  }, [] as ProgressBarCategory[]);

  useEffect(() => {
    if (categoriesList) {
      setBaseCategoryBarWidth(categoriesListRef.current.offsetWidth / categoriesList.length - 2);
    }
  }, [categoriesList]);

  return {
    isTooltipShown,
    showTooltip: () => {
      setIsTooltipShown(true);
    },
    hideTooltip: () => {
      setIsTooltipShown(false);
    },
    tooltipRef,
    setTooltipRef,
    categoriesList,
    categoriesListRef,
    baseCategoryBarWidth,
  };
}
