import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { parseInt } from 'lodash';

import { AudioPlayerStimulus, AudioStimulus } from 'Shared/types/shared';
import { AudioContext } from 'Shared/context/audioContext';

export interface AudioPlayerContentProps {
  stimulus: AudioStimulus | AudioPlayerStimulus;
  resourceUrl: string;
  setHasPlayed: Dispatch<SetStateAction<boolean>>;
}

export function useAudioPlayer({ stimulus, resourceUrl, setHasPlayed }: AudioPlayerContentProps) {
  const {
    isPlaying,
    hasFinished,
    currentAudioElement,
    playAudioSequence,
    stopAudioSequence,
    resumeAudioSequence,
  } = useContext(AudioContext);

  const audioPlayerContent = useRef<HTMLDivElement>(null);

  //Extra flag to track whether this particular audioSequence has started playing
  const [hasStarted, setHasStarted] = useState(false);

  const [skipTime, setSkipTime] = useState<number>();

  const [playbackTime, setPlaybackTime] = useState<number>(0);
  const [playbackDuration, setPlaybackDuration] = useState<number>(100);

  const handlePlay = useCallback(() => {
    stopAudioSequence();

    if (!hasStarted) {
      setHasStarted(true);

      playAudioSequence({
        audioElementsList: stimulus.audioPaths.map((instructionPath) => {
          return new Audio(resourceUrl + instructionPath);
        }),
      });
    }

    if (hasStarted && !isPlaying) {
      resumeAudioSequence();
    }
  }, [stimulus, isPlaying, hasStarted]);

  const handleSkipToTime = (direction: string) => () => {
    let newTime = skipTime;

    if (direction === 'forward') {
      newTime = stimulus.audioSegments.find(
        (element) => element > (skipTime || currentAudioElement.currentTime)
      );
    } else {
      newTime = stimulus.audioSegments.findLast(
        (element) => element < (skipTime || currentAudioElement.currentTime)
      );
    }

    setSkipTime(newTime);
  };

  const handleSeekChange = (e: SyntheticEvent & { target: HTMLInputElement }) => {
    const value = parseInt(e.target.value);

    setSkipTime(value);
    setPlaybackTime(value);

    audioPlayerContent.current.style.setProperty(
      '--seek-before-width',
      `${(value / playbackDuration) * 100}%`
    );
  };

  useEffect(() => {
    if (hasStarted && hasFinished) {
      setHasPlayed(true);
    }
  }, [hasStarted, hasFinished, setHasPlayed]);

  // This logic serves a purpose to add a delay between a user's "skip" click and staring an
  // actual play so a user can click "skip" multiple times
  useEffect(() => {
    if (skipTime) {
      const delayAfterSkip = setTimeout(() => {
        currentAudioElement.currentTime = skipTime;
        setSkipTime(0);
      }, 1000);
      return () => {
        clearTimeout(delayAfterSkip);
      };
    }
  }, [skipTime]);

  useEffect(() => {
    if (hasStarted) {
      currentAudioElement.addEventListener('loadedmetadata', () => {
        setPlaybackDuration(Math.floor(currentAudioElement.duration));
      });
      currentAudioElement.addEventListener('timeupdate', () => {
        setPlaybackTime(Math.floor(currentAudioElement.currentTime));

        audioPlayerContent.current.style.setProperty(
          '--seek-before-width',
          `${
            (Math.floor(currentAudioElement.currentTime) /
              Math.floor(currentAudioElement.duration)) *
            100
          }%`
        );
      });
    }
  }, [hasStarted]);

  return {
    audioPlayerContent,
    handlePlay,
    handleSkipToTime,
    isPlaying,
    hasStarted,
    hasFinished,
    playbackTime,
    playbackDuration,
    handleSeekChange,
  };
}
