import React, { FC } from 'react';

import { PerformanceReportDomain } from 'Shared/types/shared';

import styles from './termsList.module.scss';

interface TermsListProps {
  skillAreas: {
    [key: string]: PerformanceReportDomain;
  };
}

export const TermsList: FC<TermsListProps> = ({ skillAreas }) => {
  return (
    <ul className={styles.termsList}>
      {Object.entries(skillAreas).map(([key, skillArea]) => {
        return (
          <li key={key}>
            <span className="term">{skillArea.displayName}</span>
            <a href={`#${encodeURIComponent(skillArea.displayName)}`} className="inPageNavLink">
              View Details
            </a>
          </li>
        );
      })}
    </ul>
  );
};
