import React, { FC, useState } from 'react';
import { FormSubmitHandler } from 'redux-form';

import { Basic } from './basic';
import { Advanced } from './advanced';
import { Conditions } from './conditions';
import { ConditionsPeriod } from './conditionsPeriod';
import { Areas } from './areas';

interface WizardNewPatientProps {
  clearPatientType: () => void;
  handleSaveNewPatient: FormSubmitHandler;
}

export const WizardNewPatient: FC<WizardNewPatientProps> = ({
  clearPatientType,
  handleSaveNewPatient,
}) => {
  const [page, setPage] = useState(1);

  switch (page) {
    case 1:
      return <Basic onSubmit={() => setPage(page + 1)} previousPage={() => clearPatientType()} />;
    case 2:
      return <Advanced onSubmit={() => setPage(page + 1)} previousPage={() => setPage(page - 1)} />;
    case 3:
      return (
        <Conditions onSubmit={() => setPage(page + 1)} previousPage={() => setPage(page - 1)} />
      );
    case 4:
      return (
        <ConditionsPeriod
          onSubmit={() => setPage(page + 1)}
          previousPage={() => setPage(page - 1)}
        />
      );
    case 5:
      return <Areas onSubmit={handleSaveNewPatient} previousPage={() => setPage(page - 1)} />;
    default:
      return null;
  }
};
