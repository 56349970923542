export const ADD_PATIENT_FORM_NAME = 'addPatientWizard';

export const genderList = {
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
};

export const educationList = {
  some_high_school: 'Some high school',
  high_school: 'High school',
  some_college: 'Some college',
  bachelor: 'College degree',
  graduate_degree: 'Graduate degree',
  other: 'Other',
};

export const conditionsList = [
  {
    value: 'stroke',
    label: 'Stroke',
  },
  {
    value: 'tbi',
    label: 'Traumatic Brain Injury (TBI)',
  },
  {
    value: 'aphasia',
    label: 'Aphasia',
  },
  {
    value: 'concussion',
    label: 'Concussion',
  },
  {
    value: 'apraxia',
    label: 'Apraxia',
  },
  {
    value: 'dementia',
    label: 'Dementia',
  },
  {
    value: 'learning',
    label: 'Learning disorder',
  },
  {
    value: 'dysarthria',
    label: 'Dysarthria',
  },
  {
    value: 'other',
    label: 'Other brain condition',
  },
];

export const conditionsPeriodList = [
  {
    value: '0-3M',
    label: '0-3 months',
  },
  {
    value: '3-6M',
    label: '4-6 months',
  },
  {
    value: '6-12M',
    label: '7-12 months',
  },
  {
    value: '1-5Y',
    label: '1-5 years',
  },
  {
    value: '6-10Y',
    label: '6-10 years',
  },
  {
    value: '>10Y',
    label: 'More than 10 years',
  },
  {
    value: 'NA',
    label: 'Not applicable / Not sure',
  },
];

export const areasList = [
  {
    value: 'auditory_comprehension',
    label: 'Listening & understanding',
  },
  {
    value: 'attention',
    label: 'Staying focused',
  },
  {
    value: 'executive_functioning',
    label: 'Planning & organizing',
  },
  {
    value: 'visual',
    label: 'Understanding what you see',
  },
  {
    value: 'reading',
    label: 'Reading',
  },
  {
    value: 'quantitative',
    label: 'Using math & numbers',
  },
  {
    value: 'memory',
    label: 'Remembering',
  },
  {
    value: 'writing',
    label: 'Writing',
  },
  {
    value: 'speaking',
    label: 'Speaking',
  },
];
