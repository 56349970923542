import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { User, USER_TYPES } from 'Shared/types/shared';
import {
  HTML_HEADER_ID,
  PAYMENT_METHODS,
  SUBSCRIPTION_STATUSES_WITH_CC_AVAILABLE,
} from 'Shared/constants';

import styles from './index.module.scss';

const mountingPoint = document.getElementById(HTML_HEADER_ID);

interface NavigationProps {
  authUser: User;
}

export const Navigation: FC<NavigationProps> = ({ authUser }) => {
  return ReactDOM.createPortal(
    <nav className={styles.navigation}>
      <ul className={styles.tabs}>
        <li>
          <CTUI.Font.Text>
            <NavLink to="/account" activeClassName={styles.active} exact>
              Account Details
            </NavLink>
          </CTUI.Font.Text>
        </li>
        <li>
          <CTUI.Font.Text>
            <NavLink to="/account/password" activeClassName={styles.active} exact>
              Change Password
            </NavLink>
          </CTUI.Font.Text>
        </li>
        {authUser.user.type === USER_TYPES.PATIENT &&
          authUser.subscriptionState?.paymentMethod === PAYMENT_METHODS.stripe &&
          SUBSCRIPTION_STATUSES_WITH_CC_AVAILABLE.includes(authUser.subscriptionState?.status) && (
            <li>
              <CTUI.Font.Text>
                <a href="/payment_update">
                  Update Payment Method <FontAwesomeIcon icon={faExternalLink} />
                </a>
              </CTUI.Font.Text>
            </li>
          )}
      </ul>
    </nav>,
    mountingPoint
  );
};
