import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Spinner } from 'Shared/components/spinner';
import { PageError } from 'Shared/components/pageError';

import { RequestBrochure } from '../shared/requestBrochure';

import { Meta } from './meta';
import { ViewPatient } from './viewPatient';
import { DashboardNavigation } from './dashboardNavigation';
import { PatientsList } from './patientsList';
import { AddPatient } from './addPatient';
import { useClinicianDashboard, ClinicianDashboardProps } from './useClinicianDashboard';

import styles from './index.module.scss';

export const ClinicianDashboard: FC<ClinicianDashboardProps> = ({ clinician }) => {
  const { isLoading, dashboardData, dashboardError } = useClinicianDashboard({ clinician });

  if (isLoading) {
    return <Spinner />;
  }

  if (dashboardError) {
    return (
      <PageError
        error={typeof dashboardError === 'string' ? { message: dashboardError } : dashboardError}
      />
    );
  }

  return (
    <div className={styles.clinicianDashboard}>
      <Meta />
      <DashboardNavigation dashboardData={dashboardData} />
      <Switch>
        <Route exact path="/dashboard">
          <PatientsList clinician={clinician} dashboardData={dashboardData} />
        </Route>
        <Route path="/dashboard/patient/:patientId">
          <ViewPatient dashboardData={dashboardData} />
        </Route>
        <Route path="/dashboard/request-brochure/:patientId/:sourceView">
          <RequestBrochure clinician={clinician} dashboardData={dashboardData} />
        </Route>
        <Route path="/dashboard/add-patient">
          <AddPatient clinician={clinician} />
        </Route>
      </Switch>
    </div>
  );
};
