export const DASHBOARD = {
  DASHBOARD: 'dashboard',
  INITIAL_BROCHURE_DOWNLOAD: 'dashboard/patients-list/popup-initial-brochure-download',
  INITIAL_POPUP: 'dashboard/patients-list/popup-initial',
  REQUEST_BROCHURE: {
    DOWNLOAD: 'dashboard/request-brochure/download',
    PLAY_VIDEO: 'dashboard/request-brochure/clinician-plays-brochure-video',
    VIEW: 'dashboard/request-brochure',
  },
  CLINICIAN_VIEW_PATIENT_REPORT: 'dashboard/patient/clinician-dashboard-reports',
  PATIENT_VIEW_PATIENT_REPORT: 'dashboard/view-patient/patient-dashboard-reports',
  EMPTY_REPORT_DOWNLOAD: 'dashboard/empty-report-download',
};

export const ACTIVATION = {
  HW_INVITE: 'activate/homework-invite',
};

export const SUBSCRIPTION = {
  SUBSCRIPTION: 'subscribe/index',
  ALREADY_SUBSCRIBED: 'subscribe/subscribed-user',
  VALIDATE_USER: 'subscribe/validate-user',
  PLANS_LIST: 'subscribe/plans-list',
  CHOOSE_PLAN: 'subscribe/choose-plan',
  CHOOSE_PLAN_LATER: 'subscribe/choose-plan-later',
  SUCCESS: {
    SUCCESS: 'subscribe/success',
    CONFIRMATION: 'subscribe/success/confirmation',
    TABLET_SHIPPING: 'subscribe/success/tablet-shipping',
  },
  FAIL: 'subscribe/fail',
};

export const DO_NOT_CONTACT = 'do-not-contact';

export const RETRIAL = 'retrial';

export const LOGIN = {
  CLINICIAN_VIA_EMAIL: 'login/clinician-logged-in-via-email',
};

export const DOWNLOAD_APP = 'download-app';

export const SIGNUP = {
  SIGNUP: 'signup/index',
  USER_TYPE_SELECTION: 'signup/user-type',
  USER_TYPE_SELECTED: 'signup/user-type-selected',
  CLINICIAN_BASIC: 'signup/clinician-basic',
  CLINICIAN_ADVANCED: 'signup/clinician-advanced',
  CLINICIAN_EXTRA: 'signup/clinician-extra',
  CLINICIAN_SIGNED_UP: 'signup/clinician-signed-up',
  PATIENT_BASIC: 'signup/patient-basic',
  PATIENT_ADVANCED: 'signup/patient-advanced',
  PATIENT_EXTRA: 'signup/patient-extra',
  PATIENT_SIGNED_UP: 'signup/patient-signed-up',
};

export const LANDING_PAGES = {
  DIGITAL_CLINIC_SUBMIT: 'digital/submit',
  DIGITAL_CLINIC_SCHEDULE: 'digital/schedule',
};

export const GA_EVENTS = {
  PAGEVIEW: 'pageview',
  EVENT: 'event',
};
