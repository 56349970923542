import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { User } from 'Shared/types/shared';

import { Meta } from './meta';
import { DashboardNavigation } from './dashboardNavigation';
import { Report } from './report';
import { DetailedPerformance } from './detailedPerformance';
import { Calendar } from './calendar';
import { ProgressNote } from './notes/progressNote';

import styles from './index.module.scss';

interface PatientDashboardProps {
  patient: User;
}

export const PatientDashboard: FC<PatientDashboardProps> = ({ patient }) => {
  return (
    <div className={styles.patientDashboard}>
      <Meta />
      <DashboardNavigation patientId={patient.user.userId.toString()} />
      <Switch>
        <Redirect exact from="/dashboard" to="/dashboard/report" />
        <Route path="/dashboard/report">
          <Report patient={patient} />
        </Route>
        <Route path="/dashboard/detailed-performance" exact>
          <DetailedPerformance patient={patient} />
        </Route>
        <Route exact path="/dashboard/calendar">
          <Calendar patient={patient} />
        </Route>
        <Route path="/dashboard/patient/:patientId/progress-note/:startDate/:endDate" exact>
          <ProgressNote patient={patient} />
        </Route>
      </Switch>
    </div>
  );
};
