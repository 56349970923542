import React, {
  CSSProperties,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

import { USERS_STATUSES } from 'Shared/constants';
import {
  activateUser,
  reInviteUser,
  removeUser,
  resetUserPassword,
  suspendUser,
} from 'Shared/actions/institution';
import { setFlashMessage } from 'Shared/actions/service';
import { FlashMessageType, InstitutionUser, USER_TYPES } from 'Shared/types/shared';

import { ResetUserPasswordModal } from './modals/resetUserPassword';
import { ActivateUserModal } from './modals/activateUser';
import { SuspendUserModal } from './modals/suspendUser';
import { RemoveUserModal } from './modals/removeUser';
import { DischargeUserModal } from './modals/dischargeUser';
import { ReInviteUserModal } from './modals/reInviteUser';

export interface ActionCellMenuProps extends InstitutionUser {
  institutionId: number;
  popper: {
    ref: React.Dispatch<SetStateAction<HTMLElement>>;
    style: CSSProperties;
    attributes: object;
  };
  hideMenu: () => void;
}

export enum MODALS_LIST {
  RESET = 'reset',
  SUSPEND = 'suspend',
  REACTIVATE = 'reactivate',
  REINVITE = 'reinvite',
  REMOVE = 'remove',
  DISCHARGE = 'discharge',
}

export function useActionsCellMenu({
  institutionId,
  id: userId,
  email,
  statusDisplay,
  hideMenu,
  type,
}: ActionCellMenuProps) {
  const dispatch: Dispatch = useDispatch();
  const [activeModal, setActiveModal] = useState<MODALS_LIST>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalSubmissionError, setModalSubmissionError] = useState<ReactNode>(null);
  const modalRef = useRef(null);

  const actionsList = useMemo(() => {
    return {
      [MODALS_LIST.RESET]: {
        title: 'Reset password',
        successMessage: `Password reset for <b>${email}</b> has been successfully sent.`,
        component: ResetUserPasswordModal,
        action: resetUserPassword,
      },
      ...(statusDisplay === USERS_STATUSES.ACTIVE && {
        [MODALS_LIST.SUSPEND]: {
          title: 'Suspend user',
          successMessage: `The user <b>${email}</b> has been suspended.`,
          component: SuspendUserModal,
          action: suspendUser,
        },
      }),
      ...(statusDisplay === USERS_STATUSES.SUSPENDED && {
        [MODALS_LIST.REACTIVATE]: {
          title: 'Re-activate user',
          successMessage: `The user <b>${email}</b> has been activated.`,
          component: ActivateUserModal,
          action: activateUser,
        },
      }),
      ...(statusDisplay === USERS_STATUSES.INVITED && {
        [MODALS_LIST.REINVITE]: {
          title: 'Re-send invitation',
          successMessage: `Invitation for <b>${email}</b> has been successfully sent.`,
          component: ReInviteUserModal,
          action: reInviteUser,
        },
      }),
      ...(type === USER_TYPES.CLINICIAN && {
        [MODALS_LIST.REMOVE]: {
          title: 'Remove user',
          successMessage: `The user <b>${email}</b> has been removed.`,
          component: RemoveUserModal,
          action: removeUser,
        },
      }),
      ...(type === USER_TYPES.PATIENT && {
        [MODALS_LIST.DISCHARGE]: {
          title: 'Discharge user',
          successMessage: `The user <b>${email}</b> has been discharged.`,
          component: DischargeUserModal,
          action: removeUser,
        },
      }),
    };
  }, [statusDisplay]);

  const closeActiveModal = useCallback(() => {
    if (activeModal) {
      setActiveModal(null);
      setModalSubmissionError(null);
    }
  }, [activeModal]);

  useClickOutside(modalRef, closeActiveModal);

  const handleModalSubmit = () => {
    void (async () => {
      setIsLoading(true);
      try {
        await actionsList[activeModal].action(institutionId, userId)(dispatch);
        setFlashMessage({
          message: actionsList[activeModal].successMessage,
          type: FlashMessageType.SUCCESS,
          keepFor: 5,
        })(dispatch);
        setIsLoading(false);
        hideMenu();
      } catch (e: unknown) {
        setIsLoading(false);
        setModalSubmissionError(e.toString());
      }
    })();
  };

  return {
    actionsList,
    activeModal,
    setActiveModal,
    closeActiveModal,
    modalRef,
    isLoading,
    handleModalSubmit,
    modalSubmissionError,
  };
}
