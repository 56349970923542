import React, { CSSProperties, FC } from 'react';

interface ChartLineProps {
  lineClasses: string;
  lineStyles: CSSProperties;
  lineValue: number;
}

import styles from './chartFigure.module.scss';

export const ChartLine: FC<ChartLineProps> = ({ lineClasses, lineStyles, lineValue }) => {
  return (
    <div className={lineClasses} style={lineStyles}>
      <span className={styles.value}>{lineValue}</span>
    </div>
  );
};
