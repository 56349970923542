import React, { FC, SyntheticEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';

import { DPRRow } from 'Shared/types/shared';

import styles from './tasksFilter.module.scss';

interface TasksFilterProps {
  tasksSelectedList: (DPRRow & { selected: boolean })[];
  handleTasksSelectedListChange: (
    affectedTaskTypeSystemNamesLevels: string[]
  ) => (e: SyntheticEvent) => void;
}

export const TasksFilter: FC<TasksFilterProps> = ({
  tasksSelectedList,
  handleTasksSelectedListChange,
}) => {
  const isAllSelected = tasksSelectedList.every((task) => task.selected);
  const accumulator: string[] = [];
  const allSelectedTasksDiff = tasksSelectedList.reduce((acc, task) => {
    if (isAllSelected && task.selected) {
      return [...acc, `${task.taskTypeSystemName}${task.taskLevel}`];
    }
    if (!isAllSelected && !task.selected) {
      return [...acc, `${task.taskTypeSystemName}${task.taskLevel}`];
    }
    return acc;
  }, accumulator);

  return (
    <ul className={styles.tasksFilter}>
      <li className={styles.item}>
        <label
          className={styles.label}
          onClick={handleTasksSelectedListChange(allSelectedTasksDiff)}
        >
          <span>Tasks List</span>
          <FontAwesomeIcon
            icon={isAllSelected ? faSquareCheck : faSquare}
            className={styles.checkboxIcon}
          />
        </label>
      </li>
      {tasksSelectedList.map((task) => {
        return (
          <li key={`${task.taskTypeSystemName}${task.taskLevel}`} className={styles.item}>
            <label
              className={styles.label}
              onClick={handleTasksSelectedListChange([
                `${task.taskTypeSystemName}${task.taskLevel}`,
              ])}
            >
              <span>{task.taskDetail}</span>
              <FontAwesomeIcon
                icon={task.selected ? faSquareCheck : faSquare}
                className={styles.checkboxIcon}
              />
            </label>
          </li>
        );
      })}
    </ul>
  );
};
