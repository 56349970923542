import { DO_USER_LOGIN, OTP_VALIDATE, DO_USER_LOGOUT_SUCCESS } from 'Shared/types/redux';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case DO_USER_LOGIN.success:
      return action.payload;
    case DO_USER_LOGIN.fail:
      return {
        ...state,
        authError: action.payload,
      };
    case OTP_VALIDATE.success:
      return {
        ...state,
        otp: action.payload,
        accessToken: action.payload.accessToken,
      };
    case DO_USER_LOGOUT_SUCCESS:
      localStorage.removeItem('accessToken');

      return {};
    default:
      return state;
  }
}
