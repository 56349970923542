import React, { createElement, FC, SyntheticEvent } from 'react';

import { useActionsCellMenu, ActionCellMenuProps, MODALS_LIST } from './useActionsCellMenu';

import styles from './menu.module.scss';

export const ActionsCellMenu: FC<ActionCellMenuProps> = (props) => {
  const {
    actionsList,
    activeModal,
    setActiveModal,
    closeActiveModal,
    modalRef,
    isLoading,
    handleModalSubmit,
    modalSubmissionError,
  } = useActionsCellMenu(props);

  return (
    <div
      className={styles.actionsCellMenu}
      ref={props.popper.ref}
      style={props.popper.style}
      {...props.popper.attributes}
    >
      <ul className={styles.actionsList}>
        {Object.entries(actionsList).map(([modalName, actionItem]) => {
          return (
            <li key={actionItem.title}>
              <a
                href="#"
                onClick={(e: SyntheticEvent) => {
                  e.preventDefault();
                  setActiveModal(modalName as MODALS_LIST);
                }}
              >
                {actionItem.title}
              </a>
              {activeModal &&
                activeModal === modalName &&
                createElement(actionItem.component, {
                  email: props.email,
                  modalRef,
                  isLoading,
                  handleModalSubmit,
                  modalSubmissionError,
                  closeActiveModal,
                })}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
