import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';

import { TextStimulus } from 'Shared/types/shared';

import { useChoices, ChoicesProps } from './useChoices';

import styles from './index.module.scss';

export const Choices: FC<ChoicesProps> = ({ task, isAnswered, handleAnswer }) => {
  const { handleCheckResult, handleNextQuestion, answerTrigger, questionIndex } = useChoices({
    task,
    isAnswered,
    handleAnswer,
  });

  return (
    <div className={styles.choices}>
      {task.questions.length > 1 && (
        <h3 className="h3 medium">{task.questions[questionIndex].question}</h3>
      )}
      {task.questions[questionIndex].choices.map((choice) => {
        const choiceText = (choice.stimulus as TextStimulus).text;
        const isTrigger = choiceText === answerTrigger;

        const buttonProps = {
          text: choiceText,
          value: choiceText,
          variant: 'primary-inverted' as const,
          customClasses: classNames(styles.button, {
            [styles.answerTrigger]: isTrigger,
            [styles.answerCorrect]: answerTrigger && choice.correct,
            [styles.answerWrong]: answerTrigger && !choice.correct,
          }),
          onClick: handleCheckResult,
          disabled: !!answerTrigger,
          ...(answerTrigger && {
            icon: choice.correct ? faCircleCheck : isTrigger ? faCircleXmark : null,
          }),
        };
        return <CTUI.Button key={choiceText} {...buttonProps} />;
      })}
      {task.questions.length > 1 && questionIndex < task.questions.length - 1 && answerTrigger && (
        <CTUI.Button text="Next Question" onClick={handleNextQuestion} />
      )}
    </div>
  );
};
