import React, { FC } from 'react';

import CorrectAnswer from 'Shared/src/themes/2023/images/therapyTasks/correct.svg';
import IncorrectAnswer from 'Shared/src/themes/2023/images/therapyTasks/incorrect.svg';
// eslint-disable-next-line max-len
import PartiallyCorrectAnswer from 'Shared/src/themes/2023/images/therapyTasks/correct_partially.png';
import CorrectWHelpAnswer from 'Shared/src/themes/2023/images/therapyTasks/correct_w_help.svg';

import { AnswerResult } from '../useTaskView';

import { useAnswerResult, type AnswerResultComponentProps } from './useAnswerResult';

import styles from './index.module.scss';

export const AnswerResultComponent: FC<AnswerResultComponentProps> = ({ answerResult }) => {
  const { shouldShowInterval } = useAnswerResult({ answerResult });

  if (!answerResult || !shouldShowInterval) return null;

  if (answerResult === AnswerResult.CORRECT) {
    return (
      <div className={styles.answerResult}>
        <img
          src={CorrectAnswer as string}
          alt="Correct!"
          title="Correct!"
          className={styles.image}
        />
      </div>
    );
  }
  if (answerResult === AnswerResult.PARTIALLY) {
    return (
      <div className={styles.answerResult}>
        <img
          src={PartiallyCorrectAnswer}
          alt="Patially correct!"
          title="Patially correct!"
          className={styles.image}
        />
      </div>
    );
  }
  if (answerResult === AnswerResult.W_HELP) {
    return (
      <div className={styles.answerResult}>
        <img
          src={CorrectWHelpAnswer as string}
          alt="Correct with help!"
          title="Correct with help!"
          className={styles.image}
        />
      </div>
    );
  }
  if (answerResult === AnswerResult.WRONG) {
    return (
      <div className={styles.answerResult}>
        <img
          src={IncorrectAnswer as string}
          alt="Incorrect"
          title="Incorrect"
          className={styles.image}
        />
      </div>
    );
  }
};
