import React, { FC } from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { Link } from 'react-router-dom';

import { SubscriptionConfirmation } from 'Shared/types/shared';

import styles from './body.module.scss';

interface ConfirmationBodyProps {
  subscriptionConfirmation: SubscriptionConfirmation;
}

export const ConfirmationBody: FC<ConfirmationBodyProps> = ({ subscriptionConfirmation }) => {
  return (
    <div className={styles.confirmationBody}>
      <div className={styles.orderDetails}>
        <p className={styles.mainInfo}>
          <FontAwesomeIcon icon={faCheckCircle} className={styles.icon} />
          Your plan: {subscriptionConfirmation.planSubtotal} {subscriptionConfirmation.planTitle}
          {subscriptionConfirmation.planTotal !== subscriptionConfirmation.planSubtotal && (
            <span>
              With coupon applied: {subscriptionConfirmation.planTotal}{' '}
              {subscriptionConfirmation.planTitle} <b>({subscriptionConfirmation.couponMessage})</b>
            </span>
          )}
        </p>
        <ul className={styles.bulletList}>
          <li className={styles.bullet}>
            Your card will be charged on{' '}
            {moment(subscriptionConfirmation.chargeDate, 'X').format('MMMM Do, YYYY')}
          </li>
          {subscriptionConfirmation.trialing && (
            <li className={styles.bullet}>
              You can cancel any time during your free trial and your card will not be charged
            </li>
          )}
          <li className={styles.bullet}>
            This plan renews automatically every {subscriptionConfirmation.chargePeriod}
          </li>
        </ul>
      </div>
      <div className={styles.downloadButton}>
        {subscriptionConfirmation.trialing ? (
          <Link to="/download" className={styles.link}>
            <CTUI.Button text="Download Constant Therapy" customClasses={styles.button} />
          </Link>
        ) : (
          <Link to="/dashboard" className={styles.link}>
            <CTUI.Button text="See my progress report" customClasses={styles.button} />
          </Link>
        )}
      </div>
    </div>
  );
};
