import React, { FC } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { PERFORMANCE_REPORT_SECTIONS_MAP } from 'Shared/constants';
import {
  PerformanceReportAssessment,
  PerformanceReportDomain,
  PerformanceReportTask,
} from 'Shared/types/shared';

import styles from './reportTabs.module.scss';

interface ReportTabsProps {
  sections: {
    assessments?: PerformanceReportAssessment[];
    domains?: {
      [key: string]: PerformanceReportDomain;
    };
    tasks?: {
      [key: string]: PerformanceReportTask;
    };
  };
}

export const ReportTabs: FC<ReportTabsProps> = ({ sections }) => {
  const { url } = useRouteMatch();

  return (
    <nav className={styles.reportTabs}>
      <ul className={styles.tabs}>
        {Object.entries(sections).map(([key, section]) => {
          //This is terrible logic, but we need to go this way because of BE-inconsistency
          let itemsCount = Object.keys(section).length;
          if (key === 'assessments' && Array.isArray(section)) {
            itemsCount = section.reduce(
              (acc: number, assessmentSection: PerformanceReportAssessment) =>
                acc + assessmentSection.questions.length,
              0
            );
          }

          return (
            <li key={key}>
              <CTUI.Font.Text>
                <NavLink
                  to={
                    url +
                    PERFORMANCE_REPORT_SECTIONS_MAP[
                      key as keyof typeof PERFORMANCE_REPORT_SECTIONS_MAP
                    ].link
                  }
                  activeClassName={styles.active}
                >
                  {
                    PERFORMANCE_REPORT_SECTIONS_MAP[
                      key as keyof typeof PERFORMANCE_REPORT_SECTIONS_MAP
                    ].title
                  }{' '}
                  ({itemsCount})
                </NavLink>
              </CTUI.Font.Text>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
