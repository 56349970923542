import { useContext, useState } from 'react';

import { StimulusCue } from 'Shared/types/shared';
import { AudioContext } from 'Shared/context/audioContext';

interface UseCuesProps {
  resourceUrl: string;
}

export function useCues({ resourceUrl }: UseCuesProps) {
  const { playAudioSequence, stopAudioSequence } = useContext(AudioContext);

  const [shouldShowCues, setShouldShowCues] = useState<boolean>(false);
  const [shouldShowTextCue, setShouldShowTextCue] = useState<boolean>(false);

  const handleClick = (cue: StimulusCue) => {
    if (cue.contentType === 'audio') {
      stopAudioSequence();
      playAudioSequence({
        audioElementsList: [new Audio(resourceUrl + cue.audioPaths[0])],
      });
    }
    if (cue.contentType === 'text') {
      setShouldShowTextCue((prevState) => !prevState);
    }
  };

  return {
    shouldShowCues,
    handleShowCues: () => {
      setShouldShowCues(true);
    },
    handleClick,
    shouldShowTextCue,
  };
}
