import React, { FC, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { DropdownProps } from 'Shared/components/layout/popperMenu';

import styles from './dropdown.module.scss';

interface MobileDropdownProps extends DropdownProps {
  showProgressPopup: (e: SyntheticEvent) => void;
  surveillanceMode?: boolean;
  patientId?: string;
}

export const Dropdown: FC<MobileDropdownProps> = ({
  popper,
  toggleMenuVisibility,
  showProgressPopup,
  surveillanceMode,
  patientId,
}) => {
  const routeBase =
    surveillanceMode && patientId ? `/dashboard/patient/${patientId}` : '/dashboard';

  return (
    <div
      className={classNames(styles.dropdown, 'text2')}
      ref={popper.ref}
      style={popper.style}
      {...popper.attributes}
      onClick={toggleMenuVisibility}
    >
      <section className={styles.section}>
        <h4 className={styles.sectionHeading}>Performance</h4>
        <ul className={styles.sectionMenu}>
          <li>
            <NavLink
              to={routeBase}
              isActive={(match, location) => {
                return !!match || location.pathname.includes(`${routeBase}/report`);
              }}
              activeClassName={styles.active}
              exact
            >
              Performance Summary
            </NavLink>
          </li>
          <li>
            <NavLink to={`${routeBase}/detailed-performance`} activeClassName={styles.active} exact>
              Detailed Performance
            </NavLink>
          </li>
          <li>
            <NavLink to={`${routeBase}/calendar`} activeClassName={styles.active} exact>
              Calendar
            </NavLink>
          </li>
        </ul>
      </section>
      <section className={styles.section}>
        <h4 className={styles.sectionHeading}>Documentation</h4>
        <ul className={styles.sectionMenu}>
          {surveillanceMode && (
            <li>
              <a href="#" onClick={showProgressPopup}>
                <span>Daily Session Note</span>
              </a>
            </li>
          )}
          <li>
            <a href="#" onClick={showProgressPopup}>
              <span>Progress Note</span>
            </a>
          </li>
          {surveillanceMode && (
            <li>
              <a href="#" onClick={showProgressPopup}>
                <span>Discharge Summary</span>
              </a>
            </li>
          )}
        </ul>
      </section>
    </div>
  );
};
