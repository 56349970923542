import React, { FC } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { US_STATES_LIST } from 'Shared/constants';

import styles from './form.module.scss';

const statesOptions = [
  <option value="" disabled={true} key="initialSelection">
    Select state
  </option>,
  ...Object.entries(US_STATES_LIST).map(([key, value]) => {
    return (
      <option key={key} value={key}>
        {value}
      </option>
    );
  }),
];

const DigitalClinicFormComponent: FC<InjectedFormProps> = ({ handleSubmit, submitting }) => {
  const queryParams = useQueryParams();

  return (
    <CTUI.Form.Form
      handleSubmit={handleSubmit}
      customClasses={styles.digitalClinicForm}
      actionsWrapperClasses={styles.actions}
      actions={
        <CTUI.Form.SubmitButton
          isPristine={false}
          isSubmitting={submitting}
          customClasses={styles.submit}
          text="Check Availability"
        />
      }
    >
      {!queryParams.has('token') && (
        <Field
          type="email"
          name="email"
          required={true}
          label="Email address"
          component={CTUI.Form.Field}
        />
      )}
      <CTUI.Font.H3 variant="bold" customClasses={styles.stateH3}>
        Choose your state
      </CTUI.Font.H3>
      <Field
        type="select"
        name="state"
        component={CTUI.Form.Select}
        label="We need to know where you’re located to check the availability of licensed
        therapists in your state"
        required={true}
        options={statesOptions}
      />
    </CTUI.Form.Form>
  );
};

const DigitalClinicForm = reduxForm({ form: 'digitalClinicForm' })(DigitalClinicFormComponent);

export { DigitalClinicForm };
