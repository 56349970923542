import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { VALIDATE_USER_FORM_NAME } from './constants';

import styles from './validateUserForm.module.scss';

interface ValidateUserFormComponentProps {
  externalError?: string;
}

const ValidateUserFormComponent: FC<
  ValidateUserFormComponentProps & InjectedFormProps<object, ValidateUserFormComponentProps>
> = ({ handleSubmit, externalError, error, pristine, submitting }) => {
  return (
    <CTUI.Form.Form
      customClasses={styles.validateUserForm}
      error={error || externalError}
      handleSubmit={handleSubmit}
      actions={
        <CTUI.Form.SubmitButton
          customClasses={styles.button}
          isSubmitting={submitting}
          isPristine={pristine}
          text="Continue to payment"
        />
      }
    >
      <Field
        name="emailOrUsername"
        label="Please enter <b>patient's</b> username or email address:"
        component={CTUI.Form.Field}
        type="text"
        required={true}
        autoFocus={true}
        autoComplete="on"
      />
    </CTUI.Form.Form>
  );
};

const ValidateUserForm = reduxForm<object, ValidateUserFormComponentProps>({
  form: VALIDATE_USER_FORM_NAME,
})(ValidateUserFormComponent);

export { ValidateUserForm };
