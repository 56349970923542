import React, { FC } from 'react';
import classNames from 'classnames';

import { USER_SUBSCRIPTION_STATUSES, USERS_LEADSOURCES } from 'Shared/constants';
import { User } from 'Shared/types/shared';

import { Details } from './details';
import { SubscriptionInfo } from './subscriptionInfo';
import { CompleteAccount } from './completeAccount';

import styles from './index.module.scss';

interface ProfileProps {
  authUser: User;
}

export const Profile: FC<ProfileProps> = ({ authUser }) => {
  const isAccountCompleted =
    authUser.subscriptionState?.status !== USER_SUBSCRIPTION_STATUSES.ACCOUNT_CREATED;
  const isFlintCustomer = authUser.user.leadSourceDetails === USERS_LEADSOURCES.flint;

  return (
    <div className={styles.profile}>
      <h1 className={classNames('h1', styles.title)}>Account Details</h1>
      <Details authUser={authUser} />
      <div className={styles.subscription}>
        {isAccountCompleted ? (
          <SubscriptionInfo authUser={authUser} />
        ) : (
          <CompleteAccount isFlintCustomer={isFlintCustomer} />
        )}
      </div>
    </div>
  );
};
