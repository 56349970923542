import { useState } from 'react';

import { TaskViewMultipleChoice } from 'Shared/types/shared';

interface UseMultipleChoiceProps {
  task: TaskViewMultipleChoice;
}

export function useMultipleChoice({ task }: UseMultipleChoiceProps) {
  const [hasPlayed, setHasPlayed] = useState(false);

  return {
    hasPlayed,
    setHasPlayed,
  };
}
