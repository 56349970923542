import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { PATIENT_BROCHURE_URL } from 'Shared/constants';
import brochurePreviewImage from 'Shared/src/images/requestBrochure.png';

import { RequestBrochureProps, useRequestBrochure } from './useRequestBrochure';

import styles from './index.module.scss';

export const RequestBrochure: FC<RequestBrochureProps> = ({ clinician, dashboardData }) => {
  const { downloadHandler, playVideoHandler } = useRequestBrochure({ clinician, dashboardData });

  return (
    <div className={styles.requestBrochure}>
      {clinician && (
        <Link to="/dashboard" className={styles.backLink}>
          <FontAwesomeIcon icon={faAngleLeft} /> Back to My Dashboard
        </Link>
      )}
      <div className={styles.promo}>
        <div className={styles.left}>
          <h1 className={classNames('h1', styles.title)}>
            Empower your patients to do more exercises at home.
          </h1>
          <p className={styles.description}>
            Research shows that stroke and traumatic brain injury patients who were provided access
            to Constant Therapy at home did on average 4 times more speech, language, and cognitive
            exercises compared to patients who only received speech therapy in the clinic.
            <br />
            <br />
            <a
              href={PATIENT_BROCHURE_URL}
              onClick={downloadHandler}
              title="download and share our brochure"
              target="_blank"
              rel="noreferrer"
              className={styles.brochureLink}
            >
              Get your patients started with Constant Therapy at home.
            </a>
          </p>
          <div className={styles.video}>
            <h3 className={classNames('h3', styles.videoTitle)}>
              Watch this video to learn about Constant Therapy Homework.
            </h3>
            <video height="100%" width="100%" controls onPlay={playVideoHandler}>
              <source
                src="https://s3.amazonaws.com/CT-resources/3.1.0/help/homework_instructions.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className={styles.right}>
          <a
            href={PATIENT_BROCHURE_URL}
            onClick={downloadHandler}
            title="download and share our brochure"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={brochurePreviewImage}
              alt="Constant Therapy Brochure"
              title="Constant Therapy Brochure"
              className={styles.preview}
            />
          </a>
          <a
            href={PATIENT_BROCHURE_URL}
            onClick={downloadHandler}
            title="download and share our brochure"
            className={styles.downloadLink}
            target="_blank"
            rel="noreferrer"
          >
            <CTUI.Button text="Download Patient Brochure" customClasses={styles.button} />
          </a>
        </div>
      </div>
    </div>
  );
};
