import { GET_PATIENT_REPORT, GET_PATIENT_CALENDAR } from '../types/redux';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PATIENT_REPORT.success:
      return {
        ...state,
        report: action.payload,
      };
    case GET_PATIENT_REPORT.fail:
      return {
        ...state,
        reportError: action.payload,
      };
    case GET_PATIENT_CALENDAR.success:
      return {
        ...state,
        calendar: action.payload,
      };
    case GET_PATIENT_CALENDAR.fail:
      return {
        ...state,
        calendarError: action.payload,
      };
    default:
      return state;
  }
}
