import React, { FC } from 'react';

import { Spinner } from 'Shared/components/spinner';

import { HowAreYourExercisesFeeling } from './howAreYourTasks/howAreYourExercisesFeeling';
import { AdditionalInfo } from './howAreYourTasks/additionalInfo';
import { Thanks4Feedback } from './howAreYourTasks/thanks4Feedback';
import { useHowAreYourTasks } from './howAreYourTasks/useHowAreYourTasks';

export const HowAreYourTasksWizard: FC = () => {
  const { token, phase, satisfaction, page, setPage, handleSubmit } = useHowAreYourTasks();

  switch (page) {
    case 1:
      return (
        <HowAreYourExercisesFeeling
          onSubmit={() => setPage(page + 1)}
          initialValues={{
            satisfaction,
            phase,
          }}
        />
      );
    case 2:
      return <AdditionalInfo previousPage={() => setPage(page - 1)} onSubmit={handleSubmit} />;
    case 3:
      return <Thanks4Feedback token={token} />;
    default:
      return <Spinner />;
  }
};
