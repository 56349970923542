import React, { FC } from 'react';
import classNames from 'classnames';

import { type TaskViewMemoryBoard } from 'Shared/types/shared';

import { type TaskTypeProps } from '../../types';

import { Controls } from './controls';

import sharedStyles from '../index.module.scss';
import styles from './index.module.scss';

export const MemoryBoard: FC<TaskTypeProps<TaskViewMemoryBoard>> = ({
  task,
  oldTask,
  isAnswered,
  handleAnswer,
  resetAnswer,
  isInitialAudioInstructionsShouldBePlayed,
}) => {
  return (
    <div className={classNames(styles.memoryBoard, sharedStyles.taskType)}>
      <Controls task={task} handleAnswer={handleAnswer} />
    </div>
  );
};
