import {
  SIGNUP_SELECT_USER_TYPE,
  SIGNUP_CLEAR_USER_TYPE,
  SIGNUP_SET_EMAIL,
  SIGNUP_SET_INSTITUTION_ID,
  SIGNUP_SET_INVITATION_TOKEN,
  SIGNUP_SET_SOURCE,
} from 'Shared/types/redux';

export default function (state = {}, action) {
  switch (action.type) {
    case SIGNUP_SELECT_USER_TYPE:
    case SIGNUP_CLEAR_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    case SIGNUP_SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SIGNUP_SET_INSTITUTION_ID:
      return {
        ...state,
        institutionId: action.payload,
      };
    case SIGNUP_SET_INVITATION_TOKEN:
      return {
        ...state,
        invitationToken: action.payload,
      };
    case SIGNUP_SET_SOURCE:
      return {
        ...state,
        source: action.payload,
      };
    default:
      return state;
  }
}
