import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { RequestBrochure } from './shared/requestBrochure';

export const PublicDashboard: FC = () => {
  const content = `Empower your patients to do more therapy at home. 
  Research shows that stroke and TBI patients with access to Constant Therapy at home did 4 times 
  more exercises, on average, compared to patients who only received speech therapy in the clinic. 
  Learn more today!`;
  return (
    <>
      <Helmet>
        <title>Download Patient Brochure | Constant Therapy</title>
        <meta name="description" content={content} />
      </Helmet>
      <Switch>
        <Route path="/dashboard/request-brochure/:patientId?/:sourceView?">
          <RequestBrochure />
        </Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </>
  );
};
