import React, { FC } from 'react';

import { DPRRawDataElement } from 'Shared/types/shared';

import styles from './data.module.scss';

interface DataProps {
  cell: DPRRawDataElement;
}

export const Data: FC<DataProps> = ({ cell }) => {
  return (
    <div className={styles.dataContainer}>
      <table className={styles.dataTable}>
        <tbody>
          <tr>
            <td>Date</td>
            <td className={styles.value}>{cell.dateRange}</td>
          </tr>
          <tr>
            <td>Location</td>
            <td className={styles.value}>{cell.location}</td>
          </tr>
          <tr>
            <td>Tasks Count</td>
            <td className={styles.value}>{cell.completedTasks}</td>
          </tr>
          <tr>
            <td>Accuracy</td>
            <td className={styles.value}>
              {Intl.NumberFormat('en-US', { style: 'percent' }).format(cell.accuracy / 100)}
            </td>
          </tr>
          <tr>
            <td>Pop. Accuracy Mean</td>
            <td className={styles.value}>
              {Intl.NumberFormat('en-US', { style: 'percent' }).format(cell.accuracyMean / 100)}
            </td>
          </tr>
          <tr>
            <td>Accuracy Percentile</td>
            <td className={styles.value}>
              {Intl.NumberFormat('en-US', { style: 'decimal' }).format(cell.accuracyPercentile)}
            </td>
          </tr>
          <tr>
            <td>Latency</td>
            <td className={styles.value}>
              {Intl.NumberFormat('en-US', {
                style: 'unit',
                unit: 'second',
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(cell.latency)}
            </td>
          </tr>
          <tr>
            <td>Pop. Latency Mean</td>
            <td className={styles.value}>
              {Intl.NumberFormat('en-US', {
                style: 'unit',
                unit: 'second',
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(cell.latencyMean)}
            </td>
          </tr>
          <tr>
            <td>Latency Percentile</td>
            <td className={styles.value}>
              {Intl.NumberFormat('en-US', { style: 'decimal' }).format(cell.latencyPercentile)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
