import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import {
  PerformanceReportTask,
  PerformanceReportTaskLevel,
  PerformanceReportTaskLevelCue,
} from 'Shared/types/shared';

import styles from './detailed.module.scss';

interface DailySessionNoteDetailedTherapyItemProps {
  therapy: PerformanceReportTask;
  levelKey: string;
  level: PerformanceReportTaskLevel;
}

export const DailySessionNoteDetailedTherapyItem: FC<DailySessionNoteDetailedTherapyItemProps> = ({
  therapy,
  levelKey,
  level,
}) => {
  return (
    <li className={styles.dailySessionNoteDetailedTherapyItem}>
      <p className={styles.itemDescription}>
        <CTUI.Font.Text>
          Patient completed {therapy.displayName}, Level {levelKey} within the Constant Therapy app
          given clinical guidance and prompting as needed to address{' '}
          {therapy.documentationSkillReport}. During this task, the patient followed provided
          instructions: &apos;{therapy.description}&apos;
        </CTUI.Font.Text>
      </p>
      <ul className={styles.itemData}>
        <li>
          <CTUI.Font.Text>
            <CTUI.Font.Text variant="medium">Original Baseline Score:</CTUI.Font.Text>{' '}
            {level.accuracy.baseline.value}
            {level.accuracy.unit} accuracy, {level.latency.baseline.value}
            {level.latency.unit} average per question
            {getCuesText(level.baselineCues)}
          </CTUI.Font.Text>
        </li>
        <li>
          <CTUI.Font.Text>
            <CTUI.Font.Text variant="medium">Current performance:</CTUI.Font.Text>{' '}
            {level.accuracy.latest.value}
            {level.accuracy.unit} accuracy or {Math.floor(level.accuracy.latest.value / 10)}/10
            trials correct, {level.latency.latest.value}
            {level.latency.unit} average per question
            {getCuesText(level.latestCues)}
          </CTUI.Font.Text>
        </li>
      </ul>
    </li>
  );
};

function getCuesText(cues: PerformanceReportTaskLevelCue[]) {
  const cuesTextLine = cues
    .map((cue) => {
      return `used ${cue.numCues} cue${cue.numCues > 1 ? 's' : ''} for '${cue.cueType}'`;
    })
    .join(', ');

  return `, ${cuesTextLine}` || '';
}
