export interface TaskTypeCategory {
  displayName?: string;
  level: number;
  systemName?: string;
  children: TaskTypeCategory[] | TaskType[];
}

export interface TaskType {
  activityDescription: string;
  availableInVersion_Android: string;
  availableInVersion_iOS: string;
  categories: string;
  defaultViewClassName: string;
  description: string;
  descriptionLite: string;
  displayName: string;
  displayNamespace: string;
  documentationReportSkill: string;
  fullDescription: string;
  helpsWith: string;
  iconPath: string;
  instructionAudio: string;
  instructionText: string;
  instructionalVideoPath: string;
  level: number;
  maxLevel: number;
  redirectId: number;
  requiresSound: boolean;
  systemName: string;
  tags: string;
  taskDomains: string;
  taskDomainsWeb: string;
  transitionalAudioPaths: string[];
  typeId: number;
}

export interface TaskDoneRequestBody {
  localId?: number;
  uuid: string;
  sessionId: number;
  patientId: number;
  userId?: number;
  taskType: string;
  taskId: number;
  accuracy: number;
  latency: number;
  skipType: SkipType;
  skipReason?: SkipReason;
  startTime: number;
  timestamp: number;
  clientVersionNumber: string;
  clientHardwareType: string;
  clientOSVersion: string;
  taskJson: string;
  additionalDataJson: string;
  screenshotUrl?: string;
  sendAttempts?: number;
}

export enum SkipReason {
  TOO_HARD = 'TooHard',
  TOO_EASY = 'TooEasy',
  TASK_ERROR = 'ErrorInTask',
  DONT_LIKE = 'DontLike',
  SOUND_MUTED = 'SoundMuted',
  OTHER = 'Other',
  MISSING_RESOURCES = 'MissingResources',
  PERMISSION_DENIED = 'MicrophoneInaccessible',
}
export enum SkipType {
  NONE = 'NONE',
  SINGLE_ITEM = 'SINGLE_ITEM',
  SKIP_ALL = 'SKIP_ALL',
}
