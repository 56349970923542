import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { Spinner } from 'Shared/components/spinner';

import { ChangePasswordForm } from './changePasswordForm';
import { usePassword, PasswordComponentProps } from './usePassword';

import styles from './index.module.scss';

export const PasswordComponent: FC<PasswordComponentProps> = ({ authUser }) => {
  const { isLoading, handleChangeUserPassword, passwordRequirements } = usePassword({ authUser });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.password}>
      <CTUI.Font.H1 customClasses={styles.title}>Change Password</CTUI.Font.H1>
      <div className={styles.formWrapper}>
        {!authUser.user.promptChangePassword ? (
          <CTUI.Font.H4 variant="medium" customClasses={styles.description}>
            Complete this form to change your password *
          </CTUI.Font.H4>
        ) : (
          <CTUI.Font.H4 variant="medium" customClasses={styles.description}>
            You are required to change your password immediately *
          </CTUI.Font.H4>
        )}
        <ChangePasswordForm
          onSubmit={handleChangeUserPassword}
          passwordRequirements={passwordRequirements}
        />
      </div>
    </div>
  );
};
