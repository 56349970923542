import React, { FC } from 'react';

import { ClinicianDashboardSummary } from 'Shared/types/shared';

import { StatsTotalPatients } from './totalPatients';
import { StatsTotalExercises } from './totalExercises';
import { StatsHomeExercises } from './homeExercises';

import styles from './index.module.scss';

interface PatientsListStatsProps {
  Summary: ClinicianDashboardSummary;
}

export const PatientsListStats: FC<PatientsListStatsProps> = ({ Summary }) => {
  return (
    <div className={styles.patientsListStats}>
      <StatsTotalPatients Summary={Summary} />
      <StatsTotalExercises Summary={Summary} />
      <StatsHomeExercises Summary={Summary} />
    </div>
  );
};
