import React, { FC } from 'react';
import classNames from 'classnames';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { LOGIN_FORM_NAME } from 'Shared/types/forms';
import { FloatingPage } from 'Shared/components/layout/floatingPage';
import otpIcon from 'Shared/src/themes/2019/images/emptyStateEmailSent.svg';

import { useOtp } from './useOtp';

import sharedStyles from './shared.module.scss';
import styles from './otp.module.scss';

const otpLabel =
  'For security purposes, we have sent a verification code to your email ' +
  'associated with this account. Please <b>enter the code</b> below:';

interface OtpComponentProps {
  previousPage: () => void;
}

const OtpComponent: FC<OtpComponentProps & InjectedFormProps<object, OtpComponentProps>> = ({
  error,
  handleSubmit,
  previousPage,
  pristine,
  submitting,
}) => {
  const { isOtpReset, handleResetOtp, timeoutValue } = useOtp();

  return (
    <FloatingPage
      customClasses={classNames(sharedStyles.loginWizardPage, styles.otpPage)}
      header={
        <>
          <img
            src={otpIcon as string}
            alt="Verification email sent"
            title="Verification email sent"
          />
          <h1 className="h1">Verification email sent</h1>
        </>
      }
      footer={
        <>
          {!isOtpReset ? (
            <p className={classNames(styles.otpReset, 'h4')}>
              It may take a minute to receive your code. Haven’t received it?{' '}
              <a href="#" className="yeahBabe" onClick={handleResetOtp}>
                Resend email
              </a>
            </p>
          ) : (
            <p className={classNames(styles.otpReset, 'h4')}>
              Code has been successfully resent, it may take a minute to receive your code. If it’s
              still not there, you can try again in {timeoutValue} seconds
            </p>
          )}
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses={styles.otpForm}
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="secondary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text="Log In"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="otp"
          component={CTUI.Form.Otp}
          type="otp"
          required={true}
          autofocus={true}
          label={otpLabel}
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Otp = reduxForm<object, OtpComponentProps>({
  form: LOGIN_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(OtpComponent);

export { Otp };
