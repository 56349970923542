import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import '@thelearningcorp/ct-web-packages/dist/main.css';

import { SubscriptionPlansResponse } from 'Shared/types/shared';

import styles from './header.module.scss';

interface PlansListHeaderProps {
  displayName: string;
  isProgressMail: boolean;
  subscriptionPlansData: SubscriptionPlansResponse;
}

export const PlansListHeader: FC<PlansListHeaderProps> = ({
  displayName,
  isProgressMail = false,
  subscriptionPlansData,
}) => {
  const subHeader =
    subscriptionPlansData.description || 'Get unlimited therapy for less than $1 per day.';

  return (
    <>
      <CTUI.Font.H1 variant="regular" customClasses={styles.title}>
        Welcome, {displayName}!
      </CTUI.Font.H1>
      <CTUI.Font.H4 customClasses={styles.subTitle}>
        <span dangerouslySetInnerHTML={{ __html: subHeader }} />
      </CTUI.Font.H4>
      {isProgressMail && (
        <CTUI.Font.Text customClasses={styles.progressMailDescription}>
          You are just a few steps away from viewing your progress report! Gain insight into your
          overall progress toward recovery. See our plan options below and select the one that works
          best for you.
        </CTUI.Font.Text>
      )}
    </>
  );
};
