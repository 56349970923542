import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Create a free Constant Therapy account to join 
  thousands of clinicians, caregivers, and patients empowering brain recovery with our 
  evidence-based speech and cognitive therapy programs.`;
  return (
    <Helmet>
      <title>Getting Started | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
