import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content =
    'Log in with your Constant Therapy account to access your personalized speech and cognitive ' +
    'therapy program and view progress reports.';
  return (
    <Helmet>
      <title>Log In | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
