import { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { SUBSCRIPTION } from 'Shared/types/events';
import { logEvent } from 'Shared/actions/event';
import { getSubscriptionConfirmation } from 'Shared/actions/subscription';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { SubscriptionConfirmation } from 'Shared/types/shared';

interface SubscriptionError {
  title: string;
  message: string;
}

export function useSuccess() {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionConfirmation, setSubscriptionConfirmation] =
    useState<SubscriptionConfirmation>();
  const [subscriptionError, setSubscriptionError] = useState<SubscriptionError>();
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const queryParams = useQueryParams();

  const source = queryParams.get('source');

  const fetchCompoundSubscriptionInfo = async () => {
    try {
      const confirmation = (await getSubscriptionConfirmation({
        sessionId: queryParams.get('sessionId'),
      })(dispatch)) as SubscriptionConfirmation;
      setSubscriptionConfirmation(confirmation);
    } catch (e: unknown) {
      console.log(e);
      setSubscriptionError({
        title: 'Subscription Error',
        message: e.toString(),
      });
    } finally {
      void logEvent({ page: SUBSCRIPTION.SUCCESS.SUCCESS, data: { source } })(
        dispatch,
        store.getState.bind(store)
      );
      setIsLoading(false);
    }
  };

  const handleMobileRedirect = () => {
    window.WindowsIAP.success();
  };

  useEffect(() => {
    if (window.WindowsIAP) {
      handleMobileRedirect();
    } else {
      void fetchCompoundSubscriptionInfo();
    }
  }, []);

  return {
    isLoading,
    subscriptionConfirmation,
    subscriptionError,
  };
}
