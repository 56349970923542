import { useState } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { change, registerField } from 'redux-form';
import { Dispatch } from 'redux';

import { AUTH_ACTIONS } from 'Shared/constants';
import { getBaseUrl, getUserIp } from 'Shared/utils';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { loginUser, validateOtp } from 'Shared/actions/auth';
import { User } from 'Shared/types/shared';
import { LoginUsernameResponse, LoginPasswordResponse } from 'Shared/types/api';
import { LOGIN_FORM_NAME, LoginForm } from 'Shared/types/forms';
import { getUserData } from 'Shared/actions/user';

export function useWizard() {
  const dispatch: Dispatch = useDispatch();
  const queryParams = useQueryParams();
  const [page, setPage] = useState(1);
  const history = useHistory();

  const handleUsernameSubmit = async ({ username }: LoginForm) => {
    const ipAddress = await getUserIp();
    const loginResponse = (await loginUser({
      username,
      ipAddress,
      webLogin: true,
    })(dispatch)) as LoginUsernameResponse;
    if (loginResponse.action === AUTH_ACTIONS.SSO) {
      const formattedParams = queryString.stringify(
        {
          token: loginResponse?.token,
          returnUrl: `${document.location.origin}/login/sso`,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      );

      window.location.assign(`${getBaseUrl()}/api/login/sso?${formattedParams}`);
    } else {
      setPage(2);
    }
  };

  const handlePasswordSubmit = async ({ password, username }: LoginForm) => {
    const ipAddress = await getUserIp();
    const loginResponse = (await loginUser({
      username,
      password,
      ipAddress,
      webLogin: true,
    })(dispatch)) as LoginPasswordResponse;
    if (loginResponse.ctdata?.mfaRequired) {
      dispatch(registerField(LOGIN_FORM_NAME, 'otpResetToken', 'Field'));
      dispatch(change(LOGIN_FORM_NAME, 'otpResetToken', loginResponse.ctdata?.otpResetToken));

      dispatch(registerField(LOGIN_FORM_NAME, 'userId', 'Field'));
      dispatch(change(LOGIN_FORM_NAME, 'userId', loginResponse.ctdata?.userId));

      setPage(3);
    } else {
      localStorage.setItem('accessToken', loginResponse.ctdata?.accessToken);
      return handleAuthRedirect(loginResponse.ctdata?.accessToken);
    }
  };

  const handleOtpSubmit = async ({ userId, otp }: LoginForm) => {
    const loginResponse = await validateOtp({ userId, otp })(dispatch);
    localStorage.setItem('accessToken', loginResponse.accessToken);
    return handleAuthRedirect(loginResponse.accessToken);
  };

  const handleAuthRedirect = async (token: string) => {
    const authUser = (await getUserData(token)(dispatch)) as User;
    if (authUser.user.promptChangePassword) {
      return history.push('/account/password');
    }

    if (queryParams.has('returnUrl')) {
      return history.push(decodeURIComponent(queryParams.get('returnUrl')).split('?').shift());
    }

    if (authUser.institutionInfo.institutionAdmin) {
      return history.push('/enterprise-dashboard');
    }

    return history.push('/dashboard');
  };

  const handlePrevious = () => {
    setPage(page - 1);
  };

  return {
    page,
    handleUsernameSubmit,
    handlePasswordSubmit,
    handleOtpSubmit,
    handlePrevious,
  };
}
