import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { clearFlashMessage } from 'Shared/actions/service';
import { RootState } from 'Shared/types/shared';

export function useFlashMessage() {
  const dispatch: Dispatch = useDispatch();
  const service = useSelector((state: RootState) => state.service);
  const [fmTimeout, setFmTimeout] = useState<number>();
  const keepFor = service?.flashMessage?.keepFor || 6;
  const type = service?.flashMessage?.type || 'info';

  const closeFlashMessage = useCallback(() => {
    clearFlashMessage()(dispatch);
    window.clearTimeout(fmTimeout);
  }, [clearFlashMessage, dispatch, fmTimeout]);

  useEffect(() => {
    if (keepFor) {
      const timeout = window.setTimeout(() => {
        clearFlashMessage()(dispatch);
      }, keepFor * 1000);
      setFmTimeout(timeout);

      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [keepFor, dispatch]);

  return {
    closeFlashMessage,
    message: service?.flashMessage?.message,
    type,
    keepFor,
  };
}
