import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `Fill out account information quickly and connect with 
  your institution here. Welcome to Constant Therapy!`;
  return (
    <Helmet>
      <title>Enterprise Activation Page | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
