import { useRef } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';

import { TaskTypes } from 'Shared/constants';

import { DragItem } from './taskTypeItem/useTaskTypeItem';

export interface NoScheduleProps {
  moveScheduledTasks: (dragIndex: number, hoverIndex: number, item: DragItem) => void;
}

export function useNoSchedule({ moveScheduledTasks }: NoScheduleProps) {
  const noScheduleRef = useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop<DragItem, void, { isOver: boolean }>({
    accept: [TaskTypes.TASK_TYPE],
    collect: (monitor: DropTargetMonitor) => ({ isOver: monitor.isOver() }),
    drop: (item: DragItem) => {
      if (!noScheduleRef.current) {
        return;
      }
      // Time to actually perform the action.
      // This entire logic is expected to be executed only once so we always assign index `0`
      moveScheduledTasks(0, 0, item);
    },
  });

  drop(noScheduleRef);

  return { noScheduleRef, isOver };
}
