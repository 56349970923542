import { useCallback, useContext, useEffect, useState } from 'react';

import { AudioContext } from 'Shared/context/audioContext';
import { type TaskView } from 'Shared/types/shared';
import { type TherapySessionResponseOldTaskStructure } from 'Shared/types/api';

export interface TaskViewComponentProps {
  task: TaskView;
  oldTask: TherapySessionResponseOldTaskStructure;
  solveTask?: () => Promise<void>;
}

export enum AnswerResult {
  CORRECT = 'correct',
  WRONG = 'incorrect',
  PARTIALLY = 'correct_partially',
  W_HELP = 'correct_w_help',
}

export function useTaskViewComponent({ task, oldTask }: TaskViewComponentProps) {
  const { isPlaying, playAudioSequence, stopAudioSequence } = useContext(AudioContext);

  const [isAnswered, setIsAnswered] = useState(false);
  const [answerResult, setAnswerResult] = useState<AnswerResult>(null);

  //We set this value preliminary to avoid confusion that appears after stacking useEffects in child components
  const [isInitialAudioInstructionsShouldBePlayed, setIsInitialAudioInstructionsShouldBePlayed] =
    useState(task.instructions.autoplayInstructions);

  const handlePlayAudioSequenceClick = useCallback(() => {
    playAudioSequence({
      audioElementsList: task.instructions.instructionAudioPaths.map((instructionPath) => {
        return new Audio(task.resourceUrl + instructionPath);
      }),
    });
  }, [task]);

  const handleAnswer = (result: AnswerResult) => {
    setIsAnswered(true);
    setAnswerResult(result);
  };

  const resetAnswer = () => {
    setIsAnswered(false);
    setAnswerResult(null);
  };

  useEffect(() => {
    if (task) {
      resetAnswer();
    }

    if (task.instructions.autoplayInstructions) {
      setIsInitialAudioInstructionsShouldBePlayed(false);
      handlePlayAudioSequenceClick();

      return stopAudioSequence;
    }
  }, [task]);

  //Reset state on a task change
  useEffect(() => {
    setIsAnswered(false);
  }, [task]);

  return {
    isAnswered,
    answerResult,
    handleAnswer,
    resetAnswer,
    isPlaying,
    handlePlayAudioSequenceClick,
    isInitialAudioInstructionsShouldBePlayed,
  };
}
