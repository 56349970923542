import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Report } from '../../patientDashboard/report';
import { DetailedPerformance } from '../../patientDashboard/detailedPerformance';
import { Calendar } from '../../patientDashboard/calendar';
import { ProgressNote } from '../../patientDashboard/notes/progressNote';
import { DailySessionNote } from '../../patientDashboard/notes/dailySessionNote';
import { DischargeSummary } from '../../patientDashboard/notes/dischargeSummary';

import { useViewPatient, ViewPatientProps } from './useViewPatient';

export const ViewPatient: FC<ViewPatientProps> = ({ dashboardData }) => {
  const { path, patient } = useViewPatient({ dashboardData });

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/report`} />
      <Route path={`${path}/report`}>
        <Report surveillanceMode={true} patient={patient} />
      </Route>
      <Route path={`${path}/detailed-performance`} exact>
        <DetailedPerformance patient={patient} />
      </Route>
      <Route path={`${path}/calendar`}>
        <Calendar patient={patient} />
      </Route>
      <Route path={`${path}/daily-session-note/:type/:date`} exact>
        <DailySessionNote patient={patient} />
      </Route>
      <Route path={`${path}/progress-note/:startDate/:endDate`} exact>
        <ProgressNote patient={patient} />
      </Route>
      <Route path={`${path}/discharge-summary/:startDate/:endDate`} exact>
        <DischargeSummary patient={patient} />
      </Route>
    </Switch>
  );
};
