import React, { FC, useState } from 'react';
import classNames from 'classnames';

import { STIMULUS_CONTENT_TYPES } from 'Shared/types/shared';

import { AudioComponent } from './stimulus/audio';
import { ImageComponent } from './stimulus/image';
import { TextComponent } from './stimulus/text';

import type { EnhancedChoice } from '../../types';

import styles from './index.module.scss';

interface ChoiceProps {
  index: number;
  choice: EnhancedChoice;
  handleClick: (index: number) => void;
  resourceUrl: string;
}

export const Choice: FC<ChoiceProps> = ({ index, choice, handleClick, resourceUrl }) => {
  const isContentVisible = choice.isShown || choice.isPlaced;

  return (
    <li
      onClick={() => {
        handleClick(index);
      }}
      className={styles.choice}
    >
      <div className={classNames(styles.card, { [styles.isFlipped]: choice.isShown })}>
        <div className={styles.front} />
        <div className={styles.back}>
          {isContentVisible && choice.stimulus.contentType === STIMULUS_CONTENT_TYPES.TEXT && (
            <TextComponent stimulus={choice.stimulus} />
          )}
          {isContentVisible && choice.stimulus.contentType === STIMULUS_CONTENT_TYPES.IMAGE && (
            <ImageComponent stimulus={choice.stimulus} resourceUrl={resourceUrl} />
          )}
          {isContentVisible && choice.stimulus.contentType === STIMULUS_CONTENT_TYPES.AUDIO && (
            <AudioComponent stimulus={choice.stimulus} resourceUrl={resourceUrl} />
          )}
        </div>
      </div>
    </li>
  );
};
