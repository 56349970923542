import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import {
  DAILY_SESSION_NOTE_BRIEF_TYPE_ID,
  DAILY_SESSION_NOTE_DETAILED_TYPE_ID,
} from 'Shared/constants';
import { getDownloadDestination } from 'Shared/utils';

import styles from './emptyNote.module.scss';

interface EmptyNoteProps {
  type: string;
}

export const EmptyNote: FC<EmptyNoteProps> = ({ type }) => {
  return (
    <div className={styles.emptyNote}>
      <FontAwesomeIcon icon={faFileLines} className={styles.mainIcon} />
      <h3 className={classNames('h3', styles.title)}>Nothing to see here yet!</h3>
      {type === DAILY_SESSION_NOTE_BRIEF_TYPE_ID && (
        <CTUI.Font.Text>
          Complete a clinic session with a patient using your clinician app to see the data from
          your session.
        </CTUI.Font.Text>
      )}
      {type === DAILY_SESSION_NOTE_DETAILED_TYPE_ID && (
        <CTUI.Font.Text>
          A detailed report is available for clinic sessions completed within the clinician app.
          This daily session note will help therapists or other healthcare providers complete
          documentation. Download the{' '}
          <a href={getDownloadDestination()}>Constant Therapy Clinician app</a> to get started.
        </CTUI.Font.Text>
      )}
    </div>
  );
};
