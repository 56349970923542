import React, { FC } from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { PATIENT_ACTIVATION_FORM_NAME } from 'Shared/types/forms';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import sharedStyles from '../shared.module.scss';

const conditionsList = [
  {
    value: 'stroke',
    label: 'Stroke',
  },
  {
    value: 'tbi',
    label: 'Traumatic Brain Injury (TBI)',
  },
  {
    value: 'aphasia',
    label: 'Aphasia',
  },
  {
    value: 'concussion',
    label: 'Concussion',
  },
  {
    value: 'apraxia',
    label: 'Apraxia',
  },
  {
    value: 'dementia',
    label: 'Dementia',
  },
  {
    value: 'learning',
    label: 'Learning disorder',
  },
  {
    value: 'dysarthria',
    label: 'Dysarthria',
  },
  {
    value: 'other',
    label: 'Other brain condition',
  },
];

interface ConditionsComponentProps {
  previousPage: () => void;
}

const ConditionsComponent: FC<
  ConditionsComponentProps & InjectedFormProps<object, ConditionsComponentProps>
> = ({ handleSubmit, previousPage, error, pristine, submitting }) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.wizardPage}
      header={
        <>
          <ProgressBar currentStep={3} totalSteps={5} />
          <h1 className="h1">What conditions have you been diagnosed with?</h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses="flintActivationForm"
        actions={
          <>
            <CTUI.Button
              type="button"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              text="&larr; Back"
            />
            <CTUI.Form.SubmitButton
              variant="primary"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
              text="Next &rarr;"
            />
          </>
        }
      >
        <Field
          name="conditions"
          component={CTUI.Form.CheckboxGroup}
          options={conditionsList}
          type="select-multiple"
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Conditions = reduxForm<object, ConditionsComponentProps>({
  form: PATIENT_ACTIVATION_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ConditionsComponent);

export { Conditions };
