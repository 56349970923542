import { useEffect } from 'react';

/**
 * Temporary solution to replace `useReactToPrint` hook from `react-to-print` lib which stopped working for an unknown reason
 * @param onBeforePrint
 */
export function usePrintDocument({ onBeforePrint }: { onBeforePrint?: () => void }) {
  const handlePrint = window.print;

  useEffect(() => {
    function handleBeforePrint(event: Event) {
      if (onBeforePrint) {
        onBeforePrint();
      }
    }

    window.addEventListener('beforeprint', handleBeforePrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
    };
  }, []);

  return {
    handlePrint,
  };
}
