import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { SUPPORT } from 'Shared/constants';

import styles from './footer.module.scss';

interface PlansListFooterProps {
  isSignupFlow: boolean;
  handleChoosePlanLater: (e: React.SyntheticEvent) => void;
}

export const PlansListFooter: FC<PlansListFooterProps> = ({
  isSignupFlow,
  handleChoosePlanLater,
}) => {
  return (
    <div className={styles.plansListFooter}>
      {isSignupFlow && (
        <p className={styles.choosePlanLater}>
          <Link to="/dashboard" onClick={handleChoosePlanLater}>
            Choose plan later
          </Link>
        </p>
      )}
      <p className={styles.description}>
        *Save an average of 30% by using pre-tax HSA / FSA funds. Savings amount depends on your
        federal and state income tax brackets.
        <br />
        <br />
        {/* eslint-disable-next-line max-len */}
        Promotional offers/coupons cannot be combined. Subscriptions renew automatically. You can{' '}
        {/* eslint-disable-next-line max-len */}
        cancel at any time by going to the <Link to="/account">My Account</Link> page or by calling{' '}
        <a href={`tel:${SUPPORT.PHONE}`}>{SUPPORT.PHONE_FORMATTED}</a>.{' '}
        {!isSignupFlow && (
          <span>
            If you cancel an annual plan before the plan year has ended, you will not receive a
            refund for the unused portion of the plan.
          </span>
        )}
      </p>
    </div>
  );
};
