import React, { FC } from 'react';

import { USER_TYPES } from 'Shared/types/shared';
import { User } from 'Shared/types/shared';

import { ClinicianDashboard } from './clinicianDashboard';
import { PatientDashboard } from './patientDashboard';
import { PublicDashboard } from './publicDashboard';

interface DashboardProps {
  authUser?: User;
}

export const Dashboard: FC<DashboardProps> = ({ authUser }) => {
  if (authUser?.user.type === USER_TYPES.CLINICIAN) {
    return <ClinicianDashboard clinician={authUser} />;
  }

  if (authUser?.user.type === USER_TYPES.PATIENT) {
    return <PatientDashboard patient={authUser} />;
  }

  return <PublicDashboard />;
};
