import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './legend.module.scss';

export const Legend: FC = () => {
  return (
    <div className={styles.legend}>
      <span className={styles.description}>
        Click on task performance numbers to see more details
      </span>
      <span className={classNames(styles.bar, styles.low)}>Room for Growth</span>
      <span className={classNames(styles.bar, styles.medium)}>On Target</span>
      <span className={classNames(styles.bar, styles.high)}>Ready for More Challenges</span>
    </div>
  );
};
