import React, { FC, Fragment } from 'react';
import moment from 'moment-timezone';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { convertDatetimeToUTC } from 'Shared/utils';
import { PerformanceReportAssessment } from 'Shared/types/shared';

import styles from './assessmentSectionItem.module.scss';

interface PerformanceAndDischargeAssessmentSectionItemProps {
  assessmentSection: PerformanceReportAssessment;
}

export const PerformanceAndDischargeAssessmentSectionItem: FC<
  PerformanceAndDischargeAssessmentSectionItemProps
> = ({ assessmentSection }) => {
  const assessmentQuestionsList = Object.values(assessmentSection.questions);

  return (
    <div className={styles.assessmentSectionItem}>
      <header className={styles.header}>
        <CTUI.Font.H4 variant="medium">{assessmentSection.title}</CTUI.Font.H4>
        <CTUI.Font.Text customClasses={styles.description}>
          {assessmentSection.description}
        </CTUI.Font.Text>
      </header>
      <div className={styles.listWrapper}>
        <CTUI.Font.Text variant="medium" customClasses={styles.date}>
          Baseline from{' '}
          {moment(
            convertDatetimeToUTC(assessmentQuestionsList[0].baseline.date.dateTime.date),
            'X'
          ).format('MM/DD/YYYY')}
        </CTUI.Font.Text>
        <dl>
          {assessmentQuestionsList.map((question) => {
            return (
              <Fragment key={question.id}>
                <dt>
                  <CTUI.Font.Text>{question.questionText}</CTUI.Font.Text>
                </dt>
                <dd>
                  <CTUI.Font.Text variant="italic">
                    Rating out of 5: {question.baseline.value}
                  </CTUI.Font.Text>
                </dd>
              </Fragment>
            );
          })}
        </dl>
      </div>
      <div className={styles.listWrapper}>
        <CTUI.Font.Text variant="medium" customClasses={styles.date}>
          Latest from{' '}
          {moment(
            convertDatetimeToUTC(assessmentQuestionsList[0].latest.date.dateTime.date),
            'X'
          ).format('MM/DD/YYYY')}
        </CTUI.Font.Text>
        <dl>
          {assessmentQuestionsList.map((question) => {
            return (
              <Fragment key={question.id}>
                <dt>
                  <CTUI.Font.Text>{question.questionText}</CTUI.Font.Text>
                </dt>
                <dd>
                  <CTUI.Font.Text variant="italic">
                    Rating out of 5: {question.latest.value}
                  </CTUI.Font.Text>
                </dd>
              </Fragment>
            );
          })}
        </dl>
      </div>
    </div>
  );
};
