import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, getFormValues } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';

import { getBaseUrl } from 'Shared/utils';
import { asyncValidate } from 'Shared/components/form/asyncValidation';

import { ClinicianSetupFormShape } from './types';
import { CLINICIAN_SETUP_FORM_NAME } from './constants';

import styles from './form.module.scss';

const eulaLabel = `I agree to the <a href="${
  getBaseUrl() + '/api/user/eula'
}" target="_blank" rel="noreferrer">terms of use</a>`;

interface ClinicianSetupFormComponentProps {
  initialValues: ClinicianSetupFormShape;
}

const ClinicianSetupFormComponent: FC<
  ClinicianSetupFormComponentProps & InjectedFormProps<object, ClinicianSetupFormComponentProps>
> = ({ handleSubmit, error, initialValues, submitting }) => {
  const activationFormValues = useSelector(
    getFormValues(CLINICIAN_SETUP_FORM_NAME)
  ) as ClinicianSetupFormShape;
  const isAnyEmpty =
    Object.values(activationFormValues).length < Object.values(initialValues).length || //This part of condition checks whether any values were removed comparing to the original list
    Object.values(activationFormValues).some((value) => !value);

  return (
    <CTUI.Form.Form
      handleSubmit={handleSubmit}
      error={error}
      customClasses={styles.clinicianSetupForm}
      actions={
        <CTUI.Form.SubmitButton
          text="Next &rarr;"
          variant="primary"
          isSubmitting={submitting}
          isPristine={isAnyEmpty}
          customClasses={styles.submitButton}
        />
      }
    >
      <Field
        name="firstName"
        component={CTUI.Form.Field}
        type="text"
        label="First Name"
        required
        autofocus
      />
      <Field name="lastName" component={CTUI.Form.Field} type="text" label="Last Name" required />
      <Field
        name="email"
        component={CTUI.Form.Email}
        type="email"
        label="Email address"
        required
        readOnly={!!initialValues.email}
        tabIndex={!initialValues.email ? 2 : -1}
        customClasses={classNames({ [styles.prefilled]: !!initialValues.email })}
      />
      <Field
        name="acceptedEULA"
        component={CTUI.Form.Checkbox}
        type="checkbox"
        label={eulaLabel}
        required
      />
    </CTUI.Form.Form>
  );
};

const ClinicianSetupForm = reduxForm<object, ClinicianSetupFormComponentProps>({
  form: CLINICIAN_SETUP_FORM_NAME,
  asyncValidate,
  asyncBlurFields: ['email'],
})(ClinicianSetupFormComponent);

export { ClinicianSetupForm };
