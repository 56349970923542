import React, { FC, SyntheticEvent } from 'react';
import classNames from 'classnames';
import {
  useMobileOrientation,
  isMobileOnly,
  isSafari,
  isIOS,
  fullBrowserVersion,
} from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { useTaskTypeItem, TaskTypeItemProps } from './useTaskTypeItem';

import styles from './taskTypeItem.module.scss';

export const TaskTypeItem: FC<TaskTypeItemProps> = ({
  index,
  task,
  selectedItems,
  toggleItemSelection,
}) => {
  const { isPortrait } = useMobileOrientation();
  const {
    taskItemTypeRef,
    previewRef,
    isDragging,
    videoElementRef,
    handlePlayVideoClick,
    shouldRenderVideo,
  } = useTaskTypeItem({
    index,
    task,
  });

  const browserVersion = fullBrowserVersion.split('.').map((value) => parseInt(value));
  const taskTypeItemClasses = classNames(styles.taskTypeItem, { [styles.isDragging]: isDragging });
  const videoTagClasses = classNames(styles.videoTag, {
    [styles.isOldSafari]: isSafari && !isIOS && browserVersion[0] <= 16 && browserVersion[1] <= 3,
  });

  if (isMobileOnly && isPortrait) {
    return (
      <li
        className={taskTypeItemClasses}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          toggleItemSelection(task);
        }}
      >
        <div className={styles.data}>
          {task.instructionalVideoPath && (
            <>
              <FontAwesomeIcon
                icon={faCirclePlay}
                className={styles.playVideo}
                title="Play Video Instructions"
                onClick={handlePlayVideoClick}
              />
              {shouldRenderVideo && (
                <video
                  controls={true}
                  ref={videoElementRef}
                  className={videoTagClasses}
                  playsInline={isSafari}
                  autoPlay={isSafari}
                >
                  <source
                    src={`https://ct-res.s3.amazonaws.com/${task.instructionalVideoPath}`}
                    type="video/mp4"
                  />
                </video>
              )}
            </>
          )}
          <CTUI.Font.Text>{task.displayName}</CTUI.Font.Text>
        </div>
        <FontAwesomeIcon
          icon={selectedItems?.includes(task) ? faSquareCheck : faSquare}
          className={styles.checkboxIcon}
        />
      </li>
    );
  }

  return (
    <li
      ref={previewRef}
      className={taskTypeItemClasses}
      onClick={(e: SyntheticEvent) => {
        // Safari treats clicks on fullscreen videos as clicks on their parenting <li> and
        // propagates event further up to category's <li> which causes category to collapse
        // and detach the entire list of taskTypes from React's DOM
        e.stopPropagation();
      }}
    >
      <div className={styles.data}>
        {task.instructionalVideoPath && (
          <>
            <FontAwesomeIcon
              icon={faCirclePlay}
              className={styles.playVideo}
              title="Play Video Instructions"
              onClick={handlePlayVideoClick}
            />
            {shouldRenderVideo && (
              <video
                controls={true}
                ref={videoElementRef}
                className={videoTagClasses}
                autoPlay={isSafari}
              >
                <source
                  src={`https://ct-res.s3.amazonaws.com/${task.instructionalVideoPath}`}
                  type="video/mp4"
                />
              </video>
            )}
          </>
        )}
        <CTUI.Font.Text>{task.displayName}</CTUI.Font.Text>
      </div>
      <span ref={taskItemTypeRef}>
        <FontAwesomeIcon
          icon={faBars}
          className={styles.dragIcon}
          title="Drag and Drop to the right to add task to the schedule"
        />
      </span>
    </li>
  );
};
