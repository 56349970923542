import React, { FC } from 'react';

import { SUPPORT } from 'Shared/constants';

import { useFail } from './useFail';

export const Fail: FC = () => {
  useFail();

  return (
    <div className="pageContent__data--error">
      <h2>We&apos;re sorry to say that your order wasn&apos;t processed properly.</h2>
      <p>
        If you are getting this error permanently, please contact our support center{' '}
        <a href="/contact-us">directly</a> or <a href={`mailto:${SUPPORT.EMAIL}`}>send us email</a>.
      </p>
    </div>
  );
};
