import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import styles from './item.module.scss';

interface CuesListItemProps {
  type: 'latest' | 'baseline';
  cuesAmount: number;
  cuesTitle: string;
}

export const CuesListItem: FC<CuesListItemProps> = ({ type, cuesAmount, cuesTitle }) => {
  return (
    <li className={classNames(styles.item, { [styles[type]]: true })}>
      <FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />
      {cuesTitle}: {cuesAmount > 1 ? `${cuesAmount} cues were` : `${cuesAmount} cue was`} used to
      complete this task.
    </li>
  );
};
