import React, { FC, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import styles from './actions.module.scss';

interface PatientsListActionsProps {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
}

export const PatientsListActions: FC<PatientsListActionsProps> = ({
  searchText,
  setSearchText,
}) => {
  return (
    <div className={styles.patientsListActions}>
      <ul className={styles.nav}>
        <li>
          <CTUI.Font.H2>My patients</CTUI.Font.H2>
        </li>
        <li>
          <CTUI.Font.H2>
            <Link to="/dashboard/add-patient" className={styles.addPatient}>
              <FontAwesomeIcon icon={faPlusCircle} className={styles.addPatientIcon} /> Add patient
            </Link>
          </CTUI.Font.H2>
        </li>
      </ul>
      <div className={styles.searchBlock}>
        <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
        <input
          type="text"
          name="search"
          id="search"
          placeholder="Search patients"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
    </div>
  );
};
