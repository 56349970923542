import { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

import accountMenuStyles from './accountMenu.module.scss';

export function useAuthBlock() {
  const actionRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<SVGElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);
  const [isMenuShown, setIsMenuShown] = useState(false);
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 15],
          },
        },
      ],
    }
  );

  useClickOutside(actionRef, (e: MouseEvent) => {
    //We don't want to close menu if click happens when overlay is in place
    if (
      isMenuShown &&
      e.target instanceof HTMLElement &&
      !e.target.closest(`.${accountMenuStyles.accountMenu}`)
    ) {
      setIsMenuShown(false);
    }
  });
  const toggleMenuVisibility = () => {
    setIsMenuShown(!isMenuShown);
  };

  return {
    actionRef,
    toggleMenuVisibility,
    setReferenceElement,
    isMenuShown,
    popper: {
      ref: setPopperElement,
      style: popperStyles.popper,
      attributes: popperAttributes.popper,
    },
  };
}
