import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { defaultValidators } from 'Shared/validators';
import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { PATIENT_SIGNUP_FORM_NAME } from '../../constants';

import sharedStyles from '../shared.module.scss';

interface BasicComponentProps {
  previousPage: () => void;
  totalSteps: number;
}

const BasicComponent: FC<BasicComponentProps & InjectedFormProps<object, BasicComponentProps>> = ({
  handleSubmit,
  previousPage,
  totalSteps,
  error,
  pristine,
  submitting,
}) => {
  return (
    <FloatingPage
      customClasses={sharedStyles.wizardPage}
      header={
        <>
          <ProgressBar currentStep={1} totalSteps={totalSteps} />
          <h1 className="h1">
            Who will <b>do therapy?</b>
          </h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses="signupForm"
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text="Next &rarr;"
              variant="primary"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="firstName"
          component={CTUI.Form.Field}
          type="text"
          label="First Name"
          required
          autofocus
        />
        <Field name="lastName" component={CTUI.Form.Field} type="text" label="Last Name" required />
        <Field
          name="age"
          component={CTUI.Form.Number}
          type="number"
          label={'Age <i>(helps estimate therapy duration)</i>'}
          validate={[defaultValidators.decimal.bind(defaultValidators)]}
          min={5}
          max={130}
          required
          customClasses={sharedStyles.oneHalf}
        />
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Basic = reduxForm<object, BasicComponentProps>({
  form: PATIENT_SIGNUP_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(BasicComponent);

export { Basic };
