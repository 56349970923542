import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';

import { HtmlContentProps, useHtml } from './useHtml';

import styles from './html.module.scss';

export const HtmlContent: FC<HtmlContentProps> = ({ stimulus, resourceUrl }) => {
  const {
    htmlReplaced,
    modalRef,
    isFullscreenPreviewShown,
    showFullscreenPreview,
    hideFullscreenPreview,
    iframeRef,
  } = useHtml({ stimulus, resourceUrl });

  return (
    <div className={classNames(styles.html, styles.content)}>
      {isFullscreenPreviewShown ? (
        <CTUI.Modal
          ref={modalRef}
          id={styles.fullscreenPreviewModal}
          size={CTUI.ModalSizes.FULLSCREEN}
          closeButtonHandler={hideFullscreenPreview}
        >
          <iframe
            srcDoc={htmlReplaced}
            width="100%"
            height="100%"
            allow="fullscreen"
            ref={iframeRef}
            onClick={showFullscreenPreview}
          />
        </CTUI.Modal>
      ) : (
        <iframe
          srcDoc={htmlReplaced}
          width="100%"
          height="100%"
          allow="fullscreen"
          ref={iframeRef}
          onClick={showFullscreenPreview}
        />
      )}
      <p className={styles.label}>Tap to see it bigger</p>
    </div>
  );
};
