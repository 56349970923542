import { GET_DASHBOARD_DATA, GET_DASHBOARD_PATIENT_EXPANSION } from '../types/redux';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_DATA.success:
      return {
        ...state,
        dashboardData: action.payload,
      };
    case GET_DASHBOARD_DATA.fail:
      return {
        ...state,
        dashboardError: action.payload,
      };
    case GET_DASHBOARD_PATIENT_EXPANSION.success:
      return {
        ...state,
        patientsExpansions: {
          ...state.patientsExpansions,
          ...action.payload,
        },
      };
    case GET_DASHBOARD_PATIENT_EXPANSION.fail:
      return {
        ...state,
        patientsExpansionsError: action.payload,
      };
    default:
      return state;
  }
}
