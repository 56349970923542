import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { filterUsersList } from 'Shared/actions/institution';
import type { RootState } from 'Shared/types/shared';
import { USER_TYPES } from 'Shared/types/shared';

export function useHeaderActions() {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const usersList = useSelector((state: RootState) => state.institution.usersList);
  const [type, setType] = useState<USER_TYPES>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const doSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm: string = e.target.value.toLowerCase();
    const processedUsersList = searchTerm
      ? usersList.filter((user) => {
          const isInFirstName = user.firstName && user.firstName.toLowerCase().includes(searchTerm);
          const isInLastName = user.lastName && user.lastName.toLowerCase().includes(searchTerm);
          const isInEmail = user.email && user.email.toLowerCase().includes(searchTerm);
          return isInFirstName || isInLastName || isInEmail;
        })
      : usersList;

    setSearchTerm(e.target.value); //We don't want to transform searchTerm to lowercase in the actual input field
    setType(null); //Search resets type-filtering

    return filterUsersList(processedUsersList)(dispatch, store.getState.bind(store));
  };

  const filterByType = (e: SyntheticEvent, type: USER_TYPES) => {
    e.preventDefault();
    const processedUsersList = type
      ? usersList.filter((user) => type && user.type === type)
      : usersList;

    setType(type);
    setSearchTerm(''); //Type-filtering resets search

    return filterUsersList(processedUsersList)(dispatch, store.getState.bind(store));
  };

  return {
    type,
    searchTerm,
    filterByType,
    doSearch,
  };
}
