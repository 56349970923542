import React, { FC, createContext, useState } from 'react';

export interface ScheduleContextProps {
  isChangedDuringSession: boolean;
  setIsChangedDuringSession: (value: boolean) => void;
}

export const ScheduleContext = createContext<ScheduleContextProps>(null);

interface ScheduleProviderProps {
  children: React.ReactNode;
}

export const ScheduleContextProvider: FC<ScheduleProviderProps> = ({ children }) => {
  const [isChangedDuringSession, setIsChangedDuringSession] = useState<boolean>(false);

  return (
    <ScheduleContext.Provider value={{ isChangedDuringSession, setIsChangedDuringSession }}>
      {children}
    </ScheduleContext.Provider>
  );
};
