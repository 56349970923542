import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { USER_TYPES } from 'Shared/types/shared';
import { DownloadAppList } from 'Shared/components/downloadAppList';

import styles from './empty.module.scss';

export const TableEmpty: FC = () => {
  return (
    <div className={styles.patientsListTableEmpty}>
      <div className={styles.body}>
        <CTUI.Font.Text>
          Welcome to your <b>Clinician Dashboard</b>.
          <br />
          As you can see, it&apos;s a bit empty at the moment.
          <br />
          <br />
          Patient data will show up here once you add a patient and they complete some exercises in
          the Constant Therapy app.
        </CTUI.Font.Text>
      </div>
      <CTUI.Font.H2 className={styles.subTitle}>
        DOWNLOAD THE CONSTANT THERAPY CLINICIAN APP TO GET STARTED
      </CTUI.Font.H2>
      <DownloadAppList userType={USER_TYPES.CLINICIAN} />
    </div>
  );
};
