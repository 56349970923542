// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Brand colors */\n.shared-app-subscribe-success-confirmation-footer-module__confirmationFooter--ECMjE {\n  margin: 35px 0 0 0;\n}", "",{"version":3,"sources":["webpack://./shared/src/styles/_variables.scss","webpack://./shared/app/subscribe/success/confirmation/footer.module.scss"],"names":[],"mappings":"AAaA,iBAAA;ACPA;EACE,kBAAA;AAJF","sourcesContent":["$commonGrayBackgroundColor: #fcfcfc;\n$commonGrayBorderColor: #bfbfbf;\n$commonGrayFontColor: #555555;\n$commonBlueLinkHoverColor: #00b2f3;\n$commonBlueH1Color: #287fba;\n$whiteColor: #fff;\n$redButtonColor: rgba(217, 92, 92, 1);\n$redButtonColorHover: rgba(217, 92, 92, 0.8);\n$blueButtonColor: rgba(59, 131, 192, 1);\n$blueButtonColorHover: rgba(59, 131, 192, 0.8);\n$orangeButtonColorHover: rgba(255, 170, 0, 0.8);\n$bodyMinWidth: 320px;\n\n/* Brand colors */\n$primaryBrandBlue: #41A4E6;\n$accentTeal: #3FBBC1;\n$actionOrange: #FFAA00;\n$accentSalmon: #F86653;\n$baseBlack: #131313;\n$charcoal: #414141;\n$accentGrey: #868686;\n$lightGrey: #F7F7F7;\n$successGreen: #14D15B;\n$mediumRiskYellow: #ECCA0E;\n$highRiskRed: #F44336;\n$highRiskRed04: rgba(244, 67, 54, 0.4);\n$highRiskRed005: rgba(244, 67, 54, 0.05);\n$popupErrorColor: #fe6559;\n\n//Colors introduced by Casey Coleman\n$black: #000;\n$fill: #a5a5a5;\n$teal: #00babe;\n$darkTeal: #0da9ac;\n$baseline: #747474;\n\n\n$pageContentMarginTop: 25px;\n$statefulHeaderHeight: 75px;\n$statefulFooterHeight: 260px;\n$statefulFooterMarginTop: 25px;\n","\n              @import \"/Users/al1mirage/Sites/ct-web/shared/src/styles/_variables.scss\"; \n              @import \"/Users/al1mirage/Sites/ct-web/shared/src/themes/2019/styles/variables\";\n            \n@import \"~@thelearningcorp/ct-web-packages/src/core-ui/styles/variables\";\n\n.confirmationFooter {\n  margin: 35px 0 0 0;\n}\n\n@media only screen and (min-width: $mobilePortraitMaxWidth) {\n  .confirmationFooter {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationFooter": "shared-app-subscribe-success-confirmation-footer-module__confirmationFooter--ECMjE"
};
export default ___CSS_LOADER_EXPORT___;
