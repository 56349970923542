import React, { CSSProperties, Dispatch, FC, SetStateAction } from 'react';

import styles from './copyToClipboardTooltip.module.scss';

interface CopyToClipboardTooltipProps {
  popper: {
    ref: Dispatch<SetStateAction<HTMLElement>>;
    style: CSSProperties;
    attributes: object;
  };
  arrow: {
    ref: Dispatch<SetStateAction<HTMLElement>>;
    style: CSSProperties;
  };
}

export const CopyToClipboardTooltip: FC<CopyToClipboardTooltipProps> = ({
  popper: { ref, style, attributes },
  arrow: { ref: arrowRef, style: arrowStyle },
}) => {
  return (
    <div className={styles.copyToClipboardTooltip} ref={ref} style={style} {...attributes}>
      <p className={styles.tooltipTitle}>Copied!</p>
      <div ref={arrowRef} style={arrowStyle} className={styles.arrow} />
    </div>
  );
};
