import React, { FC } from 'react';

import type { TextStimulus } from 'Shared/types/shared';

interface TextComponentProps {
  stimulus: TextStimulus;
}

export const TextComponent: FC<TextComponentProps> = ({ stimulus }) => {
  return <h2 className="h2 medium">{stimulus.text}</h2>;
};
