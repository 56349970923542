import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, InjectedFormProps, getFormValues } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { FloatingPage } from 'Shared/components/layout/floatingPage';
import { LOGIN_FORM_NAME, LoginForm } from 'Shared/types/forms';

import sharedStyles from './shared.module.scss';
import styles from './password.module.scss';

interface PasswordComponentProps {
  previousPage: () => void;
}

const PasswordComponent: FC<
  PasswordComponentProps & InjectedFormProps<object, PasswordComponentProps>
> = ({ error, handleSubmit, previousPage, pristine, submitting }) => {
  const loginFormData = useSelector(getFormValues(LOGIN_FORM_NAME)) as LoginForm;

  return (
    <FloatingPage
      customClasses={sharedStyles.loginWizardPage}
      header={
        <h1 className={classNames('h1', styles.title)}>
          Welcome back, {loginFormData.username}! <br />
          Log in to continue.
        </h1>
      }
      footer={
        <>
          <span className={sharedStyles.hint}>Don’t have an account yet? Join us!</span>
          <Link to="/signup" className={sharedStyles.link}>
            <CTUI.Button
              text="Sign up"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
            />
          </Link>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="secondary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text="Continue"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field
          name="password"
          label="Password"
          component={CTUI.Form.Password}
          type="password"
          required={true}
          autofocus={true}
          autoComplete="on"
        />
        <Link
          to="/forgot-password"
          className={classNames('h4', 'yeahBabe', styles.forgotPasswordLink)}
        >
          <FontAwesomeIcon icon={faLock} className={styles.icon} />
          Forgot Password
        </Link>
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Password = reduxForm<object, PasswordComponentProps>({
  form: LOGIN_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PasswordComponent);

export { Password };
