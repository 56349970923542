import { SyntheticEvent, useState } from 'react';

import { TaskViewMultipleChoice } from 'Shared/types/shared';

import { AnswerResult } from '../../../useTaskView';

export interface ChoicesProps {
  task: TaskViewMultipleChoice;
  isAnswered: boolean;
  handleAnswer: (result: AnswerResult) => void;
}

export function useChoices({ task, isAnswered, handleAnswer }: ChoicesProps) {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answerTrigger, setAnswerTrigger] = useState<string>('');
  const [resultsStack, setResultsStack] = useState<boolean[]>([]);

  const handleCheckResult = (e: SyntheticEvent & { target: HTMLButtonElement }) => {
    const answer = e.target.value;
    setAnswerTrigger(answer);

    setResultsStack((prev) => [
      ...prev,
      task.questions[questionIndex].choices.find((choice) => choice.stimulus.text === answer)
        .correct,
    ]);

    if (questionIndex + 1 === task.questions.length) {
      return handleResponse(e);
    }
  };

  const handleNextQuestion = (e: SyntheticEvent & { target: HTMLButtonElement }) => {
    e.preventDefault();

    setQuestionIndex((prev) => prev + 1);
    setAnswerTrigger('');
  };

  const handleResponse = (e: SyntheticEvent & { target: HTMLButtonElement }) => {
    e.preventDefault();

    if (resultsStack.every((result) => result)) {
      return handleAnswer(AnswerResult.CORRECT);
    }

    if (resultsStack.every((result) => !result)) {
      return handleAnswer(AnswerResult.WRONG);
    }

    return handleAnswer(AnswerResult.PARTIALLY);
  };

  return {
    handleCheckResult,
    handleNextQuestion,
    handleResponse,
    answerTrigger,
    questionIndex,
  };
}
