import { useEffect, useState } from 'react';

import { STIMULUS_CONTENT_TYPES, TaskViewChoice, TaskViewMemoryBoard } from 'Shared/types/shared';

import { AnswerResult } from '../../../useTaskView';

import type { EnhancedChoice } from '../types';

interface UseControlsProps {
  task: TaskViewMemoryBoard;
  handleAnswer: (result: AnswerResult) => void;
}

interface UseControlsShape {
  itemsList: EnhancedChoice[];
  handleClick: (index: number) => void;
}

const maxAllowedToShow = 2;

export function useControls({ task, handleAnswer }: UseControlsProps): UseControlsShape {
  const [itemsList, setItemsList] = useState<EnhancedChoice[]>(enhanceItems(task.items));
  const [indicesShown, setIndicesShown] = useState<number[]>([]);

  const handleClick = (index: number) => {
    if (itemsList[index].isPlaced) {
      return;
    }
    if (itemsList[index].isShown) {
      return;
    }

    // We duplicate state values inside this local variable because React batches state updates
    // @see https://react.dev/reference/react/useState#setstate
    let nextIndiciesShown: number[];
    if (!indicesShown.length || indicesShown.length >= maxAllowedToShow) {
      nextIndiciesShown = [index];
    } else {
      nextIndiciesShown = [...indicesShown, index];
    }

    setIndicesShown(nextIndiciesShown);
    setItemsList((prevState) => {
      return prevState.map((item, key) => {
        if (nextIndiciesShown.includes(key)) {
          return {
            ...item,
            isShown: true,
            ...(nextIndiciesShown.length === maxAllowedToShow &&
              prevState[nextIndiciesShown[0]].value === prevState[nextIndiciesShown[1]].value && {
                isPlaced: true,
              }),
          };
        }
        return {
          ...item,
          ...(!item.isPlaced && { isShown: false }),
        };
      });
    });
  };

  useEffect(() => {
    if (isEverythingPlaced(itemsList)) {
      handleAnswer(AnswerResult.CORRECT);
    }
  }, [itemsList]);

  useEffect(() => {
    if (task.items) {
      preloadImages(task.items, task.resourceUrl);
      setItemsList(enhanceItems(task.items));
    }
  }, [task]);

  return {
    itemsList,
    handleClick,
  };
}

function enhanceItems(items: TaskViewChoice[]): EnhancedChoice[] {
  return items.map((item) => {
    return { ...item, isShown: false, isPlaced: false };
  });
}

function isEverythingPlaced(itemsList: EnhancedChoice[]) {
  if (!itemsList) {
    return false;
  }
  return itemsList.every((item) => item.isPlaced);
}

function preloadImages(items: TaskViewChoice[], resourceUrl: string) {
  items.forEach((item) => {
    if (item.stimulus.contentType !== STIMULUS_CONTENT_TYPES.IMAGE) {
      return;
    }
    const image = new Image();
    image.src = resourceUrl + item.stimulus.imagePath;
  });
}
