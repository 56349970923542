import React, { FC } from 'react';

import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { ForgotPasswordForm } from './forgotPasswordForm';
import { useMain } from './useMain';

import sharedStyles from './shared.module.scss';

export const Main: FC = () => {
  const { handleResetPassword } = useMain();

  return (
    <FloatingPage
      customClasses={sharedStyles.forgotPasswordPage}
      header={<h1 className="h1">Forgot your password?</h1>}
    >
      <ForgotPasswordForm onSubmit={handleResetPassword} />
    </FloatingPage>
  );
};
