import { SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { FlashMessageType } from 'Shared/types/shared';
import { inviteUser } from 'Shared/actions/institution';
import { setFlashMessage } from 'Shared/actions/service';

import type { InvitationFormShape } from './constants';

export interface AddNewUserProps {
  institutionId: number;
}

export function useAddNewUser({ institutionId }: AddNewUserProps) {
  const dispatch: Dispatch = useDispatch();
  const [isPopupShown, setIsPopupShown] = useState(false);

  function addUserHandler(e: SyntheticEvent) {
    e.preventDefault();
    setIsPopupShown(true);
  }

  const inviteUserHandler = async (formData: InvitationFormShape) => {
    await inviteUser({ institutionId, ...formData })(dispatch);
    setFlashMessage({
      message: `Invitation for <b>${formData.email}</b> has been successfully sent.`,
      type: FlashMessageType.SUCCESS,
      keepFor: 5,
    })(dispatch);
    setIsPopupShown(false);
  };

  function hidePopup() {
    setIsPopupShown(false);
  }

  return {
    isPopupShown,
    addUserHandler,
    inviteUserHandler,
    hidePopup,
  };
}
