import React, { FC } from 'react';
import DataTable from 'react-data-table-component';

import { Spinner } from 'Shared/components/spinner';

import { HeaderActions } from './headerActions';
import { NoData } from './noData';
import { useUsersList, UsersListProps } from './useUsersList';

import styles from './index.module.scss';

export const UsersList: FC<UsersListProps> = ({ institutionId }) => {
  const { isLoading, columns, filteredUsersList } = useUsersList({ institutionId });

  return (
    <div className={styles.usersList}>
      <DataTable
        title="Users"
        highlightOnHover={true}
        defaultSortFieldId={1}
        columns={columns}
        data={filteredUsersList}
        actions={<HeaderActions institutionId={institutionId} />}
        noDataComponent={<NoData />}
        persistTableHead={true}
        progressPending={isLoading}
        progressComponent={<Spinner />}
        pagination={true}
      />
    </div>
  );
};
