import React, { FC } from 'react';
import ReactGA from 'react-ga';

import { USER_TYPES } from 'Shared/types/shared';
import {
  useDownloadAppList,
  DownloadAppListProps,
} from 'Shared/components/downloadAppList/useDownloadAppList';
import downloadAppStoreImage from 'Shared/src/images/downloadAppStore.png';
import downloadPlayStoreImage from 'Shared/src/images/downloadPlayStore.png';
import downloadAmazonAppsImage from 'Shared/src/images/downloadAmazonApps.png';

import styles from './index.module.scss';

export const DownloadAppList: FC<DownloadAppListProps> = ({ userType = USER_TYPES.PATIENT }) => {
  const { appStoreLink, playStoreLink, amazonLink, handleOutboundClick } = useDownloadAppList({
    userType,
  });

  return (
    <ul className={styles.downloadAppList}>
      <li className={styles.item}>
        <ReactGA.OutboundLink
          eventLabel="downloadFrom:appStore"
          to={appStoreLink}
          onClick={() => {
            handleOutboundClick(appStoreLink);
          }}
        >
          <img
            src={downloadAppStoreImage}
            alt="Download the Constant Therapy app on the App Store"
            title="Download the Constant Therapy app on the App Store"
          />
        </ReactGA.OutboundLink>
      </li>
      <li className={styles.item}>
        <ReactGA.OutboundLink
          eventLabel="downloadFrom:playStore"
          to={playStoreLink}
          onClick={() => {
            handleOutboundClick(playStoreLink);
          }}
        >
          <img
            src={downloadPlayStoreImage}
            alt="Download the Constant Therapy app on the Google Play"
            title="Download the Constant Therapy app on the Google Play"
          />
        </ReactGA.OutboundLink>
      </li>
      <li className={styles.item}>
        <ReactGA.OutboundLink
          eventLabel="downloadFrom:amazonStore"
          to={amazonLink}
          onClick={() => {
            handleOutboundClick(amazonLink);
          }}
        >
          <img
            src={downloadAmazonAppsImage}
            alt="Download the Constant Therapy app on the Amazon"
            title="Download the Constant Therapy app on the Amazon"
          />
        </ReactGA.OutboundLink>
      </li>
    </ul>
  );
};
