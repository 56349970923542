import React, { FC } from 'react';

import styles from './calendarInfo.module.scss';

export const CalendarInfo: FC = () => {
  return (
    <table className={styles.calendarInfo}>
      <thead>
        <tr>
          <th colSpan={2}>Home practice</th>
          <th className={styles.gap} />
          <th>In clinic</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={2}>
            <i className={styles.home} />
          </td>
          <td />
          <td>
            <i className={styles.clinic} />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div className={styles.legend}>
              <span>1 min</span>
              <span>30 min +</span>
            </div>
          </td>
          <td />
          <td />
        </tr>
      </tbody>
    </table>
  );
};
