import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressBook,
  faHospital,
  faUserMd,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { getDisplayName } from 'Shared/utils';
import type { User } from 'Shared/types/shared';

import styles from './welcome.module.scss';

const tilesList = [
  {
    title: 'Users',
    description: 'Add or manage users',
    icon: faAddressBook,
    url: '/enterprise-dashboard/users',
    isNewTab: false,
  },
  {
    title: 'Institution Settings',
    description: 'Update information about your company',
    icon: faHospital,
    url: '/enterprise-dashboard/institution',
    isNewTab: false,
  },
  {
    title: 'Clinician Dashboard',
    description: 'Manage your patients and keep track of their performance in a single place',
    icon: faUserMd,
    url: '/dashboard',
    isNewTab: true,
  },
  {
    title: 'My Account',
    description: 'Manage your administrator account',
    icon: faBriefcase,
    url: '/account',
    isNewTab: true,
  },
];

interface WelcomeProps {
  authUser: User;
}

export const Welcome: FC<WelcomeProps> = ({ authUser }) => {
  const displayName = getDisplayName({
    userObject: authUser,
    username: null,
    email: null,
  });

  return (
    <div className={styles.welcome}>
      <h1>Welcome, {displayName}</h1>
      <ul className={styles.actionsList}>
        {tilesList.map((tile) => {
          const children = (
            <>
              <FontAwesomeIcon icon={tile.icon} className={styles.icon} />
              <CTUI.Font.H3 customClasses={styles.title}>{tile.title}</CTUI.Font.H3>
              <CTUI.Font.Text2 customClasses={styles.description}>
                {tile.description}
              </CTUI.Font.Text2>
            </>
          );

          return (
            <li className={styles.item} key={tile.title}>
              {tile.isNewTab ? (
                <a href={tile.url} className={styles.link} target="_blank" rel="noreferrer">
                  {children}
                </a>
              ) : (
                <Link to={tile.url} className={styles.link}>
                  {children}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
