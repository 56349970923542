export enum AccuracyLatency {
  accuracy = 'accuracy',
  latency = 'latency',
}

export enum ColumnType {
  bySchedule = 'BySchedule',
  byDay = 'ByDay',
  byWeek = 'ByWeek',
}
