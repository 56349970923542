import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { FloatingPage } from 'Shared/components/layout/floatingPage';

import sharedStyles from '../shared.module.scss';
import styles from './thanks4Feedback.module.scss';

interface Thanks4FeedbackProps {
  token: string;
}

export const Thanks4Feedback: FC<Thanks4FeedbackProps> = ({ token }) => {
  const formValues = { difficulty: 'perfect', phase: '0' };
  // const formValues = useSelector(getFormValues(SURVEY_CAMPAIGN_FORM_NAME)) as SurveyCampaignForm;
  const title =
    formValues.difficulty === 'perfect'
      ? 'Thanks for your feedback. If you asked any questions, ' +
        "we'll follow up with you as soon as we can."
      : "Thanks for your feedback. We'll take a look at your program and make some adjustments.";

  if (!formValues.phase || formValues.phase === '0') {
    return (
      <FloatingPage
        customClasses={classNames(sharedStyles.surveyPage, styles.thanks4Feedback)}
        header={<h1 className="h1">{title}</h1>}
        footer={
          <p>
            <b>*Important note: </b>
            <span>
              To get this discount, you must subscribe using the button above; NOT through your
              device’s App store.
            </span>
          </p>
        }
      >
        <Link to={`/dashboard?token=${token}`}>Go to my Dashboard</Link>
        <span className={styles.separator}>OR</span>
        <Link to={'/subscribe?cc=HOPE'} className={styles.link}>
          <CTUI.Button text="SUBSCRIBE NOW* - SAVE 15%" variant="secondary-inverted" />
        </Link>
      </FloatingPage>
    );
  }

  return (
    <FloatingPage
      customClasses={classNames(sharedStyles.surveyPage, styles.thanks4Feedback)}
      header={<h1 className="h1">{title}</h1>}
    >
      <Link to={`/dashboard?token=${token}`} className={styles.link}>
        <CTUI.Button text="Go to my Dashboard" variant="secondary-inverted" />
      </Link>
    </FloatingPage>
  );
};
