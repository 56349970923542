import React, { FC } from 'react';
import classNames from 'classnames';

import { Spinner } from 'Shared/components/spinner';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { useDownload, DownloadProps } from './useDownload';

import styles from './index.module.scss';

export const Download: FC<DownloadProps> = ({ authUser }) => {
  useDownload({ authUser });

  return (
    <FloatingPage
      customClasses={styles.downloadPage}
      header={
        <h1 className={classNames('h1', styles.title)}>
          We&apos;re taking you to the correct location, please give us a moment.
        </h1>
      }
    >
      <Spinner />
    </FloatingPage>
  );
};
