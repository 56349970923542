import React, { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import styles from './fullscreenMobileMenu.module.scss';

interface FullscreenMobileMenuProps {
  className?: string;
  side?: 'left' | 'right';
  title: string;
  launcher: ReactNode;
  children: ReactNode;
}

export const FullscreenMobileMenu: FC<FullscreenMobileMenuProps> = ({
  className,
  side = 'left',
  title,
  launcher,
  children,
}) => {
  const [isShown, setIsShown] = useState(false);

  const toggleMenu = () => {
    setIsShown(!isShown);
  };

  return (
    <div className={classNames(styles.fullscreenMobileMenu, className)}>
      <span className={styles.launcher} onClick={toggleMenu}>
        {launcher}
      </span>
      <nav className={classNames(styles.menu, [styles[side]], { [styles.visible]: isShown })}>
        <header className={styles.header}>
          <h3 className="h3">{title}</h3>
          <span onClick={toggleMenu}>
            <FontAwesomeIcon icon={faClose} className={styles.icon} /> Close
          </span>
        </header>
        {children}
      </nav>
      <div
        className={classNames(styles.mobileWrapper, { [styles.visible]: isShown })}
        onClick={toggleMenu}
      />
    </div>
  );
};
