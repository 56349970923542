import queryString from 'query-string';
import { Dispatch } from 'redux';
import { ClinicianSetupFormShape } from 'App/activate/clinicianSetup/types';

import { fetchWrapper } from 'Shared/fetchWrapper';
import { getBaseUrl } from 'Shared/utils';
import {
  ACCEPT_INVITATION,
  GET_PASSWORD_REQUIREMENTS,
  CHANGE_PASSWORD,
  GET_USER_DATA,
  GET_USER_DATA_NA,
  GET_USER_SUBSCRIPTION,
  GET_UNSUBSCRIBE_REASONS,
  GET_USER_LOCATION,
  RESET_USER_PASSWORD,
  DO_NOT_CONTACT,
  CLEAR_USER_SUBSCRIPTION_CACHE,
  SAVE_USER_INFO,
} from 'Shared/types/redux';
import { USER_TYPES } from 'Shared/constants';
import { RootState } from 'Shared/types/shared';
import type { ChangePasswordForm } from 'Shared/types/forms';

export const getUserData = (userId?: number | string) => (dispatch: Dispatch) => {
  const formattedParams = queryString.stringify(
    {
      token: userId || localStorage.getItem('accessToken'),
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  return fetchWrapper.get(
    `${getBaseUrl({ apiSection: 'dashboard' })}/api/portal/user?${formattedParams}`,
    {
      dispatch,
      types: GET_USER_DATA,
    }
  );
};

/**
 * !!!NOTE: Temporary solution for getting user data w/o authentication
 */
export const getUserDataNoAuth = (userId: number | string) => (dispatch: Dispatch) => {
  const formattedParams = queryString.stringify(
    {
      token: userId || localStorage.getItem('accessToken'),
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  return fetchWrapper.get(
    `${getBaseUrl({ apiSection: 'dashboard' })}/api/portal/user?${formattedParams}`,
    {
      dispatch,
      types: GET_USER_DATA_NA,
    }
  );
};

/**
 * Verifies the token by making an API request to fetch user data.
 * Note: This is a 3rd wrapper over the same API-method.
 * The difference from the first two is:
 * - this function uses !only! token passed via url-params;
 * - skips token stored in the localStorage;
 * - skips user's authentication
 */
export const verifyToken = (token: string) => (dispatch: Dispatch) => {
  const formattedParams = queryString.stringify(
    {
      token,
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  return fetchWrapper.get(
    `${getBaseUrl({ apiSection: 'dashboard' })}/api/portal/user?${formattedParams}`,
    {
      dispatch,
      types: GET_USER_DATA_NA,
    },
    { skipAccessToken: true }
  );
};

export const getUserSubscription =
  ({ token, emailOrUsername }: { token?: string; emailOrUsername?: string } = {}) =>
  (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        emailOrUsername,
        token,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return fetchWrapper.get(
      `${getBaseUrl({ apiSection: 'dashboard' })}/api/portal/status/simple?${formattedParams}`,
      {
        dispatch,
        types: GET_USER_SUBSCRIPTION,
      },
      {
        skipParams: true,
      }
    );
  };

export const clearUserSubscriptionCache = () => (dispatch: Dispatch) => {
  return Promise.resolve(dispatch({ type: CLEAR_USER_SUBSCRIPTION_CACHE }));
};

export const changeUserPassword =
  (data: ChangePasswordForm & { userId: number | string }) => (dispatch: Dispatch) => {
    return fetchWrapper.put(
      `${getBaseUrl()}/api/users/${data.userId}/password`,
      {
        dispatch,
        types: CHANGE_PASSWORD,
      },
      {
        oldPassword: data.current_password,
        newPassword: data.new_password,
      },
      {
        isSubmission: true,
      }
    );
  };

export const getUnsubscribeReasons = () => (dispatch: Dispatch, getState: () => RootState) => {
  const formattedParams = queryString.stringify(
    {
      token: localStorage.getItem('accessToken'),
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  if (getState().user && getState().user.unsubscribeReasons) {
    dispatch({ type: GET_UNSUBSCRIBE_REASONS.start });

    dispatch({
      type: GET_UNSUBSCRIBE_REASONS.success,
      payload: getState().user.unsubscribeReasons,
    });

    return Promise.resolve(getState().user.unsubscribeReasons);
  }

  return fetchWrapper.get(
    `${getBaseUrl()}/api/clinician/discharge/discontinue/reasons?${formattedParams}`,
    {
      dispatch,
      types: GET_UNSUBSCRIBE_REASONS,
    }
  );
};

export const getUserLocation =
  ({ token, emailOrUsername }: { token?: string; emailOrUsername?: string }) =>
  (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        emailOrUsername: emailOrUsername,
        token: token,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return fetchWrapper.get(
      `${getBaseUrl({ apiSection: 'dashboard' })}/api/portal/userlocation?${formattedParams}`,
      {
        dispatch,
        types: GET_USER_LOCATION,
      }
    );
  };

export const resetPassword =
  ({ username }: { username: string }) =>
  (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        email: (username.includes('@') && username) || null,
        username: (!username.includes('@') && username) || null,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return fetchWrapper.get(
      `${getBaseUrl()}/api/user/forgotpassword?${formattedParams}`,
      {
        dispatch,
        types: RESET_USER_PASSWORD,
      },
      { isSubmission: true }
    );
  };

export const acceptInvitation = (activationToken: string) => (dispatch: Dispatch) => {
  return fetchWrapper.patch(
    `${getBaseUrl({ apiSection: 'license' })}/api/invitations/${activationToken}`,
    {
      dispatch,
      types: ACCEPT_INVITATION,
    }
  );
};

/**
 * NOT TO BE CONFUSED BY `getPasswordRequirements` from `institution` actions!
 * Gets password requirements for every particular user
 */
export const getPasswordRequirements =
  ({ userId }: { userId: number }) =>
  (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return fetchWrapper.get(
      `${getBaseUrl()}/api/users/${userId}/password/requirements?${formattedParams}`,
      {
        dispatch,
        types: GET_PASSWORD_REQUIREMENTS,
      }
    );
  };

export const doNotContact =
  ({ token }: { token: string }) =>
  (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token,
        type: USER_TYPES.PATIENT,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return fetchWrapper.get(
      `${getBaseUrl({ apiSection: 'dashboard' })}/api/portal/noemail?${formattedParams}`,
      {
        dispatch,
        types: DO_NOT_CONTACT,
      }
    );
  };

export const saveUserInfo =
  ({ userId, data }: { userId: number; data: ClinicianSetupFormShape }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return await fetchWrapper.post(
      `${getBaseUrl()}/api/user/info/${userId}?${formattedParams}`,
      {
        dispatch,
        types: SAVE_USER_INFO,
      },
      data,
      { isSubmission: true }
    );
  };
