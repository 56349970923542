import React, { Dispatch, FC, SetStateAction } from 'react';

import styles from './radioGroup.module.scss';

interface RadioGroupProps {
  checkedValue: string;
  setIsChecked: Dispatch<SetStateAction<string>>;
  radioData: {
    value: string;
    label: string;
    name: string;
  }[];
}

export const RadioGroup: FC<RadioGroupProps> = ({ checkedValue, setIsChecked, radioData }) => {
  return (
    <React.Fragment>
      {radioData.map((item) => {
        return (
          <label className={styles.radioButton} key={item.value}>
            <input
              type="radio"
              value={item.value}
              name={item.name}
              checked={item.value === checkedValue}
              onChange={() => setIsChecked(item.value)}
            />
            {item.label}
          </label>
        );
      })}
    </React.Fragment>
  );
};
