import { useCallback, useContext } from 'react';
import { useDrag } from 'react-dnd';

import { AudioContext } from 'Shared/context/audioContext';
import { STIMULUS_CONTENT_TYPES } from 'Shared/types/shared';

import type { EnhancedChoice } from '../types';

export interface ChoiceProps {
  index: number;
  choice: EnhancedChoice;
  resourceUrl: string;
}

export function useChoice({ index, choice, resourceUrl }: ChoiceProps) {
  const { playAudioSequence, stopAudioSequence } = useContext(AudioContext);

  const handleClick = useCallback(() => {
    if (choice.playSoundOnFirstTap) {
      stopAudioSequence();
      playAudioSequence({
        audioElementsList: [new Audio(resourceUrl + choice.stimulus.audioPaths[0])],
      });
    }
  }, [choice]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: choice.value,
      item: {
        index,
        name: choice.value,
        contentType: choice.stimulus.contentType,
        contentValue:
          choice.stimulus.contentType === STIMULUS_CONTENT_TYPES.IMAGE
            ? resourceUrl + choice.stimulus.imagePath
            : choice.stimulus.text,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [choice]
  );

  return {
    drag,
    isDragging,
    handleClick,
  };
}
