import React, { FC } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import isempty from 'lodash.isempty';

import { PERFORMANCE_REPORT_SECTIONS_MAP } from 'Shared/constants';
import {
  PerformanceReportAssessment,
  PerformanceReportDomain,
  PerformanceReportTask,
} from 'Shared/types/shared';

import { SkillAreas } from './sections/skillAreas';
import { Therapies } from './sections/therapies';
import { Assessments } from './sections/assessments';

import './reportSections.scss';

interface ReportSectionsProps {
  canViewExpandedDashboard: boolean;
  sections: {
    assessments?: PerformanceReportAssessment[];
    domains?: {
      [key: string]: PerformanceReportDomain;
    };
    tasks?: {
      [key: string]: PerformanceReportTask;
    };
  };
}

export const ReportSections: FC<ReportSectionsProps> = ({
  canViewExpandedDashboard,
  sections: { domains: skillAreas, tasks: therapies, assessments },
}) => {
  const { path } = useRouteMatch();
  //TODO: Rewrite this dummy logic
  let redirectPath = path + PERFORMANCE_REPORT_SECTIONS_MAP.domains.link;
  if (isempty(skillAreas)) {
    redirectPath = path + PERFORMANCE_REPORT_SECTIONS_MAP.tasks.link;
    if (isempty(therapies)) {
      redirectPath = path + PERFORMANCE_REPORT_SECTIONS_MAP.assessments.link;
    }
  }

  return (
    <Switch>
      <Redirect exact from={path} to={redirectPath} />
      <Route path={path + PERFORMANCE_REPORT_SECTIONS_MAP.domains.link}>
        <SkillAreas skillAreas={skillAreas} canViewExpandedDashboard={canViewExpandedDashboard} />
      </Route>
      <Route path={path + PERFORMANCE_REPORT_SECTIONS_MAP.tasks.link}>
        <Therapies therapies={therapies} />
      </Route>
      <Route path={path + PERFORMANCE_REPORT_SECTIONS_MAP.assessments.link}>
        <Assessments assessments={assessments} />
      </Route>
    </Switch>
  );
};
