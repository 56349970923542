import { ClinicianDashboardPatientBrief, ClinicianDashboardStatusType } from 'Shared/types/shared';

export interface DataRow extends ClinicianDashboardPatientBrief {
  status: ClinicianDashboardStatusType;
}

export interface UsePatientsListTableProps {
  Patients: ClinicianDashboardPatientBrief[];
  StatusTypes: ClinicianDashboardStatusType[];
  searchText: string;
}

export function usePatientsListTable({
  Patients,
  StatusTypes,
  searchText,
}: UsePatientsListTableProps) {
  let data: DataRow[] = Patients.map((patient) => {
    return {
      ...patient,
      status: StatusTypes.find((status) => status.statusLabel === patient.statusLabel),
    };
  });

  if (searchText.length) {
    data = data.filter((item) => {
      return (
        item.patientUsername.toLowerCase().includes(searchText.toLowerCase()) ||
        item.statusLabel.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }

  return {
    data,
  };
}
