import React, { FC, SetStateAction, Dispatch } from 'react';
import { isMobile } from 'react-device-detect';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { TherapySessionResponse } from 'Shared/types/shared';

import styles from './chart.module.scss';
import colors from '../../../colors.module.scss';

interface ChartProps {
  therapySessionResponses: TherapySessionResponse[];
  setSelectedResponse: Dispatch<SetStateAction<TherapySessionResponse>>;
}

interface ResponsesBarChartData {
  responseId: number;
  accuracy: number;
  x: string;
  y: number;
}

export const Chart: FC<ChartProps> = ({ therapySessionResponses, setSelectedResponse }) => {
  const sortedResponses = therapySessionResponses.sort(
    (responseA, responseB) => responseA.startTime - responseB.startTime
  );

  return (
    <div className={styles.chart}>
      <p className={styles.title}>Items Completed</p>
      <div className={styles.legend}>
        <span className={classNames(styles.legendItem, styles.correct)}>Correct</span>
        <span className={classNames(styles.legendItem, styles.wrong)}>Incorrect</span>
      </div>
      <CTUI.BarChart
        data={sortedResponses.map((response): ResponsesBarChartData => {
          return {
            responseId: response.responseId,
            accuracy: response.accuracy,
            x: response.startTime.toString(),
            y: response.latency,
          };
        })}
        options={{
          width: isMobile ? 200 : 300,
          height: 200,
          marginTop: 30,
          marginLeft: 45,
          xName: 'time',
          yName: '↑ Latency, sec',
          xTickFormat: (value: string) => {
            return moment(value, 'X').format('HH:mm');
          },
          yTickFormat: (value: number) => {
            return `${Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value)} sec`;
          },
          fillColor: (item: ResponsesBarChartData) => {
            if (item.accuracy) {
              return colors.highColor;
            }
            return colors.mediumColor;
          },
          barClickHandler: (e, d: ResponsesBarChartData) => {
            setSelectedResponse(
              therapySessionResponses.find((response) => {
                return response.responseId === d.responseId;
              })
            );
          },
          barHoverColor: colors.barHoverColor,
        }}
      />
      <p className={styles.hint}>Click on Bar Chart to see which task your client worked on</p>
    </div>
  );
};
