import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';

interface WatchModalProps {
  modalRef: React.Ref<HTMLDivElement>;
  hideModal: () => void;
  categoryInstructionVideoPath: string;
}

export const WatchModal: FC<WatchModalProps> = ({
  modalRef,
  hideModal,
  categoryInstructionVideoPath,
}) => {
  return (
    <CTUI.Modal ref={modalRef} closeButtonHandler={hideModal} size={CTUI.ModalSizes.LARGE}>
      <video controls={true} width="100%">
        <source
          src={`https://ct-res.s3.amazonaws.com/${categoryInstructionVideoPath}`}
          type="video/mp4"
        />
      </video>
    </CTUI.Modal>
  );
};
