import React, { FC, SyntheticEvent, useState } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { UnsubscribeReasons } from 'Shared/types/shared';

import { UNSUBSCRIBE_FORM_NAME } from '../constants';

import styles from './form.module.scss';

interface UnsubscribeFormComponentProps {
  unsubscribeReasons: UnsubscribeReasons;
}

const UnsubscribeFormComponent: FC<
  UnsubscribeFormComponentProps & InjectedFormProps<object, UnsubscribeFormComponentProps>
> = ({ handleSubmit, error, pristine, submitting, unsubscribeReasons }) => {
  const [shouldShowReasonOther, setShouldShowReasonOther] = useState(false);
  const handleReasonChange = (e: SyntheticEvent, newValue: string) => {
    setShouldShowReasonOther(newValue === 'Other');
  };
  const options = unsubscribeReasons.reasons.map(({ key, value }) => (
    <option key={key} value={value}>
      {value}
    </option>
  ));
  options.unshift(
    <option key="0" value="" disabled>
      Select an option
    </option>
  );

  return (
    <CTUI.Form.Form
      customClasses={styles.unsubscribeForm}
      error={error}
      handleSubmit={handleSubmit}
      actions={
        <CTUI.Form.SubmitButton
          isSubmitting={submitting}
          isPristine={pristine}
          customClasses={styles.button}
          text="Cancel Subscription"
        />
      }
    >
      <>
        <Field
          name="reason"
          component={CTUI.Form.Select}
          type="select"
          label="Please tell us why you would like to cancel your subscription:"
          options={options}
          value=""
          required
          autofocus
          onChange={handleReasonChange}
        />
        {shouldShowReasonOther && (
          <Field
            name="reasonOTHER"
            component={CTUI.Form.Textarea}
            type="textarea"
            label="Can you be more specific?"
            required
            rows={5}
            cols={30}
          />
        )}
      </>
    </CTUI.Form.Form>
  );
};

const UnsubscribeForm = reduxForm<object, UnsubscribeFormComponentProps>({
  form: UNSUBSCRIBE_FORM_NAME,
})(UnsubscribeFormComponent);

export { UnsubscribeForm };
