import React, { FC } from 'react';
import classNames from 'classnames';

import type { TextStimulus } from 'Shared/types/shared';

import sharedStyles from './shared.module.scss';
import styles from './text.module.scss';

interface TextComponentProps {
  stimulus: TextStimulus;
}

export const TextComponent: FC<TextComponentProps> = ({ stimulus }) => {
  return (
    <div className={classNames(sharedStyles.stimulus, styles.text)}>
      <h2 className="h2 medium">{stimulus.text}</h2>
    </div>
  );
};
