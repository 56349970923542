import { useRef, useState } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

import { TaskType } from 'Shared/types/task';

interface UseAllTasksModalProps {
  modalCloseHandler: () => Promise<void>;
}

export function useAllTasksModal({ modalCloseHandler }: UseAllTasksModalProps) {
  const modalRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState<TaskType[]>([]);

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      void modalCloseHandler();
    }
  });

  function toggleItemSelection(item: TaskType) {
    setSelectedItems((prevSelectedItems: TaskType[]) => {
      const nextSelectedItems = [...prevSelectedItems];
      if (prevSelectedItems.includes(item)) {
        return nextSelectedItems.filter(
          (prevSelectedItem) => prevSelectedItem.typeId !== item.typeId
        );
      } else {
        return nextSelectedItems.concat(item);
      }
    });
  }

  return { modalRef, selectedItems, toggleItemSelection };
}
