import React, { FC } from 'react';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { STIMULUS_CONTENT_TYPES, type TaskViewMemorySequence } from 'Shared/types/shared';

import { type TaskTypeProps } from '../../types';

import { TextComponent } from './stimulus/text';
import { ImageComponent } from './stimulus/image';
import { AudioComponent } from './stimulus/audio';
import { useMemorySequence } from './useMemorySequence';

import sharedStyles from '../index.module.scss';
import styles from './index.module.scss';

export const MemorySequence: FC<TaskTypeProps<TaskViewMemorySequence>> = ({
  task,
  isAnswered,
  handleAnswer,
  isInitialAudioInstructionsShouldBePlayed,
}) => {
  const {
    activeItemKey,
    isStarted,
    handleStartClick,
    handleItemClick,
    animationStyles,
    isClickedCorrect,
    isClickedWrong,
  } = useMemorySequence({
    task,
    handleAnswer,
  });
  return (
    <div className={classNames(sharedStyles.taskType, styles.memorySequence)}>
      <ul className={styles.itemsList}>
        {task.sequence.map((item, key) => {
          return (
            <li
              key={key}
              className={classNames(styles.item, {
                [styles.visible]: activeItemKey === key,
                [styles.correct]: isClickedCorrect,
                [styles.wrong]: isClickedWrong,
              })}
              style={activeItemKey === key ? animationStyles : {}}
              onClick={() => handleItemClick(key)}
            >
              {item.stimulus.contentType === STIMULUS_CONTENT_TYPES.TEXT && (
                <TextComponent stimulus={item.stimulus} />
              )}
              {item.stimulus.contentType === STIMULUS_CONTENT_TYPES.IMAGE && (
                <ImageComponent stimulus={item.stimulus} resourceUrl={task.resourceUrl} />
              )}
              {item.stimulus.contentType === STIMULUS_CONTENT_TYPES.AUDIO && (
                <AudioComponent
                  stimulus={item.stimulus}
                  resourceUrl={task.resourceUrl}
                  isActive={isStarted && activeItemKey === key}
                />
              )}
            </li>
          );
        })}
      </ul>
      {!isStarted && (
        <CTUI.Button
          text="Tap to Start"
          onClick={handleStartClick}
          customClasses={styles.startButton}
        />
      )}
    </div>
  );
};
