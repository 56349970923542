import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { passwordMatch } from 'Shared/validators';
import { PasswordRequirements } from 'Shared/types/shared';
import { CHANGE_PASSWORD_FORM_NAME } from 'Shared/types/forms';

import styles from './changePasswordForm.module.scss';

interface ChangePasswordFormComponentProps {
  passwordRequirements: PasswordRequirements;
}

const ChangePasswordFormComponent: FC<
  ChangePasswordFormComponentProps & InjectedFormProps<object, ChangePasswordFormComponentProps>
> = ({ error, handleSubmit, pristine, submitting, passwordRequirements }) => {
  return (
    <CTUI.Form.Form
      handleSubmit={handleSubmit}
      customClasses={styles.changePasswordForm}
      error={error}
      actions={
        <CTUI.Form.SubmitButton
          isSubmitting={submitting}
          isPristine={pristine}
          variant="primary-inverted"
          text="Change Password"
          customClasses={styles.submit}
        />
      }
    >
      <Field
        name="current_password"
        label="Current password"
        component={CTUI.Form.Password}
        type="password"
        required
      />
      <Field
        name="new_password"
        label="New password"
        component={CTUI.Form.Password}
        type="password"
        required
        passwordRequirements={passwordRequirements}
      />
      <Field
        name="new_password_match"
        label="Repeat password"
        component={CTUI.Form.Password}
        type="password"
        validate={[passwordMatch]}
        required
      />
      <CTUI.Font.Text customClasses={styles.hint}>
        * Once your password is changed you will be taken to the Dashboard
      </CTUI.Font.Text>
    </CTUI.Form.Form>
  );
};

const ChangePasswordForm = reduxForm<object, ChangePasswordFormComponentProps>({
  form: CHANGE_PASSWORD_FORM_NAME,
})(ChangePasswordFormComponent);

export { ChangePasswordForm };
