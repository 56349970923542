import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { Link } from 'react-router-dom';
// eslint-disable-next-line max-len
import { useSubscribe2Unlock } from 'App/dashboard/patientDashboard/report/reportDetails/sections/skillAreas/useSubscribe2Unlock';

// eslint-disable-next-line max-len
import sectionDomainsSampleImage from 'Shared/src/images/performanceReport/section.domains.sample.png';

import styles from './subscribe2Unlock.module.scss';

export const Subscribe2Unlock: FC = () => {
  const { modalRef, shouldShowModal, modalCloseHandler } = useSubscribe2Unlock();

  return (
    <div className={styles.subscribe2unlock}>
      {shouldShowModal && (
        <CTUI.Modal
          ref={modalRef}
          id={styles.subscribe2unlockModal}
          footerClasses={styles.modalFooter}
          closeButtonHandler={modalCloseHandler}
          footer={
            <Link to="/subscribe?source=patients_report_trial" className={styles.link}>
              <CTUI.Button text="View subscription plans" />
            </Link>
          }
        >
          <p>Subscribe to unlock your full progress report.</p>
        </CTUI.Modal>
      )}
      <img
        src={sectionDomainsSampleImage}
        alt="Subscribe to unlock your skill areas report section"
        title="Subscribe to unlock your skill areas report section"
      />
    </div>
  );
};
