import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import md5 from 'md5';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ClinicianDashboardPatientExpansionDomain } from 'Shared/types/shared';

import { ChartScanty } from './scanty';
import { ChartEmpty } from './empty';

import sharedStyles from '../widget.module.scss';
import styles from './index.module.scss';

interface ExpandableRowChartProps {
  patientId: number;
  skillAreas: ClinicianDashboardPatientExpansionDomain[];
}

export const ExpandableRowChart: FC<ExpandableRowChartProps> = ({ patientId, skillAreas }) => {
  const chartClasses = classNames(sharedStyles.widget, styles.expandableRowChart);
  const sortedAreas = [...skillAreas].sort(
    (first, second) => first.radarDisplayOrder - second.radarDisplayOrder
  );

  return (
    <div className={chartClasses}>
      <h4 className="h4">Home Skill Areas</h4>
      {sortedAreas.length > 2 && (
        <CTUI.BrainFingerprint skillAreas={sortedAreas} customClasses={styles.chartWrapper} />
      )}
      {sortedAreas.length <= 2 && sortedAreas.length > 0 && (
        <ChartScanty skillAreas={sortedAreas} />
      )}
      {sortedAreas.length === 0 && <ChartEmpty />}
      <Link
        to={`/dashboard/patient/${md5(patientId.toString())}/report`}
        title="Review Performance"
        className={classNames('yeahBabe', sharedStyles.link)}
      >
        Review Performance{' '}
        <FontAwesomeIcon icon={faChevronRight} className={sharedStyles.chevronRightIcon} />
      </Link>
    </div>
  );
};
