import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const Meta: FC = () => {
  const content = `We'll miss you! Navigate to our Unsubscribe page to 
  end your subscription and leave feedback that will help us improve. We'll be here to welcome 
  you back if you decide to rejoin!`;
  return (
    <Helmet>
      <title>Unsubscribe | Constant Therapy</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};
