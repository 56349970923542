import React, { FC } from 'react';
import moment from 'moment-timezone';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import { convertDatetimeToUTC, getDomainsKey } from 'Shared/utils';
import { PerformanceReportDomain } from 'Shared/types/shared';

import itemStyles from './item.module.scss';

interface PerformanceAndDischargeDomainItemProps {
  domain: PerformanceReportDomain;
}

export const PerformanceAndDischargeDomainItem: FC<PerformanceAndDischargeDomainItemProps> = ({
  domain,
}) => {
  return (
    <li className={itemStyles.item}>
      <header className={itemStyles.header}>
        <CTUI.Font.H4 variant="medium" id={getDomainsKey(domain.displayName)}>
          {domain.displayName} overview:
        </CTUI.Font.H4>
        <CTUI.Font.Text customClasses={itemStyles.description}>
          Description: {domain.description}
        </CTUI.Font.Text>
      </header>
      <table className={itemStyles.data}>
        <caption>
          <CTUI.Font.H4 variant="medium" customClasses={itemStyles.caption}>
            Performance:
          </CTUI.Font.H4>
        </caption>
        <thead>
          <tr>
            <th>
              <CTUI.Font.Text variant="medium">Metric (baseline)</CTUI.Font.Text>
            </th>
            <th>
              <CTUI.Font.Text variant="medium">Metric (latest)</CTUI.Font.Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <CTUI.Font.Text>Domain score: {domain.baseline.value}</CTUI.Font.Text>
            </td>
            <td>
              <CTUI.Font.Text>Domain score: {domain.latest.value}</CTUI.Font.Text>
            </td>
          </tr>
          <tr>
            <td>
              <CTUI.Font.Text>
                Date:{' '}
                {moment(convertDatetimeToUTC(domain.baseline.date.dateTime.date), 'X').format(
                  'MM/DD/YYYY'
                )}
              </CTUI.Font.Text>
            </td>
            <td>
              <CTUI.Font.Text>
                Date:{' '}
                {moment(convertDatetimeToUTC(domain.latest.date.dateTime.date), 'X').format(
                  'MM/DD/YYYY'
                )}
              </CTUI.Font.Text>
            </td>
          </tr>
          <tr>
            <td>
              <CTUI.Font.Text>
                Demonstrated ability level: {domain.baseline.description}
              </CTUI.Font.Text>
            </td>
            <td>
              <CTUI.Font.Text>
                Demonstrated ability level: {domain.latest.description}
              </CTUI.Font.Text>
            </td>
          </tr>
        </tbody>
      </table>
    </li>
  );
};
