import React, { FC } from 'react';
import isempty from 'lodash.isempty';

import { Spinner } from 'Shared/components/spinner';

import { instanceOfPerformanceReport } from '../../shared/reportUtils';

import { EmptyReport } from './emptyReport';
import { ReportSummary } from './reportSummary';
import { ReportDetails } from './reportDetails';
import { ErrorReport } from './errorReport';
import { useReport, ReportProps } from './useReport';

import styles from './index.module.scss';

export const Report: FC<ReportProps> = ({ surveillanceMode = false, patient }) => {
  const { isLoading, isEmptyReport, canViewExpandedDashboard, report, reportError } = useReport({
    patient,
    surveillanceMode,
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmptyReport) {
    return <EmptyReport surveillanceMode={surveillanceMode} />;
  }

  if (reportError || !instanceOfPerformanceReport(report)) {
    return <ErrorReport reportError={reportError} />;
  }

  return (
    <div className={styles.report}>
      <ReportSummary summary={report.summary} />
      <ReportDetails
        sections={Object.fromEntries(
          Object.entries(report.sections).filter(([_, value]) => !isempty(value))
        )}
        canViewExpandedDashboard={canViewExpandedDashboard}
      />
    </div>
  );
};
