import { useRef } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

interface UseActiveScheduleTasksModalProps {
  modalCloseHandler: () => Promise<void>;
}

export function useActiveScheduleTasksModal({
  modalCloseHandler,
}: UseActiveScheduleTasksModalProps) {
  const modalRef = useRef(null);

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      void modalCloseHandler();
    }
  });

  return { modalRef };
}
