import { useCallback, useContext } from 'react';

import { AudioPlayerStimulus, AudioStimulus } from 'Shared/types/shared';
import { AudioContext } from 'Shared/context/audioContext';

export interface AudioContentProps {
  stimulus: AudioStimulus | AudioPlayerStimulus;
  resourceUrl: string;
}

export function useAudio({ stimulus, resourceUrl }: AudioContentProps) {
  const { isPlaying, playAudioSequence, stopAudioSequence } = useContext(AudioContext);

  const handlePlayAudioSequenceClick = useCallback(() => {
    if (isPlaying) {
      stopAudioSequence();
    }
    playAudioSequence({
      audioElementsList: stimulus.audioPaths.map((instructionPath) => {
        return new Audio(resourceUrl + instructionPath);
      }),
    });
  }, [stimulus, isPlaying]);

  return {
    handleAudioCuePlay: handlePlayAudioSequenceClick,
  };
}
