import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNoteSticky, faCalculator } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { useControls, type ControlsProps } from './useControls';
import { SketchPad } from './sketchPad';
import { Calculator } from './calculator';

import styles from './index.module.scss';

export const Controls: FC<ControlsProps> = ({ showScratchpad, showCalculator }) => {
  const { activeTab, handleSwitchActiveTab } = useControls({ showScratchpad, showCalculator });

  return (
    <div className={styles.controls}>
      {showScratchpad && showCalculator && (
        <ul className={styles.tabs}>
          <li>
            <button
              className={classNames(styles.tab, activeTab === 1 && styles.activeTab)}
              onClick={handleSwitchActiveTab}
              value={1}
              title="Open Scratchpad"
            >
              <FontAwesomeIcon icon={faNoteSticky} />
            </button>
          </li>
          <li>
            <button
              className={classNames(styles.tab, activeTab === 2 && styles.activeTab)}
              onClick={handleSwitchActiveTab}
              value={2}
              title="Open Calculator"
            >
              <FontAwesomeIcon icon={faCalculator} />
            </button>
          </li>
        </ul>
      )}
      {showScratchpad && activeTab === 1 && <SketchPad />}
      {showCalculator && activeTab === 2 && <Calculator />}
    </div>
  );
};
