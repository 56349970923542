import { useRef } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

interface UseInvitationPopupProps {
  hidePopup: () => void;
}

export function useInvitationPopup({ hidePopup }: UseInvitationPopupProps) {
  const modalRef = useRef();

  useClickOutside(modalRef, hidePopup);

  return {
    modalRef,
  };
}
