import React, { FC } from 'react';

import { PerformanceReportDomain } from 'Shared/types/shared';

import { ChartPointer } from './chartPointer';
import { ChartData } from './chartData';
import { ChartScale } from './chartScale';
import { ChartLegend } from './chartLegend';

import styles from './chart.module.scss';

interface ChartProps {
  skillArea: PerformanceReportDomain;
}

export const Chart: FC<ChartProps> = ({ skillArea }) => {
  return (
    <div className={styles.chart}>
      <ChartPointer skillArea={skillArea} />
      <ChartData skillArea={skillArea} />
      <ChartScale />
      <ChartLegend skillArea={skillArea} />
    </div>
  );
};
